import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import queryString from 'query-string';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/th';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';
import { initialFetchCars } from '../../../ducks/actions/cars';
import SelectPicker from '../../organisms/SelectPicker';
import DateTimePicker from '../../organisms/DateTimePicker';
import Button from '../../atoms/Button';
import { getMomentFromTime as getMoment, timeSlot, compareTime, getLocations, currentTime, isSameDay } from './util';
import { dataLayer, pushDataLayer, pushTrackEvent } from '@utils/datalayer';

moment.locale('th'); // TODO:

const START_DATE = 'startDate';
const END_DATE = 'endDate';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
      searchResult: [],
      isOpenSearchBarMobile: false,

      // SelectPicker
      locationName: '',
      locationID: this.props.location_id,
      focused: false,
      isLoadingLocation: false,

      //DateTimePicker
      startDate: this.initialDate(this.props.pickup_datetime, 1),
      endDate: this.initialDate(this.props.return_datetime, 2),
      focusedInput: null,
      startDateFocused: false,
      endDateFocused: false,
      pickupTime: this.initialTime(this.props.pickup_datetime),
      returnTime: this.initialTime(this.props.return_datetime),
      showGreyScreen: false
    };
  }

  componentDidMount() {
    this.getLocationsAndInitLocationName();
    this.initialPicker();
  }
  initialPicker = () => {
    const { query } = queryString.parseUrl(window.location.href);
    if (query.openCalendar === 'true') {
      return this.setState(
        {
          startDateFocused: true,
          endDateFocused: false,
          focusedInput: START_DATE,
          showGreyScreen: true,
          isOpenSearchBarMobile: true
        },
        () => this.initialDateTime(true, false)
      );
    }
    return this.initialDateTime(true, false);
  };

  initialDate = (date, addDays) => {
    const _date = moment(date, 'YYYY-MM-DD');

    if (date === undefined || !_date.isValid()) {
      return moment().add(addDays, 'days');
    } else {
      return _date;
    }
  };

  initialTime = (time) => {
    const _time = moment(time);

    if (time === undefined || !_time.isValid()) {
      return '10:00';
    } else {
      return _time.format('HH:mm');
    }
  };

  getSelectableTime = (time) => {
    const times = timeSlot();
    return times.filter((t) => compareTime(t.value, time))[0];
  };

  getPickupTime = (base, compare) => {
    const momentA = getMoment(base);
    const momentB = getMoment(compare);
    const latestTime = momentA.isAfter(momentB) ? base : compare;
    return this.getSelectableTime(latestTime);
  };

  initialDateTime = (isInit = false, adjastReturnTime = true) => {
    const state = Object.assign({}, this.state);
    const { startDate, endDate, pickupTime } = this.state;
    if (isInit) {
      if (isSameDay(startDate, moment())) {
        const pickupTime = this.getPickupTime(startDate.format('HH:mm'), moment().format('HH:mm'));
        if (compareTime(this.state.pickupTime, pickupTime.value)) {
          state.pickupTime = this.state.pickupTime;
        } else {
          state.pickupTime = pickupTime.value;
          if (!isSameDay(startDate, endDate)) {
            state.returnTime = pickupTime.value;
          }
        }
      } else {
        if (adjastReturnTime) {
          state.returnTime = pickupTime;
        }
      }
    }
    if (isSameDay(startDate, endDate)) {
      const nextFourHours = getMoment(pickupTime)
        .add(4, 'hours')
        .format('HH:mm');
      if (compareTime(pickupTime, nextFourHours)) {
        state.endDate = moment(endDate.format()).add(1, 'day');
        state.returnTime = pickupTime;
      } else {
        state.returnTime = nextFourHours;
      }
    }
    this.setState(state);
  };

  getLocationsAndInitLocationName = () => {
    this.setState({ isLoadingLocation: true });

    getLocations()
      .then((locations) => {
        const converted = [];
        locations.forEach((city) => {
          city.locations.forEach((pickupPoint) => {
            converted.push({
              ...pickupPoint,
              city: {
                id: city.id,
                name: city.name
              }
            });
          });
        });
        this.props.fetchedLocation(converted.sort((a, b) => a.id - b.id));
        for (const i in locations) {
          if (locations[i].id === 28) {
            delete locations[i];
          }
        }
        this.setState({
          locations,
          isLoadingLocation: false
        });
      })
      .then(() => {
        this.setState({
          locationName: this._InitLocation(this.state.locationID)
        });
      })
      .catch((e) => console.log('error: ' + e));
  };

  setStartDateFocused = () => {
    this.setState({
      startDateFocused: !this.state.startDateFocused,
      endDateFocused: false,
      focusedInput: START_DATE
    });
  };

  setEndDateFocused = () => {
    this.setState({
      endDateFocused: !this.state.endDateFocused,
      startDateFocused: false,
      focusedInput: END_DATE
    });
  };

  onFocusChange = (focusedInput) => {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput
    });
  };

  dismissAll = () => {
    this.setState({
      startDateFocused: false,
      endDateFocused: false,
      showGreyScreen: false
    });
  };

  handleClick = () => {
    if (this.state.focused) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
  };

  handleOutsideClick = (e) => {
    if (this.SelectPicker && this.SelectPicker.contains(e.target)) {
      return;
    }
    this.handleClick();
    this.setState({
      endDateFocused: false,
      focused: false
    });
  };

  onDateChange = ({ startDate, endDate }) => {
    const state = { startDate, endDate };
    this.setState(state, () => {
      this.initialDateTime(true);
    });
    if (startDate && endDate && this.state.focusedInput === END_DATE) {
      setTimeout(() => {
        this.dismissAll();
      }, 500);
    }
  };

  setPickUpTime = (event) => {
    const value = event.target.value;
    this.setState({ pickupTime: value, returnTime: value }, () => this.initialDateTime());
  };

  setReturnTime = (event) => {
    this.setState({ returnTime: event.target.value });
  };

  _InitLocation = (LocationID) => {
    let location = '';
    this.state.locations.every((item) => {
      return item.locations.every((pickup) => {
        if (parseInt(pickup.dh_location_code, 10) === parseInt(LocationID, 10)) {
          location = pickup.name.th;
          return false;
        }
        return true;
      });
    });

    return location;
  };

  _onSubmit = (event) => {
    const { returnTime, pickupTime, startDate, endDate, locationID } = this.state;
    const { widget, submitButtonAction } = this.props;

    event.preventDefault();
    if (!widget) {
      if (returnTime && pickupTime && startDate && endDate && locationID) {
        const pickup_datetime = `${moment(startDate).format('YYYY-MM-DD')} ${pickupTime}`;
        const return_datetime = `${moment(endDate).format('YYYY-MM-DD')} ${returnTime}`;

        this.props.initialFetchCars(locationID, pickup_datetime, return_datetime);
      } else {
        pushTrackEvent('bookings_section', 'search_bookings_fail', 'from_search_result_page');
        alert('โปรดระบุข้อมูลให้ครบถ้วน');
      }
    } else {
      const pickup_datetime = moment(startDate).format(`YYYY-MM-DD ${pickupTime}`);
      const return_datetime = moment(endDate).format(`YYYY-MM-DD ${returnTime}`);
      submitButtonAction(pickup_datetime, return_datetime);
    }
    const dataArgs = {
      ...this.dataLayerArgs,
      search_pickup_date: moment(startDate)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_pickup_time: pickupTime + ':00',
      search_return_date: moment(endDate)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_return_time: returnTime + ':00'
    };
    pushDataLayer(dataArgs);
    this.onToggleModalMobile();
  };

  onToggleModalMobile = () => {
    const { onToggleSearchBarMobile, widget } = this.props;
    this.setState(
      {
        isOpenSearchBarMobile: !this.state.isOpenSearchBarMobile,
        showGreyScreen: false
      },
      () => {
        if (!widget) {
          if (this.state.isOpenSearchBarMobile) onToggleSearchBarMobile(true);
          else onToggleSearchBarMobile(false);
        } else {
          onToggleSearchBarMobile(false); // NOTE: Always false if it is a widget
        }
      }
    );
  };

  get pickupTimes() {
    const times = timeSlot();
    const { startDate } = this.state;
    if (startDate) {
      if (startDate.startOf('day').isSame(moment().startOf('day'))) {
        return times.filter((t) => compareTime(t.value, currentTime()));
      }
    } else {
      return times.filter((t) => compareTime(t.value, currentTime()));
    }
    return times;
  }

  get returnTimes() {
    const times = timeSlot();
    const { startDate, endDate, pickupTime } = this.state;
    if (isSameDay(startDate, endDate)) {
      const nextFourHours = getMoment(pickupTime)
        .add(4, 'hours')
        .format('HH:mm');
      return times.filter((t) => compareTime(t.value, nextFourHours));
    }
    return times;
  }

  get dataLayerArgs() {
    const query = queryString.parse(window.location.search);
    const locationID = parseInt(query.location_id);
    const location = this.props.filter.location.list.find((l) => l.id === locationID);

    return {
      event: 'track_event',
      event_category: 'bookings_section',
      event_action: 'search_bookings_success',
      event_label: 'from_product_detail_page',
      search_destination: location.city.name.th,
      search_pickup_location: location.name.th,
      search_dealer_type: query.sources || 'undefined',
      search_dealer_name: 'undefined',
      search_car_type: 'undefined',
      search_promotion_name: 'undefined',
      search_rental_type: 'shortterm',
      sort_by: query.sort_by,
      search_timestamp: moment()
        .locale('en')
        .format('YYYY-MMM-DD HH:mm:ss')
    };
  }

  render() {
    const {
      locations,
      startDate,
      endDate,
      pickupTime,
      returnTime,
      locationName,
      isLoadingLocation,
      isOpenSearchBarMobile
    } = this.state;

    const { style, widget } = this.props;

    return (
      <div style={style}>
        <div
          className={`${
            widget ? 'widget d-block' : isOpenSearchBarMobile ? 'd-block' : 'd-none'
          } d-lg-block search-bar `}
        >
          {' '}
          <div className="container">
            <div className={`${widget ? 'd-lg-block' : 'd-lg-none'} row d-md-block`}>
              <div
                data-event-category="bookings_section"
                data-event-action="close"
                data-event-label="date_change_menu"
                className="col-3 offset-9 text-right close-modal"
                onClick={(e) => {
                  dataLayer(e);
                  this.onToggleModalMobile();
                }}
              >
                ปิด <i className="flaticon-cancel" />
              </div>
            </div>
            <div className="row search-bar-wrapper">
              {!widget && (
                <div
                  ref={(component) => {
                    this.SelectPicker = component;
                  }}
                  className={`${widget ? 'col-lg-12 mt-2' : 'col-lg-3'} col-md-12 select-location`}
                >
                  <SelectPicker
                    onChange={(event) => {
                      this.setState({ locationName: event.target.value });
                    }}
                    searchable={true}
                    value={this.state.locationName}
                    focused={this.state.focused}
                    placeholder="เลือกจุดหมาย"
                    mobilePlaceholder="เลือกจุดหมาย"
                    onSelect={({ name: { th: locationName }, dh_location_code: locationID }) => {
                      this.setState({
                        locationName,
                        locationID,
                        focused: false
                      });
                      if (this.state.startDate == null) this.setState({ startDateFocused: true });
                      document.body.classList.remove('noscroll');
                    }}
                    data={locations}
                    handleClick={() => this.handleClick()}
                    onClearText={() => this.setState({ locationName: '', locationID: '' })}
                    onFocus={() => {
                      this.setState({ focused: true });
                    }}
                    onBlur={() => this.setState({ focused: false })}
                    loading={isLoadingLocation}
                  />
                </div>
              )}

              <div className={`${widget ? 'col-lg-12 mt-2 widget-gap' : 'col-lg-6'} col-md-12 datetime-picker-wrapper`}>
                <DateTimePicker
                  eventCategory="bookings_section"
                  startDate={startDate}
                  endDate={endDate}
                  pickupTime={pickupTime}
                  returnTime={returnTime}
                  onDatesChange={this.onDateChange}
                  setPickUpTime={this.setPickUpTime}
                  pickupOptions={this.pickupTimes}
                  returnOptions={this.returnTimes}
                  setReturnTime={this.setReturnTime}
                  onClose={this.dismissAll}
                />
              </div>
              <div
                className={`${
                  widget ? 'col-lg-12 mb-4 widget-submit-button widget-gap' : 'col-lg-3'
                } col-md-12 submit-button`}
              >
                <Button pulse="true" onClick={this._onSubmit.bind(this)}>
                  {widget ? 'ค้นหา' : 'ค้นหารถเช่าราคาดีที่สุด'}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            widget ? 'd-none' : isOpenSearchBarMobile ? ' d-none' : 'd-md-block d-lg-none'
          } mobile-info-wrapper`}
        >
          <div className="container">
            <div className="row">
              <div className="col-10">
                <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                <p className="mobile-info-text">
                  <b>{locationName ? locationName : 'กรุณาเลือกจุดรับรถ'} </b>{' '}
                </p>
                <br />
                <i className="fa fa-calendar" aria-hidden="true" />
                <p className="mobile-info-text">
                  {moment(startDate).format('DD/MM/YY') + ' ' + pickupTime} &nbsp;
                  <i className="flaticon-right-arrow arrow-mobile" /> &nbsp;
                  {moment(endDate).format('DD/MM/YY') + ' ' + returnTime}
                </p>
              </div>
              <div className="col-2 align-self-center text-right">
                <button type="button" className="btn btn-primary btn-edit-mobile" onClick={this.onToggleModalMobile}>
                  แก้ไข
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showGreyScreen && (
          <div onClick={() => this.setState({ showGreyScreen: false })} className="grey-screen" />
        )}
      </div>
    );
  }
}

SearchBar.propTypes = {
  pickup_datetime: PropTypes.string,
  return_datetime: PropTypes.string,
  location_id: PropTypes.string
};
SearchBar.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      initialFetchCars,
      fetchedLocation: (locations) => ({
        type: 'LOCATION_FETCHED',
        payload: locations
      })
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
