import React, { Component } from 'react'
import Panel from '../../molecules/Panel'

export default class OriginalCondition extends Component {
  render() {
    return (
      <div className="rental-condition__local">
        <Panel title="ค่ามัดจำ/ค่าประกันความเสียหาย">
          ผู้เช่าจะต้องจ่ายค่ามัดจำ/ค่าประกันความเสียหายรถตามความจุตัวถังของรถที่เช่า ดังต่อไปนี้
          <ul>
            <li>A. ความจุ 1,200 cc เช่น Almera, March, Brio, Mirage เริ่มต้น 3,000-5,000 บาท </li>
            <li>B. ความจุ 1,500 cc เช่น Vios, City, Jazz, Mazda2 เริ่มต้น 3,000-5,000 บาท </li>
            <li>C. ความจุ 1,800 cc เช่น Altis, Civic เริ่มต้น 5,000-10,000 บาท</li>
            <li>
              D. ความจุ 2,000 cc-3,000 cc เช่น Fortuner, Camry, Accord, Mu-7 เริ่มต้น 10,000 บาท
            </li>
          </ul>
          ค่ามัดจำ/ค่าประกันความเสียหายดังกล่าว
          ผู้เช่าจะได้รับคืนทั้งจำนวนภายหลังจากสิ้นสุดการเช่ารถ (ในกรณีที่ไม่มีค่าปรับใดๆ)
        </Panel>
        <Panel title="เอกสารต้องใช้อะไรบ้าง">
          <ul style={{ marginBottom: 20 }}>
            <li>
              ผู้ทำการเช่ารถยนต์จำเป็นต้องมีบัตรประจำตัวประชาชน ( passport สำหรับคนต่างชาติ)
              และใบอนุญาตขับขี่รถส่วนบุคคลที่ยังไม่หมดอายุ และได้รับมาไม่ต่ำกว่า 1 ปี
            </li>
            <li>
              กรณีคนขับเสริม ผู้ที่ขับเสริมต้องเซ็นเอกสารสำหรับการเช่ารถยนต์
              และต้องมีใบอนุญาตขับขี่รถส่วนบุคคลที่ไม่หมดอายุ
            </li>
            <li>
              ผู้ให้เช่าอาจมีการขอเอกสารของผู้เช่าเพิ่มเติม เช่น บัตรโดยสารเครื่องบิน, หลักฐานการจอง
              โรงแรม, บัตรประจำตัวพนักงาน, บัตรประจำตัวนักศึกษา, สลิปเงินเดือน, ใบรับรองบริษัท
              หรืออย่างใดอย่างหนึ่งเพื่อความปลอดภัยในการเช่า
            </li>
          </ul>
          <a target="_blank" rel="noopener noreferrer" href="https://www.drivehub.co/th/documents">
            ดูเอกสารที่ต้องใช้แบ่งตามจังหวัดและลักษณะการเช่า
          </a>
        </Panel>
        <Panel title="สิ่งที่รวมมากับราคาเช่ารถ">
          <ul>
            <li>ราคาค่าเช่าตามวันและเวลาที่ระบุไว้</li>
            <li>ประกันภัยบุคคลที่ 3</li>
            <li>ประกันภัยแบบมีค่าเสียหายส่วนแรก</li>
            <li>ค่าบริการรับ/ส่งตามสถานที่ที่ระบุไว้</li>
          </ul>
        </Panel>
        <Panel title="สิ่งที่ไม่ได้รวมมากับราคาเช่ารถ">
          <ul>
            <li>ค่าบริการรับ/ส่งรถนอกเวลาทำการ (เวลาทำการ 8.30-20.00)</li>
            <li>
              ค่าบริการรับ/ส่งรถนอกสถานที่ที่ระบุไว้ หรือคืนรถต่างสถานที่
              (ขึ้นอยู่กับเงื่อนไขของแต่ละบริษัทรถเช่า)
            </li>
            <li>ภาษีมูลค่าเพิ่ม 7% (หากผู้เช่าต้องการออกใบกับภาษี)</li>
          </ul>
        </Panel>
        <Panel title="ประกันภัย/ความคุ้มครอง">
          <p>รถเช่าทุกคันรวมประกันชั้น1 พื้นฐาน </p>
          <p>
            หากเกิดอุบัติเหตุและผู้เช่าหรือลูกค้าไม่ซื้อประกันเพิ่มเติมกับผู้ใช้บริการ
            โดยผู้เช่าหรือลูกค้าเป็นฝ่ายผิดหรือไม่มีคู่กรณีผู้ใช้บริการสามารถจะเก็บค่าเสียหาย(“ค่าเสียหายส่วนแรก”)
            ระหว่าง1,000 ถึง 50,000บาท ในอัตราอ้างอิงตามประเภทรถดังนี้
          </p>

          <table className="table table-bordered mt-3 mb-3 table-sm">
            <tr>
              <th>ประเภทรถ</th>
              <th className="text-center">ตัวอย่าง</th>
              <th className="text-center">ค่าเสียหายส่วนแรกมากที่สุด (บาท)</th>
            </tr>
            <tr>
              <td>รถอีโคคาร์ และ รถขนาดเล็ก</td>
              <td className="text-center">March / Almera / Yaris / Vios / City / Jazz / Ciaz</td>
              <td className="text-center">30,000</td>
            </tr>
            <tr>
              <td>รถขนาดกลาง</td>
              <td className="text-center">Altis / Civic / CHR / HRV / Slyphy / Juke</td>
              <td className="text-center">40,000</td>
            </tr>
            <tr>
              <td>รถขนาดใหญ่ และ รถเอสยูวี</td>
              <td className="text-center">Camry / Accord / Fortuner / CRV / Pajero / Teana</td>
              <td className="text-center">50,000</td>
            </tr>
          </table>
          <p>
            หากบริษัทประกันภัยประเมินราคาแล้ว ได้ความว่า ค่าเสียหายเกินกว่าวงเงินคุ้มครองที่ก
            าหนดไว้
            ผู้เช่า/ผู้ยืมตกลงรับผิดชอบชดใช้เงินค่าใช้จ่ายส่วนเกินที่บริษัทประกันภัยไม่ได้คุ้มครอ
          </p>
        </Panel>
        <Panel title="สถานที่รับรถ">
          หลังจากทำการจองเสร็จจะมีเจ้าหน้าคอนเฟิร์ม หากคุณลูกค้าเลือกสถานที่รับคืนรถที่สนามบิน
          เจ้าหน้าที่จะนำรถไปรอที่ประตูทางออกสนามบิน หรือตามจุดนัดพบ
          กรุณาเตรียมเอกสารตัวจริงเพื่อตรวจสอบ หากเอกสารไม่ครบขอสงวนสิทธิ์การปล่อยรถ
        </Panel>
        <Panel title="นโนบายการเติมน้ำมัน">
          ณ วันคืนรถยนต์เช่า ผู้เช่าจำเป็นต้องเติมน้ำมันให้อยู่ในระดับเดียวกันกับวันรับรถยนต์
          โดยระดับของน้ำมันนั้นปกติแล้วจะอยู่ในระดับเต็มถัง แต่ในบางกรณีอาจไม่เต็มถัง
          ซึ่งผู้เช่าก็สามารถคืนรถในระดับเดียวกันกับระดับเดิมได้
          หากผู้เช่าทำการคืนรถเช่าแล้วระดับของน้ำมันไม่เทียบเท่ากับตอนที่รับรถเช่า
          จำเป็นต้องมีค่าปรับ โดยจะคำนวณจากสัดส่วนที่ขาดหายไปตามจริง
        </Panel>
        <Panel title="บริการนอกเวลาทำการ">
          การรับรถ-ส่งรถนอกเวลาทำการ อาจจะต้องมีค่าใช้จ่ายเพิ่มเติม
          ซึ่งขึ้นอยู่กับนโยบายของบริษัทรถเช่าแต่ละที่
        </Panel>
        <Panel title="บริการ/อุปกรณ์เสริม">
          บริการอุปกรณ์เสริมเช่น Car seat
          และอื่นๆจะมีให้บริการในบางร้านเท่านั้นหากลูกค้าต้องการอุปกรณ์เสริมกรุณาสอบถามก่อนทาการจองผ่านช่องทางไลน์@หรือคอลเซ็นเตอร์ไดรฟ์ฮับ
        </Panel>
      </div>
    )
  }
}
