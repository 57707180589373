import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { PENDING, CANCELLED, CANCELED, REJECTED, ACCEPTED } from '../../../variables/bookingStatus';
import './style.scss';

const NotificationBox = (props) => {
  const getContent = () => {
    const { status, createdAt } = props;
    const _createdAt = moment(createdAt);

    switch (status) {
      case PENDING: {
        const now = moment();
        const hour = parseInt(moment().format('H'), 10);

        if (now.diff(_createdAt, 'minutes') > 30) {
          return (
            <span>
              หากไม่ได้รับการติดต่อกลับจากบริษัทเช่ารถ <b>ติดต่อ Call center</b>
            </span>
          );
        }
        if (hour >= 20 && hour <= 24) {
          return (
            <span>
              เจ้าหน้าที่จะติดต่อเพื่อยืนยันการจองในเวลาทำการของวันถัดไป เนื่องจากขณะนี้อยู่นอกเหนือเวลาทำการของเรา
              (เวลาทำการ ทุกวัน เวลา 8:00น. - 20:00น.)
            </span>
          );
        }

        if (hour >= 0 && hour <= 8) {
          return <span>กรุณารอการติดต่อกลับจากบริษัทรถเช่าในเวลาทำการ 08.00 - 20.00 น.</span>;
        }

        return (
          <span>
            บริษัทรถเช่ากำลังติดต่อหาคุณภายใน <b>30 นาที</b>
          </span>
        );
      }
      case ACCEPTED: {
        return <span>คุณชำระเงินแล้วในส่วนแรก</span>;
      }
      case CANCELLED:
      case CANCELED: {
        return <span>คุณได้ยกเลิกการจองนี้เรียบร้อยแล้ว</span>;
      }
      case REJECTED: {
        return <span>การจองถูกยกเลิกโดยบริษัทรถเช่า</span>;
      }
      default: {
        return '';
      }
    }
  };

  const getIcon = () => {
    const { status } = props;
    switch (status) {
      case PENDING: {
        return 'flaticon-infomation1x';
      }
      case ACCEPTED: {
        return 'flaticon-check-correctfill1x-2';
      }
      case CANCELLED:
      case CANCELED:
      case REJECTED: {
        return 'flaticon-check-wrongtfill1x-1';
      }
      default: {
        return 'flaticon-infomation1x';
      }
    }
  };

  const getBoxColor = () => {
    const { status } = props;
    switch (status) {
      case PENDING: {
        return 'warning';
      }
      case ACCEPTED: {
        return 'success';
      }
      case CANCELLED:
      case CANCELED:
      case REJECTED: {
        return 'danger';
      }
      default: {
        return 'warning';
      }
    }
  };
  const { status } = props;
  return status === 'completed' ? (
    ''
  ) : (
    <div className={`noti-box noti-box--${getBoxColor()}`}>
      <div className="icon">
        <i className={getIcon()}></i>
      </div>
      <div className="content">{getContent()}</div>
    </div>
  );
};

NotificationBox.defaultProps = {
  status: 'pending',
  createdAt: moment().add(-10, 'minutes')
};

NotificationBox.propTypes = {
  status: PropTypes.string,
  createdAt: PropTypes.any
};

export default NotificationBox;
