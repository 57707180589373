import { readCookie, deleteCookie, setCookie } from '../../../utils/index'
import axios from 'axios'
import { LEGACY_WEB } from '../../../variables/API'
import createError from 'http-errors'

export const authUpdateInfo = data => {
  const consumerToken = readCookie('_consumerToken')
  if (!consumerToken) return Promise.reject(createError('401', 'Unauthorized'))

  return axios({
    method: 'put',
    url: `${LEGACY_WEB}/api/v1/consumer`,
    headers: {
      'content-type': 'application/json',
      Authorization: consumerToken,
    },
    data: {
      ...data,
    },
  })
    .then(({ data }) => {
      deleteCookie('_registerModal')
      setCookie('_welcomeModal', true, 24, '/')
      setCookie('_consumerToken', data.token, 24, '/')
      return
    })
    .catch(e => {
      throw e.response ? e.response : new Error(e)
    })
}
