import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button';
import { SEARCH_HOST_URL } from '../../../variables/API';
import moment from 'moment';
import './style.scss';

export enum LinkType {
  Line = 'line@',
  IG = 'IG',
  Facebook = 'FB',
  FacebookAndIG = 'FB/IG',
  Other = 'อื่นๆ'
}
const getTrackingParam = (type: LinkType) => {
  const date = moment().format('DD-MM-YY');
  switch (type) {
    case LinkType.Line:
      return `&utm_source=callcenter&utm_medium=callcenter&utm_campaign=Linemsg&utm_content=linemsg${date}`;
    case LinkType.FacebookAndIG:
      return `&utm_source=callcenter&utm_medium=callcenter&utm_campaign=FBmessanger&utm_content=fbmsg${date}`;
    case LinkType.Other:
      return `&utm_source=callcenter&utm_medium=callcenter&utm_campaign=other&utm_content=other${date}`;
    default:
      return '';
  }
};
const copyShareLink = (type: LinkType) => {
  const path = window.location.pathname;
  const trackingParam = getTrackingParam(type);
  const param = queryString.parse(window.location.search);
  delete param['utm_source'];
  delete param['utm_medium'];
  delete param['utm_campaign'];
  delete param['utm_content'];
  const search = queryString.stringify(param);
  const shareURL = `${SEARCH_HOST_URL}${path}?${search}${trackingParam}`;
  const textArea = document.createElement('textarea');
  if (!textArea) return;
  textArea.value = shareURL;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
  return;
};

interface ShareButton {
  type: LinkType;
  size?: 'sm' | 'lg';
  page: 'car-detail' | 'search';
  className?: string;
}
const ShareButton: React.FC<ShareButton> = ({ type, size, className }) => {
  const [copy, setCopy] = useState<boolean>(false);
  let timeOutRef;
  const setTimeOutCopy = (callBack: () => void) => {
    timeOutRef = setTimeout(() => callBack(), 1000);
    return;
  };

  useEffect(() => {
    if (!timeOutRef && !copy) return;
    clearTimeout(timeOutRef);
    if (copy) setTimeOutCopy(() => setCopy(false));
    return;
  });
  return (
    <Button
      size={size}
      variant={`${copy ? 'success' : 'secondary'}`}
      className={`mt-2 mt-lg-3 float-right border-transparent ${copy ? 'border-success' : ''} btn--cs-share ${
        className ? className : ''
      }`}
      onClick={() => {
        copyShareLink(type);
        setCopy(true);
      }}
    >
      {copy ? 'copied' : `ช่องทาง ${type}`}
    </Button>
  );
};
export default ShareButton;
