import React, { Component } from 'react'
import Modal from 'rc-dialog'
import queryString from 'query-string'
import moment from 'moment'
import imageAlert from '../../../assets/images/car_walkin_alert.png'
import { SEARCH_PAGE } from '../../../variables/route'

export default class ModalCarWalkIn extends Component {
  onRedirectToSearchPage = () => {
    const query = queryString.parse(window.location.search)
    query.booking_begin = query.pickup_datetime
    query.booking_end = query.return_datetime
    delete query.pickup_datetime
    delete query.return_datetime
    delete query.car_id
    delete query.insurance_id
    if (query.booking_begin.includes('T')) {
      query.booking_begin = moment(query.booking_begin).format('YYYY-MM-DD HH:mm')
    }
    if (query.booking_end.includes('T')) {
      query.booking_end = moment(query.booking_end).format('YYYY-MM-DD HH:mm')
    }
    const nextQuery = queryString.stringify(query)
    window.location.href = `${SEARCH_PAGE}?${nextQuery}`
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        animation="zoom"
        maskAnimation="fade"
        className="modal-car-walkin"
      >
        <div className="center">
          <img src={imageAlert} alt="alert" />
          <h3>รถคันนี้ไม่สามารถให้บริการได้ในขณะนี้</h3>
          <button className="btn btn-primary" onClick={this.onRedirectToSearchPage}>
            เลือกรถคันอื่น
          </button>
        </div>
      </Modal>
    )
  }
}
