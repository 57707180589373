import { dataLayer } from '@ui/utils/datalayer';
import React, { useState } from 'react';
import {
  onClickLogin,
  onClickLogout,
  onClickRegister,
  ConsumerInfo,
  authTotalIncomingBookings
} from '../../components/context/auth';
import { WEB_LEGACY_LINK } from '../../config';

export const DefaultDropdownAuth: React.FC<{ prefix: string }> = ({ prefix }) => {
  return (
    <React.Fragment>
      <div className="dropdown-item dropdown-item--auth">
        <div id="auth-login">
          รับส่วนลดพิเศษสำหรับสมาชิก Drivehub พร้อมการจองที่รวดเร็วและไม่ยุ่งยาก!
          <button
            id="dropdown-sign-in"
            className="btn btn-block btn-primary"
            data-event-category="header_section"
            data-event-action="click_sub_menu"
            data-event-label="Login"
            onClick={(e) => {
              dataLayer(e);
              onClickLogin(prefix);
            }}
          >
            เข้าสู่ระบบ
          </button>
        </div>
      </div>
      <div className="dropdown-item dropdown-item--underline">
        <div id="auth-underline" />
      </div>
      <div className="dropdown-item dropdown-item--auth">
        <div id="auth-register">
          เข้าเว็บไซต์ Drivehub เป็นครั้งแรกใช่ไหม?
          <a
            id="dropdown-sign-up"
            data-event-category="header_section"
            data-event-action="click_sub_menu"
            data-event-label="Registration"
            href={`#register`}
            onClick={(e) => {
              dataLayer(e);
              onClickRegister(prefix);
            }}
          >
            สมัครสมาชิกเลย
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export const DropdownAuth: React.FC<{
  prefix: string;
  consumer: ConsumerInfo;
}> = ({ prefix, consumer }) => {
  const [totalIncomingBookings, setTotalIncomingBookings] = useState(0);
  authTotalIncomingBookings().then((result: number) => {
    setTotalIncomingBookings(result);
  });
  return (
    <React.Fragment>
      <div className="dropdown-item dropdown-item--auth" id="auth-user">
        <p>
          {consumer.firstName === '' ? (
            'ยินดีต้อนรับ'
          ) : (
            <React.Fragment>
              ยินดีต้อนรับ คุณ <span>{consumer.firstName}</span>
            </React.Fragment>
          )}
        </p>
        คุณมี <span>{totalIncomingBookings}</span> การจองที่กำลังจะมาถึง!
      </div>
      <div className="dropdown-item dropdown-item--underline">
        <div id="auth-underline" />
      </div>
      <div className="dropdown-item dropdown-item--auth">
        <a
          id="menu-user-profile"
          className="auth-icon"
          data-event-category="header_section"
          data-event-action="click_sub_menu"
          data-event-label="แก้ไขข้อมูลส่วนตัว"
          onClick={(e) => dataLayer(e)}
          href={`${WEB_LEGACY_LINK}/${prefix}/user/profile`}
        >
          <i className="icon-auth"></i> แก้ไขข้อมูลส่วนตัว
        </a>
      </div>
      <div className="dropdown-item dropdown-item--auth">
        <a
          id="menu-user-password"
          className="auth-icon"
          data-event-category="header_section"
          data-event-action="click_sub_menu"
          data-event-label="แก้ไขรหัสผ่าน"
          onClick={(e) => dataLayer(e)}
          href={`${WEB_LEGACY_LINK}/${prefix}/user/change-password`}
        >
          <i className="icon-key"></i> แก้ไขรหัสผ่าน
        </a>
      </div>
      <div className="dropdown-item dropdown-item--auth">
        <a
          id="menu-user-booking"
          className="auth-icon"
          data-event-category="header_section"
          data-event-action="click_sub_menu"
          data-event-label="การเช่ารถของฉัน"
          onClick={(e) => dataLayer(e)}
          href={`${WEB_LEGACY_LINK}/${prefix}/user/booking`}
        >
          <i className="icon-doc-text-inv"></i> การเช่ารถของฉัน
        </a>
      </div>

      <div className="dropdown-item dropdown-item--auth">
        <a
          id="menu-user-social-networks"
          className="auth-icon"
          data-event-category="header_section"
          data-event-action="click_sub_menu"
          data-event-label="การเชื่อมต่อ"
          onClick={(e) => dataLayer(e)}
          href={`${WEB_LEGACY_LINK}/${prefix}/user/social-networks`}
        >
          <i className="icon-chain" style={{ paddingRight: '0.25rem' }} />
          การเชื่อมต่อ
        </a>
      </div>

      <div className="dropdown-item dropdown-item--auth">
        <a
          id="menu-user-logout"
          className="auth-icon"
          data-event-category="header_section"
          data-event-action="click_sub_menu"
          data-event-label="ออกจากระบบ"
          onClick={(e) => {
            dataLayer(e);
            onClickLogout();
          }}
          href={`#logout`}
        >
          <i className="icon-logout"></i> ออกจากระบบ
        </a>
      </div>
    </React.Fragment>
  );
};

export const DropdownAuthWelcome: React.FC<{
  prefix: string;
  consumer: ConsumerInfo;
}> = ({ prefix, consumer }) => {
  const [totalIncomingBookings, setTotalIncomingBookings] = useState(0);
  authTotalIncomingBookings().then((result: number) => {
    setTotalIncomingBookings(result);
  });
  return (
    <div className="dropdown-item dropdown-item--auth" id="auth-user">
      <p>
        {consumer.firstName === '' ? (
          'ยินดีต้อนรับ'
        ) : (
          <React.Fragment>
            ยินดีต้อนรับ คุณ <span>{consumer.firstName}</span>
          </React.Fragment>
        )}
      </p>
      คุณมี <span>{totalIncomingBookings}</span> การจองที่กำลังจะมาถึง!
      <a
        href={`${WEB_LEGACY_LINK}/${prefix}/user/booking`}
        style={{ display: 'block', fontSize: 14, color: '#0078ff' }}
      >
        การเช่ารถของฉัน
      </a>
    </div>
  );
};

export default DefaultDropdownAuth;
