import React, { Component } from 'react'

export default class Separator extends Component {
  render() {
    return (
      <div className="calendar--separator">
        <div>
          <svg height="76" width="19">
            <line x1="0" y1="0" x2="19" y2="38" />
            <line x1="0" y1="76" x2="19" y2="38" />
          </svg>
        </div>
        <div />
      </div>
    )
  }
}
