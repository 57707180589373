import styled, { ThemedStyledFunction } from 'styled-components';

export const withProps = <U,>() => <P, T, O>(
  fn: ThemedStyledFunction<P, T, O>
): ThemedStyledFunction<P & U, T, O & U> => fn;

interface Props {
  type?: 'local' | 'inter';
  size: 'small' | 'medium' | 'large';
  bg?:
    | 'primary'
    | 'blue'
    | 'dark'
    | 'interPrimary'
    | 'localPrimary'
    | 'inter'
    | 'local'
    | 'veryLightBlue'
    | 'pale'
    | 'scarlet';
  font?: 'dark' | 'white' | 'black';
  borderRadius?: number;
}

export default withProps<Props>()(styled.span.attrs((p: Props) => ({ ...p })))`
  display: inline-block;

  .badge__wrapper {
    display: flex;
    align-items: center;
    border-radius: ${(p) => p.borderRadius}px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: ${(p) => p.theme.badge.fontSize[p.size]};
    height: ${(p) => p.theme.badge.height[p.size]};
    background: ${(p) => p.theme.colors[p.type || p.bg || 'white']};
    color: ${(p) => p.theme.colors[p.font || 'white']};
  }
  img {
    height: 17px;
    margin-left: 3px;
  }
  span {
    padding: 4px;
    display: inline-block;
  }
`;
