import { applyMiddleware, compose } from 'redux'
import { connectRouter } from 'connected-react-router'
import Reactotron from './reactotronConfig'
import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history'
import rootReducer from './reducers'

export default function configureStore(preloadedState) {
  const history = createBrowserHistory()
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  let enhancers = [middlewareEnhancer]
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers = [
      middlewareEnhancer,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    ]
  }
  const composedEnhancers = compose(...enhancers)

  const store = Reactotron.createStore(
    connectRouter(history)(rootReducer),
    preloadedState,
    composedEnhancers,
  )

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }
  return store
}
