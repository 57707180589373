import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { StickyContainer, Sticky } from 'react-sticky';
import queryString from 'query-string';
import moment from 'moment-timezone';
import Helmet from 'react-helmet';
// import ToolTip from 'rc-tooltip'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBranchLevelReviewsStats } from '../../ducks/actions/reviews';
import { withTranslation } from '../../locales';
import Faq from '../../components/organisms/Faq';
import Gallery from '../../components/organisms/Gallery';
import InfoTab from '../../components/organisms/InfoTab';
// import VendorDetails from '../../components/molecules/VendorDetails'
import VendorAndReviews from '../../components/ecosystems/VendorAndReviews';
import DetailsBoxWithBookingSummary from '../../components/ecosystems/DetailsBoxWithBookingSummary';
import fetchCarDetailApi from '../../components/hoc/fetchCarDetailApi';
import ProgressBar from '../../components/molecules/ProgressBar';
import TooLong from '../../components/molecules/TooLong';
import { CAR_DETAILS } from '../../variables/page';
import { SEARCH_PAGE } from '../../variables/route';
import { SEARCH_API_URL } from '../../variables/API';

import ModalCarWalkIn from '../../components/ecosystems/DetailsBoxWithBookingSummary/ModalCarWalkIn';
import ShareButton, { LinkType } from '../../components/atoms/ShareButton';
import { CS_MODE } from '../../variables/features';

import './style.scss';
import axios from 'axios';
import { pushDataLayer } from '@utils/datalayer';

class CarDetailsAndBookingReview extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(window.location.search);
    this.state = {
      pickup_datetime: query.pickup_datetime
        ? moment(query.pickup_datetime)
        : moment()
            .add(1, 'days')
            .hour(10)
            .minutes(0),
      return_datetime: query.return_datetime
        ? moment(query.return_datetime)
        : moment()
            .add(2, 'days')
            .hour(10)
            .minutes(0)
    };
    this.vendorAndReviewsRef = React.createRef();
  }

  componentDidMount() {
    document.title = this.props.t('page.title');
    if (window.fbq) {
      if (!this.isInter) {
        window.fbq('track', 'ViewContent', {
          currency: 'THB',
          content_type: 'vehicle',
          content_ids: this.props.match.params.id
        });
      }
    }
    this.fetchBranchStat();
    this.checkBookingCreated();
    this.pushDataLayer();
  }

  pushDataLayer = () => {
    setTimeout(() => {
      pushDataLayer({
        event: 'ecommerce_productDetail_impression',
        event_category: 'ecommerce',
        event_action: 'product_detail_impression',
        event_label: this.props.details.car.title.en,
        ecommerce: {
          detail: {
            products: [
              {
                name: this.props.details.car.title.en, // Name or ID is required.
                id: (this.isInter ? 'I' : 'L') + this.props.match.params.id, // For Example 0001
                price: this.props.pricing.grand_total_price, // For Example 3500
                brand: `${this.props.details.vendor.title.en} : ${this.props.details.branch.title.en}`, // For Example True Leasing : True สาขาเชียงใหม่
                category: this.props.details.category.en, // For Example Eco, Suv, Medium, Van, Car_with_driver
                variant: this.isInter ? 'Inter' : 'Local' // Dealer Type
              }
            ]
          }
        }
      });
    }, 1000);
  };

  fetchBranchStat = () => {
    this.props.getBranchLevelReviewsStats(this.props.detail.branch.id, this.props.detail.source);
  };

  checkBookingCreated = () => {
    const referrer = `${window.location.pathname}${window.location.search}`;
    const bookingID = window.localStorage.getItem(referrer);
    if (bookingID) {
      const query = queryString.parse(window.location.search);
      axios
        .get(`${SEARCH_API_URL}/booking_status/${bookingID}`)
        .then((response) => response.data)
        .then((response) => {
          const { status } = response;
          const lastName = this.props.customer.last_name;
          if (status !== 'drafted' && status !== '' && lastName !== '') {
            window.localStorage.removeItem(referrer);
            window.location.href = `/success/${bookingID}?source=${query.source}&last_name=${lastName}`;
          }
        });
    }
  };
  onDatesChange = ([pickup_datetime, return_datetime]) => {
    this.setState({
      pickup_datetime: pickup_datetime.format('YYYY-MM-DD HH:mm'),
      return_datetime: return_datetime.format('YYYY-MM-DD HH:mm')
    });
  };

  get carId() {
    return this.props.match.params.id;
  }

  get isInter() {
    return ['inter', 'bigbrand'].includes(this.props.detail.source);
  }

  get carAvailableFor() {
    if (!this.isInter) {
      return this.props.detail.car.available_for;
    }
    return 'online';
  }

  get title() {
    if (!this.isInter) {
      return this.props.detail.car.title.th;
    }
    return (
      <React.Fragment>
        {this.props.detail.car.title.en} <small>{this.props.t('title.or_equal')}</small>
      </React.Fragment>
    );
  }

  get urlToSearchResultPage() {
    const query = {
      booking_begin: moment(this.state.pickup_datetime).format('YYYY-MM-DD HH:mm'),
      booking_end: moment(this.state.return_datetime).format('YYYY-MM-DD HH:mm'),
      location_id: queryString.parse(window.location.search).location_id
    };
    const parsedQuery = queryString.stringify(query);
    return `${SEARCH_PAGE}?${parsedQuery}`;
  }

  onClickSeeReviews = () => {
    const ratingReviewstabIndex = 1;
    this.vendorAndReviewsRef.current.setSelectTabIndex(ratingReviewstabIndex);
    document.getElementById('vendor-reviews-section').scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const { car, vendor, branch, source, badges } = this.props.detail;
    const { t, lang } = this.props;
    const { branchLevelStats, currentStats } = this.props.reviews;

    return (
      <div style={{ backgroundColor: '#f6f6f6' }} className="pt-4 pb-5">
        <Helmet>
          <script>
            {`
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({'title': '${this.title}'}, {'vendor': '${vendor.title[lang]}'})
            `}
          </script>
        </Helmet>

        <MediaQuery minWidth={769}>
          {(matches) => {
            if (matches)
              // NOTE: Desktop Layout
              return (
                <React.Fragment>
                  {/* Desktop Layout */}
                  <ProgressBar
                    active={CAR_DETAILS}
                    urlToSearchResultPage={this.urlToSearchResultPage}
                    carSource={source}
                  />
                  <ModalCarWalkIn visible={car.available_for === 'walk_in'} />
                  <div className="container merged_container">
                    {/* Left Layout */}
                    <div className="mt-3 left_container">
                      <div className="header_container">
                        <div className="header_left">
                          <h3>{this.title}</h3>
                          <TooLong
                            length={100}
                            text={`${vendor.title[lang]} · ${branch.title[lang]}`}
                            placement="bottomRight"
                          >
                            {(text) => (
                              <div className="branch_content">
                                <span>{t('branch.by')}</span> {text}
                              </div>
                            )}
                          </TooLong>
                        </div>
                        <div className="header_right">
                          <div className="review_content" onClick={this.onClickSeeReviews}>
                            <div
                              className={`review_score ${branchLevelStats.total === 0 ? 'review_score--empty' : ''}`}
                            >
                              {branchLevelStats.rating}
                              <span>/5</span>
                            </div>
                            <div className="review_total">
                              {branchLevelStats.total === 0 ? (
                                'ยังไม่มีรีวิว'
                              ) : (
                                <React.Fragment>
                                  ดีมาก <span>({branchLevelStats.total} รีวิว)</span>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <Gallery car={car} />
                      {this.isInter && (
                        <React.Fragment>
                          <span className="font-weight-bold invoice_modal_box--warning mt-2 mb-3">
                            <i className="flaticon-information mr-2" id="invoice_icon" />
                            {t('bigbrand.required_credit')}
                          </span>
                          <span className="font-weight-bold invoice_modal_box p-2 mt-2 mb-3">
                            <i className="flaticon-information mr-2" id="invoice_icon" />
                            <div>
                              {t('bigbrand.request_tax_invoice')}
                              <br />
                              {t('bigbrand.request_tax_invoice_2')}
                            </div>
                          </span>
                        </React.Fragment>
                      )}
                      <InfoTab {...this.props.detail} />
                      <VendorAndReviews
                        t={t}
                        vendor={vendor}
                        branch={branch}
                        badges={badges}
                        ref={this.vendorAndReviewsRef}
                        branchLevelStats={branchLevelStats}
                        currentStats={currentStats}
                        lang={lang}
                        source={source}
                      />
                    </div>
                    {/* Right Layout */}
                    <StickyContainer className="mt-3 right_container">
                      <Sticky>
                        {({ style }) => {
                          const newStyle = Object.assign({}, style);
                          delete newStyle.transform;
                          return (
                            <div
                              style={{
                                ...newStyle
                              }}
                            >
                              <DetailsBoxWithBookingSummary
                                onDatesChange={this.onDatesChange}
                                pricingUrl={this.props.pricingUrl}
                                carTitle={this.title}
                                carAvailableFor={this.carAvailableFor}
                                badges={badges}
                                vendor={vendor}
                              />
                              {CS_MODE && (
                                <div className="d-flex justify-content-around mb-3">
                                  <ShareButton
                                    type={LinkType.FacebookAndIG}
                                    size="sm"
                                    page="car-detail"
                                    className="flex-grow-1 mx-2"
                                  />
                                  <ShareButton
                                    type={LinkType.Line}
                                    size="sm"
                                    page="car-detail"
                                    className="flex-grow-1 mx-2"
                                  />
                                  <ShareButton
                                    type={LinkType.Other}
                                    size="sm"
                                    page="car-detail"
                                    className="flex-grow-1 mx-2"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        }}
                      </Sticky>
                    </StickyContainer>
                  </div>
                </React.Fragment>
              );
            // NOTE: Mobile & Tablet Layout
            else
              return (
                <React.Fragment>
                  {/* Mobile & Tablet Layout */}
                  <ProgressBar
                    active={CAR_DETAILS}
                    urlToSearchResultPage={this.urlToSearchResultPage}
                    carSource={source}
                  />
                  <ModalCarWalkIn visible={car.available_for === 'walk_in'} />
                  <div className="mt-4 merged_container">
                    <div className="full-width">
                      {CS_MODE && (
                        <div className="d-flex justify-content-around mb-3">
                          <ShareButton
                            type={LinkType.FacebookAndIG}
                            size="sm"
                            page="car-detail"
                            className="flex-grow-1 mx-2"
                          />
                          <ShareButton type={LinkType.Line} size="sm" page="car-detail" className="flex-grow-1 mx-2" />
                          <ShareButton type={LinkType.Other} size="sm" page="car-detail" className="flex-grow-1 mx-2" />
                        </div>
                      )}
                      <div className="header_container">
                        <div className="header_left">
                          <h3>{this.title}</h3>
                          <TooLong
                            length={90}
                            text={`${vendor.title[lang]} · ${branch.title[lang]}`}
                            placement="bottomRight"
                          >
                            {(text) => (
                              <div className="branch_content">
                                <span>{t('branch.by')}</span> {text}
                              </div>
                            )}
                          </TooLong>
                        </div>
                        <div className="header_right">
                          <div className="review_content" onClick={this.onClickSeeReviews}>
                            <div
                              className={`review_score ${branchLevelStats.total === 0 ? 'review_score--empty' : ''}`}
                            >
                              {branchLevelStats.rating}
                              <span>/5</span>
                            </div>
                            <div className="review_total">
                              {branchLevelStats.total === 0 ? (
                                'ยังไม่มีรีวิว'
                              ) : (
                                <React.Fragment>
                                  ดีมาก <span>({branchLevelStats.total} รีวิว)</span>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Gallery car={car} />
                      {this.isInter && (
                        <React.Fragment>
                          <span className="font-weight-bold invoice_modal_box--warning p-2 mt-2 mb-3">
                            <i className="flaticon-information mr-2" id="invoice_icon" />
                            {t('bigbrand.required_credit')}
                          </span>
                          <span className="font-weight-bold invoice_modal_box p-2 mt-2 mb-3">
                            <i className="flaticon-information mr-2" id="invoice_icon" />
                            <div>
                              {t('bigbrand.request_tax_invoice')}
                              <br />
                              {t('bigbrand.request_tax_invoice_2')}
                            </div>
                          </span>
                        </React.Fragment>
                      )}
                      <div className="mb-3 mt-2">
                        <DetailsBoxWithBookingSummary
                          onDatesChange={this.onDatesChange}
                          pricingUrl={this.props.pricingUrl}
                          carTitle={this.title}
                          carAvailableFor={this.carAvailableFor}
                          badges={badges}
                          vendor={vendor}
                        />
                      </div>
                      <InfoTab {...this.props.detail} />
                      <VendorAndReviews
                        t={t}
                        vendor={vendor}
                        branch={branch}
                        badges={badges}
                        ref={this.vendorAndReviewsRef}
                        branchLevelStats={branchLevelStats}
                        currentStats={currentStats}
                        lang={lang}
                        source={source}
                      />
                    </div>
                  </div>
                  <Faq source={source} active={CAR_DETAILS} />
                </React.Fragment>
              );
          }}
        </MediaQuery>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getBranchLevelReviewsStats }, dispatch);
};

const mapStateToProps = (state) => ({
  ...state.reviews,
  customer: state.booking.customer,
  pricing: state.fetch.pricing,
  details: state.fetch.details
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common', 'car_detail')(fetchCarDetailApi(CarDetailsAndBookingReview)));
// export default withTranslation('common', 'car_detail')(
//   fetchCarDetailApi(CarDetailsAndBookingReview),
// )
