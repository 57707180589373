import React, { Component, useState } from 'react';
import { withTranslation } from '../../../locales';
// import InterLocalBadge from '../../atoms/InterLocalBadge'
// import InterIcon from '../../../assets/icon/inter-icon.svg'
// import LocalIcon from '../../../assets/icon/local-icon.svg'
import ReactMarkdown from 'react-markdown/with-html';
import './style.scss';

// const DEFAULT_IMG = 'http://placehold.jp/96x96.png'

class Index extends Component {
  getHours(str) {
    return str
      .split(':')
      .filter((x, index) => index <= 1)
      .join(':');
  }
  get isInter() {
    return ['inter', 'bigbrand'].includes(this.props.source);
  }
  get opening() {
    return this.getHours(this.props.branch.opening_hours);
  }
  get closing() {
    return this.getHours(this.props.branch.closing_hours);
  }
  get isDescriptionNull() {
    return descriptionFields.every((key) => {
      return this.props.branch[key] == null;
    });
  }
  get detail() {
    const { branch, t } = this.props;

    if (this.isDescriptionNull) {
      return (
        <React.Fragment>
          {branch.description && <b>{t('vendor.detail')}</b>}
          {(branch.description || '').split('\n').map((text, index) => (
            <div key={text + index}>{text}</div>
          ))}
        </React.Fragment>
      );
    }

    return descriptionFields
      .filter((key) => {
        const value = branch[key];
        return value !== '';
      })
      .map((key) => {
        const label = t(`description.${key}`);
        const content = branch[key].split('\r\n');
        return <DescriptionBox t={t} key={label} label={label} content={content} />;
      });
  }
  get interOperationHours() {
    const { branch } = this.props;
    if (branch.opening_hours === '00:30:00' && branch.closing_hours === '23:30:00') {
      return '24 ชั่วโมง';
    }
    return `${branch.opening_hours.substring(0, 5)}น. - ${branch.closing_hours.substring(0, 5)}น.`;
  }
  get detailInter() {
    const { branch, t } = this.props;

    return (
      <div>
        <DescriptionBoxInter
          t={t}
          key={t(`description.operation_hours`)}
          label={t(`description.operation_hours`)}
          length={this.interOperationHours}
          content={this.interOperationHours}
        />
        <DescriptionBoxInter
          t={t}
          key={t(`description.other`)}
          label={t(`description.other`)}
          length={1}
          content={branch.branch_location_description}
        />
      </div>
    );
  }
  render() {
    return this.isInter ? this.detailInter : this.detail;
  }
}

class DescriptionBox extends Component {
  state = {
    extend: false
  };
  toggle = () => this.setState({ extend: !this.state.extend });
  get content() {
    const content = this.props.content;
    if (this.state.extend) {
      return content;
    }
    return content.slice(0, 4);
  }
  render() {
    const { label, t } = this.props;
    return (
      <div className="description-box">
        <label>{label}</label>
        <div className="content">
          {this.content.map((text) => (
            <div key={text}>{text}</div>
          ))}
        </div>
        {this.props.content.length >= 4 && this.state.extend === false && <div className="blur" />}
        {this.props.content.length >= 4 && (
          <button className={this.state.extend ? 'active readmore' : 'readmore'} onClick={this.toggle}>
            {this.state.extend ? t('see_less') : t('see_more')}
          </button>
        )}
      </div>
    );
  }
}

const DescriptionBoxInter = ({ label, content, length, t }) => {
  const [extend, setExtend] = useState(false);
  return (
    <div className={`description-box`}>
      <label>{label}</label>
      <div className={`content content--inter ${extend ? 'extended' : ''}`}>
        <ReactMarkdown source={content} escapeHtml={false} />
      </div>
      {length >= 4 && extend === false && <div className="blur" />}
      {length >= 4 && (
        <button className={extend ? 'active readmore' : 'readmore'} onClick={() => setExtend(!extend)}>
          {extend ? t('see_less') : t('see_more')}
        </button>
      )}
    </div>
  );
};

const descriptionFields = [
  'operation_hours',
  'operation_overtime',
  'pickup_area',
  'payment_description',
  'insurance_addon',
  'extra_accessory',
  'other'
];

export default withTranslation('car_detail')(Index);
