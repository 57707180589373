import React from 'react'

const question = {
  icon: '',
  question: 'การจำกัดระยะทาง',
  answer: (
    <div>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <div>
        <b>ลูกค้าที่เช่ากับบริษัทรถเช่าท้องถิ่น</b>
      </div>
      <p>
        การจำกัดระยะทาง จะขึ้นอยู่กับจำนวนวันและเวลาที่ทำการเช่า
        และเป็นไปตามเงื่อนไขของร้านรถเช่านั้นๆ ท่านสามารถสอบถามก่อนทำการจอง โดยติดต่อเจ้าหน้าที่
        Drivehub ที่เบอร์ 02-038-5222 หรือ Line Id: @Drivehub
      </p>
      <b>
        <u>หากท่านทำการจองรถที่ต้องใช้บัตรเครดิต</u>
      </b>
      <div>
        <b>ลูกค้าที่เช่ากับบริษัท National, Sixt</b>
        <br />
        ไม่จำกัดระยะทาง
      </div>
    </div>
  ),
}

export default question
