import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AuthInfoBox from '../AuthInfoBox';
import { deleteCookie, readCookie } from '../../../utils';
import { ErrorBox } from '../../atoms/ErrorBox';
import disableScroll from 'disable-scroll';
import './style.scss';

class RegisterModal extends React.PureComponent {
  state = {
    visible: false,
    isError: false
  };

  componentDidMount() {
    this.checkRegisterModal();
  }

  componentWillUnmount() {
    disableScroll.off();
  }

  checkRegisterModal = () => {
    const registerModal = readCookie('_registerModal');
    if (registerModal) return this.setState({ visible: true }, () => disableScroll.on());
  };

  onSubmitForm = () => {
    this.toggleModalRegister();
    deleteCookie('_registerModal');
  };

  get initailFormValues() {
    const defaultEmail = readCookie('rg_email') || '';
    return {
      first_name: '',
      last_name: '',
      phone_number: '',
      email: defaultEmail
    };
  }
  toggleIsError = () => {
    this.setState({ isError: !this.state.isError }, () => {
      if (this.state.isError) setTimeout(() => this.setState({ isError: false }), 2000);
    });
  };
  toggleModalRegister = () => {
    this.setState({ visible: !this.state.visible }, () => !this.state.visible && disableScroll.off());
  };
  render() {
    const { visible, isError } = this.state;
    const { prefix } = this.props;
    return (
      <Modal
        isOpen={visible}
        toggle={this.toggleModalRegister}
        centered
        modalClassName="modal-register"
        className="modal-dialog--register"
        onExit={() => deleteCookie('_registerModal')}
        id="register-info"
      >
        <ModalHeader className="modal-header--register d-lg-none">
          <div className="text-center">
            ยินดีต้อนรับ
            <i className="icon-close" onClick={this.toggleModalRegister} id="new-user-welcome-close" />
          </div>
        </ModalHeader>
        <ModalBody className="p-0 position-relative">
          <ErrorBox isError={isError} hideDesktop />
          <i className="icon-close d-none d-lg-block" onClick={this.toggleModalRegister} />
          <div className="text-center px-3 py-3 pt-lg-5">
            <h3>บัญชีของคุณพร้อมใช้งานแล้ว!</h3>
            <p>ข้อมูลนี้จะถูกใช้เพื่อป้อนรายละเอียดของคุณโดยอัตโนมัติเพื่อให้คุณสามารถทำการจองได้อย่างรวดเร็ว</p>
          </div>
          <AuthInfoBox
            prefix={prefix}
            onToggleModal={this.modalRef && this.modalRef.toggleModal}
            onSubmit={this.onSubmitForm}
            onError={this.toggleIsError}
            initailFormValues={this.initailFormValues}
            disabledEmailField={true}
            isError={isError}
          />
          <p
            className="text-center"
            id="new-user-welcome-skip"
            onClick={() => {
              this.onSubmitForm();
            }}
            style={{ color: '#0078ff', padding: '1rem 1rem', cursor: 'pointer' }}
          >
            ข้ามไปก่อน
          </p>
        </ModalBody>
      </Modal>
    );
  }
}

export default RegisterModal;
