import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RatingsReviews from '../../ecosystems/RatingsReviews';
import VendorDetails from '../../molecules/VendorDetails';
import InterLocalBadge from '../../atoms/InterLocalBadge';
import InterIcon from '../../../assets/icon/inter-icon.svg';
import LocalIcon from '../../../assets/icon/local-icon.svg';
import './style.scss';
import { dataLayer } from '@utils/datalayer';

const DEFAULT_IMG = 'http://placehold.jp/96x96.png';

class VendorAndReviews extends Component {
  state = {
    tabIndex: 0
  };
  setSelectTabIndex = (index) => {
    this.setState({ tabIndex: index });
  };
  render() {
    const { vendor, branch, badges, branchLevelStats, t, lang, source } = this.props;

    return (
      <div className="info_tab_wrapper mt-4" id="vendor-reviews-section">
        <DealerHeader
          vendor={vendor}
          branch={branch}
          branchLevelStats={branchLevelStats}
          t={t}
          lang={lang}
          source={source}
        />
        <Tabs selectedIndex={this.state.tabIndex} onSelect={(tabIndex) => this.setSelectTabIndex(tabIndex)}>
          <TabList>
            <Tab>
              <p
                className="tab_title"
                data-event-category="product_detail_section"
                data-event-action="click_for_read"
                data-event-label="dealer_detail"
                onClick={(e) => dataLayer(e)}
              >
                {t('review.vendor')}
              </p>
            </Tab>
            <Tab style={{ animation: 'none' }}>
              <p
                className="tab_title"
                data-event-category="product_detail_section"
                data-event-action="click_for_read"
                data-event-label="review_point"
                onClick={(e) => dataLayer(e)}
              >
                {t('review.from_user')}
              </p>
            </Tab>
          </TabList>

          <TabPanel>
            <VendorDetails vendor={vendor} branch={branch} badges={badges} source={source} />
          </TabPanel>
          <TabPanel>
            <RatingsReviews source={source} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const DealerHeader = ({ branchLevelStats, vendor, branch, t, lang, source }) => {
  return (
    <div className="container dealership">
      <div className="row">
        <p className="col-12" style={{ marginBottom: 12 }}>
          <b>{t('vendor.title')}</b>
        </p>
      </div>
      <div className="row dealership_wrapper">
        <div className="col-12 col-lg-6 pr-lg-0 dealership_content">
          <img src={vendor ? vendor.logo : DEFAULT_IMG} alt="Thumb 1e38b880 5f6a 43d8 8cdb f9615555a278" />
          <div className="dealership_name">
            <h4 className="m-0">{vendor ? vendor.title[lang] : 'Vendor Name'}</h4>
            <p className="branch_name">{branch ? branch.title[lang] : 'Branch Name'}</p>
            <div className="mt-1">
              <InterLocalBadge
                icon={source === 'inter' ? InterIcon : LocalIcon}
                type={source === 'inter' ? 'inter' : 'local'}
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 pl-lg-0 review_wrapper">
          <div className="review_recommend">
            {branchLevelStats.summary && branchLevelStats.summary.length > 0 && (
              <React.Fragment>
                <div className="accuracy_item">
                  {source === 'inter' ? (
                    <p className="d-flex flex-column align-items-center">
                      <i className="flaticon-guarantee" />
                      ได้รถรุ่นที่จอง
                      <br />
                      หรือเทียบเท่า
                    </p>
                  ) : (
                    <React.Fragment>
                      <p>
                        <i className="flaticon-guarantee" />
                        {branchLevelStats.summary[2].name}
                      </p>
                      <p
                        className={`text-percent ${branchLevelStats.summary[2].score <= 0 ? 'text-percent--zero' : ''}`}
                      >
                        {branchLevelStats.summary[2].percent <= 0 ? '-' : branchLevelStats.summary[2].percent}
                        <span>%</span>
                      </p>
                    </React.Fragment>
                  )}
                </div>
                <span className="vl" />
                <div className="recommend_item">
                  <p>
                    <i className="flaticon-like-1" />
                    {branchLevelStats.summary[5].name}
                  </p>
                  <p className={`text-percent ${branchLevelStats.summary[5].score <= 0 ? 'text-percent--zero' : ''}`}>
                    {branchLevelStats.summary[5].percent <= 0 ? '-' : branchLevelStats.summary[5].percent}
                    <span>%</span>
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className={'review_score'}>
            <p>คะแนนเฉลี่ย</p>
            <p className={`score ${branchLevelStats.total === 0 ? 'score--empty' : ''}`}>
              {branchLevelStats.rating}
              <span>/5</span>
            </p>
            <div className="review_total">
              {branchLevelStats.total === 0 ? (
                t('review.empty_text')
              ) : (
                <React.Fragment>
                  {branchLevelStats.level}{' '}
                  <span>
                    ({branchLevelStats.total} {t('review.text')})
                  </span>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorAndReviews;
