import React, { Component } from 'react';
import visalogo from '../../../assets/images/visa-mastercard-logo.png';
import DefaultBigbrandCondition from '../../organisms/RentalCondition/default';
import './style.scss';
import { withTranslation } from '../../../locales';

class NoteInfo extends Component {
  constructor(props) {
    super(props);
    this.panels = [];
    this.state = {
      isOpen: false
    };
  }
  togglePanels = () => {
    this.setState({ isOpen: !this.state.isOpen }, () => this.condition.togglePanels(!this.state.isOpen));
  };
  render() {
    const { t } = this.props;
    return (
      <div style={{ marginBottom: 20 }}>
        {/* <h3>ข้อมูลที่ควรรู้</h3> */}
        <div>
          <table className="table note-detail">
            <tbody>
              <tr>
                <td>
                  <strong> {t('info.confirmation')} </strong>
                </td>
                <td>{t('info.confirmation_detail')}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t('info.payment')}</strong>
                </td>
                <td>
                  <div>
                    <b>{t('info.payment_credit')}</b>
                    <p>{t('info.payment_credit_detail')}</p>
                    <img style={{ width: '50%' }} src={visalogo} alt="drivehub-visa" />
                  </div>
                  <div>
                    <b>{t('info.payment_cash')}</b>
                    <p>
                      <b>{t('info.payment_cash_detail')}</b>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t('info.vehicle_pickup')}</strong>{' '}
                </td>
                <td>{t('info.vehicle_pickup_detail')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ height: 30, position: 'relative' }}>
          <button
            className={`panel-toggle ${this.state.isOpen ? 'toggle-open' : 'toggle-close'}`}
            onClick={this.togglePanels}
          >
            {this.state.isOpen ? t('hide_all') : t('expand_all')}
          </button>
        </div>
        <DefaultBigbrandCondition ref={(ref) => (this.condition = ref)} hideVisa />
      </div>
    );
  }
}
export default withTranslation('car_detail')(NoteInfo);
