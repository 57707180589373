const initalState = {
  data: {},
  url: '',
  details: {},
  pricing: {},
}

const reducer = (state = initalState, { type, url, data }) => {
  switch (type) {
    case 'FETCH_HOC':
      // eslint-disable-next-line no-case-declarations
      const key = url.split('/')[url.split('/').length - 1].split('?')[0]
      return {
        ...state,
        url,
        data,
        [key]: data,
      }
    default:
      return state
  }
}

export default reducer
