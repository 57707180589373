import React, { PureComponent } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import { API_LEGACY_GET_BOOKING, API_BIGBRAND_GET_BOOKING, LEGACY_WEB } from '../../variables/API';
import { COMPLETED } from '../../variables/bookingStatus';
import NotificationBox from '../../components/molecules/NotificationBox';
import BookingStatus from '../../components/molecules/BookingStatus';
import CarDetail from '../../components/organisms/CarDetail';
import CustomerDetail from '../../components/molecules/CustomerDetail';
import DocumentDetail from '../../components/molecules/DocumentDetail';
import RentalDetail from '../../components/organisms/RentalDetail';
import RentalEndWithSurvey from '../../components/molecules/RentalEndWithSurvey';
import BookingVendorDetail from '../../components/molecules/BookingVendorDetail';
import BookingInsuranceDetail from '../../components/molecules/BookingInsuranceDetail';
import { LoadingBox } from '../../components/atoms/Loading';
import LoadingSVG from '../../assets/icon/loading.svg';
import ErrorPage from '../../components/molecules/ErrorPage';
import './style.scss';

const ENDPOINT = {
  local: API_LEGACY_GET_BOOKING,
  inter: API_BIGBRAND_GET_BOOKING
};
export default class Index extends PureComponent {
  state = {
    isLoading: true,
    isError: false
  };
  componentDidMount() {
    const { query } = queryString.parseUrl(window.location.href);
    const lastName = query.last_name;
    const bookingID = this.props.match.params.id;
    const endpoint = ENDPOINT[this.source];

    axios
      .get(`${endpoint}/${bookingID}`, {
        params: {
          last_name: lastName
        }
      })
      .then(({ data }) => {
        this.setState({ ...data }, () => {
          this.getRatingReviewBranch(data.dealer.branch_id);
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false, isError: true });
      });
  }

  getRatingReviewBranch = (branchId) => {
    const source = ['local', 'original'].includes(this.source) ? 'original' : 'bigbrand';
    axios
      .get(`${LEGACY_WEB}/api/v1/th/branch/${branchId}/stat?source=${source}`)
      .then((response) => {
        this.setState({ rating: response.data, isLoading: false });
      })
      .catch((error) => console.log(`Cannot get rating on branchId ${branchId}: ${error}`));
  };

  get source() {
    const { query } = queryString.parseUrl(window.location.href);
    const source = query.source;
    if (['local', 'original'].includes(source)) {
      return 'local';
    } else {
      return 'inter';
    }
  }

  get insurance() {
    if (this.source === 'local') {
      const { dealer } = this.state;
      if (dealer.badges.length === 0) return null;
      const haveZeroExcess = dealer.badges.filter((b) => b.name === 'ZERO_EXCESS').length > 0;
      if (haveZeroExcess)
        return {
          id: 1,
          description: undefined,
          name: 'ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess',
          type: 'Zero Excess'
        };
      return null;
    }
    const { has_insurances, insurance_default, insurance } = this.state;
    if (has_insurances) return insurance ? insurance : insurance_default;
    else return null;
  }
  get lastName() {
    const { query } = queryString.parseUrl(window.location.href);
    const lastName = query.last_name;
    return lastName;
  }
  get bookingID() {
    return this.props.match.params.id;
  }
  get feature() {
    if (this.state.feature) {
      return this.state.feature;
    }
    return {};
  }

  render() {
    if (this.state.isLoading)
      return (
        <div style={{ height: '65vh' }}>
          <LoadingBox bg={LoadingSVG} dark />
        </div>
      );
    if (this.state.isError) return <ErrorPage />;
    const { booking, customer, dealer, transitions, rating } = this.state;
    return (
      <Container>
        <Helmet>
          <title>คำขอจองของคุณได้ถูกสร้างขึ้นแล้ว! — Drivehub</title>
        </Helmet>
        <Layout className="container">
          <NotificationBox status={booking.current_state} createdAt={booking.created_at} />
          <div className="row">
            <div className="col-12 col-lg-4">
              <div>
                <BookingStatus
                  transitions={transitions}
                  bookingId={booking.id}
                  pickupDatetime={booking.pickup_datetime}
                  returnDatetime={booking.return_datetime}
                  source={this.source}
                />
              </div>
              <div>
                <CustomerDetail customer={customer} onOpenContactModal={() => alert('contact modal')} />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <DocumentDetail
                source={this.source}
                currentState={booking.current_state}
                bookingID={this.bookingID}
                lastName={this.lastName}
                feature={this.feature}
              />
              {booking.current_state === COMPLETED && (
                <RentalEndWithSurvey source={this.source} bookingId={booking.id} />
              )}
              <div>
                {rating && <BookingVendorDetail branchLevelStats={rating} dealer={dealer} source={this.source} />}
              </div>
              <div>
                <CarDetail car={this.state.car} source={this.source} />
              </div>
              <div>
                <RentalDetail
                  source={this.source}
                  pricing={this.state.pricing}
                  booking={this.state.booking}
                  insurance={this.state.insurance}
                  insurance_default={this.state.insurance_default}
                />
              </div>
              <div>
                <BookingInsuranceDetail source={this.source} insurance={this.insurance} />
              </div>
            </div>
          </div>
        </Layout>
      </Container>
    );
  }
}

const Container = styled.div`
  background-color: #f9f9f9;
  font-size: 16px;

  @media (max-width: 767px) {
    .row {
      margin-left: 0px;
      margin-right: 0px;
      .col-12 {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
`;

const Layout = styled.div`
  padding: 32px 0;
  @media (max-width: 767px) {
    padding: 0 !important;
  }
`;
