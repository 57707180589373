import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../ducks/actions/booking';
import queryString from 'query-string';

import './style.scss';
import { withTranslation } from '../../../locales';
import { dataLayer } from '@utils/datalayer';

class CouponBox extends Component {
  state = {};
  static getDerivedStateFromProps(props) {
    if (window) {
      const query = queryString.parse(window.location.search);
      if (query.source === 'inter') return null;
      if (!props.campaign_name) return null;
      if (!props.campaign_name.includes('retention')) return null;
    }
    return null;
  }

  get isAppied() {
    if (this.props.code === '') return false;
    if (this.props.isTyping) return false;
    return this.props.discount_amount > 0;
  }
  get isCodeValid() {
    if (this.props.code === '') return true;
    return this.props.is_code_valid === true;
  }
  get isPulse() {
    if (this.props.code === '') return false;
    if (this.props.isTyping) return true;
    if (this.props.code === null) return false;
    if (this.isCodeValid) return false;
    return true;
  }
  onApplyCoupon = (clear) => {
    if (clear === '') {
      this.props.bookingFieldChange('code', '');
      this.props.onApplyCoupon('');
    } else {
      this.props.onApplyCoupon(this.props.code);
    }
  };
  handleInputChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^A-Za-z0-9-.]/gi, '');
    if (value.includes('-')) {
      this.props.bookingFieldChange('code', value);
    } else {
      this.props.bookingFieldChange('code', value.toUpperCase());
    }
  };
  render() {
    if (this.props.step > 0) {
      return <div />;
    }
    const { t } = this.props;

    return (
      <React.Fragment>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.onApplyCoupon();
          }}
          className="coupon__box__wrapper"
        >
          <div>
            <input
              value={this.props.code}
              onChange={this.handleInputChange}
              placeholder={t('placeholder')}
              className={`form-control ${this.isAppied && 'coupon-applied'} ${!this.isCodeValid && 'coupon-invalid'}`}
            />
            <i
              className={`${
                this.isAppied ? 'flaticon-check valid-coupon coupon-icon' : 'flaticon-price coupon-icon black-icon'
              } price-tag`}
              aria-hidden="true"
            />
            {this.props.code && (
              <i
                className="flaticon-close cancel-coupon"
                aria-hidden="true"
                data-event-category="bookings_section"
                data-event-action="clear"
                data-event-label="coupon"
                onClick={(e) => {
                  dataLayer(e);
                  this.onApplyCoupon('');
                }}
              />
            )}
          </div>
          <button
            className={`btn ${this.isPulse ? 'dh-animate-pulse' : ''} btn-primary-ghost`}
            data-event-category="bookings_section"
            data-event-action="use"
            data-event-label="coupon"
            onClick={(e) => {
              dataLayer(e);
              this.onApplyCoupon(e);
            }}
          >
            {t('use')}
          </button>
        </form>
        <div className="coupon__box__wrapper">
          {this.isCodeValid === false && (
            <div>
              <small style={{ color: '#AE0015' }}>{t('fail')}</small>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  code: state.booking.code === null ? props.code : state.booking.code,
  is_code_valid: props.is_code_valid ? true : state.booking.code !== props.code,
  step: state.booking.step
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ bookingFieldChange: actions.bookingFieldChange }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('coupon')(CouponBox));
