import React, { Component } from 'react';
import InsuranceTable from '../InsuranceTable';

import Modal from 'rc-dialog';

import './style.scss';

export default class InsuranceDialog extends Component {
  state = {
    visible: false
  };

  toggle = () => {
    window.toggleSticky();
    this.setState({
      visible: !this.state.visible
    });
  };
  get content() {
    return (
      <div className="instant-book-wrapper">
        <div className="instant-book-content">
          <h3 className="typo__header text-primary">
            ประกันที่ครอบคลุมค่าเสียหายประเภทต่างๆ <br />
            เพื่อลดค่าเสียหายส่วนแรก
          </h3>
          <p>
            ในกรณีที่เกิดความเสียหายระหว่างการเช่า <b>ท่านไม่ต้องรับผิดชอบต่อคค่าเสียหายในรายการที่ครอบคลุม</b>{' '}
            การคุ้มครองนี้มีผลต่อเมื่อท่านปฏิบัติตามข้อตกลงการเช่าเท่านั้น
          </p>
          <InsuranceTable />
          <p>*อัตราราคาต่อวันขึ้นอยู่กับประเภทรถ</p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        closable
        destroyOnClose
        visible={this.state.visible}
        onClose={this.toggle}
        animation="fade"
        maskAnimation="fade"
        className="modal--insurance"
      >
        <div className="container pt-3 pb-3">{this.content}</div>
      </Modal>
    );
  }
}
