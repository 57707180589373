/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import queryString from 'query-string';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { bindActionCreators } from 'redux';
import throttle from 'lodash.throttle';
import { StickyContainer, Sticky } from 'react-sticky';
import StickyBox from 'react-sticky-box';
import LoadingBar from 'react-top-loading-bar';
import { withTranslation } from '../../locales';
import { fetchNextCars, initialFetchCars } from '../../ducks/actions/cars';
import { getAvailableFeatures } from '../../ducks/actions/features';
import {
  location as locationActions,
  pickupDatetime as pickupDatetimeActions,
  returnDatetime as returnDatetimeActions,
  category as categoryActions,
  dealer as dealerActions
} from '../../ducks/actions/filter';
import MainMenu from '../../components/organisms/MainMenu';
import ProgressBar from '../../components/molecules/ProgressBar';
import ValueProp from '../../components/molecules/ValueProp';
// import Footer from '../../components/molecules/Footer'
import Footer from '@ui/layouts/footer';

import FilterDesktop from '../../components/organisms/FilterDesktop';
import FilterMobile from '../../components/organisms/FilterMobile';
import FilterBar from '../../components/organisms/FilterBar';

import Faq from '../../components/organisms/Faq';
import CardDetails from '../../components/organisms/CardDetails';
import SearchBar from '../../components/ecosystems/SearchBar';
import { LoadingBox, LoadingIcon } from '../../components/atoms/Loading';
import IntroduceModal from '../../components/organisms/IntroduceModal';
import IntroduceModalCNX from '../../components/organisms/IntroduceModalCNX';
import { SEARCH_CARS } from '../../variables/page';
import LoadingSVG from '../../assets/icon/loading.svg';
import EmptyCar from '../../assets/images/emptyCar.png';
import LoadingScreen from '../../components/organisms/LoadingScreen';

// import ReturningPopup from '../../components/ecosystems/ReturningPopup'
import RegisterModal from '../../components/organisms/RegisterModal';
import ShareButton, { LinkType } from '../../components/atoms/ShareButton';
import { CS_MODE } from '../../variables/features';
import './style.scss';
import { defaultSearchDataLayerArgs, pushDataLayer } from '@utils/datalayer';

const CNX_LOCATION = ['1', '2'];

class SearchResultPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchBarMobileOpen: false,
      isMobileFilterOpen: false,
      isShowGuide: false
    };
  }

  componentDidMount() {
    if (CS_MODE) this.csInit();
    document.title = 'ผลการค้นหา — Drivehub';
    this.props.reduxInit();
    window.addEventListener('scroll', throttle(this.onScroll, 1000), false);

    this.props.getAvailableFeatures();

    const { query } = queryString.parseUrl(window.location.href);
    const { booking_begin, booking_end, location_id } = query;

    if (booking_begin && booking_end && location_id) {
      this.loading();
      this.props.initialFetchCars();
    }
    const isSeenGuide = window.localStorage.getItem('isSeenGuide');

    if (isSeenGuide === null) {
      window.localStorage.setItem('isSeenGuide', 'true');
      setTimeout(() => {
        this.setState({
          isShowGuide: true
        });
        this.scrollGuideToViewDesktop();
      }, 4750);
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', throttle(this.onScroll, 1000), false);
  }

  csInit = () => {
    const date = moment().format('DD-MM-YY');
    const param = queryString.parse(window.location.search);
    const searchKey = Object.keys(param);
    if (
      !searchKey.includes('utm_source') ||
      !searchKey.includes('utm_medium') ||
      !searchKey.includes('utm_campaign') ||
      !searchKey.includes('utm_content') ||
      param['utm_source'] !== 'callcenter' ||
      param['utm_medium'] !== 'callcenter' ||
      param['utm_campaign'] !== 'employees' ||
      param['utm_content'] !== `employees${date}`
    ) {
      param['utm_source'] = 'callcenter';
      param['utm_medium'] = 'callcenter';
      param['utm_campaign'] = 'employees';
      param['utm_content'] = `employees${date}`;
      const stringified = queryString.stringify(param);
      window.location.search = stringified;
    }
  };

  loading() {
    if (this.LoadingBar) {
      setTimeout(() => {
        this.LoadingBar.complete();
      }, 1000);
      this.LoadingBar.continousStart();
    }
  }

  handleClickOutside = (event) => {
    this.filterGuideBox = document.getElementsByClassName('filter-guide__box')[0];
    this.filterGuideBoxMobile = document.getElementsByClassName('filter-guide__box--mobile')[0];
    try {
      if (
        (this.filterGuideBox && !this.filterGuideBox.contains(event.target)) ||
        (this.filterGuideBoxMobile && !this.filterGuideBoxMobile.contains(event.target))
      ) {
        this.onCloseFilterGuideBox();
      }
    } catch (error) {
      console.log('Cannot close guide box: ', error);
      this.onCloseFilterGuideBox();
    }
  };

  scrollGuideToViewDesktop = () => {
    window.scrollTo({
      top: 180,
      behavior: 'smooth'
    });
  };

  showGuidehandle = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({
      isShowGuide: true
    });
  };

  onCloseFilterGuideBox = () => {
    this.setState({
      isShowGuide: false
    });
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.body.style.overflow = 'unset';
    window.scrollTo(0, 0);
  };

  onScroll = () => {
    const { cars } = this.props;
    const hasCars = this.props.cars.list.length > 0;
    let isNoMoreCars = !cars.fetching && cars.next === null && cars.list.length !== 0;
    if (cars.next === null) {
      isNoMoreCars = true;
    }
    const triggerOffset = document.getElementsByTagName('footer')[0].offsetTop;
    if (
      !isNoMoreCars &&
      window.innerHeight + window.scrollY + 500 >= triggerOffset &&
      hasCars &&
      !this.props.cars.fetching
    ) {
      this.onLoadMoreCars();
    }
  };

  onLoadMoreCars = () => {
    if (this.props.cars.next) {
      this.props.fetchNextCars();
    }
  };

  onToggleMobileFilter = (open) => {
    this.setState({ isMobileFilterOpen: open });
    if (open || this.state.isSearchBarMobileOpen) {
      this.disableScrolling();
    } else {
      this.enableScrolling();
    }
  };

  onToggleSearchBarMobile = (open) => {
    this.setState({ isSearchBarMobileOpen: open });
    if (open || this.state.isMobileFilterOpen) {
      this.disableScrolling();
    } else {
      this.enableScrolling();
    }
  };

  enableScrolling = () => {
    document.body.className = '';
  };

  disableScrolling = () => {
    document.body.className = 'stop-scrolling';
  };

  isInFestivalRange = () => {
    const query = queryString.parse(this.props.location.search);

    const pickupDate = moment(query.pickup_datetime);
    const returnDate = moment(query.return_datetime);

    if (!query.pickup_datetime || !query.return_datetime) return false;

    let isInFestivalRange = false;
    isInFestivalRange = [pickupDate, returnDate].some((date) =>
      date.isBetween('2019-03-31T23:59:59+07:00', '2019-04-16T23:59:59+07:00')
    );
    if (pickupDate.isSameOrBefore('2019-04-01') && returnDate.isSameOrAfter('2019-04-16')) {
      isInFestivalRange = true;
    }
    return isInFestivalRange;
  };

  isIn10to13Apr = () => {
    const query = queryString.parse(this.props.location.search);

    const pickupDate = moment(query.pickup_datetime);
    const returnDate = moment(query.return_datetime);

    if (!query.pickup_datetime || !query.return_datetime) return false;

    let isIn10to13Apr = false;
    isIn10to13Apr = [pickupDate, returnDate].some((date) =>
      date.isBetween('2019-04-09T23:59:59+07:00', '2019-04-13T23:59:59+07:00')
    );
    if (pickupDate.isSameOrBefore('2019-04-09') && returnDate.isSameOrAfter('2019-04-13')) {
      isIn10to13Apr = true;
    }
    return isIn10to13Apr;
  };

  onSubmit = (state) => {
    this.loading();
    this.props.selectLocation(state.locationID);
    setTimeout(() => {
      this.props.selectPickupDatetime(state.pickup_datetime);
    }, 5);
    setTimeout(() => {
      this.props.selectReturnDatetime(state.return_datetime);
    }, 10);
    setTimeout(() => {
      this.props.initialFetchCars();
      this.props.fetchCategories();
      this.props.fetchDealers();
    }, 15);
  };

  getSnapshotBeforeUpdate(prevProps) {
    const nextCarCount = this.props.cars.count;
    const prevCarCount = prevProps.cars.count;

    if (nextCarCount === 0 && nextCarCount !== prevCarCount) {
      const dataLayerArgs = defaultSearchDataLayerArgs(this.props.filter);
      pushDataLayer({
        ...dataLayerArgs,
        event_category: 'search_bookings_section',
        event_action: 'search_bookings_success',
        event_label: 'no_available_car'
      });
    }
    return null;
  }

  render() {
    const { location, cars, features, t, filter } = this.props;
    const { isMobileFilterOpen, isSearchBarMobileOpen } = this.state;
    const query = queryString.parse(location.search);

    const isFirstLoading = (cars.list.length === 0 && cars.fetching) || features.fetching;

    const isMoreLoading = cars.list.length > 0 && cars.fetching;
    const isNoMoreCars = !cars.fetching && cars.next === null && cars.list.length !== 0;
    const isCarNotFound = cars.list.length === 0 && !cars.fetching;
    const isSearchBarFilterOpen = isMobileFilterOpen || isSearchBarMobileOpen;

    return (
      <React.Fragment>
        <LoadingScreen />
        <MediaQuery minWidth={992}>
          <MainMenu sticky={false} />
        </MediaQuery>

        <StickyContainer className="search-page-wrapper" ref="scrollWrapper">
          {/* <ReturningPopup prefix={'th'} /> */}
          <RegisterModal />
          {/* --- Begin : Desktop Search Bar --- */}
          <MediaQuery minWidth={992}>
            <LoadingBar height={3} color="#0078ff" onRef={(ref) => (this.LoadingBar = ref)} />
            <Sticky disableHardwareAcceleration>
              {({ style }) => {
                const modifiedStyle = { ...style, zIndex: 99 };
                return (
                  <div style={modifiedStyle}>
                    <SearchBar onSubmit={this.onSubmit} onToggleSearchBarMobile={this.onToggleSearchBarMobile} />
                    {CS_MODE && (
                      <div
                        className="d-flex flex-column"
                        style={{ zIndex: 99, position: 'absolute', right: '0.5rem', minWidth: '150px' }}
                      >
                        <ShareButton type={LinkType.FacebookAndIG} page="search" className="flex-grow-1" />
                        <ShareButton type={LinkType.Line} page="search" className="flex-grow-1" />
                        <ShareButton type={LinkType.Other} page="search" className="flex-grow-1" />
                      </div>
                    )}
                  </div>
                );
              }}
            </Sticky>
            <MediaQuery minWidth={992}>
              <ProgressBar active={SEARCH_CARS} />
            </MediaQuery>
          </MediaQuery>
          {/* --- End : Desktop Search Bar --- */}

          {/* --- Begin : Tablet & Mobile Search Bar --- */}
          <MediaQuery maxWidth={991}>
            <Sticky disableHardwareAcceleration>
              {({ style, isSticky }) => {
                const modifiedStyle = { ...style, zIndex: 100, height: 128 };
                return (
                  <div
                    style={modifiedStyle}
                    id="sticky-head"
                    className={`${isSticky ? '' : isSearchBarFilterOpen ? 'z-index-99' : ''}`}
                  >
                    <MainMenu sticky={false} />
                    <SearchBar onSubmit={this.onSubmit} onToggleSearchBarMobile={this.onToggleSearchBarMobile} />
                    {CS_MODE && (
                      <div
                        className="d-flex flex-column"
                        style={{ zIndex: 99, position: 'absolute', right: '0.5rem', minWidth: '150px' }}
                      >
                        <ShareButton type={LinkType.FacebookAndIG} size="sm" page="search" className="flex-grow-1" />
                        <ShareButton type={LinkType.Line} size="sm" page="search" className="flex-grow-1" />
                        <ShareButton type={LinkType.Other} size="sm" page="search" className="flex-grow-1" />
                      </div>
                    )}
                  </div>
                );
              }}
            </Sticky>
            <FilterMobile t={this.props.t} />
            <Faq ref={(ref) => (this.faq = ref)} isSearchResultPage={true} />
          </MediaQuery>
          {/* --- End : Tablet & Mobile Search Bar --- */}

          {/* --- Begin :  Gray BG --- */}
          <MediaQuery maxWidth={991}>
            {(matches) => {
              if (matches) {
                if (isSearchBarFilterOpen) this.disableScrolling();
                return isSearchBarFilterOpen ? <div className="gray-bg" /> : '';
              } else {
                this.enableScrolling();
                return null;
              }
            }}
          </MediaQuery>
          {/* --- End :  Gray BG --- */}

          <div className="container">
            <div className="row">
              <MediaQuery minWidth={992}>
                <div className="col-lg-4" style={{ position: 'relative', marginBottom: 15 }}>
                  <FilterDesktop t={this.props.t} />
                  <StickyBox offsetTop={70} offsetBottom={20}>
                    <ValueProp />
                    <Faq isSearchResultPage={true} />
                  </StickyBox>
                </div>
              </MediaQuery>
              <div className="col-12 col-lg-8">
                {this.isInFestivalRange() && !CNX_LOCATION.includes(query.location_id) && <IntroduceModal />}
                {this.isIn10to13Apr() && CNX_LOCATION.includes(query.location_id) && <IntroduceModalCNX />}

                <MediaQuery minWidth={992}>
                  {(matches) => {
                    if (matches) {
                      return <FilterBar />;
                    } else {
                      return <FilterBar onToggleFaq={this.faq && this.faq.toggle} />;
                    }
                  }}
                </MediaQuery>
                {isFirstLoading ? (
                  <div className="loading-box">
                    <LoadingBox bg={LoadingSVG} dark />
                  </div>
                ) : (
                  <React.Fragment>
                    {isCarNotFound && (
                      <div className="margin-top-sm">
                        <img className="empty-car" src={EmptyCar} alt={t('not_found')} />
                      </div>
                    )}
                    {filter.filterLayout.filterLayoutMobileOpen ? (
                      <div style={{ height: '100vh' }} />
                    ) : (
                      <CardDetails cars={cars} />
                    )}
                    {/* <CardDetails cars={cars} /> */}
                    {isMoreLoading && (
                      <div className="text-center infinite-load">
                        <LoadingIcon bg={LoadingSVG} dark />
                      </div>
                    )}
                    {isNoMoreCars && <div className="alert alert-secondary text-center">{t('no_more')}</div>}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </StickyContainer>
        <Footer prefix="th" />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchNextCars,
      initialFetchCars,
      getAvailableFeatures,
      selectPickupDatetime: pickupDatetimeActions.select,
      selectReturnDatetime: returnDatetimeActions.select,
      selectLocation: locationActions.select,
      fetchCategories: categoryActions.get,
      fetchDealers: dealerActions.get,
      reduxInit: () => ({
        type: '@@INIT'
      })
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  cars: state.cars,
  filter: state.filter,
  features: state.features
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('filter')(SearchResultPage));
