let _GTM_ID = '';
let _GA_ID = '';
let _TRACKJS_VERSION = '';
let _OPTIMIZE_ID = '';

if (process.env.REACT_APP_STAGE === 'production') {
  _GTM_ID = process.env.REACT_APP_GTM_ID;
  _GA_ID = process.env.REACT_APP_GA_ID;
  _TRACKJS_VERSION = process.env.REACT_APP_TRACKJS || 'app-staging';
  _OPTIMIZE_ID = process.env.REACT_APP_OPTIMIZE_ID;
} else {
  _GTM_ID = 'GTM-W3TRFG5';
  _GA_ID = 'UA-83410238-3';
  _OPTIMIZE_ID = 'GTM-PKCBGVW';
}

export const DEFAULT_IMG = 'http://via.placeholder.com/350x200';
export const DEFAULT_LOGO = 'http://via.placeholder.com/52x52';
export const GTM_ID = _GTM_ID;
export const GA_ID = _GA_ID;
export const TRACKJS_VERSION = _TRACKJS_VERSION;
export const OPTIMIZE_ID = _OPTIMIZE_ID;
