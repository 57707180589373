import React from 'react'

const question = {
  icon: '',
  question: 'ยกเลิกการจองอย่างไร?',
  answer: (
    <div>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        ท่านสามารถแจ้งการยกเลิกได้ 2 ช่องทาง <br />
        <div className="indent">1. บริษัทรถเช่าที่ท่านทำการจองเข้ามา</div>
        <div className="indent">
          2. เจ้าหน้าที่ Drivehub ทีเบอร์ 02-038-5222 หรือ Line Id: @Drivehub
        </div>
      </div>
      <p className="indent">
        กรณียกเลิก 72 ชั่วโมงก่อนรับรถ ท่านจะได้รับค่าล็อคคิวรถคืน
        ทั้งนี้เงื่อนไขการคืนเงินบริษัทรถเช่าอาจมีการเปลี่ยนแปลง
        ท่านสามารถสอบถามข้อมูลกับบริษัทรถเช่าได้โดยตรง
      </p>
      <b>
        <u>หากท่านทำการจองรถที่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        ท่านสามารถแจ้งการยกเลิกโดยตอบกลับอีเมลล์การจองที่ท่านได้รับจาก Drivehub โดยการยกเลิก 48
        ชม.ก่อนรับรถ ระบบจะส่งเรื่องขอคืนเงินไปยังธนาคารภายในวันทำการถัดไป
        <div>
          <b>
            *การคืนเงินจะขึ้นอยู่กับนโยบายของแต่ละธนาคาร
            ตามปกติแล้วผู้ถือบัตรจะได้รับเงินคืนในรอบบิลถัดไป
          </b>
        </div>
      </div>
      <u>เงื่อนไขในการได้รับเงินคืนในกรณีที่มีการยกเลิกการจองนั้น ให้เป็นไปตามที่ระบุดังต่อไปนี้</u>
      <ul>
        <li>หากมีการยกเลิก 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านจะได้รับเงินคืนเต็มจำนวน</li>
        <li>
          หากมีการยกเลิกภายใน 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านอาจจะได้รับเงินคืนบางส่วน
          เป็นจำนวนที่ได้ชำระเข้ามาหักลบด้วยค่าเช่า 3 วัน
        </li>
        <li>
          ถ้าท่านได้ทำจองรถน้อยกว่า 3 วัน จะไม่มีค่าใช้จ่ายใด ๆเพิ่มเติม แต่จะไม่ได้รับเงินคืน
        </li>
        <li>
          <p>
            ในกรณีที่ท่านไม่สามารถไปรับรถได้ในเวลาที่ตกลงกัน / หรือเอกสารที่จำเป็นไม่ครบ /
            หรือบัตรเครดิตมีวงเงินไม่เพียงพอที่จะเช่ารถ บริษัทรถเช่าจะสงวนสิทธิ์การเช่า
            และไม่ได้รับเงินคืน ทั้งนี้
          </p>
          <u>กรณีที่ไม่แสดงตัว</u>
          <div>ท่านไม่สามารถได้รับเงินคืนในกรณีที่ท่านไม่แสดงตัว ซึ่งหมายถึงเมื่อท่าน</div>
          <ul>
            <li>
              ต้องการยกเลิก แต่ไม่ได้แจ้งให้ทางบริษัทหรือ Drivehub.co
              ทราบก่อนจะเริ่มกำหนดการเช่าตามเวลาที่กำหนด
            </li>
            <li>ไม่สามารถรับรถได้ตามเวลาที่แจ้งไว้</li>
            <li>ไม่สามารถให้เอกสารบริษัทรถเช่าร้องขอให้แสดงเมื่อไปรับรถ</li>
            <li>
              ไม่สามารถที่จะให้บัตรเครดิตในชื่อของผู้ขับขี่หลักหรือวงเงินในบัตรเครดิตไม่เพียงพอค่ามัดจำ
            </li>
          </ul>
          <b>
            ทั้งนี้บริษัทรถเช่าขอสงวนสิทธิที่จะไม่ส่งมอบรถให้ลูกค้าที่มารับรถไม่ตรงเวลา
            หรือไม่มีเอกสารในการจองรถเช่า หรือวงเงินในบัตรเครดิตไม่เพียงพอสำหรับเป็นค่ามัดจำการเช่า
          </b>
        </li>
      </ul>
    </div>
  ),
}

export default question
