import React, { Component } from 'react';
import propTypes from 'prop-types';
import InterLocalBadge from '../../atoms/InterLocalBadge';
import InterIcon from '../../../assets/icon/inter-icon.svg';
import LocalIcon from '../../../assets/icon/local-icon.svg';
import MediaQuery from 'react-responsive';
import './style.scss';

class Checkbox extends Component {
  static propTypes = {
    value: propTypes.string.isRequired,
    disabled: propTypes.bool,
    subLabel: propTypes.object,
    image: propTypes.oneOf('creditCard'),
    isChecked: propTypes.bool,
    handleCheckboxChange: propTypes.func.isRequired,
    className: propTypes.string
  };
  render() {
    const { label, image, subLabel, value } = this.props;
    const { isChecked, handleCheckboxChange, className, disabled } = this.props;

    return (
      <div className={`${className ? className : ''} checkbox-wrapper`}>
        <div className="pl-4 pr-0">
          <label>
            <input
              type="checkbox"
              value={value}
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={`checkbox ${disabled ? 'disabled' : ''}`}
              disabled={disabled}
            />
            <div className="checkmark" />
            <MediaQuery maxWidth={768}>
              {(matches) => {
                if (matches) {
                  // Mobile
                  return (
                    <span className="label-title">
                      {image === 'creditCard' ? (
                        <span className="mr-2">
                          <InterLocalBadge icon={InterIcon} type="inter" size="small" />
                        </span>
                      ) : (
                        <span className="mr-2">
                          <InterLocalBadge icon={LocalIcon} type="local" size="small" />
                        </span>
                      )}
                      {label}
                    </span>
                  );
                } else {
                  // Desktop
                  return (
                    <span className="label-title">
                      {label}
                      {image === 'creditCard' ? (
                        <span className="ml-2">
                          <InterLocalBadge icon={InterIcon} type="inter" size="small" />
                        </span>
                      ) : (
                        <span className="ml-2">
                          <InterLocalBadge icon={LocalIcon} type="local" size="small" />
                        </span>
                      )}
                    </span>
                  );
                }
              }}
            </MediaQuery>
          </label>
          <span className="sub-label">{subLabel}</span>
        </div>
      </div>
    );
  }
}

export default Checkbox;
