import { defineAction } from 'redux-define';
import { combineReducers } from 'redux';
import queryString from 'query-string';

const STATE = ['FETCHING', 'FETCHED', 'ERROR', 'INITIAL', 'SELECTED', 'UNSELECTED', 'TOGGLE', 'ONCHANGE'];
export const CATEGORY = defineAction('CATEGORY', STATE);
export const DEALER = defineAction('DEALER', STATE);
export const CITY = defineAction('CITY', STATE);
export const LOCATION = defineAction('LOCATION', STATE);
export const SOURCE = defineAction('SOURCE', STATE);
export const PICKUP = defineAction('PICKUP', STATE);
export const RETURN = defineAction('RETURN', STATE);
export const SORTBY = defineAction('SORTBY', STATE);
export const COUNT = defineAction('COUNT', STATE);

export const singleFactory = (actions, queryName = '', skippingActions = []) => {
  const initial = {
    list: [],
    selected: ''
  };
  return (state = initial, { type, payload }) => {
    switch (type) {
      case '@@INIT': {
        const { query } = queryString.parseUrl(window.location.search);
        console.log(query);
        if (queryName === '' || !query[queryName]) return state;

        return {
          ...state,
          selected: query[queryName]
        };
      }
      case 'FILTER_CLEAR': {
        if (skippingActions.includes(type)) return { ...state };
        if (queryName !== '') {
          const { query } = queryString.parseUrl(window.location.search);
          const nextQuery = Object.assign({}, query);
          delete nextQuery[queryName];
          const q = queryString.stringify(nextQuery);
          window.history.pushState('', '', '?' + q);
        }
        return {
          ...state,
          selected: ''
        };
      }
      case actions.SELECTED:
      case actions.ONCHANGE: {
        const { query } = queryString.parseUrl(window.location.search);
        if (queryName !== '' && payload) {
          const q = queryString.stringify({ ...query, [queryName]: payload });
          window.history.pushState('', '', '?' + q);
        }
        if (queryName !== '' && payload === '') {
          delete query[queryName];
          const q = queryString.stringify({ ...query });
          window.history.pushState('', '', '?' + q);
        }
        return {
          ...state,
          selected: payload
        };
      }
      case actions.FETCHED: {
        return {
          ...state,
          list: payload
        };
      }
      default:
        return state;
    }
  };
};

/**
 * @param  {Array} actions
 * @param  {String} queryName
 */
export const factory = (actions, queryName = '', skippingActions = []) => {
  const initial = {
    fetching: false,
    selected: [],
    list: []
  };

  return (state = initial, { type, payload }) => {
    if (skippingActions.includes(type)) return state;
    switch (type) {
      case '@@INIT': {
        const { query } = queryString.parseUrl(window.location.search);

        if (queryName === '' || !query[queryName]) return state;

        return {
          ...state,
          selected: query[queryName].split(',')
        };
      }
      case 'FILTER_CLEAR': {
        if (queryName !== '') {
          const { query } = queryString.parseUrl(window.location.search);
          const nextQuery = Object.assign({}, query);
          delete nextQuery[queryName];
          const q = queryString.stringify(nextQuery);
          window.history.pushState('', '', '?' + q);
        }
        return {
          ...state,
          selected: []
        };
      }
      case actions.FETCHING: {
        return {
          ...state,
          fetching: true
        };
      }
      case actions.FETCHED: {
        const nextSelected = Object.assign([], state.selected).filter((id) =>
          payload.find((p) => p.id + '' === id + '') ? true : false
        );

        return {
          ...state,
          fetching: false,
          list: payload,
          selected: nextSelected
        };
      }
      case actions.SELECTED: {
        const nextSelected = payload.map((i) => i + '');
        const { query } = queryString.parseUrl(window.location.search);
        if (queryName !== '' && nextSelected.length > 0) {
          const q = queryString.stringify({ ...query, [queryName]: nextSelected.join(',') });
          window.history.pushState('', '', '?' + q);
        }
        return {
          ...state,
          selected: nextSelected
        };
      }
      case actions.TOGGLE: {
        let nextSelected = Object.assign([], state.selected).map((i) => i + '');
        if (nextSelected.includes(payload + '')) {
          nextSelected = nextSelected.filter((s) => s !== payload + '');
        } else {
          nextSelected = nextSelected.concat(payload + '');
        }

        const { query } = queryString.parseUrl(window.location.search);
        if (queryName !== '' && nextSelected.length > 0) {
          const q = queryString.stringify({ ...query, [queryName]: nextSelected.join(',') });
          window.history.pushState('', '', '?' + q);
        }
        if (queryName !== '' && nextSelected.length === 0) {
          const nextQuery = Object.assign({}, query);
          delete nextQuery[queryName];
          const q = queryString.stringify(nextQuery);
          window.history.pushState('', '', '?' + q);
        }
        return {
          ...state,
          selected: nextSelected
        };
      }
      case actions.ERROR: {
        return {
          ...state,
          fetching: false
        };
      }
      default:
        return state;
    }
  };
};

const category = factory(CATEGORY, 'categories');
const dealer = factory(DEALER, 'dealers');
const source = factory(SOURCE, 'sources');

const city = factory(CITY);
const pickupDatetime = singleFactory(PICKUP, 'booking_begin', ['FILTER_CLEAR']);
const returnDatetime = singleFactory(RETURN, 'booking_end', ['FILTER_CLEAR']);
const location = singleFactory(LOCATION, 'location_id', ['FILTER_CLEAR']);
const sortBy = singleFactory(SORTBY, 'sort_by', ['FILTER_CLEAR']);

const countInitial = {
  fetching: false,
  cars: 0,
  dealers: 0
};
export const count = (state = countInitial, { type, payload }) => {
  switch (type) {
    case COUNT.FETCHING: {
      return {
        ...state,
        fetching: true
      };
    }
    case COUNT.FETCHED: {
      return {
        fetching: false,
        cars: payload.cars,
        dealers: payload.dealers
      };
    }
    default:
      return state;
  }
};

const FILTER_LAYOUT_ACTION = ['INITIAL', 'OPEN', 'CLOSE'];

export const FILTER_LAYOUT = defineAction('FILTER_LAYOUT', FILTER_LAYOUT_ACTION);

const initialFilterLayout = {
  filterLayoutMobileOpen: false,
  initialFilterSelected: {}
};

export const filterLayout = (state = initialFilterLayout, { type, payload }) => {
  switch (type) {
    case FILTER_LAYOUT.INITIAL: {
      return state;
    }
    case FILTER_LAYOUT.OPEN: {
      return {
        filterLayoutMobileOpen: true,
        initialFilterSelected: payload
      };
    }
    case FILTER_LAYOUT.CLOSE: {
      return {
        ...state,
        filterLayoutMobileOpen: false
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  category,
  city,
  count,
  dealer,
  pickupDatetime,
  returnDatetime,
  source,
  location,
  sortBy,
  filterLayout
});
