import React from 'react';
import Modal from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import './style.scss';
import { dataLayer } from '@ui/utils/datalayer';

class ContactModal extends React.PureComponent {
  state = {
    open: false
  };
  toggle = () => this.setState({ open: !this.state.open });
  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <Modal
          visible={this.state.open}
          onClose={this.toggle}
          className="contactmodal"
          wrapClassName="contactmodal__wrap"
          animation="zoom"
          maskAnimation="fade"
        >
          <h3>ติดต่อเจ้าหน้าที่ Drivehub</h3>
          <p>
            หากท่านต้องการแก้ไข/ยกเลิกการจอง <br /> กรุณาติดต่อเจ้าหน้าที่ตามช่องทางด้านล่าง
          </p>
          <div className="button__container">
            <a
              data-event-category="bookingstatus_check_section"
              data-event-action="contact_us"
              data-event-label="line"
              rel="nofollow"
              className="button button__line"
              href="https://line.me/ti/p/%40drivehub"
              onClick={(e) => dataLayer(e)}
            >
              <i className="flaticon-line1x" />
              @drivehub
            </a>
            <a
              data-event-category="bookingstatus_check_section"
              data-event-action="contact_us"
              data-event-label="callcenter"
              rel="nofollow"
              href="tel:+6620385222"
              className="button button__phone"
              onClick={(e) => dataLayer(e)}
            >
              <i className="flaticon-phone1x" />
              02 038 5222
            </a>
          </div>
        </Modal>
        <span style={{ cursor: 'pointer' }} onClick={this.toggle}>
          {children}
        </span>
      </React.Fragment>
    );
  }
}

export default ContactModal;
