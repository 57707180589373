import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Modal = (props) => {
  const { children, className, active } = props;
  if (active) {
    document.body.classList.add('noscroll');
  }

  return active ? (
    <div className="dh-modal-wrapper">
      <div className={`dh-modal ${className}`}>
        <i
          className="fa fa-times close-button"
          aria-hidden="true"
          onClick={(event) => {
            props.onClose(event);
            document.body.classList.remove('noscroll');
          }}
        />
        {children}
      </div>
    </div>
  ) : (
    ''
  );
};

Modal.propTypes = {
  onClose: PropTypes.func
};

export default Modal;
