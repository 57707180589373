import React from 'react';
import queryString from 'query-string';
import FetchApi from './fetchApi';
import { API_LEGACY_GET_CAR_DETAIL, API_GET_CAR_DETAIL } from '../../variables/API';

const fetchCar = (Component) => {
  class Wrapped extends React.Component {
    get isInter() {
      return ['inter', 'bigbrand'].includes(this.params.source);
    }
    get url() {
      const baseUrl = this.isInter ? API_GET_CAR_DETAIL : API_LEGACY_GET_CAR_DETAIL;
      return `${baseUrl}${this.params.car_id}/details?${queryString.stringify(this.params)}`;
    }

    get pricingUrl() {
      return this.url.replace('details', 'pricing');
    }
    get params() {
      return {
        ...queryString.parse(this.props.location.search),
        car_id: this.props.match.params.id
      };
    }
    render() {
      return (
        <FetchApi url={this.url}>
          {(data) => (
            <Component
              {...this.props}
              detail={{
                ...data,
                source: this.params.source
              }}
              pricingUrl={this.pricingUrl}
            />
          )}
        </FetchApi>
      );
    }
  }
  return Wrapped;
};

export default fetchCar;
