import React from 'react';
import PropTypes from 'prop-types';
import { LEGACY_WEB } from '../../../variables/API';
import { LOCAL } from '../../../variables/source';
import './style.scss';
import { dataLayer } from '@ui/utils/datalayer';

const DEFAULT_INSURANCE_TEXT =
  'ฟรีประกันภัยชั้น 1 ขั้นพื้นฐาน ซึ่งไม่รวมค่าเสียหายส่วนแรกในกรณีที่ผู้เช่าเป็นฝ่ายผิด หรือไม่มีคู่กรณี';
const BookingInsuranceDetail = ({ insurance, source }) => {
  const getInsuranceUrl = () => {
    if (source === LOCAL) {
      return `${LEGACY_WEB}/th/insurance#cash`;
    } else {
      return `${LEGACY_WEB}/th/insurance#credit`;
    }
  };
  return (
    <div className="booking-insurance">
      <p className="title">ประกันภัย</p>
      <p className="content">
        {insurance ? (
          insurance.name ? (
            insurance.type === 'Zero Excess' ? (
              <LocalZeroExcessBox />
            ) : (
              insurance.name
            )
          ) : (
            DEFAULT_INSURANCE_TEXT
          )
        ) : (
          DEFAULT_INSURANCE_TEXT
        )}
      </p>
      <a
        data-event-category="bookingstatus_check_section"
        data-event-action="read"
        data-event-label="insurance_detail"
        className="btn btn-outline-secondary"
        target="_blank"
        rel="noopener noreferrer"
        href={getInsuranceUrl()}
        onClick={(e) => dataLayer(e)}
      >
        อ่านรายละเอียดของประกัน<i className="flaticon-arrow-right1x"></i>
      </a>
    </div>
  );
};
const LocalZeroExcessBox = () => {
  return (
    <div className="zero-excess-local-box">
      <p className="title">ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess</p>
      <p className="pb-3">
        กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ
        ไม่ครอบคลุมถึงส่วนอื่นๆ ของรถ<sup>1</sup> หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ<sup>2</sup> และไม่รวมถึงค่าปรับ
        ค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า ทั้งนี้
        การคุ้มครองจะมีผลก็ต่อเมื่อผู้เช่าปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
      </p>
      <p>
        <sup>1</sup>เช่น กระจก ยาง ฝาครอบล้อ กระทะล้อ ภายใน หลังคา หรือช่วงล่างของรถ
      </p>
      <p>
        <sup>2</sup>เช่น เก้าอี้เด็ก อุปกรณ์ GPS อุปกรณ์เสริมกุญแจ เอกสารสำคัญของรถ หรือทรัพย์สินส่วนบุคคลอื่นๆ
      </p>
    </div>
  );
};

BookingInsuranceDetail.defaultProps = {
  insurance: {
    description: DEFAULT_INSURANCE_TEXT,
    id: 1,
    name: 'ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)',
    type: ''
  },
  source: 'local'
};

BookingInsuranceDetail.propTypes = {
  insurance: PropTypes.object,
  source: PropTypes.string
};

export default BookingInsuranceDetail;
