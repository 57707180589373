import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const LogoBox = (props) => {
  return (
    <React.Fragment>
      {props.logos.map((logo, i) => (
        <div key={i} className="logo__box">
          <div className="img__wrapper">
            <img
              src={require(`../../../assets/images/${logo.img}`)}
              alt={logo}
              style={logo.styles !== '' ? logo.styles : {}}
            />
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

LogoBox.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string,
      styles: PropTypes.any
    })
  ).isRequired
};

export default LogoBox;
