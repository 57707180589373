import { dataLayer, pushDataLayer } from '@utils/datalayer';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import MediaQuery from 'react-responsive';
import { withTranslation } from '../../../locales';
import FilterGuideBox, { Mobile as FilterGuideMobile } from '../../molecules/FilterGuideBox';
import './style.scss';

const FILTER_DEFAULT_OPEN = ['category', 'source', 'dealer'];
class FilterLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: FILTER_DEFAULT_OPEN,
      isShowGuide: false,
      isUserScroll: false
    };
  }

  componentDidMount() {
    const isSeenGuide = true; // window.localStorage.getItem('isSeenGuide')
    if (isSeenGuide === null) {
      window.localStorage.setItem('isSeenGuide', 'true');
      this.toggleFilterGuide().then(() => {
        // document.body.style.overflow = 'hidden'
      });
    } else {
      document.body.style.overflow = 'unset';
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  toggleFilterGuide = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState({
          isShowGuide: true
        });
        window.scrollTo({
          top: 350,
          behavior: 'smooth'
        });
        resolve('Filter Guide is open');
      }, 4750);
    });
  };

  handleClickOutside = (event) => {
    this.filterGuideBox = document.getElementsByClassName('filter-guide__box')[0];
    this.filterGuideBoxMobile = document.getElementsByClassName('filter-guide__box--mobile')[0];
    try {
      if (
        (this.filterGuideBox && !this.filterGuideBox.contains(event.target)) ||
        (this.filterGuideBoxMobile && !this.filterGuideBoxMobile.contains(event.target))
      ) {
        this.onCloseFilterGuideBox();
      }
    } catch (error) {
      console.log('Cannot close guide box: ', error);
      this.onCloseFilterGuideBox();
    }
  };

  showGuidehandle = (event) => {
    dataLayer(event, 'read_more');
    this.setState({
      isShowGuide: true,
      expanded: this.state.expanded.concat('source'),
      isUserScroll: window.scrollY < 340 ? false : true
    });
    document.addEventListener('mousedown', this.handleClickOutside);

    document.getElementById('filter-guide-box').scrollIntoView({ behavior: 'smooth', block: 'center' });
    document.body.style.overflow = 'hidden';
  };

  onCloseFilterGuideBox = () => {
    this.setState({
      isShowGuide: false
    });
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.body.style.overflow = 'unset';
    if (!this.state.isUserScroll) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  onToggleExpand = (key) => {
    if (!this.state.isShowGuide) {
      const { expanded } = this.state;
      if (expanded.includes(key)) {
        this.setState({ expanded: expanded.filter((el) => el !== key) });
      } else {
        this.setState({ expanded: expanded.concat(key) });
      }
    }
  };
  onToggleCleanFilter = () => {
    this.props.cleanAllFilter();
    this.setState({ expanded: FILTER_DEFAULT_OPEN });
  };
  onToggleFilterMobile = () => {
    this.props.toggleMobileOpen('CLOSE');
  };

  toggleSubmitFilter = () => {
    this.props.fetchCar();
    this.props.toggleMobileOpen('SUBMIT');
    pushDataLayer(this.dataLayerArgs);
  };

  get dataLayerArgs() {
    const query = queryString.parse(window.location.search);
    const dateFormat = 'YYYY-MM-DD HH:mm';

    const locationID = parseInt(this.props.filter.location.selected);
    const location = this.props.filter.location.list.find((l) => l.id === locationID);

    const carType =
      this.props.filter.category.selected
        .map((id) => {
          const selected = this.props.filter.category.list.find((c) => c.id === parseInt(id));
          if (!selected) {
            return '';
          }
          return selected.name.en;
        })
        .join(', ') || 'undefined';

    const dealerName = query.dealers
      ? query.dealers
          .split(',')
          .map((id) => {
            const selected = this.props.filter.dealer.list.find((d) => d.id === parseInt(id));
            if (!selected) {
              return '';
            }
            return selected.name.en;
          })
          .join(', ')
      : 'undefined';

    return {
      event: 'track_event',
      event_category: 'search_bookings_section',
      event_action: 'search_bookings_success',
      event_label: 'filter_tab',
      search_pickup_date: moment(query.booking_begin, dateFormat)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_pickup_time: moment(query.booking_begin, dateFormat)
        .locale('en')
        .format('HH:mm:ss'),
      search_return_date: moment(query.booking_end, dateFormat)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_return_time: moment(query.booking_end, dateFormat)
        .locale('en')
        .format('HH:mm:ss'),
      search_destination: location.city.name.th,
      search_pickup_location: location.name.th,
      search_dealer_type: query.sources || 'undefined',
      search_dealer_name: dealerName,
      search_car_type: carType,
      search_promotion_name: 'undefined',
      search_rental_type: 'shortterm',
      sort_by: query.sort_by,
      search_timestamp: moment()
        .locale('en')
        .format('YYYY-MMM-DD HH:mm:ss')
    };
  }

  render() {
    const { t, isFilterLayoutMobileOpen } = this.props;
    const { expanded, isShowGuide } = this.state;
    const children = this.props.children.filter((child) => child);

    return (
      <React.Fragment>
        {isShowGuide && <div className="overlay-guide" />}
        <MediaQuery minWidth={992}>
          <div className="filter-section">
            <div className="filter__header">
              <p>{t('filter_layout.filter_header')}</p>
              <span className="clean-btn" onClick={this.props.toggleCleanFilter}>
                {t('filter_layout.filter_clean_all')}
              </span>
            </div>
            {children.map((child) => (
              <div
                className={`filter__tab ${
                  isShowGuide && child.props.filterKey === 'source' ? 'source-filter__wrapper' : ''
                }`}
                key={child.props.title}
              >
                <div className="tab-header">
                  <p className="tab-title">
                    {child.props.title}
                    {child.props.filterKey === 'source' && (
                      <React.Fragment>
                        <span
                          className="filter-guide"
                          data-event-category="search_result_section"
                          data-event-label="dealer_type_detail"
                          onClick={this.showGuidehandle}
                        >
                          {t('filter_layout.tab.source_more_detail')}
                          <i className="flaticon-information" />
                        </span>
                      </React.Fragment>
                    )}
                  </p>
                  {child.props.filterKey === 'source' && (
                    <div id="filter-guide-box">
                      {isShowGuide && (
                        <div className="filter-guide__wrapper" ref={(el) => (this.filterGuideBox = el)}>
                          <FilterGuideBox onClose={this.onCloseFilterGuideBox} />
                        </div>
                      )}{' '}
                    </div>
                  )}
                  <span onClick={() => this.onToggleExpand(child.props.filterKey)}>
                    <i className={expanded.includes(child.props.filterKey) ? 'flaticon-minus' : 'flaticon-plus'} />
                  </span>
                </div>
                <div className={`tab-content ${expanded.includes(child.props.filterKey) ? 'active' : ''}`}>{child}</div>
              </div>
            ))}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={992}>
          {isShowGuide && (
            <div className="filter-guide-mobile__wrapper" ref={(el) => (this.filterGuideBoxMobile = el)}>
              <FilterGuideMobile onClose={this.onCloseFilterGuideBox} />
            </div>
          )}
          <div className={`filter-section filter-section--mobile ${isFilterLayoutMobileOpen ? 'active' : ''}`}>
            <div className="filter__header filter__header--mobile">
              <i className="flaticon-cancel" onClick={this.onToggleFilterMobile} />
              <p>{t('filter_layout.filter_header')}</p>
              <p className="clean-btn clean-btn--mobile" onClick={this.props.toggleCleanFilter}>
                {t('filter_layout.filter_clean_all')}
              </p>
            </div>
            {children.map((child, i) => (
              <div className="filter__tab--mobile" key={child.props.title}>
                <div className="tab-header--mobile">
                  <p className="tab-title">
                    {child.props.title}
                    {child.props.filterKey === 'source' && (
                      <React.Fragment>
                        <span
                          className="filter-guide"
                          data-event-category="search_result_section"
                          data-event-label="dealer_type_detail"
                          onClick={this.showGuidehandle}
                        >
                          {t('filter_layout.tab.source_more_detail')}
                          <i className="flaticon-information" />
                        </span>
                      </React.Fragment>
                    )}
                  </p>
                </div>
                <div className={`tab-content--mobile ${expanded.includes(child.props.filterKey) ? 'active' : ''}`}>
                  {child}
                </div>
              </div>
            ))}
            <div style={{ height: 60 }} />
            <div className="filter__button--mobile" onClick={this.toggleSubmitFilter}>
              <p>
                {t('filter_layout.on_show')}
                <span className="pl-1 pr-1">{this.props.carCount}</span>
                {t('filter_layout.car_unit')}
              </p>
            </div>
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ filter: state.filter });
export default connect(mapStateToProps)(withTranslation('filter')(FilterLayout));
