import React, { Component } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import Helmet from 'react-helmet';
import IconBookingSuccess from '../../assets/icon/booking_success_icon.svg';
import ChatButton from '../../components/atoms/ChatButton';
import { LOCAL, INTER } from '../../variables/source';
import { API_LEGACY_GET_BOOKING, API_BIGBRAND_GET_BOOKING, SEARCH_HOST_URL } from '../../variables/API';
import { readCookie } from '../../utils';
import { CS_MODE } from '../../variables/features';

import './style.scss';
import { dataLayer, pushDataLayer } from '@utils/datalayer';

export default class BookingSuccess extends Component {
  state = {
    booking: null
  };
  componentDidMount() {
    this.loadBooking();
    this.insertEcomobiScript();
    this.pushDataLayer();
  }

  get isInter() {
    const params = queryString.parse(window.location.search);
    return ['inter', 'bigbrand'].includes(params.source);
  }

  get getSource() {
    return this.isInter ? INTER : LOCAL;
  }

  get bookingId() {
    return this.props.match.params.id;
  }

  get signatureLastname() {
    const params = queryString.parse(window.location.search);
    return params.last_name;
  }

  get getBookingDetailURL() {
    return this.isInter
      ? `${API_BIGBRAND_GET_BOOKING}/${this.bookingId}?last_name=${this.signatureLastname}`
      : `${API_LEGACY_GET_BOOKING}/${this.bookingId}?last_name=${this.signatureLastname}`;
  }

  get booking() {
    return import('axios').then((a) => {
      return a.default.get(this.getBookingDetailURL).then(({ data }) => data);
    });
  }

  get ecomobiURL() {
    if (process.env.REACT_APP_STAGE === 'production') return '//go.ecotrackings.com/eco_px.js';
    return '//dev-go.ecotrackings.com/eco_px.js';
  }
  
  async loadBooking() {
    const booking = await this.booking;
    this.setState({ booking });
  }

  pushDataLayer = async () => {
    if (localStorage.getItem('ecom_tracking_id') === this.bookingId) return;
    const { booking, car, dealer, pricing, has_insurance, insurance } = await this.booking;
    let insurancePrice = '0.00';
    if (has_insurance) {
      if (insurance) {
        insurancePrice = insurance.total_price.toFixed(2);
      }
    }
    const args = {
      event: 'ecommerce_purchase',
      event_category: 'ecommerce',
      event_action: 'product_purchase',
      event_label: car.model,
      booking_id: (this.isInter ? 'I' : 'L') + booking.id, // For Example Trasnsaction ID
      booking_status: 'Booked', // For Example Booked
      booking_timestamp: moment(booking.created_at)
        .locale('en')
        .format('YYYY-MMM-DD HH:mm:ss'), // For Example 2020-Mar-01 12:30:54
      booking_dealer_id: (this.isInter ? 'I' : 'L') + dealer.id || 'undefined', // For Example DL0001
      booking_dealer_name: dealer.name, // For Example HERTZ
      booking_dealer_type: this.isInter ? 'Inter' : 'Local', // For Example Local|Inter
      booking_payment_type: this.isInter ? 'Credit' : 'Cash', // For Example Cash|Credit
      booking_pickup_date: moment(booking.pickup_datetime)
        .locale('en')
        .format('YYYY-MMM-DD'), // For Example 2020-Mar-01
      booking_pickup_time: moment(booking.pickup_datetime).format('HH:mm:ss'), // For Example 00:35:50
      booking_pickup_location: booking.location, // For Example สนามบินสุวรรณภูมิ
      booking_pickup_destination: booking.city, // For Example กรุงเทพมหานคร
      booking_rental_type: 'Shortterm', // For Example Longterm, Shortterm, WithDriver
      booking_return_date: moment(booking.return_datetime)
        .locale('en')
        .format('YYYY-MMM-DD'), // For Example 2020-Mar-01
      booking_return_time: moment(booking.return_datetime).format('HH:mm:ss'), // For Example 00:35:50
      booking_product_name: car.model,
      booking_product_category: car.category,
      booking_product_year: car.registration_year,
      updated_booking_status_date: 'undefined', // For Example 2020-Mar-01
      coupon_name: pricing.coupon_code, // MEDICAL200
      delivery_cost: pricing.delivery_price.toFixed(2), // custom metric number format  For Example 0.00
      discount_price: pricing.discount.toFixed(2), // custom metric number format  For Example 0.00
      insurance_cost: insurancePrice, // custom metric number format  For Example 0.00
      pickup_cost: pricing.pickup_price.toFixed(2), // custom metric number format  For Example 0.00
      coupon_price: pricing.discount.toFixed(2), // custom metric number format  For Example 0.00
      expected_revenue: pricing.grand_total_price.toFixed(2), // custom metric number format  For Example 0.00
      ecommerce: {
        purchase: {
          actionField: {
            id: (this.isInter ? 'I' : 'L') + booking.id, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Booking',
            revenue: '0', // Total transaction value (default 0)
            tax: '0',
            shipping: '0',
            coupon: pricing.coupon_code
          },
          products: [
            {
              name: car.model, // Name or ID is required.
              id: (this.isInter ? 'I' : 'L') + (car.id || 'undefined'), // For Example 0001
              price: 0, // For Example 3500
              brand: `${dealer.name} : ${dealer.branch_name}`, // For Example True Leasing : True สาขาเชียงใหม่
              category: car.category, // For Example Eco, Suv, Medium, Van, Car_with_driver
              variant: this.isInter ? 'Inter' : 'Local', // Dealer Type
              quantity: 1,
              coupon: pricing.coupon_code // Optional fields may be omitted or set to empty string.
            }
          ]
        }
      }
    };
    pushDataLayer(args);
    localStorage.setItem('ecom_tracking_id', this.bookingId);
  };

  insertEcomobiScript = async () => {
    if (localStorage.getItem('eco_id') === this.bookingId) return;
    const { booking, car } = await this.booking;
    const sessionID = readCookie('__dsid');
    const ecoTrafficID = readCookie('eco_traffic_id');

    const mainScript = document.createElement('script');
    mainScript.src = this.ecomobiURL;
    const scriptData = document.createElement('script');
    const ecoToken = '802c4652de1ff4379ae720745a6ec623'; // Prod and Staging use the same value.

    scriptData.append(`
      var eco_token = "${ecoToken}";
      var eco_data = {
          "offer_id" : "drivehub.co",
          "transaction_id" : "${booking.id.toString()}",
          "transaction_time" : "${booking.created_at}",
          "session_ip" : "${sessionID}",
          "traffic_id" : "${ecoTrafficID}",
          "products"   :   [{
              "id"            :  "${booking.id.toString()}",
              "sku"           :  "${booking.id.toString()}",
              "name"          :  "${booking.id.toString()}",
              "category_id"   :  "${car.car_id}",
              "category_name" :  "${car.category}",
              "price"         :  0,
              "quantity"      :  1,
              "commission"    :  250,
              "status_code"   :  0,
              "link"          :  "https://www.drivehub.co"
          }]
      };
    `);
    document.body.after(scriptData, mainScript);
    localStorage.setItem('eco_id', this.bookingId);
  };

  insertAccessTradeScript = () => {
    if (localStorage.getItem('id') === this.bookingId) {
      return '';
    } else {
      localStorage.setItem('id', this.bookingId);
      return `var __atw = __atw || [];__atw.push({"mcn": "218a0aefd1d1a4be65601cc6ddc1520e","param": {"result_id": "1","identifier": "${this.bookingId}"}});(function (d) {var s = d.createElement('script');s.src = 'https://cv.accesstrade.in.th/js/nct/cv.js';s.async = true;var e = d.getElementsByTagName('script')[0];e.parentNode.insertBefore(s, e);})(document);`;
    }
  };

  render() {
    const { query } = queryString.parseUrl(window.location.href);
    const bookingID = this.props.match.params.id;
    const bookingDetailUrl = CS_MODE
      ? `${SEARCH_HOST_URL}/booking/${bookingID}?source=${query.source}&last_name=${query.last_name}`
      : `/booking/${bookingID}?source=${query.source}&last_name=${query.last_name}`;

    return (
      <div className="booking-success">
        <Helmet>
          <title>คำขอจองของคุณได้ถูกสร้างขึ้นแล้ว! — Drivehub</title>
          <script>{this.insertAccessTradeScript()}</script>
        </Helmet>
        <div className="container">
          <div className="img">
            <img src={IconBookingSuccess} alt="booking sucess" />
          </div>
          <div className="title">
            <p>การจองของคุณได้ถูกสร้างขึ้นแล้ว!</p>
            <span>คุณสามารถตรวจสอบสถานะการจองได้ที่</span>
          </div>
          <div className="action">
            <a
              className="btn btn-primary"
              data-event-category="bookings_section"
              data-event-label="check_status"
              onClick={(e) => dataLayer(e)}
              href={bookingDetailUrl}
            >
              ตรวจสอบสถานะ
            </a>
            {this.state.booking && this.state.booking.feature.chat === true && (
              <ChatButton source={query.source} lastName={query.last_name} bookingID={bookingID} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
