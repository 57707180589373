import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingBox } from '../atoms/Loading';
import LoadingSVG from '../../assets/icon/loading.svg';
import { pushDataLayer, pushTrackEvent } from '@utils/datalayer';
import moment from 'moment';
import queryString from 'query-string';

class FetchApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: null,
      error: null
    };
  }
  componentDidMount() {
    this.fetch();
  }
  componentDidUpdate(prevProps) {
    return prevProps.url !== this.props.url;
  }
  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.fetch();
    }
    return null;
  }
  fetch() {
    const { url, onLoad } = this.props;
    this.setState({ fetching: true });
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        this.props.sendToRedux(url, data);
        if (onLoad) {
          onLoad(data);
        }
        this.setState({ data, fetching: false, error: null });
      })
      .catch((error) => this.setState({ error, fetching: false }));
  }
  render() {
    if (this.state.fetching) {
      return (
        <div className="loading-box">
          <LoadingBox bg={LoadingSVG} dark />
        </div>
      );
    }
    if (this.state.error) {
      if (this.props.onError) return this.props.onError(this.state.error);
      console.log('error:', this.state.error);
      return (
        <div className="container">
          {' '}
          Error <span onClick={() => this.fetch()}>Click to Retry</span>
        </div>
      );
    }
    return this.props.children(this.state.data);
  }
}

FetchApi.propTypes = {
  url: PropTypes.string,
  children: PropTypes.func.isRequired,
  onError: PropTypes.func
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendToRedux: (url, data) => {
        if (url.includes('/pricing')) {
          const query = queryString.parse(window.location.search);
          if (!query.code) {
            query.code = '';
          }
          if (data.is_code_valid && data.discount_amount > 0 && query.code !== '') {
            pushTrackEvent('bookings_section', 'valid_coupon', `code_${query.code}`);
          }

          if (data.is_code_valid === false && query.code !== '') {
            pushTrackEvent('bookings_section', 'invalid_coupon', `code_${query.code}`);
          }

          if (data.available === false) {
            pushDataLayer({
              event: 'track_event',
              event_category: 'bookings_section',
              event_action: 'search_bookings',
              event_label: 'not_available_car',
              search_pickup_date: moment(data.pickup_datetime)
                .locale('en')
                .format('YYYY-MMM-DD'),
              search_pickup_time: moment(data.pickup_datetime).format('HH:mm:ss'),
              search_return_date: moment(data.return_datetime)
                .locale('en')
                .format('YYYY-MMM-DD'),
              search_return_time: moment(data.return_datetime).format('HH:mm:ss'),
              search_destination: 'undefined',
              search_pickup_location: data.location.th,
              search_dealer_type: 'undefined',
              search_dealer_name: 'undefined',
              search_car_type: 'undefined',
              search_promotion_name: 'undefined',
              search_rental_type: 'shortterm',
              sort_by: 'undefined',
              search_timestamp: moment()
                .locale('en')
                .format('YYYY-MMM-DD HH:mm:ss')
            });
          }
        }
        return {
          type: 'FETCH_HOC',
          url,
          data
        };
      }
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(FetchApi);
