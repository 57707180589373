import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { ContextType } from '@ui/components/context';
import { PENDING, CANCELLED, CANCELED, REJECTED, ACCEPTED } from '../../../variables/bookingStatus';
import { LOCAL, INTER } from '../../../variables/source';
import './style.scss';
import { dataLayer, pushDataLayer } from '@ui/utils/datalayer';

const ACTIVE = 'active';
const INACTIVE = 'inactive';
const ON_GOING = 'on-going';
const ICON_CANCELED = 'canceled';

class BookingStatus extends Component {
  state = {
    isPushedDataLayer: false,
    status: {
      bookingRequest: {
        text: 'ทำการจองแล้ว',
        class: 'active'
      },
      waitingForDealer: {
        text: 'รอการติดต่อกลับจากบริษัทรถเช่า',
        class: 'on-going'
      },
      bookingAccepted: {
        text: 'ได้รับการอนุมัติการจองแล้ว',
        class: 'inactive'
      },
      waitingForPayment: {
        text: 'รอชำระเงิน',
        class: 'inactive'
      },
      waitingForPickup: {
        text: 'รอรับรถ',
        class: 'inactive'
      },
      returnCar: {
        text: 'คืนรถ (การเช่าเสร็จสิ้น)',
        class: 'inactive'
      }
    },
    statusInter: {
      bookingRequest: {
        text: 'ทำการจองแล้ว',
        class: 'active'
      },
      paid: {
        text: 'ชำระเงินแล้ว',
        class: 'active'
      },
      waitingForPickup: {
        text: 'รอรับรถ',
        class: 'on-going'
      },
      returnCar: {
        text: 'คืนรถ (การเช่าเสร็จสิ้น)',
        class: 'inactive'
      }
    },
    latestStatus: this.props.source === LOCAL ? 'waitingForDealer' : 'waitingForPickup',
    isExpand: false
  };

  componentDidMount() {
    this.updateStatus();
    this.checkDateForPickUpReturn();
    this.getLatestStatusBySource();
    if (this.context.isMobile === false) {
      this.pushStatusSeenDataLayer();
    }
  }

  updateStatus = () => {
    const { transitions, source } = this.props;
    const { status, statusInter } = this.state;

    switch (source) {
      case LOCAL: {
        let _status = status;
        transitions.map((transition, index) => {
          switch (transition) {
            case PENDING:
              return '';
            case ACCEPTED: {
              _status.waitingForDealer.class = 'active';
              _status.bookingAccepted.class = 'active';
              _status.waitingForPayment.class = 'active';
              _status.waitingForPickup.class = 'on-going';
              return '';
            }
            case CANCELED:
            case CANCELLED:
            case REJECTED: {
              if (index === 1) {
                _status.waitingForDealer.text = transition === REJECTED ? 'ยกเลิกโดยบริษัทรถเช่า' : 'ยกเลิกการจอง';
                _status.waitingForDealer.class = 'canceled';
              } else {
                _status.waitingForPickup.text = transition === REJECTED ? 'ยกเลิกโดยบริษัทรถเช่า' : 'ยกเลิกการจอง';
                _status.waitingForPickup.class = 'canceled';
              }
              return '';
            }
            default:
              return '';
          }
        });
        this.setState({
          status: _status
        });
        break;
      }
      case INTER: {
        let _statusInter = statusInter;
        if (transitions.includes(CANCELED) || transitions.includes(CANCELLED)) {
          _statusInter.waitingForPickup.text = 'ยกเลิกการจอง';
          _statusInter.waitingForPickup.class = 'canceled';
        }
        this.setState({
          statusInter: _statusInter
        });
        break;
      }
      default:
        break;
    }
  };

  checkDateForPickUpReturn = () => {
    const { transitions, pickupDatetime, returnDatetime } = this.props;
    const { status, statusInter } = this.state;
    let _status = status;
    let _statusInter = statusInter;
    const _pickupDatetime = moment(pickupDatetime);
    const _returnDatetime = moment(returnDatetime);

    if (transitions.includes(CANCELED) || transitions.includes(CANCELLED) || transitions.includes(REJECTED)) {
      return;
    }

    if (transitions.includes(ACCEPTED)) {
      const now = moment();
      if (now.diff(_pickupDatetime, 'minutes') > 0) {
        _status.waitingForPickup.class = 'active';
        _status.returnCar.class = 'on-going';
        _statusInter.waitingForPickup.class = 'active';
        _statusInter.returnCar.class = 'on-going';
      }
      if (now.diff(_returnDatetime, 'minutes') > 0) {
        _status.returnCar.class = 'active';
        _statusInter.returnCar.class = 'active';
      }
    }
    this.setState({
      status: _status,
      statusInter: _statusInter
    });
  };

  getLatestStatusBySource = () => {
    const { status, statusInter } = this.state;
    const { source } = this.props;

    if (source === LOCAL) {
      this.getLatestStatus(status);
    } else {
      this.getLatestStatus(statusInter);
    }
  };

  getLatestStatus = (status) => {
    const keys = Object.keys(status);
    let latestStatus;

    // NOTE: Find 'on-going' status first
    latestStatus = keys.filter((key) => {
      return status[key].class === ON_GOING;
    });
    if (latestStatus.length !== 0) {
      const [status] = latestStatus;
      this.setState({ latestStatus: status });
      return;
    }

    // NOTE: Else pick the 'canceled' one
    latestStatus = keys.filter((key) => {
      return status[key].class === ICON_CANCELED;
    });
    if (latestStatus.length !== 0) {
      const [status] = latestStatus;
      this.setState({ latestStatus: status });
      return;
    }

    // NOTE: Else Pick the last active
    latestStatus = keys.filter((key) => {
      return status[key].class === ACTIVE;
    });
    if (latestStatus.length !== 0) {
      this.setState({ latestStatus: latestStatus[latestStatus.length - 1] });
      return;
    }
  };

  getIconStatus = (state) => {
    switch (state) {
      case ACTIVE:
        return <i className="flaticon-check-correctfill1x-2 green"></i>;
      case INACTIVE:
        return <div className="status-icon inactive"></div>;
      case ON_GOING:
        return <div className="status-icon on-going"></div>;
      case ICON_CANCELED:
        return <i className="flaticon-check-wrongtfill1x-1 red"></i>;
      default:
        return <div className="status-icon inactive"></div>;
    }
  };

  pushStatusSeenDataLayer() {
    pushDataLayer({
      event: 'track_event',
      event_category: 'bookingstatus_check_section',
      event_action: 'seen',
      event_label: 'lastest_status_popup',
      check_lastest_status: this.props.source === LOCAL ? 'รอการติดต่อกลับจากบริษัทรถเช่า' : 'รอรับรถ',
      check_booking_id: (this.props.source === LOCAL ? 'L' : 'I') + this.props.bookingId
    });
  }

  onToggleExpand = () => {
    if (this.state.isExpand === false && this.state.isPushedDataLayer === false) {
      this.pushStatusSeenDataLayer();
    }

    this.setState({
      isExpand: !this.state.isExpand,
      isPushedDataLayer: true
    });
  };

  render() {
    const { bookingId, source } = this.props;
    const { status, statusInter, latestStatus, isExpand } = this.state;
    const renderStatus = source === LOCAL ? status : statusInter;
    return (
      <div className="booking-status">
        <div>
          <small>หมายเลขการจอง</small>
          <p>{bookingId}</p>
        </div>
        <div>
          <small>สถานะการจอง</small>
          {/* NOTE: Desktop Status */}
          <div className={`status-items desktop ${isExpand ? 'show' : ''}`}>
            {Object.keys(renderStatus).map((key, index) => {
              return (
                <div className="item" key={index}>
                  {this.getIconStatus(renderStatus[key].class)}
                  <p className={renderStatus[key].class}>{renderStatus[key].text}</p>
                </div>
              );
            })}
          </div>
          {/* NOTE: Mobile Status */}
          <div className={`status-items mobile ${isExpand ? 'hide' : ''}`}>
            <div className={`item line-before ${latestStatus === 'returnCar' ? '' : 'line-after'}`}>
              {this.getIconStatus(renderStatus[latestStatus].class)}
              <p className={renderStatus[latestStatus].class}>{renderStatus[latestStatus].text}</p>
            </div>
          </div>
          <div
            className="see-more"
            data-event-category="bookingstatus_check_section"
            data-event-action={isExpand ? 'hide' : 'expand'}
            data-event-label="more_detail"
            onClick={(e) => {
              dataLayer(e);
              this.onToggleExpand();
            }}
          >
            <span>{isExpand ? 'ย่อสถานะ' : 'ดูสถานะเพิ่มเติม'}</span>
            <i className={isExpand ? 'flaticon-arrow-up1x' : 'flaticon-arrow-down1x'}></i>
          </div>
        </div>
      </div>
    );
  }
}

BookingStatus.contextType = ContextType;
BookingStatus.defaultProps = {
  transitions: ['pending', 'accepted', 'pending', 'rejected'],
  bookingId: 281136,
  pickupDatetime: moment().add(2, 'days'),
  returnDatetime: moment().add(4, 'days'),
  source: 'local'
};

BookingStatus.propTypes = {
  transitions: PropTypes.array,
  bookingId: PropTypes.number,
  pickupDatetime: PropTypes.string,
  returnDatetime: PropTypes.string,
  source: PropTypes.string
};

export default BookingStatus;
