import { combineReducers } from 'redux';
import moment from 'moment-timezone';
import {
  NEXT,
  PREV,
  CUSTOMER_CHANGE,
  NOTE_CHANGE,
  CODE_CHANGE,
  PAYMENT_CHANGE,
  PAYMENT_INVALID,
  PAYMENT_VALID,
  PAYMENT_LOADING,
  FORM_VALIDATION,
  INSURANCE_CREATE,
  BOOKING_CREATE_ERROR,
  BOOKING_CREATING_DRAFT,
  BOOKING_CREATED_DRAFT,
  CAR_AVAILABLE_FOR_WALK_IN,
  SKIP
} from '../actions/booking';
import { readCookie } from '../../utils';

const step = (state = 0, { type, payload }) => {
  switch (type) {
    case NEXT: {
      return state + 1;
    }
    case PREV: {
      const nextState = state - 1;
      return nextState < 0 ? 0 : nextState;
    }
    case SKIP: {
      return payload;
    }
    default:
      return state;
  }
};

const initialCustomer = {
  consumer_id: null,
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  isValid: false
};
const loadCustomerFromStorage = () => {
  const authToken = readCookie('_consumerToken');
  if (!authToken) {
    const response = JSON.parse(localStorage.getItem('customer'));
    if (response === null) {
      return initialCustomer;
    }
    return response;
  }

  const defaultCustomer = {
    first_name: localStorage.getItem('consumer_first_name') || '',
    last_name: localStorage.getItem('consumer_last_name') || '',
    email: localStorage.getItem('consumer_email') || '',
    phone_number: localStorage.getItem('consumer_phone_number') || ''
  };
  const numberOfemptyValue = Object.keys(defaultCustomer).filter((key) => {
    return defaultCustomer[key] === '';
  }).length;

  return {
    consumer_id: readCookie('_consumerID') || undefined,
    first_name: localStorage.getItem('consumer_first_name') || '',
    last_name: localStorage.getItem('consumer_last_name') || '',
    email: localStorage.getItem('consumer_email') || '',
    phone_number: localStorage.getItem('consumer_phone_number') || '',
    isValid: numberOfemptyValue === 0 ? true : false
  };
};
const customerValidate = (state) => {
  let isValid = false;
  const fields = Object.keys(state).filter((k) => k !== 'isValid');
  isValid = fields.map((field) => state[field]).every((v) => v !== '');
  const nextState = {
    ...state,
    isValid
  };
  localStorage.setItem('customer', JSON.stringify(nextState));
  return nextState;
};

const customer = (state = loadCustomerFromStorage(), { type, payload }) => {
  switch (type) {
    case CUSTOMER_CHANGE: {
      return customerValidate({
        ...state,
        [payload.field]: payload.value
      });
    }
    case '@@INIT': {
      return loadCustomerFromStorage();
    }
    default: {
      return state;
    }
  }
};

const initialPayment = {
  number: '',
  cvc: '',
  expires: `01/${moment().format('YYYY')}`,
  name: '',
  isValid: false,
  error: undefined,
  token: '',
  isLoading: false
};
const payment = (state = initialPayment, { type, payload }) => {
  switch (type) {
    case PREV: {
      return {
        ...state,
        isLoading: false
      };
    }
    case PAYMENT_CHANGE: {
      return {
        ...state,
        [payload.field]: payload.value,
        error: undefined
      };
    }
    case PAYMENT_VALID: {
      return {
        ...state,
        isValid: true,
        token: payload,
        isLoading: false,
        error: undefined
      };
    }
    case PAYMENT_INVALID: {
      return {
        ...state,
        error: payload,
        isLoading: false
      };
    }
    case PAYMENT_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    default: {
      return state;
    }
  }
};

const initialBookingStatus = {
  isError: false,
  isCarWalkIn: false,
  isCreatingDraft: false
};
const bookingStatus = (state = initialBookingStatus, { type, payload }) => {
  switch (type) {
    case PREV: {
      return { isError: false };
    }
    case BOOKING_CREATE_ERROR: {
      return { isError: payload };
    }
    case CAR_AVAILABLE_FOR_WALK_IN: {
      return { isCarWalkIn: true };
    }
    case BOOKING_CREATING_DRAFT: {
      return {
        ...state,
        isCreatingDraft: true
      };
    }
    case BOOKING_CREATED_DRAFT: {
      return {
        ...state,
        isCreatingDraft: false
      };
    }
    default: {
      return state;
    }
  }
};

const note = (state = '', { type, payload }) => {
  switch (type) {
    case NOTE_CHANGE: {
      return payload.value;
    }
    default: {
      return state;
    }
  }
};
const code = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case CODE_CHANGE: {
      return payload.value;
    }
    default: {
      return state;
    }
  }
};

const initialErrorValidations = {
  email: '',
  phone_number: ''
};

const errorValidations = (state = initialErrorValidations, { type, payload }) => {
  switch (type) {
    case FORM_VALIDATION: {
      return {
        ...payload
      };
    }
    default: {
      return state;
    }
  }
};

const insurance = (state = '', { type, payload }) => {
  switch (type) {
    case INSURANCE_CREATE: {
      return {
        ...payload
      };
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  customer,
  insurance,
  payment,
  note,
  code,
  step,
  errorValidations,
  bookingStatus
});
