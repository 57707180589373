import React, { Component } from 'react';
import './style.scss';
import { withTranslation } from '../../../locales';

class FilterButton extends Component {
  render() {
    const { id, price, children, onClick, active, showImage } = this.props;
    const { t } = this.props;
    return (
      <div className={`${active ? 'selected-type' : ''} category-type flex-center flex-column`} onClick={onClick}>
        {active ? <i className={`${!showImage ? 'mini-icon' : ''} flaticon-checked-circle checked-type`} /> : ''}
        <img
          className={`${showImage ? '' : 'no-show-img'} img-fluid`}
          src={require(`./images/car_filter_${id}.png`)}
          alt="car type"
        />
        <p className="text-center">
          <span className="type-title">{children}</span>
          {t('price_from')} &#3647;{parseInt(price, 10).toLocaleString()}
        </p>
      </div>
    );
  }
}
export default withTranslation('info_test')(FilterButton);
