import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import LoadingBar from 'react-top-loading-bar';
import * as actions from '../../../ducks/actions/filter';
import * as carActions from '../../../ducks/actions/cars';
import { withTranslation } from '../../../locales';
import FilterLayout from '../FilterLayout';
import FilterCategory from '../NewFilterCategory';
import FilterSource from '../NewFilterSource';
import FilterDealer from '../FilterDealer';
import { pushDataLayer } from '@utils/datalayer';

class FilterDesktop extends Component {
  state = {
    categoryFetching: true
  };
  componentDidMount() {
    this.props.dispatch(actions.category.get());
    this.props.dispatch(actions.dealer.get());
    this.props.dispatch(actions.source.load(['inter', 'local']));
    this.props.dispatch(actions.count.get());
  }

  toggleMobile = () => {
    this.props.dispatch(actions.toggleFilterLayoutMobile());
  };
  toggleCleanFilter = () => {
    this.LoadingBar.staticStart();
    this.props.dispatch({ type: 'FILTER_CLEAR' });
    this.fetchCars();
  };

  fetchCars = debounce(() => {
    this.props.dispatch(carActions.initialFetchCars());
    this.props.dispatch(actions.count.get());
    this.LoadingBar.complete();
  }, 1000);
  categoryDebounce = debounce(() => {
    this.LoadingBar.complete();
    this.props.dispatch(actions.dealer.get());
  }, 1000);
  sourceDebounce = debounce(() => {
    this.LoadingBar.complete();
    this.setState({ categoryFetching: false });
  }, 1000);

  pushSourceToDataLayer = () => {
    pushDataLayer({ ...this.dataLayerArgs, event_label: `dealertype_${this.dataLayerArgs.search_dealer_type}` });
  };

  pushDealerToDataLayer = () => {
    pushDataLayer({
      ...this.dataLayerArgs,
      event_label: `dealer_${this.dataLayerArgs.search_dealer_name}`
    });
  };

  get dataLayerArgs() {
    const query = queryString.parse(window.location.search);
    const dateFormat = 'YYYY-MM-DD HH:mm';

    const locationID = parseInt(this.props.location.selected);
    const location = this.props.location.list.find((l) => l.id === locationID);

    const carType =
      this.props.category.selected
        .map((id) => {
          const selected = this.props.category.list.find((c) => c.id === parseInt(id));
          if (!selected) {
            return '';
          }
          return selected.name.en;
        })
        .join(', ') || 'undefined';

    const dealerName = query.dealers
      ? query.dealers
          .split(',')
          .map((id) => {
            const selected = this.props.dealer.list.find((d) => d.id === parseInt(id));
            if (!selected) {
              return '';
            }
            return selected.name.en;
          })
          .join(', ')
      : 'undefined';

    return {
      event: 'track_event',
      event_category: 'search_result_section',
      event_action: 'search_bookings_success',
      search_pickup_date: moment(query.booking_begin, dateFormat)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_pickup_time: moment(query.booking_begin, dateFormat)
        .locale('en')
        .format('HH:mm:ss'),
      search_return_date: moment(query.booking_end, dateFormat)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_return_time: moment(query.booking_end, dateFormat)
        .locale('en')
        .format('HH:mm:ss'),
      search_destination: location.city.name.th,
      search_pickup_location: location.name.th,
      search_dealer_type: query.sources || 'undefined',
      search_dealer_name: dealerName,
      search_car_type: carType,
      search_promotion_name: 'undefined',
      search_rental_type: 'shortterm',
      sort_by: query.sort_by,
      search_timestamp: moment()
        .locale('en')
        .format('YYYY-MMM-DD HH:mm:ss')
    };
  }

  get dealers() {
    return this.props.dealer.list;
  }

  get sourcesDisabled() {
    const { list, selected } = this.props.category;
    if (selected.length === 0) return [];
    const available = selected
      .map((id) => list.find((item) => item.id + '' === id + ''))
      .filter((x) => x)
      .reduce((p, c) => {
        return [...p, ...c.sources];
      }, [])
      .reduce((p, c) => (p.includes(c) ? p : p.concat(c)), []);

    return ['local', 'inter'].filter((source) => !available.includes(source));
  }

  get isShowDealers() {
    const { selected } = this.props.source;
    if (selected.length === 1 && selected[0] === 'local') return false;
    if (this.props.dealer.list.length === 0) return false;
    return true;
  }
  render() {
    const { t } = this.props;
    const { category, dealer, source, filterLayout } = this.props;

    return (
      <div>
        <LoadingBar height={3} color="#0078ff" onRef={(ref) => (this.LoadingBar = ref)} />
        <FilterLayout
          t={t}
          isFilterLayoutMobileOpen={filterLayout.filterLayoutMobileOpen}
          toggleMobileOpen={this.toggleMobile}
          toggleCleanFilter={this.toggleCleanFilter}
        >
          <FilterCategory
            categories={category.list}
            selected={category.selected.map((i) => parseInt(i, 10))}
            fetch={this.state.categoryFetching && category.fetching}
            onChange={(id) => {
              this.LoadingBar.staticStart();
              this.props.dispatch(actions.category.toggle(id));
              this.categoryDebounce();
              this.fetchCars();
            }}
            filterKey="category"
            title={t('filter_layout.tab.category')}
          />
          <FilterSource
            onChange={(value) => {
              this.LoadingBar.staticStart();
              this.props.dispatch(actions.source.toggle(value));
              const selected = source.selected.concat(value);
              if (!selected.includes('local')) {
                const selected = dealer.selected.filter((item) => item !== '0');
                this.props.dispatch(actions.dealer.select(selected));
              }
              this.sourceDebounce();
              this.fetchCars();
              this.pushSourceToDataLayer();
            }}
            disabled={this.sourcesDisabled}
            selected={source.selected}
            filterKey="source"
            title={t('filter_layout.tab.source')}
          />
          {this.isShowDealers && (
            <FilterDealer
              dealers={this.dealers}
              selected={dealer.selected.map((i) => parseInt(i, 10))}
              fetch={dealer.fetching}
              onChange={(id) => {
                this.LoadingBar.staticStart();
                this.props.dispatch(actions.dealer.toggle(id));
                this.fetchCars();
                this.pushDealerToDataLayer();
              }}
              filterKey="dealer"
              title={t('filter_layout.tab.dealer')}
            />
          )}
        </FilterLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => state.filter;
export default connect(mapStateToProps)(withTranslation('filter')(FilterDesktop));
