import React, { Component } from 'react';
import SongkranImageMobile from '../../../assets/images/songkran-2019-02.jpg';
import SongkranImageTablet from '../../../assets/images/songkran-2019-03.jpg';
import SongkranImageDesktop from '../../../assets/images/songkran-2019-04.jpg';
import BoyImg from '../../../assets/images/boy-prop-0.png';
import { withTranslation } from '../../../locales';
import './style.scss';

class Index extends Component {
  get image() {
    if (this.props.img) return require(`../../../assets/images/${this.props.img}`);
    if (this.props.pattern !== undefined) return require(`../../../assets/images/boy-prop-${this.props.pattern}.png`);
    return BoyImg;
  }
  get header() {
    const { t } = this.props;
    const pattern = this.props.pattern || 0;
    return {
      title: t(`pattern.${pattern + 1}.header`),
      subtitle: t(`pattern.${pattern + 1}.sub_header`)
    };
  }

  get content() {
    const { t } = this.props;

    switch (this.props.pattern) {
      case 0: {
        return [
          {
            icon: 'flaticon-check green',
            text: t('list.1.1')
          },
          {
            icon: 'flaticon-check green',
            text: t('list.1.2')
          },
          {
            icon: 'flaticon-check green',
            text: t('list.1.3')
          }
        ];
      }
      case 1: {
        return [
          {
            icon: 'flaticon-cancel red',
            text: t('list.2.1')
          },
          {
            icon: 'flaticon-cancel red',
            text: t('list.2.2')
          },
          {
            icon: 'flaticon-cancel red',
            text: t('list.2.3')
          }
        ];
      }
      case 2: {
        return [
          {
            icon: 'flaticon-check green',
            text: t('list.3.1')
          },
          {
            icon: 'flaticon-check green',
            text: t('list.3.2')
          },
          {
            icon: 'flaticon-check green',
            text: t('list.3.3')
          }
        ];
      }
      default: {
        return [
          {
            icon: 'flaticon-check green',
            text: t('list.default.1')
          },
          {
            icon: 'flaticon-check green',
            text: t('list.default.2')
          },
          {
            icon: 'flaticon-check green',
            text: t('list.default.3')
          }
        ];
      }
    }
  }
  render() {
    const { pattern, isImage, t } = this.props;
    return (
      <div className="value-prop-container col-12">
        <div className="flex zero-margin">
          {isImage ? (
            <React.Fragment>
              <div className="special-img">
                <picture>
                  <source media="(min-width: 768px)" srcSet={SongkranImageDesktop} />
                  <source media="(min-width: 465px)" srcSet={SongkranImageTablet} />
                  <img src={SongkranImageMobile} alt="Songkran Festival" />
                </picture>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="col-5 col-lg-5 col-xl-4 d-flex justify-content-center p-0 pl-2">
                <img className="align-self-end value-prop-img" id="prop-img" src={this.image} alt="Boy-Prop" />
              </div>
              <div className="col-7 col-lg-7 col-xl-8 pl-0 pb-3 pr-0" style={{ paddingTop: 15 }}>
                {pattern !== undefined ? (
                  <React.Fragment>
                    <div id="prop-icon-wrapper">
                      <i className="flaticon-guarantee" />
                    </div>
                    <div id="prop-header-wrapper">
                      <span id="prop-header"> {this.header.title} </span>
                      <span id="prop-subheader"> {this.header.subtitle} </span>
                    </div>
                    <hr id="prop-hr" />
                  </React.Fragment>
                ) : (
                  <div className="prop_header_wrapper">
                    <p className="prop_header_text">
                      <span className="prefix_prop_header">{t('header')}</span>
                      <br />
                      <span className="prop_header">{t('sub_header')}</span>
                    </p>
                  </div>
                )}

                <div id="prop-content-wrapper" className="margin-top-xs">
                  {this.content.map((content) => (
                    <div key={content.text} className="prop-content d-flex pr-4">
                      <div className="icon-wrapper ">
                        <i className={content.icon} />
                      </div>
                      <div className="flex-grow-1">
                        <span className="prop-text">{content.text}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('value_props')(Index);
