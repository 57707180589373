let SEARCH_WEB = '';
let LEGACY_WEB = '';
let LEGACY_MANAGE_WEB = '';
let SEARCH_API_URL = '';
let LEGACY_API_URL = '';
let LONG_TERM_RENTAL_API = '';
let _GTM_ID = '';
let _GA_ID = '';
let _OPTIMIZE_ID = '';
let CAR_WITH_DRIVER = '';
let _BIGBRAND_API = '';
let _MARKETING_API = '';
let _HELP_WEB_URL = '';
let _WEB_PUSH_PUBLIC_KEY = '';
let _VOUCHER_API = '';
let _DRIVEHUB_API = '';
let _FACEBOOK_ID = '320705601614112';
let _GOOGLE_ID = '394392040446-3tn7ot1tditqacbh7rj6u1edjfrna92h.apps.googleusercontent.com';
let _FACEBOOK_OAUTH = 'http://localhost:9000/auth/facebook/oauth';
let _GOOGLE_OAUTH = 'http://localhost:9000/auth/google/oauth';
let _COOKIE_HOST = '.drivehub.co';
if (process.env.GATSBY_STAGE === 'production' || process.env.REACT_APP_STAGE === 'production') {
  SEARCH_API_URL = process.env.GATSBY_SEARCH_API_URL || 'https://search-api.drivehub.co/api';
  SEARCH_WEB = process.env.GATSBY_SEARCH_WEB || 'https://search.drivehub.co';
  LEGACY_WEB = process.env.GATSBY_LEGACY_WEB || 'https://www.drivehub.co';
  LEGACY_API_URL = process.env.GATSBY_LEGACY_API_URL || 'https://backend.drivehub.co/api';
  _GTM_ID = process.env.GATSBY_GTM_ID || 'GTM-N39C5GZ';
  _GA_ID = process.env.GATSBY_GA_ID || 'UA-83410238-1';
  _OPTIMIZE_ID = process.env.GATSBY_OPTIMIZE_ID || 'GTM-KNDPHXB';
  LONG_TERM_RENTAL_API = process.env.GATSBY_LONG_TERM_RENTAL_API || 'https://drivehub-eca1e.firebaseapp.com/api';
  LEGACY_MANAGE_WEB = process.env.GATSBY_LEGACY_MANAGE_WEB || 'https://manage.drivehub.co';
  CAR_WITH_DRIVER = process.env.GATSBY_CAR_WITH_DRIVER || 'https://car-with-driver.drivehub.co';
  _BIGBRAND_API = process.env.GATSBY_BIGBRAND_API_URL || 'https://bigbrand.drivehub.co/api';
  _MARKETING_API = process.env.GATSBY_MARKETING_API_URL || 'https://marketing.drivehub.co/api/v1';
  _VOUCHER_API = process.env.GATSBY_VOUCHER_API_URL || 'https://marketing-api.drivehub.co/api';
  _HELP_WEB_URL = process.env.GATSBY_HELP_WEB_URL || 'https://help.drivehub.co';
  _WEB_PUSH_PUBLIC_KEY =
    process.env.WEB_PUSH_PUBLIC_KEY ||
    'BF-eqOzbwGkXJdZS04PVOMnrPznCnZC5V3VgDtd4kKTBtui7VOyLdBrfRkhcF1WRv4s59E-6iZ5hjAuwOScevu8';
  _DRIVEHUB_API = process.env.GATSBY_DRIVEHUB_API_URL || 'https://www.drivehub.co/api';
  _FACEBOOK_ID = process.env.GATSBY_FACEBOOK_ID || '320705601614112';
  _GOOGLE_ID =
    process.env.GATSBY_GOOGLE_ID || '394392040446-3tn7ot1tditqacbh7rj6u1edjfrna92h.apps.googleusercontent.com';
  _FACEBOOK_OAUTH = process.env.GATSBY_FACEBOOK_OAUTH || 'https://drivehub.co/api/auth/facebook/oauth';
  _GOOGLE_OAUTH = process.env.GATSBY_GOOGLE_OAUTH || 'http://drievhub.co/api/auth/google/oauth';
} else {
  SEARCH_API_URL = 'https://search-api-dev.drivehub.co/api';
  SEARCH_WEB = 'https://app-dev.drivehub.co';
  LEGACY_WEB = 'https://drivehub-dev.drivehub.co';
  LEGACY_API_URL = 'https://backend-dev.drivehub.co/api';
  _GTM_ID = 'GTM-N39C5GZ';
  _GA_ID = 'UA-83410238-3';
  _OPTIMIZE_ID = 'GTM-KNDPHXB';
  LONG_TERM_RENTAL_API = 'https://drivehub-dev.firebaseapp.com/api';
  LEGACY_MANAGE_WEB = 'https://manage-dev.drivehub.co';
  CAR_WITH_DRIVER = 'https://car-with-driver-dev.drivehub.co';
  _BIGBRAND_API = 'https://bigbrand-dev.drivehub.co/api';
  _MARKETING_API = 'https://marketing-dev.drivehub.co/api/v1';
  _VOUCHER_API = 'https://marketing-api-dev.drivehub.co/api';
  _HELP_WEB_URL = 'https://help-dev.drivehub.co';
  _WEB_PUSH_PUBLIC_KEY = 'BAXqCOBnhPHMCNXWDmlc1FZbWe5stMCgSrJUfp2krDLay4y_16PjTXLMvDKIQutJzo-1bAQGDELdv3X1a4YB098';
  // _DRIVEHUB_API = 'http://localhost:9000/api';
  // _FACEBOOK_OAUTH = 'http://localhost:9000/api/auth/facebook/oauth';
  // _GOOGLE_OAUTH = 'http://localhost:9000/api/auth/google/oauth';
  // _COOKIE_HOST = 'localhost';

  _DRIVEHUB_API = 'https://drivehub-dev.drivehub.co/api';
  _FACEBOOK_OAUTH = 'https://drivehub-dev.drivehub.co/api/auth/facebook/oauth';
  _GOOGLE_OAUTH = 'https://drivehub-dev.drivehub.co/api/auth/google/oauth';
  _COOKIE_HOST = '.drivehub.co';
}

export const COOKIE_HOST = _COOKIE_HOST;
export const GTM_ID = _GTM_ID;
export const GA_ID = _GA_ID;
export const OPTIMIZE_ID = _OPTIMIZE_ID;
export const WEB_SEARCH_LINK = SEARCH_WEB;
export const WEB_LEGACY_LINK = LEGACY_WEB;
export const WEB_LEGACY_MANAGE_LINK = LEGACY_MANAGE_WEB;
export const API_LEGACY_LINK = `${LEGACY_API_URL}/v2`;
export const API_GET_LOCATIONS = `${SEARCH_API_URL}/locations`;
export const API_GET_CARS = `${SEARCH_API_URL}/cars`;
export const API_LONG_TERM_RENTAL = LONG_TERM_RENTAL_API;
export const API_CAR_WITH_DRIVER = CAR_WITH_DRIVER;
export const BIGBRAND_API = _BIGBRAND_API;
export const MARKETING_API = _MARKETING_API;
export const VOUCHER_API = _VOUCHER_API;
export const API_SEARCH = SEARCH_API_URL;
export const HELP_WEB_URL = _HELP_WEB_URL;
export const WEB_PUSH_PUBLIC_KEY = _WEB_PUSH_PUBLIC_KEY;
export const DRIVEHUB_API = _DRIVEHUB_API;
export const FACEBOOK_ID = _FACEBOOK_ID;
export const GOOGLE_ID = _GOOGLE_ID;
export const FACEBOOK_OAUTH = _FACEBOOK_OAUTH;
export const GOOGLE_OAUTH = _GOOGLE_OAUTH;

// [ BADGE-SECTION ]
export const INSTANT_BOOKING_BADGE = 'FAST60';
