import { dataLayer } from '@utils/datalayer';
import React, { Component } from 'react';
import './style.scss';

export default class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapase: false
    };
    this.elemHeight = 0;
  }
  componentDidMount() {
    if (this.element) {
      this.elemHeight = this.element.clientHeight;
      this.setState({ isCollapase: true });
    }
  }
  toggle = (isCollapase) => this.setState({ isCollapase });
  render() {
    const { title, children } = this.props;

    return (
      <div className="panel">
        <div
          onClick={(event) => {
            dataLayer(event);
            this.toggle(!this.state.isCollapase);
          }}
          data-event-category="search_bookings_section"
          data-event-label={title}
          className={`title ${this.state.isCollapase ? '' : 'open'}`}
        >
          <h4>{title}</h4>
        </div>
        <div
          ref={(elem) => (this.element = elem)}
          style={{ height: this.elemHeight > 0 ? this.elemHeight : 'auto' }}
          className={`body-wrapper ${this.state.isCollapase ? 'hide' : ''}`}
        >
          <div className={`body ${this.state.isCollapase ? 'hide' : ''}`}>{children}</div>
        </div>
      </div>
    );
  }
}
