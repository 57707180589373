/* eslint-disable react/jsx-key */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import * as actions from '../../../ducks/actions/booking'
import { bindActionCreators } from 'redux'
import ConfirmCustomerInfo from '../../molecules/ConfirmCustomerInfo'
import ContactForm from '../../organisms/ContactForm'
import CreditCardForm from '../../organisms/CreditCardForm'

class FormStep extends Component {
  bookingFieldChange = field => this.props.bookingFieldChange(field.key, field.value)

  get originalFlow() {
    return [
      <div />,
      <ContactForm
        onChange={this.bookingFieldChange}
        customer={this.props.customer}
        note={this.props.note}
        errorValidations={this.props.errorValidations}
      />,
      <ConfirmCustomerInfo customer={this.props.customer} note={this.props.note} />,
    ]
  }
  get bigbrandFlow() {
    return [
      <div />,
      <ContactForm
        onChange={this.bookingFieldChange}
        customer={this.props.customer}
        note={this.props.note}
        errorValidations={this.props.errorValidations}
        isInter={['inter', 'bigbrand'].includes(this.props.params.source)}
      />,
      <React.Fragment>
        <ConfirmCustomerInfo customer={this.props.customer} note={this.props.note} /> <hr />
        <CreditCardForm
          onChange={this.bookingFieldChange}
          {...this.props.payment}
          t={this.props.t}
          toggleTermCheckbox={this.props.toggleTermCheckbox}
          vendor={this.props.vendor}
          isTermAccept={this.props.isTermAccept}
        />
      </React.Fragment>,
    ]
  }
  get content() {
    const isInter = ['inter', 'bigbrand'].includes(this.props.params.source)
    const flow = isInter ? this.bigbrandFlow : this.originalFlow
    return flow[this.props.step]
  }
  render() {
    return <div className="step-detail--wrapper">{this.content}</div>
  }
}

const mapStateToProps = state => ({
  step: state.booking.step,
  customer: state.booking.customer,
  payment: state.booking.payment,
  note: state.booking.note,
  params: queryString.parse(state.router.location.search),
  errorValidations: state.booking.errorValidations,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ bookingFieldChange: actions.bookingFieldChange }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FormStep)
