import React, { Component } from 'react';
import Modal from 'rc-dialog';
import MediaQuery from 'react-responsive';
import StickyButton from '../../molecules/StickyButton';
import Panel from '../../molecules/Panel';
import faq from './questions';
import PropTypes from 'prop-types';
import './style.scss';
import { dataLayer } from '@utils/datalayer';

export default class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      open: -1
    };
    this.panels = [];
  }
  toggle = (open, event) => {
    if (event) {
      dataLayer(event, open === -1 ? 'more' : 'click');
    }
    window.toggleSticky();

    this.setState({ visible: !this.state.visible, open }, () => {
      setTimeout(() => {
        this.panels.forEach((panel, index) => {
          panel.toggle(open === index ? false : true);
        });
      }, 500);
    });
  };
  open = (open) => {
    this.setState({ open }, () => {
      this.panels.forEach((panel, index) => {
        if (open === index) {
          panel.toggle(false);
        }
      });
    });
  };
  render() {
    const { source, active } = this.props;
    return (
      <React.Fragment>
        <Modal
          title={<b style={{ fontSize: 18 }}>FAQs</b>}
          className="modal-center"
          wrapClassName="modal-wrapper"
          visible={this.state.visible}
          onClose={() => this.toggle(-1)}
          animation="zoom"
          maskAnimation="fade"
        >
          {faq.map((q, index) => {
            return (
              <Panel
                ref={(ref) => (this.panels[index] = ref)}
                title={q.question}
                key={q.question}
                open={index === this.state.open}
              >
                {q.answer({ toggle: this.open, source, active })}
              </Panel>
            );
          })}
        </Modal>
        {/* --- Begin : Desktop Search Bar --- */}
        <MediaQuery minWidth={992}>
          <div className="zero-margin">
            {faq
              .filter((_, index) => index <= 2)
              .map((q, index) => {
                return (
                  <button
                    className="question-button"
                    data-event-category="search_bookings_section"
                    data-event-label={q.question}
                    key={q.question}
                    onClick={(e) => this.toggle(index, e)}
                  >
                    <div>
                      <i className={q.icon} />
                      <b>{q.question}</b>
                    </div>
                    <i className="flaticon-next" />
                  </button>
                );
              })}
            <button
              className="question-button"
              data-event-category="search_bookings_section"
              data-event-label="faqs"
              onClick={(e) => this.toggle(-1, e)}
            >
              <div>
                <i className="flaticon-more" />
                <b>คำถามอื่นๆ</b>
              </div>
              <i className="flaticon-next" />
            </button>
          </div>
        </MediaQuery>
        {/* --- END : Desktop Search Bar --- */}

        {/* --- Begin : MOBILE Search Bar --- */}
        {this.props.isSearchResultPage ? (
          ''
        ) : (
          <MediaQuery maxWidth={991}>
            <StickyButton onClick={() => this.toggle(-1)} />
          </MediaQuery>
        )}
        {/* --- END : MOBILE Search Bar --- */}
      </React.Fragment>
    );
  }
}
Faq.defaultProps = {
  source: 'local',
  active: ''
};
Faq.propTypes = {
  source: PropTypes.string,
  active: PropTypes.string
};
