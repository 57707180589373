import React, { PureComponent } from 'react';
import RcToolTip from 'rc-tooltip';
import styled from 'styled-components';
import ServiceIcon from '../../../assets/icon/service-icon.svg';
import './rating-tooltip.scss';

const alignConfig = {
  offset: [-10, -8]
};
class RatingToolTip extends PureComponent {
  render() {
    return (
      <RcToolTip
        placement="top"
        overlay={<RatingBox summaryData={this.props.summaryData} />}
        overlayClassName="rating-summary"
        // visible
        align={alignConfig}
      >
        {this.props.children}
      </RcToolTip>
    );
  }
}
const RATE_LOW_COLOR = 2.5;
const HIGHCOLOR = 'rgba(29, 109, 3, 1)';
const LOWCOLOR = 'rgba(255,193,0,1)';

const RatingBox = ({ summaryData }) => {
  const [value, overall, accuracy, timing, attention, recommend] = summaryData;
  if (!value) {
    return <div></div>;
  }
  return (
    <div className="rating-container">
      <p>รีวิวจากผู้ใช้บริการ</p>
      <div className="section-score">
        <p>
          <i className="flaticon-car-compact" />
          คุณภาพรถ
        </p>
        <div className="score-items">
          <div>
            <label>{value.name}</label>
            <RatingBar percent={value.percent} color={value.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR} />
            <p
              className={`score ${value.score <= 0 ? 'score--zero' : ''}`}
              style={{
                color: value.score <= 0 ? '#6f6f6f' : value.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR
              }}
            >
              {value.score <= 0 ? 'ยังไม่มีรีวิว' : value.score}
            </p>
          </div>
          <div>
            <label>{overall.name}</label>
            <RatingBar percent={overall.percent} color={overall.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR} />
            <p
              className={`score ${overall.score <= 0 ? 'score--zero' : ''}`}
              style={{
                color: overall.score <= 0 ? '#6f6f6f' : overall.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR
              }}
            >
              {overall.score <= 0 ? 'ยังไม่มีรีวิว' : overall.score}
            </p>
          </div>
        </div>
        <p>
          <img id="service-icon" src={ServiceIcon} alt="การบริการ" />
          การบริการ
        </p>
        <div className="score-items">
          <div>
            <label>{timing.name}</label>
            <RatingBar percent={timing.percent} color={timing.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR} />
            <p
              className={`score ${timing.score <= 0 ? 'score--zero' : ''}`}
              style={{
                color: timing.score <= 0 ? '#6f6f6f' : timing.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR
              }}
            >
              {timing.score <= 0 ? 'ยังไม่มีรีวิว' : timing.score}
            </p>
          </div>
          <div>
            <label>{attention.name}</label>
            <RatingBar percent={attention.percent} color={attention.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR} />
            <p
              className={`score ${attention.score <= 0 ? 'score--zero' : ''}`}
              style={{
                color: attention.score <= 0 ? '#6f6f6f' : attention.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR
              }}
            >
              {attention.score <= 0 ? 'ยังไม่มีรีวิว' : attention.score}
            </p>
          </div>
        </div>
      </div>
      <div className="section-recommend">
        <div>
          <p>
            <i className="flaticon-guarantee" />
            {accuracy.name}
          </p>
          <p className={`text-percent ${accuracy.score <= 0 ? 'text-percent--zero' : ''}`}>
            {accuracy.percent <= 0 ? '-' : accuracy.percent}
            <span>%</span>
          </p>
        </div>
        <div>
          <p>
            <i className="flaticon-like-1" />
            {recommend.name}
          </p>
          <p className={`text-percent ${recommend.score <= 0 ? 'text-percent--zero' : ''}`}>
            {recommend.percent <= 0 ? '-' : recommend.percent}
            <span>%</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const RatingBar = styled.div`
  height: 5px;
  border-radius: 2px;
  background: ${(props) => `
    linear-gradient(
      90deg,
      ${props.color} ${props.percent}%,
      rgba(238, 243, 248, 1) ${props.percent}%,
      rgba(238, 243, 248, 1) 100%
  );`};
`;
export default RatingToolTip;
