import React, { PureComponent } from 'react';
import './style.scss';
import { withTranslation } from '../../../locales';
import Form from 'react-bootstrap/Form';

class ContactForm extends PureComponent {
  handleInputChange(key) {
    return (event) => {
      if (key === 'customer.phone_number') event.target.value = event.target.value.replace(/[^0-9]/g, '');
      this.props.onChange({
        key,
        value: event.target.value
      });
    };
  }
  render() {
    const {
      customer: { first_name, last_name, email, phone_number },
      note,
      errorValidations,
      t
    } = this.props;
    return (
      <div className="contact_form_wrapper">
        <p className="form_title">{t('title')}</p>
        <Form name="booking_customer" noValidate>
          <Form.Group>
            <Form.Control
              onChange={this.handleInputChange('customer.first_name')}
              placeholder={t('placeholder.first_name')}
              name="first_name"
              value={first_name}
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              {errorValidations.first_name && <small>{errorValidations.first_name}</small>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              onChange={this.handleInputChange('customer.last_name')}
              placeholder={t('placeholder.last_name')}
              name="last_name"
              value={last_name}
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              {errorValidations.last_name && <small>{errorValidations.last_name}</small>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <i className="flaticon-envelope" />
            <Form.Control
              type="email"
              onChange={this.handleInputChange('customer.email')}
              placeholder={t('placeholder.email')}
              name="email"
              value={email}
              className="form-control--email"
              isInvalid={errorValidations.email && errorValidations.email !== ''}
              onKeyPress={(e) => {
                const code = e.which ? e.which : e.keyCode;
                if (code === 32) {
                  e.preventDefault();
                }
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errorValidations.email && <small>{errorValidations.email}</small>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <i className="flaticon-smartphone" />
            <Form.Control
              type="tel"
              onChange={this.handleInputChange('customer.phone_number')}
              placeholder={t('placeholder.phone_number')}
              name="phone_number"
              value={phone_number}
              isInvalid={errorValidations.phone_number && errorValidations.phone_number !== ''}
              maxLength="10"
              className="form-control--smartphone"
            />
            <Form.Control.Feedback type="invalid">
              {errorValidations.phone_number && <small>{errorValidations.phone_number}</small>}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="mt-2 mb-2" id="check_form_text">
            <p>{t('confirm_warning')}</p>
          </div>
          <Form.Group>
            <Form.Control
              onChange={this.handleInputChange('note')}
              placeholder={t('placeholder.note')}
              name="note"
              value={note}
              as="textarea"
            />
            <Form.Control.Feedback>
              {errorValidations.note && <small>{errorValidations.note}</small>}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

ContactForm.defaultProps = {
  customer: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: ''
  },
  note: '',
  errorValidations: {
    email: '',
    phone_number: ''
  },
  isInter: false
};

export default withTranslation('customer_form')(ContactForm);
