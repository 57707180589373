import { observable, decorate, action } from 'mobx'
class Store {
  type = 'cash'
  selectType = type => {
    this.type = type
  }
}

decorate(Store, {
  type: observable,
  selectType: action,
})

const store = new Store()

export default store
