import { RouteComponentProps } from 'react-router';
export interface BookingDetailResp {
  booking: Booking;
  car: Car;
  customer: Customer;
  dealer: Dealer;
  pricing: Pricing;
  transitions: string[];
}

export interface ChatComponentProps extends RouteComponentProps<{ [key: string]: any }>, Record<string, unknown> {}
export enum ChatRenderState {
  Mobile = '0',
  Desktop = '1'
}
export interface ChatComponentState {
  transitions: string[];
  rating: any;
  isLoading: boolean;
  isError: boolean;
  token: string;
  chatRenderState: ChatRenderState;
  car?: Car;
  booking?: Booking;
  customer?: Customer;
  dealer?: Dealer;
  pricing?: Pricing;
}

interface Booking {
  city: string;
  created_at: string;
  current_state: string;
  duration_days: number;
  duration_hours: number;
  id: number;
  location: string;
  note: string;
  pickup_datetime: string;
  return_datetime: string;
}
interface Car {
  car_id: number;
  category: string;
  features: string[];
  image: string;
  luggages: number;
  model: string;
  passengers: number;
  photos: {
    kind: string;
    url: string;
  }[];
  registration_year: number;
  transmission: string;
}
interface Customer {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}
interface Dealer {
  badges: string[];
  branch_id: number;
  branch_name: string;
  logo: string;
  name: string;
}
interface Pricing {
  coupon_code: string;
  delivery_price: number;
  deposit: number;
  discount: number;
  duration_days: number;
  duration_hours: number;
  grand_total_price: number;
  pickup_price: number;
  total_grace_period: number;
  total_price: number;
}
