import React from 'react'

const RadioButton = props => {
  return (
    <React.Fragment>
      <input {...props} type="Radio" />
      &nbsp;&nbsp;&nbsp;
      <label className="radio-checkbox" htmlFor={props.id}>
        {props.label}
      </label>
    </React.Fragment>
  )
}
export default RadioButton
