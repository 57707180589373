import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const animationBounce = keyframes`
  0% { transform: translateY(0) rotate(0deg); }
  50% {transform: translateY(-2px) rotate(180deg);}
  100% {transform: translateY(0px) rotate(360deg);}
`

const Loading = styled.div`
  width: 200px;
  text-align: center;
  position: relative;
  left: 50%;
  margin-left: -100px;
  margin-top: -25px;
  top: 50%;
  color: #fff;

  h3 {
    color: black;
    font-size: 12px;

    @media screen and (min-width: 768px) {
      font-size: 19px;
    }
  }
`

export const LoadingIcon = styled.span`
  ${props =>
    // eslint-disable-next-line no-prototype-builtins
    props.hasOwnProperty('dark')
      ? 'filter: brightness(0.03) sepia(1) hue-rotate(180deg) saturate(5);'
      : ''};
  background: url(${props => props.bg});
  width: 30px;
  height: 30px;
  display: inline-block;
  animation: ${animationBounce} 700ms linear infinite;
`

export const LoadingBox = props => (
  <Loading {...props}>
    <LoadingIcon {...props} bg={props.bg} />
    {/* FIXME:  */}
    <h3>&nbsp;&nbsp;&nbsp;โปรดรอสักครู่...</h3>
  </Loading>
)

LoadingIcon.propTypes = {
  bg: PropTypes.string,
}

LoadingBox.propTypes = {
  bg: PropTypes.string,
}
