import React, { Component } from 'react';
import moment from 'moment-timezone';
import WidgetSearchBar from '../../ecosystems/WidgetSearchBar';
import Separator from './Separator';
import './style.scss';
import { dataLayer } from '@utils/datalayer';

export default class CalendarPicker extends Component {
  fetchPricing = (pickup_datetime, return_datetime) => {
    const { onChange } = this.props;
    onChange([moment(pickup_datetime), moment(return_datetime)]);
  };

  render() {
    const {
      pickup_datetime,
      return_datetime,
      location_name,
      onEditDateTime,
      isEditDateTime,
      isDisableChangeDateTime
    } = this.props;
    return (
      <React.Fragment>
        {isEditDateTime && (
          <div className={`pb-3`} style={{ backgroundColor: 'rgb(246, 246, 246)', paddingTop: 10 }}>
            <WidgetSearchBar
              pickup_datetime={moment(pickup_datetime).format('YYYY-MM-DD HH:mm')}
              return_datetime={moment(return_datetime).format('YYYY-MM-DD HH:mm')}
              onToggleSearchBarMobile={onEditDateTime}
              widget={true}
              submitButtonAction={this.fetchPricing}
            />
          </div>
        )}
        <div
          className={`${isDisableChangeDateTime ? 'cursor-default' : ''} calendar_picker_wrapper`}
          onClick={() => onEditDateTime(true)}
        >
          <div
            className="calendar_picker_box first"
            data-event-category="bookings_section"
            data-event-label="change_pickup_date"
            onClick={(e) => dataLayer(e)}
          >
            <p className="picker_title">
              รับรถ{' '}
              {!isDisableChangeDateTime && (
                <span>
                  <i className="flaticon-edit" />
                  <span className="ml-1 change_date">
                    <small>เปลี่ยนวัน</small>
                  </span>
                </span>
              )}
            </p>
            <p className="picker_location">
              <b>{location_name}</b>
            </p>
            <p className="picker_date">{moment(pickup_datetime).format('DD MMM YYYY HH:mm')}</p>
          </div>
          <Separator />
          <div
            className="ml-3 calendar_picker_box"
            data-event-category="bookings_section"
            data-event-label="change_return_date"
            onClick={(e) => dataLayer(e)}
          >
            <p className="picker_title">
              คืนรถ{' '}
              {!isDisableChangeDateTime && (
                <span>
                  <i className="flaticon-edit" />
                  <span className="ml-1 change_date">
                    <small>เปลี่ยนวัน</small>
                  </span>
                </span>
              )}
            </p>
            <p className="picker_location">
              <b>{location_name}</b>
            </p>
            <p className="picker_date">{moment(return_datetime).format('DD MMM YYYY HH:mm')}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
