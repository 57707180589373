import React from 'react';
import PropTypes from 'prop-types';
import InterLocalBadge from '../../atoms/InterLocalBadge';
import InterIcon from '../../../assets/icon/inter-icon.svg';
import LocalIcon from '../../../assets/icon/local-icon.svg';
import './style.scss';

const BookingVendorDetail = (props) => {
  const {
    branchLevelStats,
    dealer: { name, logo, branch_name },
    source
  } = props;
  return (
    <div className="booking-vendor-detail">
      <p className="title font-weight-bold">ข้อมูลบริษัทรถเช่า</p>
      <div className="vendor">
        <div className="col-12 col-lg-6 pr-lg-0 vendor-content">
          <img src={logo} alt={name} />
          <div className="vendor-name">
            <h4 className="m-0">{name}</h4>
            <p className="branch-name">{branch_name}</p>
            <div className="mt-1">
              <InterLocalBadge
                icon={source === 'inter' ? InterIcon : LocalIcon}
                type={source === 'inter' ? 'inter' : 'local'}
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 pl-lg-0 review">
          <div className="review-recommend">
            {branchLevelStats.summary.length > 0 && (
              <React.Fragment>
                <div className="accuracy-item">
                  {source === 'inter' ? (
                    <p className="d-flex flex-column align-items-center">
                      <i className="flaticon-guarantee" />
                      ได้รถรุ่นที่จอง
                      <br />
                      หรือเทียบเท่า
                    </p>
                  ) : (
                    <React.Fragment>
                      <p>
                        <i className="flaticon-guarantee" />
                        {branchLevelStats.summary[2].name}
                      </p>
                      <p
                        className={`text-percent ${branchLevelStats.summary[2].score <= 0 ? 'text-percent--zero' : ''}`}
                      >
                        {branchLevelStats.summary[2].percent <= 0 ? '-' : branchLevelStats.summary[2].percent}
                        <span>%</span>
                      </p>
                    </React.Fragment>
                  )}
                </div>
                <span className="vl" />
                <div className="recommend-item">
                  <p>
                    <i className="flaticon-like-1" />
                    {branchLevelStats.summary[5].name}
                  </p>
                  <p className={`text-percent ${branchLevelStats.summary[5].score <= 0 ? 'text-percent--zero' : ''}`}>
                    {branchLevelStats.summary[5].percent <= 0 ? '-' : branchLevelStats.summary[5].percent}
                    <span>%</span>
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="review-score">
            <p className="font-weight-bold">คะแนนเฉลี่ย</p>
            <p className={`score ${branchLevelStats.total === 0 ? 'score--empty' : ''}`}>
              {branchLevelStats.rating}
              <span>/5</span>
            </p>
            <div className="review-total">
              {branchLevelStats.total === 0 ? (
                'ยังไม่มีรีวิว'
              ) : (
                <React.Fragment>
                  {branchLevelStats.level} <span>({branchLevelStats.total} รีวิว)</span>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BookingVendorDetail.defaultProps = {
  rating: {},
  dealer: {
    branch_name: 'Branch Name',
    logo: 'http://placehold.jp/96x96.png',
    name: 'Vendor Name',
    source: 'local'
  }
};

BookingVendorDetail.propTypes = {
  averageRating: PropTypes.number,
  dealer: PropTypes.object,
  source: PropTypes.string
};

export default BookingVendorDetail;
