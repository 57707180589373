import React from 'react';
import Context from '@ui/components/context';
import NavBar from '@ui/layouts/navbar';
import {
  LEGACY_WEB,
  BIGBRAND_API,
  API_SEARCH_HOST,
  SEARCH_HOST_URL,
  API_LEGACY,
  HELP_WEB
} from '../../../variables/API';

class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <React.Fragment>
        <Context
          bigbrandAPI={BIGBRAND_API}
          searchAPI={API_SEARCH_HOST}
          searchHost={SEARCH_HOST_URL}
          backendAPI={API_LEGACY}
          legacyHost={LEGACY_WEB}
        >
          <NavBar prefix="th" sticky={this.props.sticky} isTransparent={false} removeFixToTop helpLink={HELP_WEB} />
        </Context>
      </React.Fragment>
    );
  }
}
export default MainMenu;
