import car_detail from './car-detail.json'
import card from './card.json'
import common from './common.json'
import customer_form from './customer_form'
import coupon from './coupon.json'
import filter from './filter.json'
import progress from './progress.json'
import search_bar from './search_bar.json'
import summary from './summary.json'
import successful from './successful.json'
import value_props from './value_props.json'
import review from './review.json'
import invoice from './invoice.json'
import info_test from './info_test.json'
import bookings from './bookings.json'
const locales = {
  common,
  car_detail,
  card,
  customer_form,
  progress,
  summary,
  coupon,
  value_props,
  review,
  successful,
  invoice,
  filter,
  search_bar,
  info_test,
  bookings,
}

export default locales
