import axios from 'axios'
import { DRIVEHUB_API } from '../../variables/API'
import { SUMMARY_RATINGS_REVIEWS_ACTION, RATINGS_REVIEWS_ACTION } from '../reducers/reviews'

export const getReviewsStats = (refID, source, isCarLevel) => dispatch => {
  const newSource = source === 'local' ? 'original' : 'bigbrand'

  const url = isCarLevel
    ? `${DRIVEHUB_API}/car/${refID}/stat?source=${newSource}`
    : `${DRIVEHUB_API}/branch/${refID}/stat?source=${newSource}`
  dispatch({
    type: SUMMARY_RATINGS_REVIEWS_ACTION.FETCHING,
  })

  axios
    .get(url)
    .then(res => {
      dispatch({
        type: SUMMARY_RATINGS_REVIEWS_ACTION.FETCHED,
        payload: res.data,
        isCarLevel: isCarLevel,
      })
    })
    .catch(error => {
      console.log('Error cannot get summary reviews: ', error)
      dispatch({
        type: SUMMARY_RATINGS_REVIEWS_ACTION.ERROR,
        payload: error,
        isCarLevel: isCarLevel,
      })
    })
}

export const getBranchLevelReviewsStats = (refID, source) => dispatch => {
  dispatch({
    type: SUMMARY_RATINGS_REVIEWS_ACTION.FETCHING_BRANCH_LEVEL,
  })
  const newSource = source === 'local' ? 'original' : 'bigbrand'
  const url = `${DRIVEHUB_API}/branch/${refID}/stat?source=${newSource}`

  axios
    .get(url)
    .then(res => {
      dispatch({
        type: SUMMARY_RATINGS_REVIEWS_ACTION.FETCHED_BRANCH_LEVEL,
        payload: res.data,
      })
    })
    .catch(error => {
      console.log('Error cannot get summary reviews: ', error)
      dispatch({
        type: SUMMARY_RATINGS_REVIEWS_ACTION.ERROR,
        payload: error,
      })
    })
}

export const getRatingsReviewsItems = (
  refID,
  source,
  isCarLevel,
  sort_by = 'rating',
  order_by = 'desc',
  offset = 0,
  limit = 5,
) => dispatch => {
  const newSource = source === 'local' ? 'original' : 'bigbrand'
  const url = `${DRIVEHUB_API}/${
    isCarLevel ? 'car' : 'branch'
  }/${refID}/reviews?source=${newSource}&offset=${offset}&limit=${limit}&sort_by=${sort_by}&order_by=${order_by}`
  dispatch({
    type: RATINGS_REVIEWS_ACTION.FETCHING,
  })
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: RATINGS_REVIEWS_ACTION.FETCHED,
        payload: res.data,
      })
    })
    .catch(error => {
      console.log('Error cannot get reviews: ', error)
      dispatch({
        type: RATINGS_REVIEWS_ACTION.ERROR,
        payload: error,
      })
    })
}
export const onToggleRatingLevel = () => async dispatch => {
  return dispatch({ type: SUMMARY_RATINGS_REVIEWS_ACTION.TOGGLE_LEVEL })
}
