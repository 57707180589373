import queryString from 'query-string';
import moment from 'moment-timezone';
import axios from 'axios';
import { setCookie, deleteLocalStorage, deleteCookie, readCookie } from '../../utils';
import {
  API_CREATE_BOOKING_RECORD as BIGBRAND_ENDPOINT,
  API_CREATE_ORIGIN_BOOKING as ORIGINAL_ENDPOINT,
  API_CREATE_ORIGIN_DRAFT as ORIGINAL_DRAFT_ENDPOINT
} from '../../variables/API';

export const NEXT = 'BOOKING_NEXT';
export const PREV = 'BOOKING_PREV';
export const CUSTOMER_VALIDATION = 'BOOKING_CUSTOMER_VALIDATION';
export const FORM_VALIDATION = 'BOOKING_FORM_VALIDATION';
export const CUSTOMER_CHANGE = 'BOOKING_CUSTOMER_FIELD_CHANGE';
export const NOTE_CHANGE = 'BOOKING_NOTE_FIELD_CHANGE';
export const CODE_CHANGE = 'BOOKING_CODE_FIELD_CHANGE';
export const PAYMENT_CHANGE = 'BOOKING_PAYMENT_FIELD_CHANGE';
export const PAYMENT_VALID = 'BOOKING_PAYMENT_VALID';
export const PAYMENT_INVALID = 'BOOKING_PAYMENT_INVALID';
export const PAYMENT_LOADING = 'BOOKING_PAYMENT_LOADING';
export const BOOKING_CREATE_ERROR = 'BOOKING_CREATE_ERROR';
export const BOOKING_CREATING_DRAFT = 'BOOKING_CREATING_DRAFT';
export const BOOKING_CREATED_DRAFT = 'BOOKING_CREATED_DRAFT';
export const CAR_AVAILABLE_FOR_WALK_IN = 'CAR_AVAILABLE_FOR_WALK_IN';
export const SKIP = 'BOOKING_SKIP_STEP';
export const INSURANCE_CREATE = 'BOOKING_INSURANCE_CREATE';

export const checkConsumerID = (dispatch) => {
  const isLogOut = readCookie('logout');
  const consumerID = readCookie('_consumerID');
  if (isLogOut !== null || consumerID === null) {
    deleteLocalStorage('customer');
    deleteCookie('logout');
  }
  return dispatch({
    type: 'BOOKING_CUSTOMER_FIELD_CHANGE',
    payload: {
      field: 'consumer_id',
      value: consumerID
    }
  });
};
export const next = () => (dispatch) => {
  checkConsumerID(dispatch);
  dispatch({
    type: NEXT
  });
};

export const prev = () => (dispatch) => {
  checkConsumerID(dispatch);
  dispatch({
    type: PREV
  });
};
export const skip = (step) => (dispatch) => {
  dispatch({ type: SKIP, payload: step });
};

export const bookingFieldChange = (key, value) => (dispatch) => {
  const [domain, field] = key.split('.');
  const type = `BOOKING_${domain.toUpperCase()}_FIELD_CHANGE`;

  if (field === 'email' || field === 'phone_number') value = value.replace(/[^a-zA-Z0-9_.+-@]/gi, '');
  checkConsumerID(dispatch);
  dispatch({
    type,
    payload: {
      field: field || domain,
      value: value
    }
  });
};
const fields = {
  first_name: 'ชื่อ',
  last_name: 'นามสกุล',
  email: 'อีเมล์',
  phone_number: 'เบอร์โทรศัพท์'
};

const errorHandler = (error) => (dispatch) => {
  const { status, data } = error.response;
  if (status === 400) {
    try {
      switch (data.code) {
        case 'car_is_not_available': {
          return dispatch({ type: BOOKING_CREATE_ERROR, payload: true });
        }
        case 'car_available_for_walkin': {
          return dispatch({ type: CAR_AVAILABLE_FOR_WALK_IN, payload: true });
        }
        case 'email_is_not_valid':
        case 'email_cant_be_blank':
        case 'last_name_cant_be_blank':
        case 'first_name_cant_be_blank':
        case 'phone_number_cant_be_blank':
        case 'phone_number_is_not_valid': {
          dispatch({ type: PREV });
          const code = data.code.replace('_is_not_valid', '').replace('_cant_be_blank', '');
          return dispatch({
            type: FORM_VALIDATION,
            payload: {
              [code]: `กรุณาตรวจสอบ${fields[code]}อีกครั้ง`
            }
          });
        }
        default: {
          dispatch({ type: BOOKING_CREATE_ERROR, payload: true });
        }
      }
    } catch (e) {
      dispatch({ type: BOOKING_CREATE_ERROR, payload: true });
    }
  } else {
    dispatch({ type: BOOKING_CREATE_ERROR, payload: true });
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const validateForm = (customer, callback = () => {}) => (dispatch) => {
  const isEmailValid = customer.email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
  const isPhoneNumberValid = customer.phone_number.match(
    /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
  );

  if (isEmailValid && isPhoneNumberValid) {
    callback();
    dispatch({ type: FORM_VALIDATION, payload: {} });
    return dispatch({
      type: NEXT
    });
  } else {
    const email = isEmailValid ? '' : 'กรุณาตรวจสอบอีเมล์อีกครั้ง';
    const phone_number = isPhoneNumberValid ? '' : 'กรุณาตรวจสอบเบอร์โทรศัพท์อีกครั้ง';
    return dispatch({ type: FORM_VALIDATION, payload: { email, phone_number } });
  }
};

export const createBigbrandBooking = () => async (dispatch, getState) => {
  dispatch({ type: BOOKING_CREATE_ERROR, payload: false });
  const booking = getState().booking;
  const { first_name, email, consumer_id } = booking.customer;
  let last_name = booking.customer.last_name;
  let phone_number = booking.customer.phone_number;
  last_name = last_name.trim();
  const location = getState().router.location;
  const params = queryString.parse(window.location.search);
  const car_id = location.pathname.split('/').reverse()[0];
  const payment = getState().booking.payment;

  let token = null;
  await window.My2c2p.getEncrypted('2c2p-payment-form', (encryptedData, errCode, errDesc) => {
    if (errCode !== 0) {
      switch (errCode) {
        case 1:
        case 2:
          return dispatch({ type: PAYMENT_INVALID, payload: { card: errDesc } });
        case 3:
        case 4:
          return dispatch({ type: PAYMENT_INVALID, payload: { expires_month: errDesc } });
        case 5:
        case 6:
          return dispatch({ type: PAYMENT_INVALID, payload: { expires_year: errDesc } });
        case 7:
        case 8:
          return dispatch({ type: PAYMENT_INVALID, payload: { expires: errDesc } });
        case 10:
          return dispatch({ type: PAYMENT_INVALID, payload: { cvc: errDesc } });
        case 11:
          return dispatch({ type: PAYMENT_INVALID, payload: { expires_month: errDesc } });
        case 12:
          return dispatch({ type: PAYMENT_INVALID, payload: { expires_year: errDesc } });
        default:
          return dispatch({ type: PAYMENT_INVALID, payload: errDesc });
      }
    }
    if (payment.name === '') return dispatch({ type: PAYMENT_INVALID, payload: { name: 'name is empty.' } });
    if (payment.cvc === '') return dispatch({ type: PAYMENT_INVALID, payload: { cvc: 'cvc is empty.' } });
    token = encryptedData['encryptedCardInfo'];
  });

  if (token === null) return;

  if (phone_number[0] === '0') {
    phone_number = '+66' + phone_number.slice(1);
  }
  const body = {
    consumer_id: consumer_id,
    credit_card_token: token,
    name: first_name,
    surname: last_name,
    mobile_number: phone_number,
    email: email,
    note: booking.note,
    car_id: car_id,
    pickup_datetime: moment(params.pickup_datetime, 'YYYY-MM-DD HH:mm').format(),
    return_datetime: moment(params.return_datetime, 'YYYY-MM-DD HH:mm').format(),
    location_id: params.location_id,
    code: booking.code,
    insurance_id: params.insurance_id
  };

  window.localStorage.removeItem(car_id);
  axios
    .post(BIGBRAND_ENDPOINT, body)
    .then((response) => response.data)
    .then((response) => {
      const { booking_id } = response;
      const pricing = getState().fetch.pricing;

      // NOTE: Google Ecommerce Tracking

      const ecPurchaseObj = {
        id: booking_id,
        affiliation: 'Online Booking',
        revenue: pricing.grand_total_price,
        shipping: pricing.pickup_price + pricing.delivery_price
      };
      if (booking.code !== '') ecPurchaseObj['coupon'] = booking.code;

      // NOTE: Google Event Tracking

      // NOTE: For Re-targeting User.
      const bookingIdEncoded = window.btoa(booking_id);
      setCookie('_CV', bookingIdEncoded, 365, '/');

      window.location.href = `/success/${booking_id}?source=inter&last_name=${last_name}`;
    })
    .catch((error) => {
      dispatch(errorHandler(error));
    });
};

export const createOriginalBooking = () => async (dispatch, getState) => {
  dispatch({ type: BOOKING_CREATE_ERROR, payload: false });
  const booking = getState().booking;
  const location = getState().router.location;
  const params = queryString.parse(window.location.search);
  const { first_name, phone_number, email, consumer_id } = booking.customer;
  let last_name = booking.customer.last_name;
  last_name = last_name.trim();

  const car_id = location.pathname.split('/').reverse()[0];
  const booking_id = window.localStorage.getItem(car_id);
  const body = {
    consumer_id: consumer_id,
    car_id,
    booking_id,
    booking_begin: params.pickup_datetime + ':00',
    booking_end: params.return_datetime + ':00',
    note: booking.note,
    location_id: params.location_id,
    user: {
      first_name,
      last_name,
      phone_number,
      email
    },
    code: booking.code
  };

  window.localStorage.removeItem(car_id);

  axios
    .post(ORIGINAL_ENDPOINT, body)
    .then((response) => response.data.data)
    .then((response) => {
      const pricing = getState().fetch.pricing;

      // NOTE: Google Ecommerce Tracking
      const ecPurchaseObj = {
        id: booking_id,
        affiliation: 'Online Booking',
        revenue: pricing.grand_total_price,
        shipping: pricing.pickup_price + pricing.delivery_price
      };
      if (booking.code !== '') ecPurchaseObj['coupon'] = booking.code;

      // NOTE: Google Event Tracking
      const { id } = response;

      // NOTE: For Re-targeting User.
      const bookingIdEncoded = window.btoa(booking_id);

      setCookie('_CV', bookingIdEncoded, 365, '/');

      window.localStorage.removeItem(car_id);
      window.location.href = `/success/${id}?source=local&last_name=${last_name}`;
    })
    .catch((error) => {
      dispatch(errorHandler(error));
    });
};

export const createInsurance = (insurance) => (dispatch) => {
  dispatch({ type: INSURANCE_CREATE, payload: insurance });
};

export const createBooking = () => async (dispatch, getState) => {
  const router = getState().router;
  const params = queryString.parse(router.location.search);
  dispatch({ type: PAYMENT_LOADING });
  if (['inter', 'bigbrand'].includes(params.source)) {
    dispatch(createBigbrandBooking());
  } else {
    dispatch(createOriginalBooking());
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const createDraftBooking = (callback = () => {}) => async (dispatch, getState) => {
  callback();
  const booking = getState().booking;
  const location = getState().router.location;

  const params = queryString.parse(window.location.search);
  const consumerID = readCookie('_consumerID');
  checkConsumerID(dispatch);
  const { first_name, last_name, phone_number, email } = booking.customer;

  const car_id = location.pathname.split('/').reverse()[0];
  const body = {
    car_id,
    booking_begin: params.pickup_datetime + ':00',
    booking_end: params.return_datetime + ':00',
    note: booking.note,
    location_id: params.location_id,
    consumer_id: consumerID,
    user: {
      first_name,
      last_name,
      phone_number,
      email
    }
  };

  if (window.localStorage.getItem(car_id)) {
    return callback();
  }

  dispatch({ type: BOOKING_CREATING_DRAFT });

  axios
    .post(ORIGINAL_DRAFT_ENDPOINT, body)
    .then((response) => response.data.data)
    .then((response) => {
      const referrer = `${window.location.pathname}${window.location.search}`;
      dispatch({ type: BOOKING_CREATED_DRAFT });
      window.localStorage.setItem(referrer, response.id);
      window.localStorage.setItem(car_id, response.id);
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: BOOKING_CREATE_ERROR, payload: true });
      dispatch({ type: BOOKING_CREATED_DRAFT });
    });
};

export const validateCustomer = () => (dispatch) => dispatch({ type: CUSTOMER_VALIDATION });
