/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import queryString from 'query-string';
import 'moment/locale/th';
import { connect } from 'react-redux';
import './style.scss';
import LocationPicker from '@ui/components/location-picker';
import DateTimePicker from '../../organisms/DateTimePicker';
import Button from '../../atoms/Button';
import { getMomentFromTime as getMoment, timeSlot, compareTime, getLocations, currentTime, isSameDay } from './util';
import { withTranslation } from '../../../locales';
import { dataLayer, pushDataLayer, pushTrackEvent } from '@utils/datalayer';

moment.locale('th'); // TODO:

const START_DATE = 'bookingBegin';
const END_DATE = 'bookingEnd';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    const { query } = queryString.parseUrl(window.location.href);
    let openFromMobileSite = false;
    if (query.open_search === 'true') {
      openFromMobileSite = true;
    }
    this.state = {
      locations: [],
      searchResult: [],
      isOpenSearchBarMobile: false,
      showGreyScreen: false,
      openFromMobileSite,

      // SelectPicker
      locationName: '',
      temporayLocationName: '',
      locationID: parseInt(query.location_id),
      focused: false,
      isLoadingLocation: false,

      //DateTimePicker
      startDate: this.initialDate(query.booking_begin, 1),
      endDate: this.initialDate(query.booking_end, 2),
      startDateFocused: false,
      endDateFocused: false,
      pickupTime: this.initialTime(query.booking_begin),
      returnTime: this.initialTime(query.booking_end)
    };
  }

  componentDidMount() {
    this.getLocationsAndInitLocationName();
    this.initialPicker();
  }
  initialPicker() {
    const { query } = queryString.parseUrl(window.location.href);
    if (query.openCalendar === 'true') {
      return this.setState(
        {
          startDateFocused: true,
          endDateFocused: false,
          focusedInput: START_DATE,
          showGreyScreen: true,
          isOpenSearchBarMobile: true
        },
        () => this.initialDateTime(true, false)
      );
    }
    return this.initialDateTime(true, false);
  }

  initialDate = (date, addDays) => {
    const _date = moment(date, 'YYYY-MM-DD');

    if (date === undefined || !_date.isValid()) {
      return moment().add(addDays, 'days');
    } else {
      return _date;
    }
  };

  initialTime = (time) => {
    const _time = moment(time);

    if (time === undefined || !_time.isValid()) {
      return '10:00';
    } else {
      return _time.format('HH:mm');
    }
  };

  getSelectableTime(time) {
    const times = timeSlot();
    return times.filter((t) => compareTime(t.value, time))[0];
  }

  getPickupTime(base, compare) {
    const momentA = getMoment(base);
    const momentB = getMoment(compare);
    const latestTime = momentA.isAfter(momentB) ? base : compare;
    return this.getSelectableTime(latestTime);
  }

  initialDateTime(isInit = false, adjastReturnTime = true) {
    const state = Object.assign({}, this.state);
    const { startDate, endDate, pickupTime } = this.state;
    if (isInit) {
      if (isSameDay(startDate, moment())) {
        const pickupTime = this.getPickupTime(startDate.format('HH:mm'), moment().format('HH:mm'));
        if (compareTime(this.state.pickupTime, pickupTime.value)) {
          state.pickupTime = this.state.pickupTime;
        } else {
          state.pickupTime = pickupTime.value;
          if (!isSameDay(startDate, endDate)) {
            state.returnTime = pickupTime.value;
          }
        }
      } else {
        if (adjastReturnTime) {
          state.returnTime = pickupTime;
        }
      }
    }
    if (isSameDay(startDate, endDate)) {
      const nextFourHours = getMoment(pickupTime)
        .add(4, 'hours')
        .format('HH:mm');
      if (compareTime(pickupTime, nextFourHours)) {
        state.endDate = moment(endDate.format()).add(1, 'day');
        state.returnTime = pickupTime;
      } else {
        if (parseInt(nextFourHours.split(':')[0], 10) > parseInt(state.returnTime.split(':')[0], 10)) {
          state.returnTime = nextFourHours;
        }
      }
    }
    this.setState(state);
  }

  getLocationsAndInitLocationName() {
    this.setState({ isLoadingLocation: true });

    getLocations()
      .then((locations) => {
        const sorted = locations.sort((a, b) => a.name.th.localeCompare(b.name.th));
        const converted = [];
        sorted.forEach((city) => {
          city.locations.forEach((pickupPoint) => {
            converted.push({
              ...pickupPoint,
              city: {
                id: city.id,
                name: city.name
              }
            });
          });
        });
        this.props.dispatch({
          type: 'LOCATION_FETCHED',
          payload: converted.sort((a, b) => a.id - b.id)
        });
        this.setState({
          locations: sorted,
          isLoadingLocation: false
        });
      })
      .then(() => {
        this.setState({
          locationName: this._InitLocation(this.state.locationID)
        });
      })
      .catch((e) => console.log('error: ' + e));
  }

  dismissAll = () => {
    this.setState({
      showGreyScreen: false
    });
  };

  onDateChange = ({ startDate, endDate }) => {
    const state = { startDate, endDate };
    this.setState(state, () => {
      this.initialDateTime(true);
    });
    if (startDate && endDate && this.state.focusedInput === END_DATE) {
      setTimeout(() => {
        this.dismissAll();
      }, 500);
    }
  };

  setPickUpTime = (event) => {
    const value = event.target.value;
    this.setState({ pickupTime: value, returnTime: value }, () => this.initialDateTime());
  };

  setReturnTime = (event) => {
    this.setState({ returnTime: event.target.value });
  };

  _InitLocation(LocationID) {
    let location = '';
    this.state.locations.every((item) => {
      return item.locations.every((pickup) => {
        if (parseInt(pickup.dh_location_code, 10) === parseInt(LocationID, 10)) {
          location = pickup.name[this.props.lang];
          return false;
        }
        return true;
      });
    });

    return location;
  }

  _onSubmit(event) {
    const { returnTime, pickupTime, startDate, endDate, locationID } = this.state;
    event.preventDefault();
    if (returnTime && pickupTime && startDate && endDate && locationID) {
      const pickup_datetime = `${moment(startDate).format('YYYY-MM-DD')} ${pickupTime}`;
      const return_datetime = `${moment(endDate).format('YYYY-MM-DD')} ${returnTime}`;

      this.props.onSubmit({ locationID, pickup_datetime, return_datetime });
    } else {
      pushTrackEvent('search_bookings_section', 'search_bookings_fail', 'from_search_result_page');
      alert('โปรดระบุข้อมูลให้ครบถ้วน');
    }
    const { query } = queryString.parseUrl(window.location.href);
    const carType =
      this.props.filter.category.selected
        .map((id) => {
          const selected = this.props.filter.category.list.find((c) => c.id === parseInt(id));
          if (!selected) {
            return '';
          }
          return selected.name.en;
        })
        .join(', ') || 'undefined';
    const dealerName =
      this.props.filter.dealer.selected
        .map((id) => {
          const selected = this.props.filter.dealer.list.find((d) => d.id === parseInt(id));
          if (!selected) {
            return '';
          }
          return selected.name.en;
        })
        .join(', ') || 'undefined';
    const location = this.props.filter.location.list.find((l) => l.id === parseInt(locationID));
    const additional = {
      search_pickup_date: moment(startDate)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_pickup_time: pickupTime + ':00',
      search_return_date: moment(endDate)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_return_time: returnTime + ':00',
      search_promotion_name: 'undefined',
      sort_by: query.sort_by,
      search_car_type: carType,
      search_dealer_type: query.sources,
      search_pickup_location: this.state.locationName,
      search_destination: location.city.name.th,
      search_rental_type: 'shortterm',
      search_dealer_name: dealerName,
      search_timestamp: moment()
        .locale('en')
        .format('YYYY-MMM-DD HH:mm:ss')
    };
    dataLayer(event, 'search_bookings_success', additional);
    this.onToggleModalMobile();
  }

  onClose = () => {
    pushTrackEvent('search_bookings_section', 'close', 'change_search_conditions');
    this.setState({ locationName: this.state.temporayLocationName }, () => this.onToggleModalMobile());
  };
  onOpen = () => {
    pushTrackEvent('search_bookings_section', 'open', 'change_search_conditions');
    this.setState(
      {
        temporayLocationName: this.state.locationName
      },
      () => {
        this.onToggleModalMobile();
      }
    );
  };
  onToggleModalMobile = () => {
    const { onToggleSearchBarMobile } = this.props;
    this.setState(
      {
        isOpenSearchBarMobile: !this.state.isOpenSearchBarMobile,
        showGreyScreen: false,
        openFromMobileSite: false
      },
      () => {
        if (this.state.isOpenSearchBarMobile) onToggleSearchBarMobile(true);
        else onToggleSearchBarMobile(false);
      }
    );
  };

  dismissAll = () => {
    this.setState({ showGreyScreen: false });
  };

  onClearLocation = () => {
    pushDataLayer({
      event: 'track_event',
      event_category: 'search_result_section',
      event_action: 'clear',
      event_label: 'X buttons'
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onExpandLocation = (city, isExpand) => {};

  onSearchLocationSuccess = (term, isSuccess, preventTracking) => {
    if (isSuccess === false && !preventTracking) {
      pushDataLayer({
        event: 'track_event',
        event_category: 'search_result_section',
        event_action: 'search_fail',
        event_label: term
      });
    }
  };
  get pickupTimes() {
    const times = timeSlot();
    const { startDate } = this.state;
    if (startDate) {
      if (startDate.startOf('day').isSame(moment().startOf('day'))) {
        return times.filter((t) => compareTime(t.value, currentTime()));
      }
    } else {
      return times.filter((t) => compareTime(t.value, currentTime()));
    }
    return times;
  }

  get returnTimes() {
    const times = timeSlot();
    const { startDate, endDate, pickupTime } = this.state;
    if (isSameDay(startDate, endDate)) {
      const nextFourHours = getMoment(pickupTime)
        .add(4, 'hours')
        .format('HH:mm');
      return times.filter((t) => compareTime(t.value, nextFourHours));
    }
    return times;
  }

  onLocationChange(locationID) {
    this.setState({ locationID: Number(locationID).toString() });

    const city = this.state.locations.find((city) => city.locations.find((location) => location.id === locationID));
    const location = city?.locations.find((location) => location.id === locationID);
    const locationName = location?.name['th'];
    this.setState({ locationName });
    pushDataLayer({
      event: 'track_event',
      event_category: 'search_result_section',
      event_action: 'search_success',
      event_label: locationName
    });
  }

  render() {
    const { locations, startDate, endDate, pickupTime, returnTime, locationName, isOpenSearchBarMobile } = this.state;

    const { style, t } = this.props;

    return (
      <div style={style}>
        <div className={(isOpenSearchBarMobile ? 'd-block' : 'd-none') + ' d-lg-block search-bar'}>
          {' '}
          <div className="container">
            <div className="row d-md-block d-lg-none">
              <div className="col-3 offset-9 text-right close-modal" onClick={this.onClose}>
                {t('close')} <i className="flaticon-cancel" />
              </div>
            </div>
            <div className="row search-bar-wrapper">
              <div
                ref={(component) => {
                  this.SelectPicker = component;
                }}
                className="col-lg-3 col-md-12 select-location"
              >
                <LocationPicker
                  locationID={parseInt(this.state.locationID)}
                  locations={locations}
                  onChange={(locationID) => this.onLocationChange(locationID)}
                  onClear={(preventTracking) => {
                    !preventTracking && this.onClearLocation();
                  }}
                  onExpand={this.onExpandLocation}
                  onSearchSuccess={this.onSearchLocationSuccess}
                  onToggle={(isTogglePicker) => this.setState({ focused: isTogglePicker })}
                  prefix="th"
                  renderList={(list) => <div className="searchbar__location--override">{list}</div>}
                  renderInput={(input) => <div className="searchbar__input--override">{input}</div>}
                />
              </div>
              <div className="col-lg-6 col-md-12 datetime-picker-wrapper">
                <DateTimePicker
                  eventCategory="search_result_section"
                  startDate={startDate}
                  endDate={endDate}
                  pickupTime={pickupTime}
                  returnTime={returnTime}
                  onDatesChange={this.onDateChange}
                  setPickUpTime={this.setPickUpTime}
                  pickupOptions={this.pickupTimes}
                  returnOptions={this.returnTimes}
                  setReturnTime={this.setReturnTime}
                  onClose={this.dismissAll}
                />
              </div>
              <div className="col-lg-3 col-md-12 submit-button">
                <Button
                  data-event-category="search_bookings_section"
                  data-event-label="from_search_result_page"
                  pulse="true"
                  onClick={this._onSubmit.bind(this)}
                >
                  {t('submit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={(isOpenSearchBarMobile ? ' d-none' : 'd-md-block d-lg-none') + ' mobile-info-wrapper'}
          onClick={this.onOpen}
        >
          <div className="container">
            <div className="row">
              <div className="col-10 d-flex flex-row align-items-center pl-2">
                <i className="flaticon-search search-icon" />
                <div>
                  <p className="mobile-info-text">
                    <b>{locationName ? locationName : t('please_select_location')} </b>{' '}
                  </p>
                  <br />
                  <p className="mobile-info-text">
                    {moment(startDate).format('DD/MM/YY') + ' ' + pickupTime} &nbsp;
                    <i className="flaticon-right-arrow arrow-mobile" /> &nbsp;
                    {moment(endDate).format('DD/MM/YY') + ' ' + returnTime}
                  </p>
                </div>
              </div>
              <div className="col-2 align-self-center text-right">
                <button type="button" className="btn btn-primary btn-edit-mobile">
                  {t('edit')}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.openFromMobileSite && (
          <div className="container search__tooltip">
            <div className="rc-tooltip tooltip-inner rc-tooltip-placement-bottomLeft ">
              <div className="rc-tooltip-content">
                <div className="rc-tooltip-arrow">
                  <div className="rc-tooltip-arrow-inner"></div>
                </div>
                <div className="rc-tooltip-inner" role="tooltip">
                  <div className="tooltip-inner--content">
                    <div>
                      เริ่มต้นค้นหารถเช่าง่ายๆ!{' '}
                      <i onClick={() => this.setState({ openFromMobileSite: false })} className="flaticon-cancel"></i>
                    </div>
                    <div>
                      เลือกสถานที่ วัน-เวลา รับและคืนรถ พร้อมกดปุ่ม `&quot;`ค้นหา`&quot;`
                      <br />
                      เพื่อแสดงรถว่างทันที
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showGreyScreen && (
          <div onClick={() => this.setState({ showGreyScreen: false })} className="grey-screen" />
        )}
      </div>
    );
  }
}

SearchBar.propTypes = {
  pickup_datetime: PropTypes.string,
  return_datetime: PropTypes.string,
  location_id: PropTypes.string
};
SearchBar.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(withTranslation('search_bar')(SearchBar));
