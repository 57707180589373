import React from 'react';
import InsuranceTable from '../../../molecules/InsuranceTable';
const question = {
  icon: '',
  question: 'ราคานี้มีประกันภัยรวมอยู่ด้วยรึเปล่า?',
  answer: (
    <div>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <p className="indent">
        รถทุกคันในระบบของ Drivehub มีประกันภัยชั้น 1 ขั้นพื้นฐาน (ซึ่งรวมอยู่ในค่าเช่าแล้ว)
        แต่จะไม่รวมค่าเสียหายส่วนแรกในกรณีที่ลูกค้าเกิดอุบัติเหตุโดยท่านเป็นฝ่ายผิดหรือไม่มีคู่กรณี
      </p>
      <div>
        หากเกิดอุบัติเหตุและท่านไม่ซื้อประกันเพิ่มเติมกับบริษัทรถเช่า
        โดยท่านเป็นฝ่ายผิดหรือไม่มีคู่กรณีบริษัทรถเช่าจะเรียกเก็บค่าเสียหาย(“ค่าเสียหายส่วนแรก”) ระหว่าง1,000 ถึง
        50,000บาท ในอัตราอ้างอิงตามประเภทรถดังนี้
        <table className="table text-center">
          <thead>
            <tr>
              <th>ประเภทรถ</th>
              <th>ตัวอย่าง</th>
              <th>ค่าเสียหายส่วนแรกมากที่สุด (บาท)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>รถอีโคคาร์ และ รถขนาดเล็ก</td>
              <td>March / Almera / Yaris / Vios / City / Jazz / Ciaz</td>
              <td>30,000</td>
            </tr>
            <tr>
              <td>รถขนาดกลาง</td>
              <td>Altis / Civic / CHR / HRV / Slyphy / Juke</td>
              <td>40,000</td>
            </tr>
            <tr>
              <td>รถขนาดใหญ่ และ รถเอสยูวี</td>
              <td>Camry / Accord / Fortuner / CRV / Pajero / Teana</td>
              <td>50,000</td>
            </tr>
          </tbody>
        </table>
        <p>
          หากบริษัทประกันภัยประเมินราคาแล้ว ได้ความว่า ค่าเสียหายเกินกว่าวงเงินคุ้มครองที่กำหนดไว้
          ผู้เช่า/ผู้ยืมตกลงรับผิดชอบชดใช้เงินค่าใช้จ่ายส่วนเกินที่บริษัทประกันภัยไม่ได้คุ้มครอง
        </p>
      </div>
      <b>
        <u>หากท่านทำการจองรถที่ต้องใช้บัตรเครดิต</u>
      </b>
      <div>
        <p className="indent">
          อุ่นใจประกันที่ครอบคลุมค่าเสียหายประเภทต่างๆ เพื่อลดค่าเสียหายส่วนแรก ในกรณีที่เกิดความเสียหายระหว่างการเช่า
          ท่านไม่ต้องรับผิดชอบต่อค่าเสียหายในรายการที่คลอบคลุม
          การคุ้มครองนี้มีผลต่อเมื่อท่านปฏิบัติตามข้อตกลงการเช่าเท่านั้น
        </p>
        <br />
        <InsuranceTable />
        <br />
        <p>*อัตราราคาต่อวันขึ้นอยู่กับประเภทรถ</p>
      </div>
      <div>
        <b>คำอธิบายประกันความคุ้มครองและสิทธิประโยชน์</b>
      </div>
      <ul>
        <li>
          <b>ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)</b>
          <div>
            Collision Damage Waiver: CDW  กรณีเกิดอุบัติเหตุระหว่างเช่าโดยที่ผู้เช่ารถเป็นฝ่ายผิดหรือไม่มีคู่กรณี
            หรือรถมีรอยขีดข่วนจากการขับขี่
            ผู้เช่าต้องรับผิดชอบค่าเสียหายที่เกิดขึ้นไม่เกินค่าเสียหายส่วนแรกที่ทางบริษัทรถเช่ากำหนด
            การคุ้มครองนี้ครอบคลุมเพียงตัวรถและไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ภายใน หลังคา
            หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ
            (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้
            การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
          </div>
        </li>
        <li>
          <b>ประกันภัยบุคคลที่ 3 (TPL)</b>
          <div>
            Third Party Liability: TPL ในกรณีที่เกิดความเสียหายทางร่างกายและทรัพย์สินของบุคคลที่ 3
            อันเกิดจากความผิดของผู้ขับขี่ระหว่างการเช่านั้น ประกันภัยบุคคลที่ 3 (TPL)
            จะคุ้มครองถึงความรับผิดดังกล่าวต่อร่างกายและทรัพย์สินตามนโยบาย ทั้งนี้
            ความคุ้มครองไม่รวมถึงความเสียหายใดทั้งทางร่างกายและทรัพย์สินของผู้เช่าเอง หรือต่อรถที่เช่า
            และการคุ้มครองนี้มีผลต่อเมื่อท่านปฏิบัติตามข้อตกลงการเช่าเท่านั้น
          </div>
        </li>
      </ul>
      <br />
      <div>
        <b>กรณีเลือกซื้อประกันความคุ้มครองของผู้เช่ารถ (เพิ่มเติม)</b>
      </div>
      <ul>
        <li>
          <b>ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (SCDW, SLDW, ECDW)</b>
          <div>
            Super Collision Damage Waiver: SCDW, Super Loss Damage Waive: SLDW, Extra Collision Damage Waiver: ECDW
          </div>
          <div>
            กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ
            และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ฝาครอบล้อ กระทะล้อ ภายใน หลังคา หรือช่วงล่างของรถ)
            หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก
            อุปกรณ์ GPS อุปกรณ์เสริมกุญแจ เอกสารสำคัญของรถยนต์หาย หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้
            การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
          </div>
        </li>
        <li>
          <b>ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ (PCDW, PLDW)</b>
          <div>Premium Collision Damage Waiver: PCDW, Premium Loss Damage Waive: PLDW</div>
          <div>
            กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ
            กระจก ยาง และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น ภายใน หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ
            ที่อาจเกิดขึ้น ระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS
            หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้
            การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
          </div>
        </li>
        <li>
          <b>ประกันการโจรกรรม (TP)</b>
          <div>
            Theft Protection: TP หากรถยนต์ที่เช่าถูกขโมย
            ประกันภัยประเภทนี้จะครอบคลุมในส่วนที่ผู้เช่าจะต้องจ่ายชดเชยในส่วนที่สูญเสียไป ในกรณีที่รถถูกโจรกรรม
            ประกันจะคุ้มครองให้กับผู้เช่ารถ ซึ่งความรับผิดชอบจากรถกรณีรถหายจะเป็น 0 บาท ทั้งนี้
            การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
          </div>
        </li>
        <li>
          <b>ประกันภัยอุบัติเหตุส่วนบุคคล (PAI)</b>
          <div>
            Personal Accident Insurance: PAI ในกรณีที่เกิดอุบัติเหตุประกันภัยประเภทนี้ครอบคลุมค่าใช้จ่ายทางการแพทย์
            และคุ้มครองตามกฎหมายในกรณีบาดเจ็บ โดยครอบคลุมถึงการเสียชีวิตและการสูญเสียแขน ขา
            ความคุ้มครองนี้ใช้ได้เฉพาะในกรณีที่ผู้ขับขี่ปฏิบัติตามเงื่อนไขของสัญญาเช่าเท่านั้น
          </div>
        </li>
      </ul>
      <br />
      <div>หมายเหตุ: ชื่อและเงื่อนไขของประกันอาจมีการเปลี่ยนแปลงไปตามที่บริษัทรถเช่ากำหนด</div>
    </div>
  )
};

export default question;
