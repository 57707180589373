import React, { PureComponent } from 'react';
import { withTranslation } from '../../../locales';
import { Formik } from 'formik';
import * as yup from 'yup';
import FormikInput from '../../atoms/InputFormik';
import { authUpdateInfo } from './function';

import THflag from '../../../assets/images/th-icon.svg';
import LoadingIcon from '../../../assets/images/loading.svg';
import './style.scss';

const SCHEMA = () => {
  return yup.object().shape({
    first_name: yup
      .string()
      .required('กรุณากรอกชื่อ')
      .matches(/^[a-zA-Zก-๛ \t\r\n\v\f]+$/, 'กรุณาใส่อักษรภาษาไทย หรือภาษาอังกฤษเท่านั้น'),
    last_name: yup
      .string()
      .required('กรุณากรอกนามสกุล')
      .matches(/^[a-zA-Zก-๛ \t\r\n\v\f]+$/, 'กรุณาใส่อักษรภาษาไทย หรือภาษาอังกฤษเท่านั้น'),
    phone_number: yup.string().matches(/^[\d +]{10}$/, 'เบอร์โทรของคุณไม่ครบถ้วน'),
    email: yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'อีเมลต้องมี @ และ .')
  });
};

class AuthInfoBox extends PureComponent {
  render() {
    const { initailFormValues, disabledEmailField, isError } = this.props;
    return (
      <div className="container auth-info-box">
        <div className="info-box">
          <Formik
            initialValues={
              initailFormValues ? initailFormValues : { first_name: '', last_name: '', phone_number: '', email: '' }
            }
            onSubmit={(values, actions) => {
              const data = {};
              Object.keys(values)
                .filter((key) => values[key] !== '')
                .forEach((k) => (data[k] = values[k]));
              authUpdateInfo({ ...data })
                .then(() => {
                  setTimeout(() => {
                    actions.setSubmitting(false);
                    this.props.onSubmit && this.props.onSubmit();
                  }, 1000);
                })
                .catch((e) => {
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 1000);
                  this.props.onError && this.props.onError();
                  console.log(e.data);
                });
            }}
            validationSchema={SCHEMA}
          >
            {(props) => {
              return (
                <form onSubmit={props.handleSubmit} className="info-form">
                  <div className="info-form__input">
                    <p>ชื่อจริง (ตามบัตรประชาชน)*</p>
                    <FormikInput
                      name="first_name"
                      placeholder="กรอกชื่อ"
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.first_name}
                      autoComplete="off"
                      className={props.errors.first_name ? 'error' : ''}
                      id="account-name-input"
                    >
                      {props.errors.first_name && <div className="feedback">{props.errors.first_name}</div>}
                    </FormikInput>
                  </div>
                  <div className="info-form__input">
                    <p>นามสกุล (ตามบัตรประชาชน)*</p>
                    <FormikInput
                      name="last_name"
                      placeholder="กรอกนามสกุล"
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.last_name}
                      autoComplete="off"
                      className={props.errors.last_name ? 'error' : ''}
                      id="account-lastname-input"
                    >
                      {props.errors.last_name && <div className="feedback">{props.errors.last_name}</div>}
                    </FormikInput>
                  </div>
                  <div className="info-form__input phone-input">
                    <p>เบอร์โทรศัพท์มือถือ</p>
                    <FormikInput
                      id="account-phone-input"
                      name="phone_number"
                      placeholder="กรอกเบอร์โทรศัพท์มือถือ"
                      type="tel"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.phone_number}
                      autoComplete="off"
                      className={props.errors.phone_number ? 'error' : ''}
                      onKeyPress={(e) => {
                        const code = e.which ? e.which : e.keyCode;
                        if (code > 31 && (code < 48 || code > 57)) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="flag-box" id="account_phone_dropdown">
                        <img src={THflag} alt="th" />
                      </div>
                      {props.errors.phone_number && <div className="feedback">{props.errors.phone_number}</div>}
                    </FormikInput>
                  </div>
                  <div className="info-form__input">
                    <p>อีเมล</p>
                    <FormikInput
                      name="email"
                      placeholder="อีเมล"
                      type="text"
                      onChange={(e) => {
                        props.handleChange(e);
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.email}
                      autoComplete="off"
                      className={`${props.errors.email ? 'error' : ''} ${disabledEmailField ? 'disabled' : ''}`}
                      disabled={disabledEmailField ? true : false}
                      id="account-email-input"
                    >
                      {props.errors.email && <div className="feedback">{props.errors.email}</div>}
                    </FormikInput>
                  </div>
                  <p className="requirement-text">*จำเป็นต้องกรอก</p>
                  <button
                    type="submit"
                    id="account-submit"
                    className={`btn btn-block btn-primary ${props.isSubmitting ? 'submiting' : ''} ${
                      isError || Object.keys(props.errors).length > 0 ? 'error' : ''
                    }`}
                    disabled={isError || Object.keys(props.errors).length > 0 ? true : false}
                  >
                    {props.isSubmitting ? (
                      <img src={LoadingIcon} style={{ width: 38 }} alt="loading" />
                    ) : isError ? (
                      'โปรดลองใหม่อีกครั้ง'
                    ) : (
                      'บันทึก'
                    )}
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default withTranslation('auth.info_form')(AuthInfoBox);
