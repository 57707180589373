import axios from 'axios'
import queryString from 'query-string'
import moment from 'moment-timezone'
import { CAR_ACTION } from '../reducers/cars'
import { API_SEARCH_HOST } from '../../variables/API'
import { getValidDate } from '../../utils'
import { count } from './filter'

moment.tz.setDefault('Asia/Bangkok')
axios.defaults.baseURL = API_SEARCH_HOST

/**
 *
 * @param {object} props
 * @param {object of query} props.query
 * @param {array} props.filterOut
 */
export const composeFetchCarUrl = (props = { query: {}, filterOut: [] }) => {
  const combinedQuery = { ...queryString.parseUrl(window.location.search).query, ...props.query }
  combinedQuery.pickup_datetime = combinedQuery.booking_begin
  combinedQuery.return_datetime = combinedQuery.booking_end
  delete combinedQuery.booking_begin
  delete combinedQuery.booking_end

  let apiQuery = Object.assign({}, combinedQuery)

  if (props.filterOut && props.filterOut.length > 0) {
    apiQuery = Object.keys(combinedQuery)
      .filter(key => !props.filterOut.includes(key))
      .reduce(
        (p, c) => ({
          ...p,
          [c]: combinedQuery[c],
        }),
        {},
      )
  }

  const parsedQuery = queryString.stringify(apiQuery)
  return `/api/filter/cars?${parsedQuery}`
}

export const composeCurrentUrl = query => {
  const hash = window.location.hash
  const { booking_begin, booking_end } = query
  const apiQuery = Object.assign({}, query)

  apiQuery.booking_begin = getValidDate(booking_begin, 1).format('YYYY-MM-DD HH:mm')
  apiQuery.booking_end = getValidDate(booking_end, 2).format('YYYY-MM-DD HH:mm')
  delete apiQuery.pickup_datetime
  delete apiQuery.return_datetime

  const parsedQuery = queryString.stringify(apiQuery)
  return parsedQuery + hash
}

const mapping = {
  category: 'categories',
  dealer: 'dealers',
  location: 'location_id',
  pickupDatetime: 'booking_begin',
  returnDatetime: 'booking_end',
  source: 'sources',
}

export const extendComposeStore = (getState, filterOut) => {
  const state = getState().filter

  let query = Object.keys(mapping)
    .map(key => {
      const values = state[key].selected
      const alias = mapping[key]
      return {
        [alias]: Array.isArray(values) ? values.join(',') : values,
      }
    })
    .reduce((p, c) => ({ ...p, ...c }), {})
  return composeFetchCarUrl({ query, filterOut })
}

export const initialFetchCars = () => (dispatch, getState) => {
  dispatch({ type: CAR_ACTION.INITIAL })
  const url = extendComposeStore(getState, ['offset'])
  dispatch(fetchCars(url))
  dispatch(count.get())
}

export const fetchNextCars = () => (dispatch, getState) => {
  let url = getState().cars.next
  if (!url) {
    url = extendComposeStore(getState)
  }
  dispatch(fetchCars(url))
}

export const filterApiUrl = endpoint => {
  const { query, url } = queryString.parseUrl(endpoint)
  const newQuery = Object.assign({}, query)
  newQuery.booking_begin = moment(query.booking_begin).format()
  newQuery.booking_end = moment(query.booking_end).format()

  if (query.source) {
    const sources = query.source.split(',')
    if (sources.length === 2) {
      delete newQuery.source
    }
  }

  const parsedQuery = queryString.stringify(newQuery)
  return `${url}?${parsedQuery}`
}

// Create axios Cancel Token
const CancelToken = axios.CancelToken
let cancel

export const fetchCars = (url = composeFetchCarUrl(), isNext = true) => dispatch => {
  dispatch({ type: CAR_ACTION.FETCHING })
  cancel && cancel()
  const axiosCancelToken = new CancelToken(c => (cancel = c))
  return axios
    .get(url, {
      cancelToken: axiosCancelToken,
    })
    .then(res => {
      dispatch({
        type: CAR_ACTION.FETCHED,
        payload: res.data,
        isNext,
      })
    })
}
