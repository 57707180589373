import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Button = (props) => {
  return (
    <button
      {...props}
      onClick={(e) => props.onClick(e)}
      className={`dh-button ${props.pulse === 'true' ? 'dh-animate-pulse' : ''}`}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  pulse: PropTypes.oneOf(['true', 'false'])
};

export default Button;
