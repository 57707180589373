import React, { Component } from 'react';
import 'rc-dialog/assets/index.css';
import Modal from 'rc-dialog';
import './style.scss';
import OriginalCondition from './original';
import BigbrandCondition from './bigbrand';
class RentalCondition extends Component {
  state = {
    visible: false,
    source: 'local'
  };
  toggle = (source = this.state.source) => {
    window.toggleSticky();
    this.setState({ visible: !this.state.visible, source });
  };

  get originalCondition() {
    return <OriginalCondition />;
  }
  get bigbrandCondition() {
    return <BigbrandCondition vendor={this.props.vendor} />;
  }
  get content() {
    if (['inter', 'bigbrand'].includes(this.state.source)) {
      return this.bigbrandCondition;
    }
    return this.originalCondition;
  }
  render() {
    return (
      <Modal
        closable
        destroyOnClose
        animation="zoom"
        maskAnimation="fade"
        onClose={this.toggle}
        visible={this.state.visible}
        className="rental-condition"
        title={<b style={{ fontSize: 18 }}>เงื่อนไขการเช่า</b>}
      >
        {this.content}
      </Modal>
    );
  }
}

export default RentalCondition;
