import React from 'react';
import './style.scss';

const SortingModal = (props) => {
  const { t, sortData } = props;

  return (
    <div className={`filter-sorting-modal ${props.isOpen ? 'active' : ''}`}>
      <div className="sorting-modal__header">
        <i className="flaticon-cancel" onClick={props.onToggleSortingFilter} />
        <p>{t('sort.modal.header')}</p>
      </div>
      <div className="sorting-modal__content">
        {sortData.map((el) => (
          <div
            className={`sort-item--modal ${el.value === props.sortBy ? 'active' : ''}`}
            onClick={() => props.onSelectSorting(el.value)}
            key={el.value}
          >
            {t(`${el.name}`)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SortingModal;
