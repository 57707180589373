import React, { Component } from 'react';
// import PhoneImg from '../../../assets/images/phone.svg'
import './style.scss';
import { withTranslation } from '../../../locales';

class index extends Component {
  render() {
    const { t } = this.props;
    return (
      <button className="call-to-action-button" onClick={this.props.onClick}>
        <i className="background-white">
          <i className="flaticon-round" />
        </i>
        <span>{t('mobile_faq')}</span>
      </button>
    );
  }
}
export default withTranslation('info_test')(index);
