import React, { Component } from 'react';
import './style.scss';

export default class FilterCheckbox extends Component {
  render() {
    const { data, selected, handleCheckboxChange } = this.props;

    return (
      <div className="col-12 filter-checkbox-container">
        {data.map((item) => {
          return (
            <div className="checkbox-item" key={item.name.th}>
              <label className="container m-0 pl-4 pb-2">
                <input
                  type="checkbox"
                  value={item.name.th}
                  checked={selected ? selected.includes(item.id) : false}
                  onChange={() => handleCheckboxChange(item.id)}
                  className="checkbox"
                />
                <span className="checkmark" />
                <span>{item.name.th}</span>
              </label>
            </div>
          );
        })}
      </div>
    );
  }
}
