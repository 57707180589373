import React, { Component } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { connect } from 'react-redux';
import FilterButton from '../../atoms/FilterButton';
import { LoadingBox } from '../../atoms/Loading';
import { withTranslation } from '../../../locales';
import LoadingSVG from '../../../assets/icon/loading.svg';
import './style.scss';
import { pushDataLayer } from '@utils/datalayer';

class NewFilterCategory extends Component {
  static defaultProps = {
    categories: [],
    onChange: (id) => id
  };
  onToggleCategory = (ctg) => {
    this.props.onChange(ctg.id);
    let selected = this.props.selected;

    let label = 'cartype_undefined';
    console.log(selected, ctg.id);
    if (selected.includes(ctg.id)) {
      selected = selected.filter((s) => s !== ctg.id);
    } else {
      selected = selected.concat(ctg.id);
    }
    if (selected.length > 0) {
      label =
        'cartype_' +
        selected
          .map((id) => this.props.categories.find((c) => c.id === id))
          .filter((x) => x)
          .map((x) => x.name.en)
          .join(',');
    }
    const dealerName = this.props.filter.dealer.selected
      .map((id) => {
        const selected = this.props.filter.dealer.list.find((d) => d.id === parseInt(id));
        if (!selected) {
          return '';
        }
        return selected.name.en;
      })
      .join(', ');

    const query = queryString.parse(window.location.search);
    const dateFormat = 'YYYY-MM-DD HH:mm';

    const locationID = parseInt(this.props.filter.location.selected);
    const location = this.props.filter.location.list.find((l) => l.id === locationID);
    pushDataLayer({
      event: 'track_event',
      event_category: 'search_result_section',
      event_action: 'search_bookings_success',
      event_label: label,
      search_pickup_date: moment(query.booking_begin, dateFormat)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_pickup_time: moment(query.booking_begin, dateFormat)
        .locale('en')
        .format('HH:mm:ss'),
      search_return_date: moment(query.booking_end, dateFormat)
        .locale('en')
        .format('YYYY-MMM-DD'),
      search_return_time: moment(query.booking_end, dateFormat)
        .locale('en')
        .format('HH:mm:ss'),
      search_destination: location.city.name.th,
      search_pickup_location: location.name.th,
      search_dealer_type: query.sources ? query.sources : 'undefined',
      search_dealer_name: dealerName ? dealerName : 'undefined',
      search_car_type: label.replace('cartype_', ''),
      search_promotion_name: 'undefined',
      search_rental_type: 'shortterm',
      sort_by: query.sort_by,
      search_timestamp: moment()
        .locale('en')
        .format('YYYY-MMM-DD HH:mm:ss')
    });
  };
  get categories() {
    const { categories } = this.props;
    return [2, 1, 6, 3, 5, 8, 7, 4, 10, 9].map((id) => categories.find((c) => c.id === id)).filter((x) => x);
  }
  render() {
    const { selected, fetch, lang } = this.props;

    return (
      <div className="filter-category">
        {fetch ? (
          <div className="ml-auto mr-auto" style={{ height: '150px' }}>
            <LoadingBox bg={LoadingSVG} dark />
          </div>
        ) : (
          <React.Fragment>
            {this.categories.length === 0 ? (
              ''
            ) : (
              <React.Fragment>
                <div className="category-item d-inline-flex flex-row flex-wrap">
                  {this.categories.map((ctg, index) => {
                    const customStyle = {
                      width: this.categories.length <= 2 ? 'unset' : ''
                    };
                    return (
                      <div key={index} className="category-button" style={customStyle}>
                        <FilterButton
                          onClick={() => this.onToggleCategory(ctg)}
                          active={selected ? selected.includes(ctg.id) : false}
                          {...ctg}
                          key={ctg.name.th.toLowerCase()}
                          showImage={true}
                        >
                          {ctg.name[lang]}
                        </FilterButton>
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  filter: state.filter
});
export default connect(mapStateToProps)(withTranslation('filter')(NewFilterCategory));
