import React from 'react';
import Button from 'react-bootstrap/Button';

import './style.scss';

interface ChatButtonProps {
  source: string;
  lastName: string;
  bookingID: string;
}

const ChatButton: React.FC<ChatButtonProps> = ({ source, lastName, bookingID }) => {
  return (
    <Button
      as="a"
      variant="outline-primary"
      size="sm"
      className="btn--chat"
      href={`/chat/${bookingID}?source=${source}&last_name=${lastName}`}
    >
      <div className="chat-icon" />
      แชทกับบริษัทรถเช่า
    </Button>
  );
};

export default ChatButton;
