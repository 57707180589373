import React from 'react';
import RcToolTip from 'rc-tooltip';
import './style.scss';

const OverleyTooltip = ({ props, title, content }) => (
  <div className="tooltip-inner--content" {...props}>
    <div>{title}</div>
    <div>{content}</div>
  </div>
);

const TooLong = ({ text, children, placement = 'topLeft', length = 50, trigger = ['click', 'hover'] }) => {
  if (text.length > length) {
    const trim = text.slice(0, length - 3) + '...';
    return (
      <RcToolTip
        overlayClassName="tooltip-inner"
        placement={placement}
        trigger={trigger}
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        overlay={<OverleyTooltip title="" content={text} />}
      >
        {children(trim)}
      </RcToolTip>
    );
  }
  return children(text);
};

export default TooLong;
