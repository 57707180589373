import { defineAction } from 'redux-define';

export const CAR_ACTION = defineAction('CARS', [
  'FETCHING',
  'FETCHED',
  'ERROR',
  'SUCCESS',
  'INITIAL',
  'FETCHED_TOTAL_DEALERS'
]);

const initialState = {
  fetching: false,
  list: [],
  next: null,
  prev: null,
  pickup_datetime: null,
  return_datetime: null,
  duration_days: null,
  duration_hours: null,
  count: 0,
  total_dealer: 0,
  initialized: false
};
const reducer = (state = initialState, { type, payload, isNext }) => {
  switch (type) {
    case CAR_ACTION.INITIAL: {
      return { ...initialState, initialized: true };
    }
    case CAR_ACTION.FETCHING: {
      return {
        ...state,
        fetching: true
      };
    }
    case CAR_ACTION.ERROR: {
      return initialState;
    }
    case CAR_ACTION.FETCHED: {
      const carsData = payload;
      const list = isNext ? state.list.concat(carsData.cars) : carsData.cars.concat(state.list);
      return {
        ...state,
        fetching: false,
        pickup_datetime: carsData.pickup_datetime,
        return_datetime: carsData.return_datetime,
        duration_days: carsData.duration_days,
        duration_hours: carsData.duration_hours,
        count: carsData.count,
        list: list.reduce((p, c) => {
          const uniquId = c.source + c.id;
          return p.findIndex((x) => x.source + x.id === uniquId) === -1 ? p.concat(c) : p;
        }, []),
        next: carsData.next,
        prev: carsData.prev,
        initialized: false
      };
    }
    case CAR_ACTION.FETCHED_TOTAL_DEALERS: {
      return {
        ...state,
        total_dealer: payload
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
