import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import DhLogo from '../../../assets/images/dh-logo.svg';
import { HELP_WEB } from '../../../variables/API';
import './style.scss';

export default class LoadingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenLoading: false
    };
  }

  componentDidMount() {
    const getReturn = localStorage.getItem('visited');
    let timeCountLoading = 7000;
    if (getReturn === 'true') {
      timeCountLoading = 4500;
    } else {
      localStorage.setItem('visited', 'true');
    }
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      this.setState({
        hiddenLoading: true
      });
    }, timeCountLoading);
  }

  render() {
    const { hiddenLoading } = this.state;

    return (
      <div className={`${hiddenLoading ? 'd-none' : 'd-block'} text-center loading-sc`}>
        <div className="loading-dh-logo">
          <img src={DhLogo} alt="Drivehub" />
        </div>
        <div className="main-loading-position">
          <MediaQuery maxWidth={768}>
            <div className="loading-button">
              <svg className="spinner" width="45px" height="45px" viewBox="0 0 66 66">
                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
              </svg>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <div className="lds-magnify">
              <div>
                <div>
                  <div />
                  <div />
                </div>
              </div>
            </div>
          </MediaQuery>
          <strong className="main-text">
            <span style={{ color: '#0078FF', paddingLeft: '12px' }}>ไดรฟ์ฮับ</span>
            กำลังค้นหารถเช่าราคาถูกที่สุด
            <br />
            <span>จากกว่า 500 บริษัทรถเช่าทั่วประเทศ</span>
          </strong>
        </div>
        <div className="underline" />
        <div className="main-content">
          <div className="content-warpper">
            <div className="content-row">
              <div className="checkmark-wrapper">
                <div className="circle-loader ">
                  <div className="check-draw delay-0" />
                </div>
              </div>
              <div>
                <span>รับประกันราคาถูก ไม่มีค่านายหน้า</span>
              </div>
            </div>
            <div className="content-row">
              <div className="checkmark-wrapper ">
                <div className="circle-loader ">
                  <div className="check-draw delay-1" />
                </div>
              </div>
              <div>
                <span>คัดเฉพาะบริษัทรถเช่าที่ได้มาตรฐาน </span>
              </div>
            </div>
            <div className="content-row">
              <div className="checkmark-wrapper">
                <div className="circle-loader ">
                  <div className="check-draw delay-2" />
                </div>
              </div>
              <div>
                <span>ได้รถชัวร์ 100% หลังยืนยันการจอง</span>
              </div>
            </div>
          </div>
        </div>
        <div className="main-footer text-center">
          เราใช้คุกกี้เพื่อมอบประสบการณ์การใช้งานเว็บไซต์ Drivehub.co ของท่านที่ดีกว่าเดิม ในการใช้งานเว็บไซต์ของเรา
          ถือว่าท่านยอมรับการใช้คุกกี้ตามที่ระบุใน{' '}
          <a className="m-0" href={`${HELP_WEB}/th/privacy`}>
            นโยบายความเป็นส่วนบุคคล
          </a>
        </div>
      </div>
    );
  }
}
