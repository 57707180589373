import React, { Component } from 'react';
import './style.scss';
import { withTranslation } from '../../../locales';

class ConfirmCustomerInfo extends Component {
  render() {
    const {
      customer: { first_name, last_name, email, phone_number },
      note,
      t
    } = this.props;
    return (
      <div className="customer_info_wrapper mb-4">
        <p className="form_title">{t('title')}</p>
        <div className="mt-2 mb-2" id="confirm_info_text">
          <p>{t('confirm_info')}</p>
        </div>
        <div>
          <p>
            <i className="flaticon-user" /> {first_name} {last_name}
          </p>
        </div>
        <div className="customer_info_box">
          <div>
            <p>
              <i className="flaticon-envelope" /> {email}{' '}
            </p>
          </div>
          <div>
            <p>
              <i className="flaticon-smartphone" /> {phone_number}{' '}
            </p>
          </div>
        </div>
        {note && (
          <div className="customer_note_box">
            <p> {note} </p>
          </div>
        )}
      </div>
    );
  }
}

ConfirmCustomerInfo.defaultProps = {
  customer: {
    first_name: 'Name',
    last_name: 'Lastname',
    email: 'email@test.com',
    phone_number: '0999999999'
  },
  note: 'TG 671 รับรถสุวรรณภูมิ'
};

export default withTranslation('customer_form')(ConfirmCustomerInfo);
