import React, { PureComponent } from 'react';
import ReactPaginate from 'react-paginate';
import CommentCard from './comment-card';
import emptyReviewIcon from '../../../assets/icon/empty_icon.svg';
import './comments-box.scss';
import './style.scss';

export default class CommentsBox extends PureComponent {
  state = {
    sortBy: 'rating',
    orderBy: 'desc',
    limit: 5,
    offset: 0,
    pageSelected: 0
  };
  onSelected = (sortBy, orderBy) => {
    this.setState({ sortBy, orderBy, pageSelected: 0 }, () => {
      this.props.fetchReview(sortBy, orderBy);
    });
  };
  handlePageClick = ({ selected }) => {
    const offset = Math.ceil(selected * this.state.limit);
    this.setState({ offset: offset, pageSelected: selected }, () => {
      this.props.fetchReview(this.state.sortBy, this.state.orderBy, offset, this.state.limit);
    });
  };
  render() {
    const { comments, t } = this.props;
    const { limit, offset, pageSelected } = this.state;

    return (
      <div className="comment-container">
        <div className="comment__header">
          <p>{t('review.comment_header')}</p>
          {comments.reviews.length > 0 && (
            <CommentsFilterBox
              onSelected={this.onSelected}
              values={{ sortBy: this.state.sortBy, orderBy: this.state.orderBy }}
              t={t}
            />
          )}
        </div>
        <div className="comment__list">
          {comments.fetching ? (
            <div>{t('review.loading')}</div>
          ) : comments.reviews.length <= 0 ? (
            <div className="empty-box text-center" style={{ height: 200, marginTop: 16 }}>
              <img src={emptyReviewIcon} width="85" alt="Empty Reviews" />
              {t('review.empty_review_1_comment')} <br />
              {t('review.empty_review_2')}
            </div>
          ) : (
            <React.Fragment>
              <div className="comment-box">
                {comments.reviews.map((c, i) => {
                  return <CommentCard key={i} {...c} t={t} />;
                })}
              </div>
              <div className="comment-total">
                <div>
                  {t('review.show_comment_at')} {offset + 1}-
                  {pageSelected + 1 === Math.ceil(comments.total / 5) ? comments.total : (pageSelected + 1) * limit}{' '}
                  {t('review.from_all')} {comments.total}
                </div>
              </div>
              <ReactPaginate
                previousLabel={<i className="flaticon-back" />}
                nextLabel={<i className="flaticon-next" />}
                breakLabel={'...'}
                breakClassName="pagination-item pagination-item--break"
                pageClassName="pagination-item"
                activeClassName={'active'}
                pageCount={Math.ceil(comments.total / 5)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={(e) => this.handlePageClick(e)}
                containerClassName={'comment-pagination'}
                disabledClassName="navigate-btn--disable"
                previousClassName="navigate-btn"
                nextClassName={`navigate-btn ${
                  pageSelected + 1 === Math.ceil(comments.total / 5) ? 'navigate-btn--disable' : ''
                }`}
                forcePage={pageSelected}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const CommentsFilterBox = ({ onSelected, values, t }) => {
  return (
    <div className="comment-filter-box">
      <div>
        <select
          id="comment-filter-select"
          onChange={(e) => {
            const value = JSON.parse(e.target.value);
            onSelected(value.sortBy, value.orderBy);
          }}
          value={JSON.stringify(values)}
        >
          {COMMENT_FILTERS.map((f, i) => {
            return (
              <option key={i} id={f.id} value={f.value}>
                {t(`review.filter.${f.id}`)}
              </option>
            );
          })}
        </select>
      </div>
      <i className="flaticon-arrow-down" />
    </div>
  );
};

const COMMENT_FILTERS = [
  {
    id: 'rating-desc',
    label: 'คะแนนมาก-น้อย',
    value: `{"sortBy": "rating","orderBy":"desc"}`
  },
  {
    id: 'rating-asc',
    label: 'คะแนนน้อย - มาก',
    value: `{"sortBy":"rating","orderBy":"asc"}`
  },
  {
    id: 'createdAt-desc',
    label: ' รีวิวล่าสุด',
    value: `{"sortBy":"created_at","orderBy":"desc"}`
  },
  {
    id: 'pickupAt-desc',
    label: 'รับรถล่าสุด',
    value: `{"sortBy":"pickup_at","orderBy":"desc"}`
  }
];
