import React, { PureComponent } from 'react';
import { deleteCookie, deleteLocalStorage, readCookie } from '../utils';
import { ConsumerInfo, authMyInfo } from './auth';
import {
  API_SEARCH,
  WEB_SEARCH_LINK,
  API_LEGACY_LINK,
  HELP_WEB_URL,
  BIGBRAND_API,
  WEB_LEGACY_LINK
} from '../../config';
export interface ContextState {
  bigbrandAPI: string;
  lang: string;
  searchAPI: string;
  searchHost: string;
  isMobile: boolean;
  backendAPI: string;
  auth: AuthenticationState;
  legacyHost: string;
  helpWebUrl: string;
  accessToken: string | null;
  refreshAuth: () => void;
}

interface AuthenticationState {
  isAuth: boolean;
  consumer: ConsumerInfo;
  fetching: boolean;
}

const defaultContext: ContextState = {
  bigbrandAPI: BIGBRAND_API,
  lang: 'th',
  searchAPI: API_SEARCH,
  searchHost: WEB_SEARCH_LINK,
  isMobile: false,
  backendAPI: API_LEGACY_LINK,
  accessToken: readCookie('_consumerToken'),
  refreshAuth: () => {
    /**/
  },
  auth: {
    fetching: true,
    isAuth: false,
    consumer: {
      id: 0,
      status: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      totalIncomingBookings: 0,
      facebookUid: '',
      googleUid: '',
      source: '',
      isPasswordSet: true
    }
  },
  legacyHost: WEB_LEGACY_LINK,
  helpWebUrl: HELP_WEB_URL
};
export interface ContextProps {
  children?: React.ReactNode;
  bigbrandAPI?: string;
  lang?: string;
  searchAPI?: string;
  searchHost?: string;
  backendAPI?: string;
  legacyHost?: string;
  helpWebUrl?: string;
}
export const ContextType = React.createContext(defaultContext);

class Context extends PureComponent<ContextProps, ContextState> {
  state = defaultContext;
  componentDidMount() {
    window.addEventListener('resize', (e) => this.handleResponsive(e), false);
    window.innerWidth <= 992 && this.setState({ isMobile: true });
    this.checkIsAuthenticated();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', (e) => this.handleResponsive(e), false);
  }

  handleResponsive = (e: any) => {
    if (e.target.innerWidth <= 992) return this.setState({ isMobile: true });
    this.setState({ isMobile: false });
  };

  get accessToken() {
    return readCookie('_consumerToken');
  }

  checkIsAuthenticated = () => {
    authMyInfo()
      .then((info: ConsumerInfo) => {
        this.setState({
          auth: {
            isAuth: true,
            fetching: false,
            consumer: info
          }
        });
      })
      .catch((e) => {
        this.setState({
          auth: {
            isAuth: false,
            fetching: false,
            consumer: this.state.auth.consumer
          }
        });
        if (e.status === 401) {
          deleteCookie('_consumerID');
          deleteCookie('_consumerToken');
          deleteLocalStorage('consumer_first_name');
          deleteLocalStorage('consumer_last_name');
          deleteLocalStorage('consumer_phone_number');
          deleteLocalStorage('consumer_email');
        }
      });
  };

  render() {
    const { children } = this.props;
    const { isMobile, bigbrandAPI, lang, searchAPI, searchHost, backendAPI, auth, legacyHost, helpWebUrl } = this.state;

    return (
      <ContextType.Provider
        value={{
          bigbrandAPI,
          lang,
          searchAPI,
          searchHost,
          backendAPI,
          isMobile,
          auth,
          legacyHost,
          helpWebUrl,
          accessToken: this.accessToken,
          refreshAuth: this.checkIsAuthenticated
        }}
      >
        {children}
      </ContextType.Provider>
    );
  }
}

export default Context;
