import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import VisibilitySensor from 'react-visibility-sensor';
import moment from 'moment-timezone';
import Card from './Card';
// import ValueProp from '../../molecules/ValueProp'
import AlertCard from '../../molecules/AlertCard';
import DocumentModal from '../../molecules/ModalDocument';
import InsuranceDialog from '../../molecules/InsuranceDialog';
import InsuranceZeroExcess from '../../molecules/InsuranceZeroExcess';
import './style.scss';

// const VALUEPROP_INDEX = [3, 9, 14, 25, 36, 49, 64, 81, 100]
const ALERTPROP_DESKTOP_INDEX = [7, 14];
const ALERTPROP_MOBILE_INDEX = [1, 7, 14];
class CardDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAlert0: false,
      isAlert1: false,
      isAlert2: false
    };
  }

  isInFestivalRange = () => {
    const { pickup_datetime, return_datetime } = this.props.cars;
    const pickupDate = moment(pickup_datetime);
    const returnDate = moment(return_datetime);

    if (!pickup_datetime || !return_datetime) return false;

    let isInFestivalRange = false;
    isInFestivalRange = [pickupDate, returnDate].some((date) =>
      date.isBetween('2019-03-31T23:59:59+07:00', '2019-04-16T23:59:59+07:00')
    );
    if (pickupDate.isSameOrBefore('2019-04-01') && returnDate.isSameOrAfter('2019-04-16')) {
      isInFestivalRange = true;
    }
    return isInFestivalRange;
  };

  render() {
    const { cars } = this.props;
    const isCarNotFound = cars.list.length === 0 && !cars.fetching;
    // const isInFestivalRange = this.isInFestivalRange()
    return (
      <div className="row car-listing">
        <InsuranceDialog ref={(ref) => (this.insuranceDialog = ref)} />
        <DocumentModal ref={(ref) => (this.documentModal = ref)} />
        <InsuranceZeroExcess ref={(ref) => (this.insuranceZeroExcess = ref)} />
        <MediaQuery minWidth={992}>
          {isCarNotFound ? (
            ''
          ) : (
            <div className="col-12 alert-fix-prop">
              <AlertCard {...this.props} />
            </div>
          )}
        </MediaQuery>

        {cars.list.map((car, index) => {
          return (
            <React.Fragment key={index}>
              <MediaQuery maxWidth={991}>
                {(matches) => {
                  if (matches) {
                    return (
                      <VisibilitySensor key={index} intervalDelay={1500} delayedCall={true} partialVisibility={true}>
                        {({ isVisible }) => {
                          if (ALERTPROP_MOBILE_INDEX.includes(index)) {
                            return (
                              <div className="col-12">
                                <span
                                  className="text-center"
                                  style={{
                                    color: 'transparent',
                                    fontSize: '0.1vh',
                                    display: 'block'
                                  }}
                                >
                                  AlertCard
                                </span>
                                {isVisible ? <AlertCard {...this.props} /> : ''}
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        }}
                      </VisibilitySensor>
                    );
                  } else {
                    return (
                      <VisibilitySensor key={index} intervalDelay={1500} delayedCall={true} partialVisibility={true}>
                        {({ isVisible }) => {
                          if (ALERTPROP_DESKTOP_INDEX.includes(index)) {
                            return (
                              <div className="col-12">
                                <span
                                  className="text-center"
                                  style={{
                                    color: 'transparent',
                                    fontSize: '0.1vh',
                                    display: 'block'
                                  }}
                                >
                                  AlertCard
                                </span>
                                {isVisible ? <AlertCard {...this.props} /> : ''}
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        }}
                      </VisibilitySensor>
                    );
                  }
                }}
              </MediaQuery>
              {/* {VALUEPROP_INDEX.includes(index) && (
                <div
                  key={index}
                  className={`${isInFestivalRange ? '' : 'd-md-block d-lg-none'} col-12`}
                >
                  <div className="row m-0">
                    <ValueProp
                      pattern={VALUEPROP_INDEX.findIndex(x => x === index) % 3}
                      isImage={isInFestivalRange}
                    />
                  </div>
                </div>
              )} */}
              <div className="col-12">
                <Card
                  position={index + 1}
                  {...car}
                  pickupDatetime={this.props.cars.pickup_datetime}
                  returnDatetime={this.props.cars.return_datetime}
                  durationDays={this.props.cars.duration_days}
                  durationHours={this.props.cars.duration_hours}
                  toggleInsuranceModal={() => this.insuranceDialog.toggle()}
                  toggleDocumentModal={(topic) => this.documentModal.toggle(topic)}
                  toggleInsuranceZeroExcess={() => this.insuranceZeroExcess.toggle()}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

CardDetails.propTypes = {
  cars: PropTypes.object,
  onClick: PropTypes.func
};

export default CardDetails;
