import React, { Component } from 'react';
import Lightbox from 'react-images';
import MediaQuery from 'react-responsive';
import Badge from '@ui/components/badge';
import './thankyou.scss';

export default class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    };
  }

  toggleAllImages = () => {
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen,
      currentImage: 0
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  gotoImage = (index) => {
    this.setState({
      currentImage: index
    });
  };

  handleClickImage = (lenghtImages) => {
    if (this.state.currentImage === lenghtImages - 1) {
      this.setState({
        currentImage: 0
      });
    } else {
      this.gotoNext();
    }
  };
  onSelectImage = (i) => {
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen,
      currentImage: i
    });
  };

  get isInter() {
    return ['inter', 'bigbrand'].includes(this.props.source);
  }
  getCarImages = (car) => {
    const images = car.images;

    return { images: images };
  };

  renderImages = (images) => {
    const isSingle = images.length === 1;
    const img = images.slice(0, 4).map((photo, i) => {
      const style = {
        background: 'url(' + photo.url + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'white',
        borderRadius: 8,
        position: 'relative'
      };
      if (isSingle) {
        style.width = '100%';
      }
      return (
        <div className={`gallery__item`} key={i} style={style} onClick={() => this.onSelectImage(i)}>
          {i === 0 && (
            <Badge
              style={{
                position: 'absolute',
                top: 8,
                right: 8
              }}
              type={this.props.source}
              payment={false}
              size="small"
            />
          )}
        </div>
      );
    });

    return <React.Fragment>{img}</React.Fragment>;
  };

  getImages = () => {
    const { car, source } = this.props;
    const { photos } = car;
    if (source === 'inter') {
      return [
        {
          src: car.image,
          thumbnail: car.image,
          orientation: 'landscape'
        }
      ];
    } else {
      return photos.map((photo) => ({
        src: photo.url,
        thumbnail: photo.url,
        orientation: 'landscape'
      }));
    }
  };

  render() {
    const { currentImage } = this.state;
    const { car, source } = this.props;

    const images = source === 'inter' ? [{ url: car.image }] : car.photos;
    const img = this.getImages();

    return (
      <div className="gallery_wrapper pb-2">
        <MediaQuery maxWidth={768}>
          <div className="gallery">{this.renderImages(images.slice(0, 1))}</div>
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <div className="gallery">
            {this.renderImages(images)}
            <div
              className={`${images.length === 1 ? 'd-none' : ''} gallery__item btn-see-all--desktop`}
              onClick={this.toggleAllImages}
            >
              <i className="flaticon-photo-camera" />
              <span>ดูภาพทั้งหมด ({images.length}</span>)
            </div>
          </div>
        </MediaQuery>
        <Lightbox
          backdropClosesModal
          showThumbnails
          images={img}
          currentImage={currentImage}
          isOpen={this.state.lightboxIsOpen}
          onClickThumbnail={this.gotoImage}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClickImage={() => this.handleClickImage(images.length)}
          onClose={this.toggleAllImages}
          imageCountSeparator={'/'}
          theme={theme}
          preventScroll
        />
      </div>
    );
  }
}
const theme = {
  container: {
    '@media (min-width: 768px)': {
      paddingBottom: '100px !important'
    }
  },
  content: {
    marginBottom: 'unset !important'
  },
  thumbnail: {
    marginBottom: '80px',
    thumbnailsSize: 10,
    height: '60px !important',
    width: '60px !important',
    '@media (min-width: 768px)': {
      height: '80px !important',
      width: '80px !important'
    },
    '@media (max-width: 320px)': {
      height: '50px !important',
      width: '50px !important'
    }
  },
  thumbnail__active: {
    boxShadow: '0 0 0 2px #2C8FFF',
    height: '60px !important',
    width: '60px !important',
    '@media (min-width: 768px)': {
      height: '80px !important',
      width: '80px !important'
    },
    '@media (max-width: 321px)': {
      height: '50px !important',
      width: '50px !important'
    }
  }
};
