import React from 'react';
import styled from 'styled-components';
import ServiceIcon from '../../../assets/icon/service-icon.svg';
import './style.scss';

const RATE_LOW_COLOR = 2.5;
const HIGHCOLOR = 'rgba(29, 109, 3, 1)';
const LOWCOLOR = 'rgba(255,193,0,1)';

const RatingsSummaryBox = ({ summaryData }) => {
  const [value, overall, accuracy, timing, attention, recommend] = summaryData;
  return (
    <div className="rating-container">
      <p>คะแนนรีวิวเฉลี่ย</p>
      <div className="section-score">
        <p>
          <i className="flaticon-car-compact" />
          คุณภาพรถ
        </p>
        <div className="score-items">
          <div>
            <label>{value.name}</label>
            <RatingBar percent={value.percent} color={value.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR} />
            <p
              className="score"
              style={{
                color: value.score <= 0 ? '#6f6f6f' : value.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR
              }}
            >
              {value.score <= 0 ? 'ยังไม่มีรีวิว' : value.score}
            </p>
          </div>
          <div>
            <label>{overall.name}</label>
            <RatingBar percent={overall.percent} color={overall.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR} />
            <p
              className="score"
              style={{
                color: overall.score <= 0 ? '#6f6f6f' : overall.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR
              }}
            >
              {overall.score <= 0 ? 'ยังไม่มีรีวิว' : overall.score}
            </p>
          </div>
        </div>
        <p>
          <img src={ServiceIcon} style={{ width: 16, height: 16, marginRight: 2 }} alt="การบริการ" />
          การบริการ
        </p>
        <div className="score-items">
          <div>
            <label>{timing.name}</label>
            <RatingBar percent={timing.percent} color={timing.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR} />
            <p
              className="score"
              style={{
                color: timing.score <= 0 ? '#6f6f6f' : timing.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR
              }}
            >
              {timing.score <= 0 ? 'ยังไม่มีรีวิว' : timing.score}
            </p>
          </div>
          <div>
            <label>{attention.name}</label>
            <RatingBar percent={attention.percent} color={attention.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR} />
            <p
              className="score"
              style={{
                color: attention.score <= 0 ? '#6f6f6f' : attention.score <= RATE_LOW_COLOR ? LOWCOLOR : HIGHCOLOR
              }}
            >
              {attention.score <= 0 ? 'ยังไม่มีรีวิว' : attention.score}
            </p>
          </div>
        </div>
        <div className="score-items score-items--recommend">
          <div>
            <p>
              <i className="flaticon-guarantee" />
              {accuracy.name}
            </p>
            <RatingBar percent={accuracy.percent} color="#0078ff" />
            <p className="score">
              {accuracy.percent <= 0 ? '-' : accuracy.percent}
              <span>%</span>
            </p>
          </div>
          <div>
            <p>
              <i className="flaticon-like-1" />
              {recommend.name}
            </p>
            <RatingBar percent={recommend.percent} color="#0078ff" />
            <p className="score">
              {recommend.percent <= 0 ? '-' : recommend.percent}
              <span>%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const RatingBar = styled.div`
  height: 6px;
  border-radius: 2px;
  background: ${(props) => `
      linear-gradient(
        90deg,
        ${props.color} ${props.percent}%,
        rgba(238, 243, 248, 1) ${props.percent}%,
        rgba(238, 243, 248, 1) 100%
    );`};
`;

export default RatingsSummaryBox;
