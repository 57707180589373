import React, { Component } from 'react';
import Lightbox from 'react-images';
import MediaQuery from 'react-responsive';
import queryString from 'query-string';
import './style.scss';
import { dataLayer } from '@utils/datalayer';

export default class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    };
  }

  toggleAllImages = () => {
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen,
      currentImage: 0
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  gotoImage = (index) => {
    this.setState({
      currentImage: index
    });
  };

  handleClickImage = (lenghtImages) => {
    if (this.state.currentImage === lenghtImages - 1) {
      this.setState({
        currentImage: 0
      });
    } else {
      this.gotoNext();
    }
  };
  onSelectImage = (i) => {
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen,
      currentImage: i
    });
  };

  get isInter() {
    const query = queryString.parse(window.location.search);
    return ['inter', 'bigbrand'].includes(query.source);
  }
  getCarImages = (car) => {
    const images = this.isInter ? [car.image_1] : car.images;
    const thumbnailImages = this.isInter ? [] : car.thumbnail_images;

    return { images: images, thumbnailImages: thumbnailImages };
  };

  renderImages = (images, thumbnailImages) => {
    const bgSize = this.isInter ? 'cover' : 'contain';
    // const bgSize = 'cover'

    const img = images.map((photo, i) => {
      const bgURL = i === 0 ? photo : thumbnailImages[i];
      if (i <= 3) {
        const style = {
          background: 'url(' + bgURL + ')',
          backgroundSize: bgSize,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundColor: 'black'
        };
        return (
          <div
            className={`${images.length === 1 ? 'gallery__item--bigbrand-img' : 'gallery__item'}`}
            key={i}
            style={style}
            onClick={() => {
              this.onSelectImage(i);
            }}
          />
        );
      }
      return '';
    });

    return <React.Fragment>{img}</React.Fragment>;
  };

  render() {
    const { currentImage } = this.state;
    const { car } = this.props;
    const { images, thumbnailImages } = this.getCarImages(car);

    const img = images.map((photo, i) => ({
      src: photo,
      thumbnail: thumbnailImages === [] ? photo : thumbnailImages[i],
      orientation: 'landscape'
    }));

    return (
      <div className="gallery_wrapper pb-2">
        <div className="gallery">
          {this.renderImages(images, thumbnailImages)}
          <div
            data-event-category="product_detail_section"
            data-event-action="see"
            data-event-label="all_image"
            className={`${images.length === 1 ? 'd-none' : ''} gallery__item btn-see-all--desktop`}
            onClick={(e) => {
              dataLayer(e);
              this.toggleAllImages();
            }}
          >
            <i className="flaticon-photo-camera" />
            <span>ดูภาพทั้งหมด ({images.length}</span>)
          </div>
        </div>
        <MediaQuery maxWidth={768}>
          <div
            data-event-category="product_detail_section"
            data-event-action="see"
            data-event-label="all_image"
            className={`${images.length === 1 ? 'd-none' : ''} text-center pt-2 pb-2 btn-see-all--mobile`}
            onClick={(e) => {
              dataLayer(e);
              this.toggleAllImages();
            }}
          >
            <i className="flaticon-photo-camera" />
            <span>ดูภาพทั้งหมด ({images.length})</span>
          </div>
        </MediaQuery>
        <Lightbox
          backdropClosesModal
          showThumbnails
          images={img}
          currentImage={currentImage}
          isOpen={this.state.lightboxIsOpen}
          onClickThumbnail={this.gotoImage}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClickImage={() => this.handleClickImage(images.length)}
          onClose={this.toggleAllImages}
          imageCountSeparator={'/'}
          theme={theme}
          preventScroll
        />
      </div>
    );
  }
}
const theme = {
  container: {
    '@media (min-width: 768px)': {
      paddingBottom: '100px !important'
    }
  },
  content: {
    marginBottom: 'unset !important'
  },
  thumbnail: {
    marginBottom: '80px',
    thumbnailsSize: 10,
    height: '60px !important',
    width: '60px !important',
    '@media (min-width: 768px)': {
      height: '80px !important',
      width: '80px !important'
    },
    '@media (max-width: 320px)': {
      height: '50px !important',
      width: '50px !important'
    }
  },
  thumbnail__active: {
    boxShadow: '0 0 0 2px #2C8FFF',
    height: '60px !important',
    width: '60px !important',
    '@media (min-width: 768px)': {
      height: '80px !important',
      width: '80px !important'
    },
    '@media (max-width: 321px)': {
      height: '50px !important',
      width: '50px !important'
    }
  }
};
