import React from 'react';
import Gallery from '../Gallery/thankyou';
import './style.scss';

class CarDetail extends React.PureComponent {
  get detail() {
    const { source, car } = this.props;
    const result = [];
    for (let icon in detail[source]) {
      const key = detail[source][icon];
      const value = car[key];
      const render = (
        <div className="pair" key={key}>
          <i className={icon} />
          {prefix[icon]} {t(value)} {postfix[icon]}
        </div>
      );
      result.push(render);
    }
    return result;
  }
  get features() {
    const { features } = this.props.car;
    return features.map((feature, index) => {
      const icon = featureIcons[feature];
      return (
        <div className="pair" key={index}>
          <i className={icon} />
          {feature}
        </div>
      );
    });
  }
  get isInter() {
    return ['inter', 'bigbrand'].includes(this.props.source);
  }
  render() {
    const { car, source } = this.props;
    if (!car) {
      return <div>CAR IS NOT HERE</div>;
    }

    return (
      <div className="cardetail">
        <h3>ข้อมูลรถเช่า</h3>
        <Gallery car={car} source={source} />
        <p className="title">{car.model}</p>

        <h4>ข้อมูลรถ</h4>
        <div className="detail">{this.detail}</div>

        {!this.isInter && (
          <React.Fragment>
            <h4>อุปกรณ์ภายในรถ</h4>
            <div className="features">{this.features}</div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const detail = {
  local: {
    'flaticon-car': 'category',
    'flaticon-gearshift1x': 'transmission',
    'flaticon-seat1x': 'passengers',
    'flaticon-bag1x': 'luggages',
    'flaticon-registered1x': 'registration_year'
  },
  inter: {
    'flaticon-car': 'category',
    'flaticon-gearshift1x': 'transmission',
    'flaticon-seat1x': 'passengers',
    'flaticon-bag1x': 'luggages'
  }
};
const postfix = {
  'flaticon-seat1x': 'ที่นั่ง',
  'flaticon-bag1x': 'ใบใหญ่'
};
const prefix = {
  'flaticon-registered1x': 'จดทะเบียน'
};
const featureIcons = {
  'FM/AM Radio': 'flaticon-radio1x',
  'USB/AUX': 'flaticon-usb1x',
  'CD/MP3': 'flaticon-cd1x',
  Bluetooth: 'flaticon-bluetooth1x',
  GPS: 'flaticon-location1x',
  '4WD': 'flaticon-wheel1x'
};

const t = (word = '') => {
  if (typeof word === 'number') return word;

  const term = word.toLowerCase();
  const category = categories[term];
  if (category) return category;
  const tran = transmissions[term];
  if (tran) return tran;
  return word;
};

const categories = {
  car_with_driver: 'รถพร้อมคนขับ',
  eco: 'อีโคคาร์',
  large: 'รถขนาดใหญ่',
  luxury: 'Luxury',
  medium: 'รถขนาดกลาง',
  small: 'รถขนาดเล็ก',
  suv: 'เอสยูวี',
  trucks: 'รถกระบะ',
  van: 'รถตู้',
  hybrid: 'ไฮบริด'
};
const transmissions = {
  auto: 'ออโต้',
  manual: 'แมนนวล'
};

export default CarDetail;
