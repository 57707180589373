import React from 'react'

const question = {
  icon: '',
  question: 'ค่าล็อคคิวรถคืออะไร?',
  answer: (
    <div>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        ทางบริษัทรถเช่าอาจเรียกเก็บค่าเช่าล่วงหน้าเพื่อล็อคคิวรถเป็นจำนวนเงิน 500-1000 บาท
        โดยจะหักจากค่าเช่ารถ มิได้เป็นการเรียกเก็บเพิ่มเติม
        และในวันรับรถท่านจะชำระเพียงค่าเช่าส่วนที่เหลือพร้อมกับค่าประกันความเสียหาย
      </div>
      <div className="mt-3">
        *กรณีเช่ารถในช่วงวันเทศกาลหรือไฮซีซั่น (เดือนตุลาคมถึงกุมภาพันธ์)
        ทางร้านอาจขอเรียกเก็บค่าล็อคคิวรถในจำนวนที่เพิ่มขึ้นทั้งนี้ขึ้นอยู่กับเงื่อนไขของทางบริษัทรถเช่านั้นๆ
      </div>
    </div>
  ),
}

export default question
