import { defineAction } from 'redux-define'

export const FEATURE_AVAILABLE_ACTION = defineAction('FEATURE_AVAILABLE', [
  'FETCHING',
  'FETCHED',
  'ERROR',
  'INITIAL',
])

const initialFeatureAvailable = {
  list: [],
}

const features = (state = initialFeatureAvailable, { type, payload }) => {
  switch (type) {
    case FEATURE_AVAILABLE_ACTION.INITIAL: {
      return initialFeatureAvailable
    }

    case FEATURE_AVAILABLE_ACTION.FETCHING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case FEATURE_AVAILABLE_ACTION.FETCHED: {
      return {
        ...state,
        list: payload,
        fetching: false,
      }
    }
    case FEATURE_AVAILABLE_ACTION.ERROR: {
      return initialFeatureAvailable
    }
    default: {
      return state
    }
  }
}

export default features
