import React from 'react';
import PropTypes from 'prop-types';
import done from '../../../assets/icon/done.png';
import { SURVEY_URL_LOCAL, SURVEY_URL_INTER, LEGACY_WEB } from '../../../variables/API';
import { LOCAL, INTER } from '../../../variables/source';
import './style.scss';

const RentalEndWithSurvey = (props) => {
  const getSurveyLink = () => {
    const { source, bookingId } = props;
    switch (source) {
      case LOCAL:
        return `${SURVEY_URL_LOCAL}?booking_id=${bookingId}`;
      case INTER:
        return `${SURVEY_URL_INTER}?booking_id=${bookingId}`;
      default:
        return '';
    }
  };

  return (
    <div className="rental-end">
      <div className="text-center img">
        <img src={done} alt="การเช่ารถของคุณเสร็จสิ้น" />
      </div>
      <div className="text-box">
        <div>
          <p className="text__primary">
            การเช่ารถของคุณเสร็จสิ้น <br />
            <span>ไดรฟ์ฮับขอบคุณที่คุณไว้วางใจใช้บริการกับเรา</span>
          </p>
          <p className="text__secondary">คุณได้รับการบริการครั้งนี้เป็นอย่างไรบ้าง</p>
        </div>
      </div>
      <div className="text-center survey-button">
        <a className="btn btn-primary" href={getSurveyLink()} target="_blank" rel="noopener noreferrer">
          <i className="flaticon-star-stoke1x"></i>
          รีวิวบริษัทรถเช่า
        </a>
      </div>
      <div className="text-center back-to-home">
        <a href={LEGACY_WEB}>
          <u>กลับสู่หน้าแรก</u>
        </a>
      </div>
    </div>
  );
};

RentalEndWithSurvey.defaultProps = {
  source: 'local',
  bookingId: 282165
};

RentalEndWithSurvey.propTypes = {
  source: PropTypes.string,
  bookingId: PropTypes.number
};

export default RentalEndWithSurvey;
