import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Table } from 'reactstrap';
import MediaQuery from 'react-responsive';
import Tooltip from 'rc-tooltip';
import 'react-tabs/style/react-tabs.css';
import RentalConditionModal from '../RentalCondition';
import NoteInfo from '../../molecules/NoteInfo';
import InstantBookingIcon from '../../../assets/icon/instant_booking_icon.svg';
import baggageIcon from '../../../assets/icon/luggage.svg';
import luggageIcon from '../../../assets/icon/suitcase.svg';
import { withTranslation } from '../../../locales';
// import { INSTANT_BOOKING_BADGE } from '../../../variables/badge'
import './style.scss';
import { dataLayer } from '@utils/datalayer';

class InfoTab extends Component {
  get isInter() {
    return ['inter', 'bigbrand'].includes(this.props.source);
  }
  openRentalCondition = () => {
    this.rentalConditionModal.toggle(this.props.source);
  };

  getBadge = () => {
    return (
      <div className="instant_booking_box">
        <img src={InstantBookingIcon} alt="ส่งรถภายใน 60 นาที" />
        <p>
          ปกติแล้วส่งรถได้ภายใน <span>60 นาที</span>
        </p>
      </div>
    );
  };
  render() {
    const { t } = this.props;
    return (
      <div className="info_tab_wrapper">
        <Tabs>
          <TabList>
            <Tab>
              <p
                className="tab_title"
                data-event-category="product_detail_section"
                data-event-action="click_for_read"
                data-event-label="car_detail"
                onClick={(e) => dataLayer(e)}
              >
                <i className="flaticon-car-compact" />
                {t('car.title')}
              </p>
            </Tab>
            <Tab>
              <p
                className="tab_title"
                data-event-category="product_detail_section"
                data-event-action="click_for_read"
                data-event-label="information_should_know"
                onClick={(e) => dataLayer(e)}
              >
                <i className="flaticon-round-error-symbol" />
                {t('information.title')}
              </p>
            </Tab>
          </TabList>

          <TabPanel>
            <p>
              <b>{t('car.detail')}</b>
            </p>
            <MediaQuery minWidth={768}>
              {(matches) => {
                if (matches)
                  // NOTE: Desktop
                  return (
                    <React.Fragment>
                      {/* TODO:BADGE Temporary disable badge  */}
                      {/* {badges && badges.includes(INSTANT_BOOKING_BADGE) && this.getBadge()} */}
                      <div className="car_info_table_wrapper">
                        <div className="car_info_table">
                          <Table striped>
                            <tbody>
                              <CarInfoFirstThreeRow
                                t={t}
                                car={this.props.car}
                                category={this.props.category}
                                source={this.props.source}
                              />
                            </tbody>
                          </Table>
                        </div>
                        <div className="car_info_table">
                          <Table striped>
                            <tbody>
                              <CarInfoLastTwoRow t={t} car={this.props.car} category={this.props.category} />
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                // NOTE: Mobile
                else
                  return (
                    <React.Fragment>
                      {/* TODO:BADGE Temporary disable badge  */}
                      {/* {badges && badges.includes(INSTANT_BOOKING_BADGE) && this.getBadge()} */}
                      <Table striped>
                        <tbody>
                          <CarInfoFirstThreeRow
                            t={t}
                            car={this.props.car}
                            category={this.props.category}
                            source={this.props.source}
                          />
                          <CarInfoLastTwoRow t={t} car={this.props.car} category={this.props.category} />
                        </tbody>
                      </Table>
                    </React.Fragment>
                  );
              }}
            </MediaQuery>

            {!this.isInter ? (
              <div className="mt-3">
                <p>
                  <b>{t('car.features')}</b>
                </p>
                {getFeatures(this.props.car.features)}
              </div>
            ) : (
              ''
            )}
          </TabPanel>
          <TabPanel>
            {this.isInter ? <NoteInfo t={t} /> : <LocalFacts t={t} />}
            {/* <div className="full_condition_box">
              <a onClick={this.openRentalCondition}>เงื่อนไขและข้อตกลงฉบับเต็ม</a>
            </div> */}
          </TabPanel>
        </Tabs>
        <RentalConditionModal ref={(ref) => (this.rentalConditionModal = ref)} />
      </div>
    );
  }
}

const getFeatureIcon = (feature) => {
  switch (feature) {
    case 'FM/AM Radio':
      return 'flaticon-radio';
    case 'USB/AUX':
      return 'flaticon-usb-cable';
    case 'GPS':
      return 'flaticon-location-arrow';
    case 'CD/MP3':
      return 'flaticon-disc';
    case 'Bluetooth':
      return 'flaticon-blueetooth';
    case '4WD':
      return 'flaticon-4wd-icon';
    default:
      return '';
  }
};
const getFeatures = (features = []) => {
  return (
    <div className="feature_wrapper">
      {features.map((feature) => (
        <div key={feature} className="feature_item">
          <p>
            <i className={getFeatureIcon(feature)} /> {feature}
          </p>
        </div>
      ))}
    </div>
  );
};

const LocalFacts = ({ t }) => {
  return (
    <div className="local_facts_wrapper">
      <p>
        <b>{t('information.title')}</b>
      </p>
      <ol>
        <li>
          <b>{t('information.1.title')}</b> {t('information.1.content')}
        </li>
        <li>
          <b>{t('information.2.title')}:</b> {t('information.2.content')}
        </li>
        <li>
          <b>{t('information.3.title')}: </b>
          {t('information.3.content')}{' '}
          <a
            href="https://www.drivehub.co/th/documents"
            data-event-category="product_detail_section"
            data-event-action="go_to"
            data-event-label="content_document"
            onClick={(e) => dataLayer(e)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('information.3.click')} <i className="flaticon-out" />
          </a>
        </li>
        <li>
          <b>{t('information.4.title')}:</b>
          {t('information.4.content1')}
          <Tooltip
            placement="top"
            overlay={t('information.4.lock')}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
            trigger={['hover', 'click']}
          >
            <span
              className="border__text border__text--normal cursor-default"
              style={{ fontWeight: 'normal', color: 'rgb(41,105,165)' }}
            >
              <i className="flaticon-information" />
            </span>
          </Tooltip>{' '}
          {t('information.4.content2')}
        </li>
        <li>
          <b>{t('information.5.title')}:</b> {t('information.5.content')}
        </li>
        <li>
          <b>{t('information.6.title')}:</b> {t('information.6.content')}
        </li>
      </ol>
    </div>
  );
};

const getTransmission = (props) => props.t(`transmission.${props.car.transmission}`);
const getCategory = (props) => {
  if (['inter', 'bigbrand'].includes(props.source)) return props.category.th;
  return props.t(`categories.${props.category.en.toLowerCase()}`);
};
const CarInfoFirstThreeRow = (props) => {
  const { t } = props;
  return (
    <React.Fragment>
      {['local', 'original'].includes(props.source) && (
        <tr>
          <td>{t('car.register_year')}</td>
          <td>{props.car.registration_year}</td>
        </tr>
      )}
      <tr>
        <td>{t('car.gear')}</td>
        <td className="info_box">
          <i className="flaticon-gearbox" />
          {getTransmission(props)}
        </td>
      </tr>
      <tr>
        <td>{t('car.kind')}</td>
        <td className="info_box">
          <i className="flaticon-car-compact" />
          {getCategory(props)}
        </td>
      </tr>
      <tr>
        <td>{t('car.passenger')}</td>
        <td className="info_box">
          <i className="flaticon-user" />
          {props.car.passengers}
        </td>
      </tr>
    </React.Fragment>
  );
};

const CarInfoLastTwoRow = (props) => {
  const { t } = props;
  return (
    <React.Fragment>
      {props.car.luggages > 0 && (
        <tr>
          <td>{t('car.luggage')}</td>
          <td className="info_box">
            <img className="luggage_icon" src={luggageIcon} alt="luggage-icon" />
            {props.car.luggages}
          </td>
        </tr>
      )}
      {props.car.baggages > 0 && (
        <tr>
          <td>{t('car.baggage')}</td>
          <td className="info_box">
            <img className="baggage_icon" src={baggageIcon} alt="baggage-icon" />
            {props.car.baggages}
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default withTranslation('car_detail')(InfoTab);
