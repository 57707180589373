import React, { Component } from 'react';
import Tooltip from 'rc-tooltip';
import RadioButton from '../../atoms/RadioButton';
import propTypes from 'prop-types';
import './style.scss';
import { withTranslation } from '../../../locales';

class InsuranceBox extends Component {
  static propTypes = {
    insurance_main: propTypes.object
  };
  render() {
    const { insurance_main, insurances, insurance_id, onApplyInsurance, t } = this.props;
    return (
      <React.Fragment>
        <div className="insurance--wrapper">
          <div className="row">
            <div className="col-auto mr-auto">
              <p className="insurance--primary">{t('insurance.title')}</p>
            </div>
          </div>
          {insurance_main && (
            <div className="row">
              <div className="col-auto mr-auto">
                <div className="basic-insurance">
                  <i className="flaticon-check" />
                  &nbsp;&nbsp;&nbsp;{insurance_main['code']}&nbsp;&nbsp;
                  <Tooltip
                    placement="bottom"
                    overlay={insurance_main['description']}
                    arrowContent={<div className="rc-tooltip-arrow-inner" />}
                    trigger={['hover', 'click']}
                  >
                    <i className="flaticon-information" />
                  </Tooltip>
                </div>
              </div>
              <div className="col-auto">
                <span className="red">{t('free')}!</span>
              </div>
            </div>
          )}
          {insurances &&
            insurances.length > 0 &&
            insurances.map((item, key) => {
              return (
                <React.Fragment key={item.id}>
                  <div className="row">
                    <div className="col-auto mr-auto">
                      <div className="basic-insurance">
                        <RadioButton
                          onClick={onApplyInsurance}
                          onChange={onApplyInsurance}
                          checked={parseInt(insurance_id, 10) === parseInt(item.id, 10)}
                          name="insurance"
                          id={`${key}-insurance`}
                          label={item.code}
                          value={item.id}
                        />
                        &nbsp;&nbsp;
                        <Tooltip
                          placement="bottom"
                          overlay={item.description}
                          arrowContent={<div className="rc-tooltip-arrow-inner" />}
                          trigger={['hover', 'click']}
                        >
                          <i className="flaticon-information" />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="col-auto text--align--right">
                      <span>
                        {parsedMoney(item.price_per_day)}
                        <br />
                        <small>({t('price_per_day')})</small>
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}
const parsedMoney = (price) => `฿${parseInt(Math.round(price), 10).toLocaleString()}`;

export default withTranslation('common', 'summary')(InsuranceBox);
