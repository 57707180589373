import React, { PureComponent, createRef } from 'react';
import { LocationPickerProps, LocationPickerState, Locations, FormatLocations } from './interface';
import './style.scss';

const popularProvinces = [2, 1, 3, 11];
class LocationPicker extends PureComponent<LocationPickerProps, LocationPickerState> {
  static defaultProps = {
    renderList: (component: React.ReactNode) => component,
    renderInput: (component: React.ReactNode) => component
  };
  constructor(props: LocationPickerProps) {
    super(props);
    this.state = {
      isTogglePicker: false,
      placeholder: '',
      active: [],
      term: '',
      skipFilter: true,
      searchOpen: [],
      isSearch: false,
      keyRef: ''
    };
  }

  dropdownRef = createRef<HTMLDivElement>();
  inputRef = createRef<HTMLInputElement>();

  componentDidUpdate(prevProps: LocationPickerProps) {
    if (prevProps.locations.length !== this.props.locations.length) {
      if (this.props.locationID) {
        this.props.locations.forEach((province) => {
          province.locations.forEach((lo) => {
            if (lo.id === this.props.locationID) {
              return this.setState({
                ...this.state,
                placeholder: lo.name[this.props.prefix],
                active: [province.id],
                searchOpen: [1], //this.state.active.concat(province.id),
                term: lo.name[this.props.prefix],
                skipFilter: true,
                keyRef: province.name.en.toLowerCase().replace(/\s/g, '')
              });
            }
          });
        });
      }
      if (!this.props.cityID) return null;
      const { cityID, locationID, locations, prefix } = this.props;

      const province = locations.filter((p) => p.id === cityID)[0];
      const lo = province.locations.filter((l) => l.id === locationID)[0];

      return this.setState({
        ...this.state,
        placeholder: lo.name[prefix],
        active: this.state.active.concat(cityID),
        term: lo.name[prefix],
        searchOpen: this.state.searchOpen.concat(cityID),
        skipFilter: true,
        keyRef: province.name.en.toLowerCase().replace(/\s/g, '')
      });
    }
  }

  handleClick = () => {
    if (this.state.isTogglePicker) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
      if (this.inputRef.current) this.inputRef.current.blur();
    }
  };

  handleOutsideClick = (e: any) => {
    if (this.dropdownRef.current && this.dropdownRef.current.contains(e.target)) return;

    if (this.inputRef.current) this.inputRef.current.blur();
    this.setState({ isTogglePicker: false, isSearch: false }, () => {
      document.removeEventListener('click', this.handleOutsideClick, false);
    });
  };

  formatLocationList = (locations: Array<Locations>) => {
    if (locations.length === 0 || locations === undefined) return [];
    const cityList = locations.map((item) => ({
      ...item,
      key: item.name.en.toLowerCase().replace(/\s/g, ''),
      data: item.locations.map((l) => ({
        ...l,
        name: l.name.th,
        key: l.name.en.toLowerCase().replace(/\s/g, '')
      })),
      name: item.name.th
    }));
    return cityList;
  };

  toggleDropdown = () => {
    if (window.innerWidth < 992)
      if (this.inputRef.current) {
        this.inputRef.current.readOnly = true;
        this.inputRef.current.blur();
      }
    this.setState({ isTogglePicker: !this.state.isTogglePicker }, () => {
      this.handleScrollTo();
      this.props.onToggle(this.state.isTogglePicker);

      if (window.innerWidth > 992) return this.handleClick();

      const navbarElem = document.getElementById('mainMenu');
      if (!navbarElem) return;
      if (this.state.isTogglePicker) {
        // if (this.inputRef.current) this.inputRef.current.readOnly = true;
        if (!this.props.isPromotionPage) {
          const bgElem = document.getElementById('content-section-bg');
          if (!bgElem) return;
          bgElem.style.zIndex = '100';
        }
        navbarElem.style.zIndex = '0';
      } else {
        if (!this.props.isPromotionPage) {
          const bgElem = document.getElementById('content-section-bg');
          if (!bgElem) return;
          bgElem.style.zIndex = '0';
        }
        navbarElem.style.zIndex = '1800';
      }
    });
  };

  handleScrollTo = () => {
    const scrollContainer = this.dropdownRef.current;
    if (this.state.keyRef !== '') {
      const divID = `province-item-${this.state.keyRef}`;
      setTimeout(() => {
        const elementRef = document.getElementById(divID);
        if (elementRef && scrollContainer) {
          scrollContainer.scroll({
            behavior: 'auto',
            top: elementRef.offsetTop
          });
        }
      }, 50);
    } else {
      const divID = `province-item-krabi`;
      setTimeout(() => {
        const elementRef = document.getElementById(divID);
        if (elementRef && scrollContainer) {
          scrollContainer.scroll({
            behavior: 'auto',
            top: elementRef.offsetTop
          });
        }
      }, 50);
    }
  };

  setCleanState = (preventTracking: boolean, callBack?: () => void) => {
    // if (this.state.term !== '') {
    //   this.props.onClear();
    // }

    this.setState(
      {
        active: [],
        searchOpen: [],
        skipFilter: true,
        term: '',
        placeholder: '',
        isSearch: false,
        keyRef: ''
      },
      () => {
        this.props.onClear(preventTracking);
        callBack && callBack();
      }
    );
  };

  onClickProvince = (cityID: number, isActive: boolean) => {
    const { active } = this.state;
    if (isActive) return this.setState({ active: active.filter((a) => a !== cityID) });
    return this.setState({ active: active.concat(cityID) });
  };

  search = () => {
    const text = this.state.term;
    if (!text) return this.setCleanState(true, () => this.props.onSearchSuccess(text, false, true));

    const result = this.formatLocationList(this.props.locations).filter(
      (item) =>
        item.name.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
        item.data.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) !== -1).length > 0
    );

    if (result.length > 0) {
      this.props.onSearchSuccess(text, true, false);
    } else {
      this.props.onSearchSuccess(text, false, false);
    }

    const searchOpen = result.map((r) => r.id);
    this.setState({ active: searchOpen, searchOpen: searchOpen });
  };

  isSearchNotFound = () => {
    const { isSearch, searchOpen, term } = this.state;
    if (isSearch || term !== '') {
      if (searchOpen.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  renderLocationList = (city: FormatLocations) => {
    return (
      <React.Fragment>
        {city.data
          .filter((lo) => {
            if (this.state.skipFilter === true) return true;
            return lo.name.toLowerCase().includes(this.state.term.toLowerCase());
          })
          .map((lo) => {
            return (
              <div
                className="location-item"
                key={lo.name}
                onClick={(event) => {
                  event.preventDefault();
                  this.props.onChange(lo.id);
                  this.setState(
                    {
                      placeholder: lo.name,
                      term: lo.name,
                      active: [city.id],
                      searchOpen: [city.id],
                      skipFilter: true,
                      keyRef: city.key
                    },
                    () => {
                      this.toggleDropdown();
                    }
                  );
                }}
              >
                {lo.name}
              </div>
            );
          })}
      </React.Fragment>
    );
  };

  renderNotFound = () => {
    return (
      <div className="search-not-found">
        <h3>ไม่พบสถานที่รับรถ</h3>
        <p>ไม่พบสถานที่รับรถ “{this.state.term}”</p>
      </div>
    );
  };

  onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        term: e.target.value,
        skipFilter: false,
        isSearch: e.target.value === '' ? false : true,
        placeholder: e.target.value === '' ? '' : this.state.placeholder
      },
      this.search
    );
  };

  get list() {
    const { locations } = this.props;
    const { isTogglePicker, placeholder, active, term, skipFilter, searchOpen } = this.state;
    const cities = this.formatLocationList(locations);
    return (
      this.props.renderList &&
      this.props.renderList(
        <div className={`location__picker ${isTogglePicker ? 'show' : ''}`}>
          <div className="picker-header">
            <input
              type="text"
              placeholder={placeholder === '' ? 'ค้นหาสถานที่รับรถ' : placeholder}
              value={term}
              onChange={(e) => this.onSearch(e)}
              autoComplete="off"
            />
            <i
              id="search-clear-location-outside"
              className={term === '' ? 'icon-search' : 'icon-cancel-circle'}
              aria-hidden="true"
              onClick={() => this.setCleanState(false)}
              style={{ zIndex: 100 }}
            />
            <i
              id="search-clear-location-outside"
              className="icon-close close-btn"
              aria-hidden="true"
              onClick={this.toggleDropdown}
            />
          </div>
          <div className={`picker-content ${this.isSearchNotFound() ? 'not-found' : ''}`} ref={this.dropdownRef}>
            <div className="accordion province-container " id="province-container-ref">
              {term !== '' && searchOpen.length === 0
                ? this.renderNotFound()
                : cities
                    .filter((city) => {
                      if (skipFilter === true) return true;
                      return searchOpen.includes(city.id);
                    })
                    .map((city) => {
                      const isActive = active.includes(city.id);
                      const cityId = Number(city.id);
                      return (
                        <div className="card province-item" key={city.name} id={`province-item-${city.key}`}>
                          <div
                            className="province-item-header"
                            onClick={() => {
                              this.props.onExpand(city, !isActive);
                              this.onClickProvince(city.id, isActive);
                            }}
                          >
                            {city.name}
                            {popularProvinces.includes(cityId) && (
                              <span className="badge badge-sm badge--popular"> ยอดนิยม </span>
                            )}
                            <i className={isActive ? 'icon-up' : 'icon-down'} />
                          </div>
                          <div className={`province-item-location ${active.includes(city.id) ? 'show' : ''}`}>
                            {active.includes(city.id) && this.renderLocationList(city)}
                          </div>
                        </div>
                      );
                    })}
            </div>
          </div>
        </div>
      )
    );
  }

  render() {
    const { isTogglePicker, placeholder, term } = this.state;

    return (
      <div className="location-container">
        <div className={`location-box dropdown ${isTogglePicker ? 'show' : ''}`}>
          {this.props.renderInput &&
            this.props.renderInput(
              <div className="location__search-box" onClick={this.toggleDropdown}>
                <input
                  type="text"
                  placeholder={placeholder === '' ? 'ค้นหาสถานที่รับรถ' : placeholder}
                  value={term}
                  onChange={(e) => this.onSearch(e)}
                  ref={this.inputRef}
                  autoComplete="off"
                />
                <i
                  id="search-clear-location-outside"
                  className={term === '' ? 'icon-search' : 'icon-cancel-circle'}
                  aria-hidden="true"
                  onClick={() => this.setCleanState(false)}
                />
              </div>
            )}
          {this.list}
        </div>
      </div>
    );
  }
}

export default LocationPicker;
