import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import ConfirmCreditModal from '../../ecosystems/DetailsBoxWithBookingSummary/ConfirmCreditModal';
import ModalCarWalkIn from '../../ecosystems/DetailsBoxWithBookingSummary/ModalCarWalkIn';
import * as actions from '../../../ducks/actions/booking';
import './style.scss';
import { withTranslation } from '../../../locales';
import { dataLayer, pushDataLayer } from '@utils/datalayer';

const originalButtons = ['select', 'check', 'confirm'];
const bigbrandButtons = ['select', 'check', 'payment_confirm'];

const originalBackButtons = ['back', 'edit'];
const bigbrandBackButtons = ['back', 'edit'];

class SubmitButton extends Component {
  state = {
    isTimeup: false
  };

  componentDidMount() {
    this.timechanged();
    this.timeupLoop = setInterval(() => this.timechanged(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timeupLoop);
  }

  componentDidUpdate() {
    return true;
  }

  timechanged() {
    const date = moment(this.props.data.pickup_datetime);
    this.setState({
      isTimeup: moment().isSameOrAfter(date) || this.props.carAvailableFor === 'walk_in'
    });
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.data.pickup_datetime !== this.props.data.pickup_datetime) {
      this.timechanged();
    }
    return null;
  }

  scroll = () => {
    if (this.props.step === 0) {
      setTimeout(() => {
        const element = document.getElementsByClassName('dealership')[0];
        element.scrollIntoView({ behavior: 'smooth' });
      }, 100);
      return;
    }
    if (this.props.step === 2) {
      return;
    }
    setTimeout(() => {
      const element = document.getElementsByClassName('gallery')[0];
      element.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  onClick = async () => {
    const args = await this.dataLayerArgs();
    if (args) {
      pushDataLayer(args);
    }
    switch (this.props.step) {
      case 0: {
        if (!this.isOriginal) {
          return this.confirmModal.toggle();
        }
        this.scroll();
        return this.props.next();
      }
      case 1: {
        // NOTE: BEGIN: Ecommerce Tracking, checkout event

        // NOTE: END: Ecommerce Tracking, checkout event

        // Create local Draft
        if (this.isOriginal) {
          return this.props.validateForm(this.props.customer, async () => {
            await this.props.createDraftBooking(this.scroll);

            // NOTE: VWO FORM BOOKING_CUSTOMER
            window.VWO = window.VWO || [];
            const formInstance = document.getElementsByName('booking_customer')[0];
            window.VWO.push(['nls.formAnalysis.markSuccess', formInstance, 1]);
          });
        }
        return this.props.validateForm(this.props.customer, this.scroll);
      }
      case 2: {
        this.scroll();
        // NOTE: VWO FORM BOOKING_PAYMENT
        window.VWO = window.VWO || [];
        const formInstance = document.getElementsByName('booking_payment')[0];
        window.VWO.push(['nls.formAnalysis.markSuccess', formInstance, 1]);

        if (this.isOriginal) {
          // Create local Book
          return this.props.createBooking();
        } else {
          if (this.props.isTermAccept) {
            document.getElementById('term-checkbox').classList.remove('checkbox-error');
            if (this.props.data.insurance_selected) {
              this.props.createInsurance(this.props.data.insurance_selected);
              return this.props.createBooking();
            } else {
              return this.props.createBooking();
            }
          } else {
            document.getElementById('term-checkbox').classList.add('checkbox-error');
            document.getElementById('term-error-text').style.display = 'block';
            document.getElementById('term-checkbox').scrollIntoView({ behavior: 'smooth' });
            return;
          }
        }
      }
      default: {
        // NOTE: BEGIN: Ecommerce Tracking, add to cart event

        // NOTE: END: Ecommerce Tracking, add to cart event

        this.scroll();
        return this.props.next();
      }
    }
  };
  get isLoading() {
    if (this.props.bookingStatus.isCreatingDraft) {
      return true;
    }
    if (this.props.bookingStatus.isError) {
      return false;
    } else {
      if (this.isOriginal) {
        switch (this.props.step) {
          case 2: {
            return this.props.payment.isLoading;
          }
          default: {
            return false;
          }
        }
      }
      switch (this.props.step) {
        case 2: {
          return this.props.payment.isLoading;
        }
        default: {
          return false;
        }
      }
    }
  }
  get isOriginal() {
    return ['local', 'original'].includes(this.props.params.source);
  }
  get isValid() {
    const isValid = true;
    if (this.state.isTimeup) return false;
    switch (this.props.step) {
      case 0: {
        return this.props.carAvailableFor === 'online';
      }
      case 1: {
        return this.props.customer.isValid;
      }
      default: {
        return isValid;
      }
    }
  }
  get button() {
    const { t } = this.props;
    if (this.props.bookingStatus.isCreatingDraft) {
      return t(`button.check`);
    }
    if (this.state.isTimeup) {
      return t('button.timeup');
    }
    const buttons = this.isOriginal ? originalButtons : bigbrandButtons;
    return t(`button.${buttons[this.props.step]}`);
  }

  get ecommerceProduct() {
    const branch = `${this.props.details.vendor.title.th} : ${this.props.details.branch.title.th}`;
    return {
      name: this.carTitle,
      id: (this.isOriginal ? 'L' : 'I') + this.props.carID,
      price: this.props.data.grand_total_price,
      brand: branch,
      category: this.props.details.category.en,
      variant: this.isOriginal ? 'local' : 'inter',
      quantity: 1
    };
  }

  dataLayerArgs = async () => {
    const buttons = this.isOriginal ? originalButtons : bigbrandButtons;
    const product = this.ecommerceProduct;

    const encryptedEmail = await sha256(this.props.customer.email);
    const encryptedPhoneNumber = await sha256(this.props.customer.phone_number);

    switch (buttons[this.props.step]) {
      case 'select':
        return {
          event: 'ecommerce_checkout',
          event_category: 'ecommerce',
          event_action: 'product_checkout_step1',
          event_label: this.carTitle,
          ecommerce: {
            checkout: {
              actionField: { step: 1 },
              products: [product]
            }
          }
        };
      case 'check':
        return {
          event: 'ecommerce_checkout',
          event_category: 'ecommerce',
          event_action: 'product_checkout_step2',
          event_label: this.carTitle,
          encrypt_email: encryptedEmail,
          encrypt_phonenumber: encryptedPhoneNumber,
          ecommerce: {
            checkout: {
              actionField: { step: 2 },
              products: [product]
            }
          }
        };
      case 'confirm':
      case 'payment_confirm':
        return {
          event: 'ecommerce_checkout',
          event_category: 'ecommerce',
          event_action: 'product_checkout_step3',
          event_label: this.carTitle,
          encrypt_email: encryptedEmail,
          encrypt_phonenumber: encryptedPhoneNumber,
          ecommerce: {
            checkout: {
              actionField: { step: 3 },
              products: [product]
            }
          }
        };
      default:
        return {};
    }
  };

  get backbuttonState() {
    const buttons = this.isOriginal ? originalBackButtons : bigbrandBackButtons;
    return buttons[this.props.step - 1];
  }

  get backButton() {
    const { t } = this.props;
    return t(`button.${this.backbuttonState}`);
  }
  get minimumDays() {
    const data = this.props.data;
    let result = 0;
    if (this.isOriginal) {
      if (data.bookability.bookable === false) {
        if (typeof data.bookability.explain === 'object') {
          return (result = data.bookability.explain.count);
        }
      }
    }
    return result;
  }

  randomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
  };

  get carTitle() {
    try {
      return this.props.details.car.title.en;
    } catch {
      return '';
    }
  }
  get buttonId() {
    // For tracking in vwo.
    const { step } = this.props;
    let btnId = '';
    switch (step) {
      case 0: {
        btnId = 'selectThisCar';
        return btnId;
      }
      case 1: {
        btnId = 'verifyDetail';
        return btnId;
      }
      case 2: {
        btnId = 'ConfirmBook';
        return btnId;
      }
      default: {
        btnId = 'bookingButton';
        return btnId;
      }
    }
  }

  render() {
    const { t } = this.props;
    if (!this.props.isCouponApplied) {
      return (
        <React.Fragment>
          <div className="submit_button_wrapper">
            <button disabled className="btn btn-secondary btn-block">
              {t('button.please_use_coupon')}
            </button>
          </div>
        </React.Fragment>
      );
    }
    if (this.props.isEditDateTime) {
      return (
        <React.Fragment>
          <div className="submit_button_wrapper">
            <button disabled className="btn btn-secondary btn-block">
              {t('button.please_select')}
            </button>
          </div>
        </React.Fragment>
      );
    }
    if (!this.props.available) {
      return (
        <React.Fragment>
          <div className="submit_button_wrapper">
            <button disabled className="btn btn-danger btn-block">
              {this.minimumDays > 0
                ? `${t('button.minimum1')} ${this.minimumDays} ${t('button.minimum2')}`
                : t('button.not_available')}
            </button>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="submit_button_wrapper">
          {this.props.bookingStatus.isCarWalkIn && <ModalCarWalkIn visible={true} />}
          {this.props.bookingStatus.isError && (
            <div id="error_text" className="alert alert-danger">
              <small className="red">
                <b>{t('too_slow.title')}</b> <br />
                {t('too_slow.1')} {this.randomInt(1, 5)} {t('too_slow.2')}{' '}
              </small>
            </div>
          )}
          <div className="button_box">
            {this.props.step > 0 && !this.isLoading && (
              <button
                data-event-category="bookings_section"
                data-event-action={this.backbuttonState === 'back' ? 'back_to' : 'edit'}
                data-event-label={this.backbuttonState === 'back' ? 'price_detail' : 'customer_detail'}
                disabled={this.isLoading}
                onClick={(e) => {
                  this.scroll();
                  dataLayer(e);
                  this.props.prev();
                  if (this.props.step === 2) this.props.resetTermCheckbox();
                }}
                className="btn btn-secondary"
                style={{ fontSize: '0.9rem' }}
              >
                {this.backButton}
              </button>
            )}
            <button
              id={this.buttonId}
              disabled={!this.isValid || this.isLoading}
              onClick={this.onClick}
              className="btn btn-primary"
            >
              {this.isLoading && <i className="fa fa-spinner fa-spin" style={{ marginRight: 10 }} />}
              {this.button}
            </button>
          </div>
        </div>
        <ConfirmCreditModal
          ref={(ref) => (this.confirmModal = ref)}
          onConfirm={() => {
            this.confirmModal.toggle();
            this.scroll();
            return this.props.next();
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  step: state.booking.step,
  customer: state.booking.customer,
  payment: state.booking.payment,
  params: queryString.parse(state.router.location.search),
  bookingStatus: state.booking.bookingStatus,
  errorValidations: state.booking.errorValidations,
  details: state.fetch.details,
  pricing: state.fetch.pricing,
  carID: state.router.location.pathname.replace('/car/', '')
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      next: actions.next,
      prev: actions.prev,
      createBooking: actions.createBooking,
      createInsurance: actions.createInsurance,
      validateForm: actions.validateForm,
      createDraftBooking: actions.createDraftBooking
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('summary', 'common')(SubmitButton));

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder('utf-8').encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
}
