import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import 'font-awesome/scss/font-awesome.scss';
import Media from 'react-responsive';
import Modal from '../../molecules/Modal';
import { withTranslation } from '../../../locales';

class SelectPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: -1
    };
  }

  _search = (text) => {
    if (text === '') return this.props.data;
    else {
      let matches = [];
      let rest = [];

      this.props.data.forEach((item) => {
        const isMatch =
          item.name[this.props.lang]
            .toLowerCase()
            .search(text ? text.toLowerCase() : text[this.props.lang].toLowerCase()) !== -1 || // NOTE: Search City if not find one then look for location.
          item.locations.filter((item) => item.name[this.props.lang].toLowerCase().search(text.toLowerCase()) !== -1)
            .length > 0; // NOTE: Search location.

        if (isMatch) {
          matches.push(item);
        } else {
          rest.push(item);
        }
      });

      const result = [...matches, ...rest];
      return result;
    }
  };

  _renderPopup = () => {
    if (this.props.focused)
      return (
        <div>
          <Media query={`(min-device-width: ${this.props.mobileWidth ? this.props.mobileWidth : '992'}px)`}>
            {this._renderDesktopList()}
          </Media>
          <Media query={`(max-device-width: ${this.props.mobileWidth ? this.props.mobileWidth : '992'}px)`}>
            {(matches) => (matches ? this._renderMobileList() : '')}
          </Media>
        </div>
      );
  };

  _renderMobileList = () => {
    return (
      <Modal active={this.props.focused} className="mobile" onClose={this.props.onBlur}>
        <div className="mobile-input">
          <h3 className="title">{this.props.mobilePlaceholder}</h3>
          {this.props.searchable ? (
            <div className="dh-input">
              <i className={'fa close fa fa-times-circle'} aria-hidden="true" onClick={this.props.onClearText} />
              <i className={`fa ${this.props.icon ? `fa ${this.props.icon}` : 'fa fa-search'}`} aria-hidden="true" />
              <input
                type="text"
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={(event) => {
                  this.props.onChange(event);
                  if (!event.target.value) return this.setState({ active: -1 });
                  this.setState({ active: 0 });
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="mobile-content-container">{this._renderList()}</div>
      </Modal>
    );
  };

  _renderDesktopList = () => {
    return <div className="desktop">{this._renderList()}</div>;
  };

  _renderList = () => {
    const dataList = this._search(this.props.value);
    let colWidth = {
      width: '100%',
      maxHeight: '500px'
    };
    const { active } = this.state;
    const { noExpand, t, lang } = this.props;

    return (
      <div className="dh-location-container" ref={(el) => (this.ScrollContainer = el)} style={colWidth}>
        {dataList.length > 0 ? (
          dataList.map((item, index) => {
            return item.locations.length === 0 ? (
              ''
            ) : (
              <div key={index} className="dh-item">
                <div
                  className="dh-title"
                  onClick={() => {
                    this.setState({ active: active === index ? -1 : index });
                  }}
                >
                  {item.name[lang]}
                  {active === index || noExpand ? (
                    <i className="fa fa-minus" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-plus" aria-hidden="true" />
                  )}
                </div>
                <div
                  className={`dh-list-container ${
                    active === index || dataList.length === 1 || noExpand ? 'active' : ''
                  }`}
                >
                  {item.locations.map((item, index) => {
                    return (
                      <div key={index}>
                        <span
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.onSelect(item);
                            this.setState({ active: 0 });
                          }}
                          className="dh-name"
                        >
                          {item.name[lang]}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <p className="not-found">{this.props.notFoundText ? this.props.notFoundText : t('not_found')}</p>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="dh-SelectPicker">
        {this.props.loading ? <i className="fa fa-spinner fa-pulse fa-3x select-loading" /> : ''}
        {this.props.value ? (
          <i className={'fa close fa fa-times-circle'} aria-hidden="true" onClick={this.props.onClearText} />
        ) : (
          ''
        )}

        <i className={`fa ${this.props.icon ? `fa ${this.props.icon}` : 'fa fa-search'}`} aria-hidden="true" />
        <Media query={`(min-device-width: ${this.props.mobileWidth ? this.props.mobileWidth : '992'}px)`}>
          <input
            type="text"
            placeholder={this.props.placeholder}
            value={this.props.value}
            onFocus={(event) => {
              this.props.onFocus(event);
            }}
            onClick={this.props.handleClick}
            onChange={(event) => {
              this.props.onChange(event);
              if (!event.target.value) return this.setState({ active: -1 });
              this.setState({ active: 0 });
            }}
          />
        </Media>
        <Media query={`(max-device-width: ${this.props.mobileWidth ? this.props.mobileWidth : '992'}px)`}>
          <div
            className="input"
            onClick={(event) => {
              this.props.onFocus(event);
            }}
          >
            {this.props.value ? this.props.value : this.props.placeholder}
          </div>
        </Media>
        {this._renderPopup()}
      </div>
    );
  }
}

SelectPicker.protoTypes = {
  onChange: PropTypes.func,
  searchable: PropTypes.bool,
  value: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  mobilePlaceholder: PropTypes.string,
  onSelect: PropTypes.func,
  data: PropTypes.array,
  handleClick: PropTypes.func,
  onClearText: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  loading: PropTypes.bool
};

export default withTranslation('search_bar')(SelectPicker);
