import React, { Component } from 'react'
import Modal from 'rc-dialog'

export default class InsuranceZeroExcess extends Component {
  state = {
    visible: false,
  }

  toggle = () => {
    window.toggleSticky()
    this.setState({
      visible: !this.state.visible,
    })
  }

  render() {
    return (
      <Modal
        closable
        destroyOnClose
        visible={this.state.visible}
        onClose={this.toggle}
        animation="fade"
        maskAnimation="fade"
        className="modal--insurance"
      >
        <div className="container pt-3 pb-3">
          <h3 className="typo__header text-primary mb-3">
            ประกันภัยแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ และ ประกันการโจรกรรม (Zero Excess)
          </h3>
          <p>
            กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น
            การคุ้มครองนี้ครอบคลุมเพียงตัวรถ กระจก ยาง และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น ภายใน
            หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า
            หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ
            เป็นต้น)
          </p>
        </div>
      </Modal>
    )
  }
}
