import React, { Component } from 'react';
import { INSURANCE_ZERO_EXCESS_BADGE } from '../../../variables/badge';
import { withTranslation } from '../../../locales';
import './style.scss';
class AdditionalValueProp extends Component {
  get isInter() {
    return ['inter', 'bigbrand'].includes(this.props.source);
  }
  render() {
    const { t } = this.props;
    return (
      <div className="addition_prop_wrapper">
        <div className="row">
          <div className="col-auto pt-1">
            <i className="flaticon-shield" />
            {this.isInter
              ? t('insurance.basic')
              : this.props.badges.includes(INSURANCE_ZERO_EXCESS_BADGE)
              ? t('insurance.full')
              : t('insurance.basic')}
          </div>
          {this.isInter ? (
            <div className="col-auto pt-2">
              <i className="flaticon-rental" />
              {t('counter')}
            </div>
          ) : (
            <div className="col-auto pt-2">
              <i className="flaticon-rental" />
              {t('delivery')}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('value_props')(AdditionalValueProp);
