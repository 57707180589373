import React from 'react';
import './style.scss';

export const ErrorBox = ({ isError, hideDesktop, errorMassage }) => {
  return (
    <div className={`error-box ${isError ? 'show' : ''} ${hideDesktop ? 'hide-desktop' : ''}`}>
      <div>
        <p>
          <i className="icon-cancel-circle" />
          {errorMassage ? errorMassage : 'ระบบเกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง'}
        </p>
      </div>
    </div>
  );
};
