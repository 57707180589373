import React, { Component } from 'react';
import moment from 'moment-timezone';
import alarmClockIcon from '../../../assets/icon/alarm_clock.png';
import './style.scss';
import { withTranslation } from '../../../locales';

class AlertCard extends Component {
  renderContent = ({ pickupDate }) => {
    const { t } = this.props;
    let isInFestivalRange = false;
    isInFestivalRange = [pickupDate].some((date) => {
      return (
        date.isBetween('2019-11-14T08:00:00+07:00', '2020-02-29T23:59:59+07:00') ||
        date.isBetween('2020-04-01T00:00:01+07:00', '2020-04-19T23:59:59+07:00')
      );
    });
    // if (pickupDate.isSameOrBefore('2019-04-01') && returnDate.isSameOrAfter('2019-04-16')) {
    //   isInFestivalRange = true
    // }

    if (isInFestivalRange)
      return (
        <React.Fragment>
          <div className="mr-2">
            <img src={alarmClockIcon} style={{ width: 30, height: 30 }} alt="รีบจอง" />
          </div>
          <div className="text-left">
            <span className="primary-text-alert">เวลาที่คุณเลือกเช่าเป็นช่วงเทศกาล</span>
            <span className="secondary-text-alert">
              <b>รีบจองตอนนี้ ก่อนราคาปรับสูงขึ้น</b>
            </span>
          </div>
        </React.Fragment>
      );
    else
      return (
        <React.Fragment>
          <img src={alarmClockIcon} style={{ width: 30, height: 30 }} alt="รีบจอง" />
          <span className="font-weight-bold">&nbsp; {t('warn_hurry')} </span> &nbsp;
          {t('warn_before')}
        </React.Fragment>
      );
  };

  render() {
    const { pickup_datetime, return_datetime } = this.props.cars;
    const pickupDate = moment(pickup_datetime);
    const returnDate = moment(return_datetime);
    return (
      <div className="alert alert-danger text-center mb-0 alert-animation alert-card" role="alert">
        {this.renderContent({ pickupDate, returnDate })}
      </div>
    );
  }
}
export default withTranslation('info_test')(AlertCard);
