import React from 'react';
import { ThemeProvider } from 'styled-components';

const defaultTheme = {
  primary: '#0078FF',
  name: 'drivehub',
  colors: {
    primary: '#0078FF',
    blue: '#0053ba',
    dark: '#30374b',
    interPrimary: '#00508d',
    localPrimary: '#ff8d41',
    inter: '#0053BA',
    local: '#ff8d41',
    brownishGrey: '#6f6f6f',
    lightBlue: '#e2eaf3',
    paleGrey: '#eef3f8',
    orangeish: '#ff8d41',
    scarlet: '#d0021b',
    pale: '#ffddd3',
    veryLightBlue: '#d8edfd',
    white: '#fff',
    black: '#000'
  },
  shadow: '0 3px 6px 0 rgba(0, 0, 0, 0.23)',

  badge: {
    fontSize: {
      small: '8px',
      medium: '12px',
      large: '16px'
    },
    height: {
      small: '19px',
      medium: '24px',
      large: '24px'
    }
  }
};

interface ThemeInterface {
  children: React.ReactChild;
  theme?: any;
}

const Theme: React.FC<ThemeInterface> = ({ children, theme = defaultTheme }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
