import React from 'react';
import MediaQuery from 'react-responsive';
import { withTranslation } from '../../locales';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import * as actions from '../../ducks/actions/booking';
import { REGISTER_PROMOTION } from '../../variables/features';
import './style.scss';

const hoc = (Component) => {
  class Wrapped extends React.Component {
    onHashChange = () => {
      // console.log(window.location.hash);
      if (window.location.hash === '') {
        return this.props.prev();
      }
      const prev = parseInt(window.location.hash.replace('#', ''), 10);
      if (this.props.step === 0) {
        return this.props.skip(prev);
      }
      const step = this.props.step;
      if (prev === step) {
        return this.props.prev();
      }
      if (prev < step) {
        return this.props.prev();
      }
      if (prev > step) {
        return this.props.prev();
      }
    };
    componentDidMount = () => {
      if (window.location.hash !== '') {
        let prev = parseInt(window.location.hash.replace('#', ''), 10);
        if (isNaN(prev)) {
          window.location.hash = '0';
          prev = 0;
        }
        if (prev > 1) {
          this.setState({ openModal: false });
          if (this.props.customer.phone_number === '') {
            return this.props.skip(1);
          }
        }
        this.props.skip(prev);
      } else {
        window.location.hash = '0';
      }
      window.addEventListener('hashchange', this.onHashChange);
    };
    componentDidUpdate() {
      return true;
    }
    getSnapshotBeforeUpdate(prevProps) {
      if (prevProps.step !== this.props.step) {
        window.history.pushState(null, null, `${window.location.search}#${this.props.step}`);
      }
      return true;
    }

    componentWillUnmount = () => {
      window.removeEventListener('hashchange', this.onHashChange);
    };

    get popupContent() {
      if (['inter', 'bigbrand'].includes(this.props.params.source))
        return 'โปรดตรวจสอบข้อมูลการจองอีกครั้งและกดปุ่ม ชำระเงินและยืนยันการจอง เพื่อทำการจอง !';
      return 'โปรดตรวจสอบข้อมูลการจองอีกครั้ง และกดปุ่ม “ยืนยันการจอง” เพื่อทำการจอง !';
    }

    get popupDriver() {
      const { t } = this.props;
      return (
        <span>
          {t('please_insert')}
          <br /> {t('driver_detail')}
        </span>
      );
    }
    render() {
      const isInter = ['inter', 'bigbrand'].includes(this.props.params.source);
      return (
        <React.Fragment>
          <MediaQuery minWidth={769}>
            <div style={{ position: 'relative' }}>
              <DesktopWrapper {...this.props} />
              {this.props.step === 1 && (
                <span className={`popup text-center ${isInter ? 'popup--driver inter' : 'popup--driver'}`}>
                  {this.popupDriver}
                </span>
              )}
              {this.props.step === 2 && <div className="gray--bg" />}
              {this.props.step === 2 && (
                <span className={`popup ${REGISTER_PROMOTION ? 'popup--register-promo' : ''}`}>
                  {this.popupContent}
                </span>
              )}
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <MobileWrapper {...this.props} />
          </MediaQuery>
        </React.Fragment>
      );
    }
  }

  class DesktopWrapper extends React.Component {
    state = {
      initial: false,
      leftToRight: false,
      rightToLeft: false
    };
    componentDidMount = () => {
      setTimeout(() => {
        this.setState({ initial: false });
      }, 500);
    };

    componentDidUpdate() {
      return true;
    }

    getSnapshotBeforeUpdate(prevProps) {
      if (prevProps.step === this.props.step) return true;
      if (prevProps.step < this.props.step) {
        setTimeout(() => {
          this.setState({ rightToLeft: false });
        }, 500);
        this.setState({ rightToLeft: true });
      } else {
        setTimeout(() => {
          this.setState({ leftToRight: false });
        }, 500);
        this.setState({ leftToRight: true });
      }
      return true;
    }
    get wrapperClasses() {
      const result = [];
      if (this.state.initial) {
        result.push('initial');
      }
      if (this.state.rightToLeft) {
        result.push('right-to-left');
      }
      if (this.state.leftToRight) {
        result.push('left-to-right');
      }

      return result.join(' ');
    }
    render() {
      return (
        <div className="desktop--wrapper">
          <div className={this.wrapperClasses}>
            <Component {...this.props} />
          </div>
        </div>
      );
    }
  }

  class MobileWrapper extends React.Component {
    state = {
      animOut: false,
      animUp: false,
      animDown: false,
      animIn: false,
      animInit: true,
      openModal: false
    };

    componentDidUpdate() {
      return false;
    }

    getSnapshotBeforeUpdate(prevProps) {
      if (this.props.step >= 1) {
        const faq = document.getElementsByClassName('call-to-action-button')[0];
        faq && faq.classList.add('hide');
      } else {
        const faq = document.getElementsByClassName('call-to-action-button')[0];
        faq && faq.classList.remove('hide');
      }
      if (this.props.step !== prevProps.step) {
        const nextState = {};
        if (this.props.step === 0 && prevProps.step > 0) {
          setTimeout(() => {
            this.setState({ animDown: false, openModal: false });
          }, 500);
          nextState.animDown = true;
        }
        if (this.props.step > 0 && prevProps.step === 0) {
          setTimeout(() => {
            this.setState({ animUp: false });
          }, 500);
          nextState.animUp = true;
        }

        if (prevProps.step !== 0) {
          if (this.props.step > 0) {
            if (prevProps.step > this.props.step) {
              nextState.animIn = true;
              setTimeout(() => {
                this.setState({ animIn: false });
              }, 500);
            } else {
              nextState.animOut = true;
              setTimeout(() => {
                this.setState({ animOut: false });
              }, 500);
            }
            this.setState(nextState);
          } else if (this.props.step !== 0) {
            nextState.animInit = true;
            nextState.animOut = true;
            this.setState(nextState);
            setTimeout(() => {
              this.setState({ animOut: false });
            }, 500);
          }
        }

        if (this.props.step === 0) {
          document.body.classList.remove('noscroll');
        } else {
          nextState.openModal = true;
          setTimeout(() => {
            document.body.classList.add('noscroll');
            let element = document.getElementsByClassName('modal-header--placeholder')[0];
            if (['inter', 'bigbrand'].includes(this.props.params.source)) {
              if (this.props.step === 2) {
                element = document.getElementsByClassName('step-detail--wrapper')[0];
                element.style.paddingTop = '30px';
              } else {
                document.getElementsByClassName('step-detail--wrapper')[0].style.paddingTop = '0px';
              }
            }
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'start'
            });
          }, 500);
        }
        this.setState(nextState);
        return null;
      }
      return null;
    }
    get title() {
      const params = queryString.parse(window.location.search);
      switch (this.props.step) {
        case 1: {
          return 'กรอกข้อมูลเพื่อทำการจอง';
        }
        case 2: {
          return params.source === 'local' ? 'ตรวจสอบข้อมูล' : 'ตรวจสอบข้อมูลและชำระเงิน';
        }
        default: {
          return '';
        }
      }
    }
    render() {
      return (
        <div>
          <div
            className={`mobile-modal--wrapper
            ${this.state.animIn ? 'mobile-modal--animation-in' : ''}
            ${this.state.animOut ? 'mobile-modal--animation-out' : ''}
            ${this.state.openModal ? 'mobile-modal' : ''}
            ${this.state.animDown ? 'mobile-modal--animation-down' : ''}
            ${this.state.animUp ? 'mobile-modal--animation-up' : ''}
            `}
          >
            {this.props.step > 0 && (
              <React.Fragment>
                <div className="modal-header">
                  <h1>{this.title}</h1>
                  <small>{`(ขั้นตอนที่ ${this.props.step + 2}/4)`}</small>
                  <i className="flaticon-cancel" onClick={() => this.props.skip(0)} />
                </div>

                <div className="modal-header--placeholder" />
              </React.Fragment>
            )}
            <Component {...this.props} />
          </div>
        </div>
      );
    }
  }
  const mapStateToProps = (state) => ({
    step: state.booking.step,
    customer: state.booking.customer,
    params: queryString.parse(state.router.location.search)
  });
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ prev: actions.prev, next: actions.next, skip: actions.skip }, dispatch);
  return connect(mapStateToProps, mapDispatchToProps)(withTranslation('summary')(Wrapped));
};

export default hoc;
