import React from 'react';
import moment from 'moment';
import ContactModal from '../../atoms/ContactModal';
import './style.scss';
import { dataLayer } from '@ui/utils/datalayer';

class RentalDetail extends React.PureComponent {
  get durationText() {
    const { booking } = this.props;
    const { duration_days, duration_hours } = booking;
    let result = '';
    if (duration_days > 0) {
      result += ` ${duration_days} วัน`;
    }
    if (duration_hours > 0) {
      result += ` ${duration_hours} ชั่วโมง`;
    }
    return result;
  }
  get paymentText() {
    const { source } = this.props;
    if (source === 'local') {
      return 'บริษัทรถเช่าจะมีการเรียกเก็บค่าเช่าส่วนแรกล่วงหน้า ซึ่งหักจากราคารวม';
    }
    return 'คุณได้ทำการชำระเงินแล้ว';
  }
  get depositPaymentText() {
    const { source } = this.props;
    if (source === 'local') return 'ชำระ ณ วันรับรถโดยใช้เงินสด, โอนเงิน, บัตรเครดิต หรือตามที่บริษัทผู้ให้เช่ากำหนด';
    return 'ชำระ ณ วันรับรถโดยใช้บัตรเครดิตเท่านั้น';
  }
  render() {
    const { pricing, booking, source, insurance } = this.props;
    const { pickup_datetime, return_datetime, note } = booking;
    const {
      deposit,
      total_price,
      total_grace_period,
      grand_total_price,
      delivery_price,
      pickup_price,
      discount,
      coupon_code
    } = pricing;

    const pickupDate = moment(pickup_datetime).format('DD MMM YYYY');
    const pickupTime = moment(pickup_datetime).format('HH:mm น.');
    const returnDate = moment(return_datetime).format('DD MMM YYYY');
    const returnTime = moment(return_datetime).format('HH:mm น.');
    const pickupReturn = delivery_price + pickup_price;

    return (
      <React.Fragment>
        <div className="rentaldetail">
          <div className="padding__inner">
            <h3>ข้อมูลการเช่ารถ</h3>
            <h4>สถานที่รับรถ/คืนรถ</h4>
            <p className="border__bottom">
              {booking.location} ({booking.city})
            </p>
            <div className="pickup__return border__bottom">
              <div>
                <h4>วันที่รับรถ</h4>
                <p>{pickupDate}</p>
                <p>{pickupTime}</p>
              </div>
              <div className="border__left">
                <h4>วันที่คืนรถ</h4>
                <p>{returnDate}</p>
                <p>{returnTime}</p>
              </div>
            </div>
            <div className="border__bottom">
              <h4>หมายเหตุ</h4>
              <p>{note}</p>
            </div>
            <div>
              <h4>ค่าเช่า</h4>
              <div className="pair__full">
                <p>ค่าเช่าสำหรับ {this.durationText}</p>
                <p>฿{(total_price + total_grace_period).toLocaleString()}</p>
              </div>
              <div className="pair__full">
                <p>ค่าบริการรับ-ส่งรถ</p>
                <p>฿{pickupReturn.toLocaleString()}</p>
              </div>
              {source === 'inter' && insurance && (
                <div className="pair__full">
                  <p>{insurance.name}</p>
                  <p className="free">฿{insurance.total_price.toLocaleString()}</p>
                </div>
              )}
              {coupon_code !== '' && (
                <div className="pair__full mb16">
                  <p>คูปองส่วนลด ({coupon_code})</p>
                  <p className="discount">฿{discount.toLocaleString()}</p>
                </div>
              )}
            </div>
          </div>
          <div className="pair__full pair__full--total mb0">
            <div>
              <p>ราคารวม</p>
              <small className={source === 'inter' ? 'free' : ''}>{this.paymentText}</small>
            </div>
            <p className="total">฿{grand_total_price.toLocaleString()}</p>
          </div>
          <div className="deposit">
            <div className="pair__full pair__full--deposit">
              <div>
                <small>
                  <i className="flaticon-information" /> ราคานี้ยังไม่รวม
                </small>
                <p>ค่ามัดจำเพื่อประกันความเสียหาย</p>
                <small style={{ fontSize: 10 }}>(ได้รับคืนหลังเสร็จสิ้นการเช่า)</small>
              </div>
              <p className="bold">฿{deposit.toLocaleString()}</p>
            </div>
            <div className="pair__full pair__full--deposit border__bottom pb16">
              <p>ค่าบริการนอกเวลาทำการ</p>
              <p className="bold">ตามที่บริษัทกำหนด</p>
            </div>
            <small>{this.depositPaymentText}</small>
          </div>
          <div className="padding__inner">
            <div className="pair__full center">
              <small style={{ fontSize: 12 }}>
                หากต้องการแก้ไขการจองกรุณาติดต่อ
                <ContactModal>
                  <span style={{ marginLeft: 4, textDecoration: 'underline', color: '#0053ba' }} rel="nofollow">
                    Call Center
                  </span>
                </ContactModal>
              </small>
              <ContactModal>
                <small
                  data-event-category="bookingstatus_check_section"
                  data-event-action="cancel_booking"
                  data-event-label="call_center"
                  style={{ fontSize: 14, textDecoration: 'underline', color: '#30374b' }}
                  onClick={(e) => dataLayer(e)}
                >
                  ยกเลิกการจอง
                </small>
              </ContactModal>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RentalDetail;
