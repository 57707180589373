import React from 'react';
import propTypes from 'prop-types';
import InterLocalBadge from '../../atoms/InterLocalBadge';
import LogoBox from '../../atoms/LogoBox';
import LoadingSpinner from '../../atoms/LoadingSpinner';
import InterIcon from '../../../assets/icon/inter-icon.svg';
import LocalIcon from '../../../assets/icon/local-icon.svg';
import mobileGuide2 from '../../../assets/images/mobile_tutorial2.jpg';
import animatedMobileGuide from '../../../assets/images/filter-guide-large.gif';
import { withTranslation } from '../../../locales';
import './style.scss';

const INTER_LOGOS = [
  {
    img: 'brand_logo_Sixt.png',
    styles: ''
  },
  {
    img: 'brand_logo_National.png',
    styles: ''
  },
  {
    img: 'brand_logo_BizCar.png',
    styles: ''
  },
  {
    img: 'brand_logo_Chic.png',
    styles: { maxHeight: 25 }
  },
  {
    img: 'brand_logo_hertz.png',
    styles: ''
  },
  {
    img: 'plus5.png',
    styles: ''
  }
];

const LOCAL_LOGOS = [
  {
    img: 'brand_logo_WorldRent.png',
    styles: { maxHeight: 35 }
  },
  {
    img: 'brand_logo_ChiangMai_wheel.png',
    styles: { maxHeight: 35 }
  },
  {
    img: 'brand_logo_True_leasing.png',
    styles: { maxHeight: 30 }
  },
  {
    img: 'brand_logo_KPN.png',
    styles: { maxHeight: 25 }
  },
  {
    img: 'brand_logo_Luv_drive.png',
    styles: { maxHeight: 35 }
  },
  {
    img: 'plus500.png',
    styles: ''
  }
];

const FilterGuideBox = (props) => {
  return (
    <div className="filter-guide__box">
      {/* Begin: Filter Guide Header */}
      <div className="filter-guide__header">
        <h4>ประเภทบริษัทรถเช่า</h4>
        <span className="close-guide" onClick={props.onClose}>
          <i className="flaticon-cancel" /> ปิด
        </span>
      </div>
      {/* End: Filter Guide Header */}

      {/* Begin: Filter Guide Body */}
      <div className="filter-guide__body">
        {/* Begin: Filter Guide Body Inter (Left) */}
        <div className="filter-guide__body--left">
          <div className="body__title">
            <InterLocalBadge icon={InterIcon} type="inter" />
            <span className="title-description">คือ บริษัทรถเช่ารายใหญ่</span>
          </div>
          <div className="body__logo">
            <LogoBox logos={INTER_LOGOS} />
          </div>
          <div className="body__content">
            <ul>
              <li>
                <div className="content__box">
                  <i className="flaticon-checked-circle" />
                  <span>
                    ใช้บัตรเครดิตในการเช่ารถ<b>เท่านั้น</b>
                  </span>
                </div>
              </li>
              <li>
                <div className="content__box">
                  <i className="flaticon-checked-circle" />
                  <span>
                    เลือกซื้อ<b>ประกันเสริม</b>ได้
                  </span>
                </div>
              </li>
              <li>
                <div className="content__box">
                  <i className="flaticon-checked-circle" />
                  <span>
                    รับรถได้ที่
                    <b>
                      เคาน์เตอร์สนามบิน <span className="d-md-block">และจุดนัดพบในสนามบิน</span>
                    </b>
                  </span>
                </div>
              </li>
              <li>
                <div className="content__box">
                  <i className="flaticon-checked-circle" />
                  <span>
                    คุณภาพและบริการที่ได้<b>มาตรฐานสากล</b>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* End: Filter Guide Body Inter (Left) */}

        {/* Begin: Filter Guide Body Local (right) */}
        <div className="filter-guide__body--right">
          <div className="body__title">
            <InterLocalBadge icon={LocalIcon} type="local" />
            <span className="title-description">คือ บริษัทรถเช่าท้องถิ่น</span>
          </div>
          <div className="body__logo">
            <LogoBox logos={LOCAL_LOGOS} />
          </div>
          <div className="body__content">
            <ul className="orange">
              <li>
                <div className="content__box">
                  <i className="flaticon-checked-circle" />
                  <span>
                    ชำระค่าเช่า, มัดจำ ได้ด้วยเงินสด
                    <b>
                      ไม่ต้องใช้ <span className="d-md-block">บัตรเครดิตในการเช่า</span>
                    </b>
                  </span>
                </div>
              </li>
              <li>
                <div className="content__box">
                  <i className="flaticon-checked-circle" />
                  <span>
                    <b>ต้องส่งเอกสารก่อนเช่า</b> เช่น ตั๋วเครื่องบิน,{' '}
                    <span className="d-md-block"> สลิปเงินเดือน (กรณีเช่าใน กทม.) </span>
                  </span>
                </div>
              </li>
              <li>
                <div className="content__box">
                  <i className="flaticon-checked-circle" />
                  <span>
                    จองคันไหน<b>ได้คันนั้น</b>
                  </span>
                </div>
              </li>
              <li>
                <div className="content__box">
                  <i className="flaticon-checked-circle" />
                  <span>
                    บริการรับส่งรถ <b>ทั้งสนามบินและนอกสถานที่</b>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* End: Filter Guide Body Local (right) */}
      </div>
      {/* End: Filter Guide Body */}
    </div>
  );
};

export default withTranslation('info_test', 'filter')(FilterGuideBox);

export class Mobile extends React.Component {
  state = {
    step: 0,
    isLoadingGifImg: true,
    isLoadingImg: true
  };
  static propTypes = {
    onClose: propTypes.func.isRequired
  };
  get footer() {
    if (this.state.step === 0) {
      return (
        <React.Fragment>
          <button className="btn btn-link" onClick={() => this.props.onClose()}>
            ข้ามไปก่อน
          </button>
          <button className="btn btn-primary" onClick={() => this.setState({ step: 1 })}>
            อ่านต่อ
          </button>
        </React.Fragment>
      );
    }
    return (
      <button className="btn btn-primary" onClick={() => this.props.onClose()}>
        ปิดหน้าต่าง
      </button>
    );
  }
  get content() {
    if (this.state.step === 0) {
      return (
        <React.Fragment>
          <h2>
            <b>เลือกประเภทบริษัทรถเช่าง่ายๆ</b>
          </h2>
          <p>
            Drivehub มีทั้งบริษัทรถเช่ารายใหญ่และท้องถิ่น <br />
            เช่ารถได้แม้ว่าคุณมีหรือไม่มีบัตรเครดิต
          </p>
        </React.Fragment>
      );
    }
    return '';
  }
  get image() {
    if (this.state.step === 0) {
      return (
        <img
          className="animated-guide__image"
          src={animatedMobileGuide}
          onLoad={() => this.setState({ isLoadingGifImg: false })}
          alt="เลือกประเภทบริษัทรถเช่าง่ายๆ"
        />
      );
    }
    return (
      <div>
        <img
          className="padding mobile-guide__image"
          src={mobileGuide2}
          onLoad={() => this.setState({ isLoadingImg: false })}
          alt="บริการ"
        />
      </div>
    );
  }
  render() {
    return (
      <div className="filter-guide__box--mobile">
        {this.image}
        {this.state.step === 0 ? (
          this.state.isLoadingGifImg ? (
            <LoadingSpinner />
          ) : (
            <div className="content">
              <section>{this.content}</section>
              <footer>{this.footer}</footer>
            </div>
          )
        ) : this.state.isLoadingImg ? (
          <LoadingSpinner />
        ) : (
          <div className="content">
            <section>{this.content}</section>
            <footer>{this.footer}</footer>
          </div>
        )}
      </div>
    );
  }
}
