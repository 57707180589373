import React, { createRef, PureComponent } from 'react';
import { DefaultDropdownAuth, DropdownAuth, DropdownAuthWelcome } from './auth-dropdown';
import { onClickLogin, onClickLogout, onClickRegister, ConsumerInfo } from '../../components/context/auth';
import { WEB_LEGACY_LINK } from '../../config';
import { dataLayer } from '@ui/utils/datalayer';
export const AuthenticationSectionMobile: React.FC<{
  isAuth?: boolean;
  prefix: string;
  host: string;
}> = ({ isAuth, prefix }) => {
  return <li> บัญชีของฉัน {isAuth ? <AuthMenu prefix={prefix} /> : <DefaultAuthMenu prefix={prefix} />}</li>;
};
export class AuthenticationSectionDesktop extends PureComponent<{
  isAuth?: boolean;
  prefix: string;
  consumer: ConsumerInfo;
  isOpenWelcomeModal: boolean;
  toggleWelcomeModal: () => void;
  isAuthFetching: boolean;
  host: string;
}> {
  navLinkRef = createRef<HTMLAnchorElement>();
  handleHoverState = (e: any) => {
    if (this.navLinkRef.current === e.target) {
      this.props.toggleWelcomeModal();
      window.removeEventListener('mouseover', this.handleHoverState);
    }
  };
  componentDidMount() {
    this.props.isAuth && window.addEventListener('mouseover', this.handleHoverState, false);
  }
  componentWillUnmount() {
    window.removeEventListener('mouseover', this.handleHoverState);
  }

  render() {
    const { isAuth, prefix, consumer, isOpenWelcomeModal, isAuthFetching } = this.props;
    return (
      <li className="nav-item dropdown dropdown--auth">
        <span
          className={`nav-link ${isAuthFetching ? 'fetching' : ''}`}
          onClick={(e) => {
            dataLayer(e);
            isAuth ? (window.location.href = `/${prefix}/user/profile`) : onClickRegister(prefix);
          }}
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          data-event-category="header_section"
          data-event-action="click_menu"
          data-event-label={isAuth ? 'my_account' : 'สมัครสมาชิก/ลงชื่อเข้าใช้'}
          aria-haspopup="true"
          aria-expanded="false"
          ref={this.navLinkRef}
        >
          {isAuth ? 'บัญชีของฉัน' : 'สมัครสมาชิก/ลงชื่อเข้าใช้'}
        </span>
        <div
          className={`dropdown-menu ${isAuth ? 'authenticated' : ''} ${
            isOpenWelcomeModal ? 'dropdown-menu--welcome show' : ''
          }
          ${isAuthFetching ? 'd-none' : ''}`}
          aria-labelledby="navbarDropdown"
        >
          <div className="dropdown-link">
            {isOpenWelcomeModal ? (
              <DropdownAuthWelcome prefix={prefix} consumer={consumer} />
            ) : (
              <DropdownAuthSection prefix={prefix} isAuth={isAuth} consumer={consumer} />
            )}
          </div>
        </div>
      </li>
    );
  }
}

export const DropdownAuthSection: React.FC<{
  isAuth?: boolean;
  prefix: string;
  consumer: ConsumerInfo;
}> = ({ isAuth, prefix, consumer }) => {
  return isAuth ? <DropdownAuth prefix={prefix} consumer={consumer} /> : <DefaultDropdownAuth prefix={prefix} />;
};

const AuthMenu: React.FC<{ prefix: string }> = ({ prefix }) => {
  return (
    <ul>
      <li>
        <a id="menu-user-profile" href={`${WEB_LEGACY_LINK}/${prefix}/user/profile`} className="auth-icon">
          <i className="icon-auth"></i> แก้ไขข้อมูลส่วนตัว
        </a>
      </li>
      <li>
        <a id="menu-user-password" href={`${WEB_LEGACY_LINK}/${prefix}/user/change-password`} className="auth-icon">
          <i className="icon-key"></i> แก้ไขรหัสผ่าน
        </a>
      </li>
      <li>
        <a id="menu-user-booking" href={`${WEB_LEGACY_LINK}/${prefix}/user/booking`} className="auth-icon">
          <i className="icon-doc-text-inv"></i> การเช่ารถของฉัน
        </a>
      </li>

      <li>
        <a
          id="menu-user-social-networks"
          href={`${WEB_LEGACY_LINK}/${prefix}/user/social-networks`}
          className="auth-icon"
        >
          <i className="icon-chain" style={{ paddingRight: '0.25rem' }} />
          การเชื่อมต่อ
        </a>
      </li>

      <li>
        <a
          id="menu-user-logout"
          onClick={() => {
            onClickLogout();
          }}
          href={`#logout`}
          className="auth-icon"
        >
          <i className="icon-logout"></i> ออกจากระบบ
        </a>
      </li>
    </ul>
  );
};
const DefaultAuthMenu: React.FC<{ prefix: string }> = ({ prefix }) => {
  return (
    <ul>
      <li>
        <a
          id="menu-sign-up"
          onClick={() => {
            onClickRegister(prefix);
          }}
          href={`#register`}
          className="auth-icon"
        >
          <i className="icon-auth"></i> สมัครสมาชิก
        </a>
      </li>
      <li>
        <a
          id="menu-sign-in"
          onClick={() => {
            onClickLogin(prefix);
          }}
          href={`#login`}
          className="auth-icon"
        >
          <i className="icon-login"></i> เข้าสู่ระบบ
        </a>
      </li>
    </ul>
  );
};

export default AuthenticationSectionMobile;
