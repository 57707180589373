/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import { LEGACY_WEB } from '../../../variables/API';

const ErrorPage = () => {
  const Wrapper = styled.section`
    width: 100%;
    height: 100%;
    padding: 4em;
    background: #f34541;
    top: 0;
    position: absolute;
    overflow: visible;
    z-index: 99;
  `;
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  const ErrorType = styled.div`
    text-align: center;
    color: #fff;
    font-size: 90px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
  `;
  const ErrorMessage = styled.div`
    margin: 20px 0;
    padding: 10px;
    background-color: #f34541;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    line-height: 1.42857143;
  `;
  const Button = styled.a`
    color: #fff;
    border-radius: 0;
    display: block;
    width: 100%;
    background-color: #d9534f;
    border-color: #d43f3a;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    align-self: center;
    @media (min-width: 700px) {
      width: 50%;
    }
  `;
  return (
    <Wrapper>
      <Container>
        <ErrorType>
          <i className="fa fa-question-circle" />
          <span>404</span>
        </ErrorType>
        <ErrorMessage>Ooops! We can't find what you're looking for.</ErrorMessage>
        <Button href={LEGACY_WEB}>
          <i className="fa fa-chevron-circle-left" />
          &nbsp;Click to go back
        </Button>
      </Container>
    </Wrapper>
  );
};
export default ErrorPage;
