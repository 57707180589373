/* eslint-disable */
import React from 'react'
import { observer } from 'mobx-react'
import PaymentTypeStore from './PaymentTypeStore'
import IdCardIcon from '../../../../assets/icon/id_card.svg'
import DrivingLicenseIcon from '../../../../assets/icon/driving_lic.svg'
import DocumentIcon from '../../../../assets/icon/documents.svg'
import CreditCardIcon from '../../../../assets/icon/creditcard.svg'
import CreditSymbol from '../../../../assets/icon/credit_symbol.png'
import { CAR_DETAILS } from '../../../../variables/page'
const question = {
  icon: 'flaticon-document',
  question: 'เอกสารต้องใช้อะไรบ้าง?',
  answer: action => {
    class DocumentQuestion extends React.Component {
      componentDidMount = () => {
        const { source } = action
        if (['inter', 'bigbrand'].includes(source)) {
          this.props.store.selectType('credit')
        }
      }
      render() {
        const type = this.props.store.type
        const select = type => this.props.store.selectType(type)
        const { active } = action
        return (
          <div className="faq">
            {active === CAR_DETAILS ? (
              ''
            ) : (
              <div className="row justify-content-around tabs no-gutters">
                <div className="col-sm">
                  {/* FIXME:  */}
                  <button
                    onClick={() => select('cash')}
                    className={`btn ${type === 'cash' ? 'active' : ''} faq_tab`}
                  >
                    ชำระด้วยเงินสด หรือ <br />
                    บัตรเครดิต ณ วันรับรถ
                  </button>
                </div>
                <div className="col-sm">
                  <button
                    onClick={() => select('credit')}
                    className={`btn ${type === 'credit' ? 'active' : ''} faq_tab`}
                  >
                    ชำระด้วยบัตรเครดิตบนหน้าเว็บ*
                  </button>
                </div>
              </div>
            )}

            {type === 'cash' && (
              <div style={{ marginBottom: 105 }}>
                <p>
                  ทางบริษัทรถเช่าอาจเรียกเก็บค่าเช่าล่วงหน้าเพื่อล็อคคิวรถเป็นจำนวนเงิน 500-1000 บาท{' '}
                </p>
                <div className="row justify-content-center">
                  {/* FIXME:  */}
                  <DocumentBlock
                    index={1}
                    title="บัตรประชาชน หรือ Passport"
                    description="อายุไม่น้อยกว่า 21 ปี และบัตรยังไม่หมดอายุ"
                    src={IdCardIcon}
                  />
                  <DocumentBlock
                    index={2}
                    title="ใบขับขี่"
                    description="ยังไม่หมดอายุ และได้รับมาไม่ต่ำกว่า 1 ปี"
                    src={DrivingLicenseIcon}
                  />
                  <DocumentBlock
                    index={3}
                    title="เอกสารประกอบอื่นๆ"
                    description="อาจมีการขอเอกสารของผู้เช่าเพิ่มเติมจากบริษัทรถเช่า"
                    src={DocumentIcon}
                  />
                </div>
                <div className="faq_more_info mt-5">
                  {/* FIXME:  */}
                  <a
                    href="https://www.drivehub.co/th/documents"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="more_info_box"
                  >
                    ดูเอกสารที่ต้องใช้แบ่งตามจังหวัดและลักษณะการเช่า{' '}
                    <i className="flaticon-next more_info_icon" />
                  </a>
                </div>
              </div>
            )}
            {type === 'credit' && (
              <div>
                <div className="row justify-content-center">
                  {/* FIXME:  */}
                  <DocumentBlock
                    index={1}
                    title="บัตรประชาชน หรือ Passport"
                    description="อายุไม่น้อยกว่า 23 ปี และบัตรยังไม่หมดอายุ"
                    src={IdCardIcon}
                  />
                  <DocumentBlock
                    index={2}
                    title="ใบขับขี่**"
                    description="ยังไม่หมดอายุ และได้รับมาไม่ต่ำกว่า 1 ปี"
                    src={DrivingLicenseIcon}
                  />
                  <DocumentBlock index={3} title="บัตรเครดิต***" src={CreditCardIcon} />
                </div>
                <div className="faq_note mt-3">
                  <u>หมายเหตุ</u>
                  {/* FIXME:  */}
                  <div>
                    *เฉพาะรถที่มีสัญลักษณ์บัตรเครดิตเท่านั้น{' '}
                    <img src={CreditSymbol} height="47" alt="Credit Card" />
                  </div>
                  <div style={{ lineHeight: '1.5' }}>
                    **ในกรณีที่ผู้เช่า/ผู้ขับขี่ถือสัญชาติอื่นที่ไม่ใช่สัญชาติไทย
                    จะต้องใบอนุญาตขับรถระหว่างประเทศ
                    หรือใบอนุญาตขับรถของประเทศที่ทางประเทศไทยอนุญาตให้ใช้เพื่อการขับขี่ภายในประเทศไทยได้
                    แต่ใบอนุญาตดังกล่าวจะต้องมีชื่อของผู้ขับขี่เป็นภาษาอังกฤษกำกับอยู่
                  </div>
                  <div style={{ lineHeight: '1.5' }}>
                    ***ต้องมีวงเงินในบัตรเพียงพอกับสำหรับค่าเช่าและค่ามัดจำเพื่อประกันความเสียหาย
                    ที่บริษัทรถเช่ากำหนด
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      }
    }

    DocumentQuestion = observer(DocumentQuestion)
    return <DocumentQuestion store={PaymentTypeStore} />
  },
}

const DocumentBlock = ({ src = '', title = '', description = '', index = '' }) => (
  <div className="col-sm-4 ">
    <div className="block">
      <div style={{ display: 'flex' }}>
        <span className="count">{index}</span>
        <img className="m-auto" src={src} height="35" width="48" alt="Content" />
      </div>
      <h6 className="document_title">{title}</h6>
      <small>{description}</small>
    </div>
  </div>
)

export default question
