import React, { Component } from 'react';
import moment from 'moment-timezone';
import './style.scss';
import { withTranslation } from '../../../locales';

class BookingInfo extends Component {
  render() {
    const { carTitle, location, pickupDatetime, returnDatetime, t } = this.props;
    return (
      <div className="booking_info_wrapper">
        <p id="booking_info_title">{t('booking_detail')}</p>
        <div className="booking_info_row">
          <div className="booking_info_col">
            <small>{t('car_selected')}</small>
            <p>{carTitle}</p>
          </div>
          <div className="booking_info_col">
            <small>{t('pickup_at')}</small>
            <p>{location}</p>
          </div>
        </div>
        <div className="booking_info_row">
          <div className="booking_info_col">
            <small>{t('pickup')}</small>
            <p>{moment(pickupDatetime).format('DD MMM YYYY HH:mm')}</p>
          </div>
          <div className="booking_info_col">
            <small>{t('return')}</small>
            <p>{moment(returnDatetime).format('DD MMM YYYY HH:mm')}</p>
          </div>
        </div>
      </div>
    );
  }
}

BookingInfo.defaultProps = {
  carTitle: '-',
  location: '-',
  pickupDatetime: moment().format('DD MMM YYYY HH:mm'),
  returnDatetime: moment()
    .add(1, 'days')
    .format('DD MMM YYYY HH:mm')
};

export default withTranslation('common')(BookingInfo);
