import React, { Component } from 'react';
import propTypes from 'prop-types';
import FilterCheckbox from '../../molecules/FilterCheckbox';
import { LoadingBox } from '../../atoms/Loading';
import LoadingSVG from '../../../assets/icon/loading.svg';
import './style.scss';

class FilterDealer extends Component {
  static propTypes = {
    dealers: propTypes.array.isRequired,
    selected: propTypes.array,
    fetch: propTypes.bool
  };
  static defaultProps = {
    dealers: [],
    selected: [],
    fetch: true
  };

  get dealers() {
    return this.props.dealers;
  }

  toggleCheckbox = (id) => {
    this.props.onChange(id);
  };

  render() {
    const { selected, fetch } = this.props;
    const dealers = this.dealers;

    return (
      <div className="filter-dealers">
        {fetch ? (
          <div className="ml-auto mr-auto" style={{ height: '150px' }}>
            <LoadingBox bg={LoadingSVG} dark />
          </div>
        ) : (
          <React.Fragment>
            {dealers.length !== 0 ? (
              <FilterCheckbox
                data={dealers}
                selected={selected}
                handleCheckboxChange={(event) => this.toggleCheckbox(event)}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FilterDealer;
