import React, { Component } from 'react';
import Panel from '../../molecules/Panel';
import visalogo from '../../../assets/images/visa-mastercard-logo.png';
import InsuranceTable from '../../molecules/InsuranceTable';

class DefaultBigbrandCondition extends Component {
  constructor(props) {
    super(props);
    this.panels = [];
  }
  componentDidMount() {
    if (this.props.defaultOpens) {
      this.props.defaultOpens.map((index) => this.panels[index].toggle(false));
    }
  }
  togglePanels = (isOpen) => {
    this.panels.forEach((panel) => panel.toggle(isOpen));
  };

  render() {
    return (
      <React.Fragment>
        <Panel ref={(ref) => (this.panels[0] = ref)} title="เอกสารสำหรับแสดงเพื่อรับรถ ณ เคาน์เตอร์">
          <ol>
            <li>
              <h6>ใบยืนยันการจอง / ใบยืนยันการจองอิเล็กทรอนิกส์</h6>
              <p>
                ท่านจะต้องแสดงใบยืนยันการจองที่ออกให้โดย Drivehub.co มาแสดงแก่เจ้าหน้าที่ของบริษัทเช่ารถ
                ในวันที่ท่านมารับรถ
              </p>
            </li>
            <li>
              <h6>บัตรเครดิตที่ใช้ในการจองรถ</h6>
              <ul>
                <li>
                  ท่านต้องแสดงบัตรเครดิตซึ่งเป็นชื่อของผู้ขับขี่หลัก
                  และต้องมีวงเงินในบัตรเพียงพอกับค่ามัดจำที่บริษัทรถเช่ากำหนด
                  ซึ่งบริษัทจะบล็อกวงเงินจากบัตรเครดิตของท่านเพื่อเป็นการมัดจำการเช่ารถ
                  และการบล็อกวงเงินดังกล่าวจะถูกยกเลิกภายในระยะเวลาไม่เกิน 30 วันนับจากการเช่าสิ้นสุดลง
                </li>
                <li>
                  หากมีความเสียหายใดๆ ต่อรถเช่าโดยความรับผิดของท่าน บริษัทฯ
                  อาจดำเนินการขยายเวลาการบล็อกวงเงินเพื่อเป็นหลักประกันจนกว่าทางบริษัทฯจะได้รับค่าเสียหายเต็มจำนวน
                </li>
              </ul>
              <p>
                <b>ประเภทบัตรเครดิตที่สามารถใช้ได้ คือ MasterCard และ Visa เท่านั้น </b>
                {!this.props.hideVisa && <img src={visalogo} height={50} alt="drivehu can pay via credit card" />}
              </p>
            </li>
            <li>
              <h6>เอกสารประจำตัวผู้ขับขี่</h6>
              <ul>
                <li>
                  ผู้เช่า/ผู้ขับขี่จะต้องแสดงใบขับขี่ในชื่อของตนเองที่ยังไม่หมดอายุให้แก่เจ้าหน้าที่ของบริษัท
                  โดยใบขับขี่ดังกล่าวต้องสามารถใช้ได้และไม่หมดอายุ (มีอายุอย่างน้อย 1 ปี)
                </li>
                <li>
                  ในกรณีที่ผู้เช่า/ผู้ขับขี่ถือสัญชาติอื่นที่ไม่ใช่สัญชาติไทย
                  จะต้องแสดงพาสปอร์ตและใบอนุญาตขับรถระหว่างประเทศ
                  หรือใบอนุญาตขับรถของประเทศที่ทางประเทศไทยอนุญาตให้ใช้เพื่อการขับขี่ภายในประเทศไทยได้
                  แต่ใบอนุญาตดังกล่าวจะต้องมีชื่อของผู้ขับขี่เป็นภาษาอังกฤษกำกับอยู่
                </li>
              </ul>
              <p>
                <b>อายุขั้นต่ำในการเช่าคือ 23 ปี</b>
              </p>
              <p>
                <b>*หากเอกสารไม่ครบ ทางบริษัทผู้ให้บริการขอสงวนสิทธิ์ไม่ปล่อยรถทุกกรณี</b>
              </p>
            </li>
          </ol>
        </Panel>
        <Panel ref={(ref) => (this.panels[1] = ref)} title="สิ่งที่รวมมากับราคาเช่ารถ">
          <ul>
            <h6>ประกันความคุ้มครองและสิทธิประโยชน์</h6>
            <li>
              <b>ประกันที่มีค่าเสียหายส่วนแรก (Collision Damage Waiver :CDW)</b>
              <p>
                ในกรณีที่เกิดความเสียหายระหว่างการเช่า
                ค่าใช้จ่ายสำหรับความเสียหายดังกล่าวที่ท่านต้องรับผิดชอบจะไม่เกินตามประกันที่มีค่าเสียหายส่วนแรก (CDW)
                ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
                และผู้ที่ทำให้เกิดความเสียหายนั้นคือชื่อของบุคคลที่ปรากฎเป็นคู่สัญญาเท่านั้น
                การคุ้มครองนี้ครอบคลุมเพียงตัวรถและไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ล้อ ภายใน หลังคา
                หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ
                ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น)
              </p>
            </li>
            <li>
              <b>ประกันภัยบุคคลที่ 3 (Third Party Liability : TPL)</b>
              <p>
                ในกรณีที่เกิดความเสียหายทางร่างกายและทรัพย์สินของบุคคลที่ 3
                อันเกิดจากความผิดของผู้ขับขี่ระหว่างการเช่านั้น ประกันภัยบุคคลที่ 3 (TPL)
                จะคุ้มครองถึงความรับผิดดังกล่าวต่อร่างกายและทรัพย์สินตามนโยบาย ทั้งนี้
                ความคุ้มครองไม่รวมถึงความเสียหายใดทั้งทางร่างกายและทรัพย์สินของผู้เช่าเอง หรือต่อรถที่เช่า
                และการคุ้มครองนี้มีผลต่อเมื่อท่านปฏิบัติตามข้อตกลงการเช่าเท่านั้น
              </p>
            </li>
            <li>
              <b>ระยะกิโลเมตร</b>
              <p>ท่านสามารถใช้รถระหว่างการเช่าได้ไม่จำกัดจำนวนระยะทาง</p>
            </li>
            <li>
              <b>ผู้ขับสำรอง</b>
              <p>ท่านสามารถระบุชื่อผู้ขับสำรอง อีกจำนวน 1 ท่าน</p>
            </li>
          </ul>
        </Panel>
        <Panel ref={(ref) => (this.panels[2] = ref)} title="สิ่งที่ไม่ได้รวมมากับราคาเช่ารถ">
          <ul>
            <h6>ค่าธรรมเนียมอื่นๆ</h6>
            <li>
              <p>
                ราคาค่าเช่ารถ ไม่รวมถึงค่าธรรมเนียมใดๆ ที่ท่านอาจต้องชำระในสถานที่ที่ท่านอาจต้องไปรับรถ
                หรือสถานที่ที่ผ่านสำหรับการเดินทางไปรับรถ รวมถึงสถานที่ที่ท่านเดินทางไปในระหว่างการเช่ารถ ค่าทางด่วน
                ค่าปรับอันเกิดจากการผิดกฎจราจร นอกจากนี้ ค่าเช่ารถยังไม่รวมถึงค่าใช้จ่ายอื่นๆ
                ที่อยู่นอกเหนือการตกลงการเช่ารถ เช่นค่าน้ำมัน ค่าบริการอุปกรณ์เสริม ค่าบริการเครื่อง GPS
                ค่าบริการเบาะที่นั่งสำหรับเด็ก เป็นต้น นอกเหนือจากนี้
                พนักงานที่เคาน์เตอร์อาจเรียกเก็บเงินค่าบริการทำความสะอาดเพิ่มในขณะที่ท่านคืนรถหรือเรียกเก็บในภายหลังพ้นระยะเช่าไปแล้ว
                หากพบว่าจะต้องทำความสะอาดรถในระดับที่เกินกว่าปกติภายหลังจากการเช่ารถของท่าน
                โดยจะแจ้งอัตราค่าบริการดังกล่าวให้ทราบอีกครั้ง
              </p>
            </li>
          </ul>
          <ul>
            <h6>การรับและคืนรถต่างสถานที่</h6>
            <li>
              <p>
                ในกรณีที่ท่านต้องการรับรถและ/หรือคืนรถ ณ สถานที่ที่ต่างไปจากที่กำหนดไว้ในภายแรก
                ท่านจะต้องแจ้งให้ทางบริษัททราบล่วงหน้าก่อน
                และทางบริษัทรถเช่าขอสงวนสิทธิในการเรียกเก็บค่ารับและ/หรือส่งรถต่างสถานที่ได้
                ทั้งนี้ทางบริษัทจะแจ้งให้ท่านทราบถึงอัตราค่าใช้จ่ายดังกล่าว
              </p>
            </li>
          </ul>
        </Panel>

        <Panel ref={(ref) => (this.panels[3] = ref)} title="มัดจำ, ค่าเสียหายส่วนแรก, ประกันภัย">
          <ul>
            <h6>ค่ามัดจำ/ค่าเสียหายส่วนแรก</h6>
            <li>
              <p>
                ท่านจะต้องชำระค่ามัดจำตามที่บริษัทรถเช่าจะเรียกเก็บเพื่อเป็นเป็นจำนวนเริ่มต้น 5,000 – 30,000 บาท
                โดยบริษัทจะคืนเงินมัดจำหลังจากที่ท่านได้คืนรถ ทั้งนี้ให้เป็นไปตามเงื่อนไขที่กำหนดไว้
                โดยแบ่งเป็นกรณีดังนี้
              </p>
              <ul>
                <li>ค่าเสียหายส่วนแรกในกรณีที่มีความเสียหายเกิดขึ้นกับรถ เริ่มต้นที่ : 8,560 - 32,100 บาท (รวมภาษี)</li>
                <li>ค่าเสียหายส่วนแรกในกรณีรถสูญหาย เริ่มต้นที่ : 8,560 - 32,100 บาท (รวมภาษี)</li>
              </ul>
              <p>
                <b>
                  หมายเหตุ: ทางบริษัทรถเช่าไม่รับผิดชอบในกรณีอัตราแลกเปลี่ยนเงินตราไม่คงที่
                  ซึ่งอาจทำให้เกิดความแตกต่างของค่าเงินของวันทำจองและวันที่มีการคืนเงินได้
                </b>
                <br />
                <br />
                <b>
                  ค่ามัดจำ/ค่าเสียหายส่วนแรก ขึ้นอยู่กับประเภทรถ และ
                  อาจมีการเปลี่ยนแปลงโดยท่านสามารถทราบข้อมูลได้ที่เคาน์เตอร์รับรถ
                </b>
              </p>
            </li>
          </ul>
          <InsuranceTable />
          <br />
          <p>*อัตราราคาต่อวันขึ้นอยู่กับประเภทรถ</p>
          <div>
            <b>คำอธิบายประกันความคุ้มครองและสิทธิประโยชน์</b>
          </div>
          <ul>
            <li>
              <b>ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)</b>
              <div>
                Collision Damage Waiver: CDW  กรณีเกิดอุบัติเหตุระหว่างเช่าโดยที่ผู้เช่ารถเป็นฝ่ายผิดหรือไม่มีคู่กรณี
                หรือรถมีรอยขีดข่วนจากการขับขี่
                ผู้เช่าต้องรับผิดชอบค่าเสียหายที่เกิดขึ้นไม่เกินค่าเสียหายส่วนแรกที่ทางบริษัทรถเช่ากำหนด
                การคุ้มครองนี้ครอบคลุมเพียงตัวรถและไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ภายใน หลังคา
                หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ
                ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้
                การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
              </div>
            </li>
            <li>
              <b>ประกันภัยบุคคลที่ 3 (TPL)</b>
              <div>
                Third Party Liability: TPL ในกรณีที่เกิดความเสียหายทางร่างกายและทรัพย์สินของบุคคลที่ 3
                อันเกิดจากความผิดของผู้ขับขี่ระหว่างการเช่านั้น ประกันภัยบุคคลที่ 3 (TPL)
                จะคุ้มครองถึงความรับผิดดังกล่าวต่อร่างกายและทรัพย์สินตามนโยบาย ทั้งนี้
                ความคุ้มครองไม่รวมถึงความเสียหายใดทั้งทางร่างกายและทรัพย์สินของผู้เช่าเอง หรือต่อรถที่เช่า
                และการคุ้มครองนี้มีผลต่อเมื่อท่านปฏิบัติตามข้อตกลงการเช่าเท่านั้น
              </div>
            </li>
          </ul>
          <br />
          <div>
            <b>กรณีเลือกซื้อประกันความคุ้มครองของผู้เช่ารถ (เพิ่มเติม)</b>
          </div>
          <ul>
            <li>
              <b>ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (SCDW, SLDW, ECDW)</b>
              <div>
                Super Collision Damage Waiver: SCDW, Super Loss Damage Waive: SLDW, Extra Collision Damage Waiver: ECDW
              </div>
              <div>
                กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น
                การคุ้มครองนี้ครอบคลุมเพียงตัวรถ และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ฝาครอบล้อ กระทะล้อ ภายใน
                หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ
                ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS อุปกรณ์เสริมกุญแจ เอกสารสำคัญของรถยนต์หาย
                หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้
                การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
              </div>
            </li>
            <li>
              <b>ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ (PCDW, PLDW)</b>
              <div>Premium Collision Damage Waiver: PCDW, Premium Loss Damage Waive: PLDW</div>
              <div>
                กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น
                การคุ้มครองนี้ครอบคลุมเพียงตัวรถ กระจก ยาง และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น ภายใน หลังคา
                หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้น ระหว่างการเช่า หรืออุปกรณ์ใดๆ
                ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้
                การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
              </div>
            </li>
            <li>
              <b>ประกันการโจรกรรม (TP)</b>
              <div>
                Theft Protection: TP หากรถยนต์ที่เช่าถูกขโมย
                ประกันภัยประเภทนี้จะครอบคลุมในส่วนที่ผู้เช่าจะต้องจ่ายชดเชยในส่วนที่สูญเสียไป ในกรณีที่รถถูกโจรกรรม
                ประกันจะคุ้มครองให้กับผู้เช่ารถ ซึ่งความรับผิดชอบจากรถกรณีรถหายจะเป็น 0 บาท ทั้งนี้
                การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
              </div>
            </li>
            <li>
              <b>ประกันภัยอุบัติเหตุส่วนบุคคล (PAI)</b>
              <div>
                Personal Accident Insurance: PAI ในกรณีที่เกิดอุบัติเหตุประกันภัยประเภทนี้ครอบคลุมค่าใช้จ่ายทางการแพทย์
                และคุ้มครองตามกฎหมายในกรณีบาดเจ็บ โดยครอบคลุมถึงการเสียชีวิตและการสูญเสียแขน ขา
                ความคุ้มครองนี้ใช้ได้เฉพาะในกรณีที่ผู้ขับขี่ปฏิบัติตามเงื่อนไขของสัญญาเช่าเท่านั้น
              </div>
            </li>
          </ul>
          <br />
          <div>หมายเหตุ: ชื่อและเงื่อนไขของประกันอาจมีการเปลี่ยนแปลงไปตามที่บริษัทรถเช่ากำหนด</div>
        </Panel>
        <Panel ref={(ref) => (this.panels[4] = ref)} title="ระเบียบการเติมน้ำมัน">
          <ul>
            <h6>รับเต็มถังคืนเต็มถัง</h6>
            <li>
              <p>
                ทางบริษัทฯได้เติมน้ำมันให้ท่านเต็มจำนวนถังก่อนที่จะส่งมอบรถ ดังนั้น ก่อนคืนรถ
                ท่านจะต้องเติมน้ำมันให้เต็มถังด้วยประเภทของน้ำมันที่ระบุไว้โดยบริษัท ทั้งนี้
                หากท่านไม่เติมน้ำมันคืนให้เต็มถัง
                พนักงานที่เคาน์เตอร์บริการจะตรวจสอบและหักเงินค่าน้ำมันในส่วนที่ท่านยังไม่ได้เติมจากบัตรเครดิตของท่าน
                จึงขอความกรุณาให้ท่านเติมน้ำมันให้เต็มถังก่อนส่งมอบรถคืน
              </p>
            </li>
          </ul>
        </Panel>
        <Panel ref={(ref) => (this.panels[5] = ref)} title="ค่าบริการเสริม">
          <ul>
            <h6>การบริการรับและคืนรถนอกเวลาทำการ</h6>
            <li>
              <p>
                ในกรณีที่ท่านต้องการให้บริษัทให้บริการจัดส่งรถให้ท่าน หรือต้องการเปลี่ยนแปลงสถานที่รับรถ/คืนรถ
                หรือเปลี่ยนแปลงเวลารับรถหรือคืนรถให้เป็นนอกเวลาทำการของเคาน์เตอร์บริการ
                ท่านจะต้องแจ้งล่วงหน้าก่อนวันเช่าอย่างน้อย 12 ชั่วโมง โดยเมื่อบริษัทผู้ให้บริการ
                ได้รับแจ้งแล้วจะดำเนินการตรวจสอบว่าสามารถจัดเจ้าหน้าที่มาบริการดังกล่าวได้หรือไม่และจะแจ้งให้ท่านทราบโดยไม่ชักช้า
                ซึ่งในกรณีที่บริษัทฯตกลงที่จะมีการบริการจัดส่งรถ หรืออนุญาตให้เปลี่ยนแปลงเวลา/สถานที่ดังกล่าวได้นั้น
                บริษัทฯขอสงวนสิทธิในการคิดค่าธรรมเนียมเพิ่มเติมนอกเหนือจากการเช่าตามอัตราที่จะแจ้งให้ท่านทราบต่อไป
              </p>
            </li>
          </ul>
          <ul>
            <h6>กฎการข้ามเขตแดน</h6>
            <li>
              <p>ท่านไม่สามารถใช้รถเช่าในการเดินทางข้ามพรมแดนไปยังประเทศอื่นโดยไม่ได้รับอนุญาตจากบริษัทให้เช่ารถ</p>
            </li>
          </ul>
        </Panel>
        <Panel ref={(ref) => (this.panels[6] = ref)} title="ค่าอุปกรณ์เสริม">
          <p>
            หากท่านต้องการอุปกรณ์เสริมใดๆ พร้อมกับการเช่ารถ ให้ท่านทำคำร้องแจ้งคำขอดังกล่าวได้ขณะที่ทำการจอง
            หรือติดต่อเจ้าหน้าที่เพื่อแจ้ง ซึ่งค่าใช้จ่ายของการใช้อุปกรณ์เสริมระหว่างการเช่านั้น
            ทางบริษัทจะแจ้งให้ท่านทราบ และท่านสามารถชำระได้โดยตรง ณ สถานที่รับรถ
            ทั้งนี้ทางบริษัทอาจขอให้ท่านวางเงินมัดจำอุปกรณ์เสริมดังกล่าว ณ เคาน์เตอร์รับรถด้วย
          </p>
        </Panel>
        <Panel ref={(ref) => (this.panels[7] = ref)} title="ข้อมูลสำคัญและการปฏิบัติเมื่อเกิดกรณีต่างๆ">
          <ul>
            <li>
              <p>
                ในกรณีที่เกิดอุบัติเหตุกับรถหรือรถเสีย ท่านต้องแจ้งบริษัทรถเช่าให้ทราบในทันที
                เพื่อดำเนินการช่วยเหลือในการซ่อมแซมหรือจัดหารถยนต์ทดแทน
                ทั้งนี้กรุณาเก็บสำเนาเอกสารที่เกี่ยวกับการเช่าทั้งหมดไว้
                รวมถึงรายงานอุบัติเหตุที่ออกให้โดยเจ้าหน้าที่ตำรวจรวมถึงรายงานที่ออกให้โดยบริษัทผู้ให้บริการ
                เพื่อเป็นประโยชน์ต่อท่านในการเรียกร้องความเสียหายต่อไปได้
              </p>
            </li>
            <li>
              <p>
                ในกรณีที่ท่านต้องการความช่วยเหลือฉุกเฉินบนท้องถนน
                โดยส่วนใหญ่บริษัทรถเช่าจะมีบริการฉุกเฉินช่วยเหลือซึ่งจะมีค่าใช้จ่ายเพิ่มเติมหรือไม่ขึ้นอยู่กับประเภทประกันที่ท่านได้ใช้
                รายละเอียดเหล่านี้ท่านสามารถตรวจสอบได้ในคู่มือที่บริษัทได้ให้ตอนรับรถหรืออยู่ในรถ
              </p>
            </li>
            <li>
              <p>
                ในกรณีที่กุญแจหาย บริษัทขอสงวนสิทธิที่จะคิดค่าใช้จ่ายในการออกกุญแจใหม่ให้
                โดยจะแจ้งให้ท่านทราบถึงอัตราค่าบริการดังกล่าว
              </p>
            </li>
            <li>
              <p>
                ในระหว่างที่ท่านขับขี่รถ ท่านจะต้องคาดเข็มขัดนิรภัยตลอดเวลา
                และโปรดทราบว่าท่านไม่สามารถสูบบุหรี่หรือผลิตภัณฑ์อื่นใดที่ก่อให้เกิดควันหรือกลิ่นเหม็น
                หากพบว่ามีการฝ่าฝืนทางบริษัทขอสงวนสิทธิที่จะปรับในอัตราที่บริษัทกำหนดไว้
              </p>
            </li>
            <li>
              <p>
                ในกรณีท่านนำรถมาคืนก่อนเวลาที่ท่านระบุไว้ในการจอง ท่านจะต้องแจ้งให้ทางบริษัททราบล่วงหน้า
                โดยท่านจะไม่สามารถขอรับเงินคืนสำหรับเวลาที่ไม่ได้ใช้ได้
              </p>
            </li>
          </ul>
        </Panel>
        <Panel ref={(ref) => (this.panels[8] = ref)} title="การยกเลิกการจอง">
          <ul>
            <h6>วิธีการยกเลิกการจอง</h6>
            <p>ผู้เช่าสามารถทำการยกเลิกการจองของท่าน ผ่านทางช่องทาง Call Center Drivehub หรือ Line@ : @drivehub</p>
          </ul>
          <ul>
            <h6>การยกเลิกการจองและการคืนเงิน</h6>
            <p>เงื่อนไขในการได้รับเงินคืนในกรณีที่มีการยกเลิกการจองนั้น ให้เป็นไปตามที่ระบุดังต่อไปนี้:</p>
            <li>
              <p>หากมีการยกเลิก 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านจะได้รับเงินคืนเต็มจำนวน</p>
            </li>
            <li>
              <p>
                หากมีการยกเลิกภายใน 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านอาจจะได้รับเงินคืนบางส่วน
                เป็นจำนวนที่ได้ชำระเข้ามาหักลบด้วยค่าเช่า 3 วัน
              </p>
            </li>
            <li>
              <p>ถ้าท่านได้ทำจองรถน้อยกว่า 3 วัน จะไม่มีค่าใช้จ่ายใด ๆเพิ่มเติม แต่จะไม่ได้รับเงินคืน</p>
            </li>
            <li>
              <p>
                ในกรณีที่ท่านไม่สามารถไปรับรถได้ในเวลาที่ตกลงกัน / หรือเอกสารที่จำเป็นไม่ครบ /
                หรือบัตรเครดิตมีวงเงินไม่เพียงพอที่จะเช่ารถ บริษัทรถเช่าจะสงวนสิทธิ์การเช่า และไม่ได้รับเงินคืน
              </p>
            </li>
          </ul>
          <ul>
            <h6>กรณีที่ไม่แสดงตัว</h6>
            <p>ท่านไม่สามารถได้รับเงินคืนในกรณีที่ท่านไม่แสดงตัว ซึ่งหมายถึงเมื่อท่าน:</p>
            <li>
              <p>ต้องการยกเลิก แต่ไม่ได้แจ้งให้ทางบริษัทหรือ Drivehub.co ทราบก่อนจะเริ่มกำหนดการเช่าตามเวลาที่กำหนด </p>
            </li>
            <li>
              <p>ไม่สามารถรับรถได้ตามเวลาที่แจ้งไว้</p>
            </li>
            <li>
              <p>ไม่สามารถให้เอกสารบริษัทรถเช่าร้องขอให้แสดงเมื่อไปรับรถ</p>
            </li>
            <li>
              <p>ไม่สามารถที่จะให้บัตรเครดิตในชื่อของผู้ขับขี่หลักหรือวงเงินในบัตรเครดิตไม่เพียงพอค่ามัดจำ</p>
            </li>
            <p>
              ทั้งนี้บริษัทรถเช่าขอสงวนสิทธิที่จะไม่ส่งมอบรถให้ลูกค้าที่มารับรถไม่ตรงเวลา หรือไม่มีเอกสารในการจองรถเช่า
              หรือวงเงินในบัตรเครดิตไม่เพียงพอสำหรับเป็นค่ามัดจำการเช่า
            </p>
          </ul>
        </Panel>
      </React.Fragment>
    );
  }
}

export default DefaultBigbrandCondition;
