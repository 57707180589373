let _CS_MODE = '';
let _REGISTER_PROMOTION = false;
switch (process.env.REACT_APP_STAGE) {
  case 'production': {
    _CS_MODE = process.env.REACT_APP_CS_MODE || 'false';
    _REGISTER_PROMOTION = process.env.REACT_APP_REGISTER_PROMOTION_TOGGLE === 'enable';

    break;
  }
  default:
  case 'staging':
    _CS_MODE = process.env.REACT_APP_CS_MODE || 'false';
    _REGISTER_PROMOTION = process.env.REACT_APP_REGISTER_PROMOTION_TOGGLE === 'enable';

    break;
}
export const CS_MODE = _CS_MODE === 'true';
export const REGISTER_PROMOTION = _REGISTER_PROMOTION;
