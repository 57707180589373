import axios from 'axios'
import queryString from 'query-string'
import {
  CATEGORY,
  DEALER,
  CITY,
  SOURCE,
  PICKUP,
  RETURN,
  LOCATION,
  SORTBY,
  FILTER_LAYOUT,
  COUNT,
} from '../reducers/filter'
import { API_SEARCH_HOST } from '../../variables/API'

const defaultMapping = {
  category: 'categories',
  dealer: 'dealers',
  location: 'location_id',
  pickupDatetime: 'pickup_datetime',
  returnDatetime: 'return_datetime',
  source: 'sources',
  sortBy: 'sort_by',
}
/**
 * @param  {array} actionTypes
 * @param  {string} endPoint
 * @param  {string} queryName
 */
export const factory = (actionTypes, endPoint, queryName = '', delay = 0, ignoreQuery = []) => {
  axios.defaults.baseURL = API_SEARCH_HOST
  // Create axios Cancel Token
  const CancelToken = axios.CancelToken
  let cancel
  return {
    get: (mapping = defaultMapping) => async (dispatch, getState) => {
      cancel && cancel()
      if (delay > 0) {
        await sleep(delay)
      }
      dispatch({ type: actionTypes.FETCHING })
      const state = getState().filter
      let params = Object.keys(mapping)
        .filter(key => {
          try {
            const values = state[key].selected
            return values.length > 0
          } catch (error) {
            return false
          }
        })
        .map(key => {
          const values = state[key].selected
          const alias = mapping[key]
          return {
            [alias]: Array.isArray(values) ? values.join(',') : values,
          }
        })
        .reduce((p, c) => ({ ...p, ...c }), {})

      const { query } = queryString.parseUrl(window.location.search)
      const nextQuery = Object.assign(
        {},
        {
          ...query,
          ...params,
        },
      )
      nextQuery.pickup_datetime = nextQuery.booking_begin
      nextQuery.return_datetime = nextQuery.booking_end
      delete nextQuery.booking_begin
      delete nextQuery.booking_end
      if (queryName) {
        delete nextQuery[queryName]
      }
      if (ignoreQuery.length > 0) {
        ignoreQuery.forEach(key => {
          delete nextQuery[key]
        })
      }
      const parsedQuery = queryString.stringify(nextQuery)
      return axios
        .get(`${endPoint}?${parsedQuery}`, {
          cancelToken: new CancelToken(c => (cancel = c)),
        })
        .then(response => {
          dispatch({
            type: actionTypes.FETCHED,
            payload: response.data,
          })
        })
        .catch(() => {
          dispatch({
            type: actionTypes.ERROR,
          })
        })
    },
    select: selected => dispatch => {
      dispatch({
        type: actionTypes.SELECTED,
        payload: selected,
      })
    },
    toggle: item => dispatch => {
      dispatch({
        type: actionTypes.TOGGLE,
        payload: item,
      })
    },
    load: items => dispatch => {
      dispatch({ type: actionTypes.FETCHED, payload: items })
    },
    getQuery: (mapping = defaultMapping) => (dispatch, getState) => {
      const state = getState().filter
      let result = Object.keys(mapping)
        .filter(key => {
          try {
            const values = state[key].selected
            return values.length > 0
          } catch (error) {
            return false
          }
        })
        .map(key => {
          const values = state[key].selected
          const alias = mapping[key]
          return `${alias}=${values.join(',')}`
        })
        .join('&')
      return result
    },
  }
}

export const category = factory(CATEGORY, '/api/filter/categories', 'categories', 0, [
  'sources',
  'dealers',
])
export const dealer = factory(DEALER, '/api/filter/dealers', 'dealers', 1, ['sources'])
export const city = factory(CITY, '/api/locations', 'locations', 2)
export const source = factory(SOURCE, '', 'sources', 3)
export const pickupDatetime = factory(PICKUP, '', 'booking_begin', 4)
export const returnDatetime = factory(RETURN, '', 'booking_end', 5)
export const location = factory(LOCATION, '', 'location_id', 6)
export const sortBy = factory(SORTBY, '/api/filter/cars', 'sort_by', 7)
export const count = factory(COUNT, '/api/filter/count', '', 8)

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
/**
 * @param  {string} action
 */
export const toggleFilterLayoutMobile = action => (dispatch, getState) => {
  if (action === 'OPEN') {
    const initState = getState().filter
    const { category, dealer, source } = initState
    const initFilterSelected = Object.assign(
      {
        category: category.selected,
        dealer: dealer.selected,
        source: source.selected,
      },
      {},
    )
    dispatch({ type: FILTER_LAYOUT.OPEN, payload: initFilterSelected })
  } else if (action === 'CLOSE') {
    const prevFilterSelected = getState().filter.filterLayout.initialFilterSelected
    dispatch({ type: FILTER_LAYOUT.CLOSE })
    dispatch({ type: 'FILTER_CLEAR' })
    dispatch(category.select(prevFilterSelected.category))
    dispatch(dealer.select(prevFilterSelected.dealer))
    dispatch(source.select(prevFilterSelected.source))
    dispatch(count.get())
  } else {
    dispatch({ type: FILTER_LAYOUT.CLOSE })
  }
}
