import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { withTranslation } from '../../../locales';
import './tooltip.scss';

const OverleyTooltip = ({ props, title, content }) => (
  <div className="tooltip-inner--content" {...props}>
    <div>{title}</div>
    <div>{content}</div>
  </div>
);

const CustomToolTip = ({ t, text, tooltip, placement = 'top' }) => {
  if (!tooltip) {
    return <span className="tooltip">{t(text)}</span>;
  }
  return (
    <span>
      <Tooltip
        placement={placement}
        overlayClassName="tooltip-inner"
        overlay={<OverleyTooltip title={t(text)} content={t(tooltip)} />}
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        trigger={['hover', 'click']}
      >
        <span className="tooltip">
          {t(text)} <i className="flaticon-information" />
        </span>
      </Tooltip>
    </span>
  );
};

export default withTranslation('card')(CustomToolTip);
