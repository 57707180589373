import React from 'react';
import Styled from './styled';

import translate from './translate.json';

export interface BadgeProps {
  type?: 'local' | 'inter';
  payment?: boolean;
  prefix?: 'th' | 'en';
  size: 'small' | 'medium' | 'large';
  bg?:
    | 'primary'
    | 'blue'
    | 'dark'
    | 'interPrimary'
    | 'localPrimary'
    | 'inter'
    | 'local'
    | 'veryLightBlue'
    | 'pale'
    | 'scarlet';
  font?: 'dark' | 'white' | 'black';
  text?: string;
  style?: any;
  borderRadius?: number;
}

const icons = {
  local: {
    payment: require('./assets/cash@2x.png'),
    default: require('./assets/local@2x.png')
  },
  inter: {
    payment: require('./assets/credit@2x.png'),
    default: require('./assets/inter@2x.png')
  }
};
const texts = {
  local: {
    payment: 'payment.local',
    default: 'local'
  },
  inter: {
    payment: 'payment.inter',
    default: 'inter'
  }
};
const getIcon = ({ payment, type }: BadgeProps) => {
  if (!type) return '';
  return icons[type][payment ? 'payment' : 'default'];
};
const getText = ({ payment, type }: BadgeProps) => {
  if (!type) return '';
  return texts[type][payment ? 'payment' : 'default'];
};
const t = (word: string, prefix: string) => {
  return translate[prefix][word];
};
class Badge extends React.Component<BadgeProps> {
  static defaultProps = {
    prefix: 'th',
    size: 'small',
    borderRadius: 5
  };
  get prefix() {
    return this.props.prefix ? this.props.prefix : 'th';
  }
  render() {
    const icon = getIcon(this.props);
    const text = this.props.text || t(getText(this.props), this.prefix);
    return (
      <Styled
        style={this.props.style}
        type={this.props.type}
        bg={this.props.bg}
        font={this.props.font}
        size={this.props.size}
        borderRadius={this.props.borderRadius}
      >
        <div className="badge__wrapper">
          {this.props.type && <img src={icon} alt={text} />}
          <span>{text}</span>
        </div>
      </Styled>
    );
  }
}

export default Badge;
