import React, { Component } from 'react';
import CNXPopup from '../../../assets/images/popup_cnx.jpg';
import './style.scss';

export default class IntroduceModalCNX extends Component {
  state = {
    step: 0,
    visible: true
  };

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.state.visible) document.documentElement.style.overflow = 'hidden';
    else document.documentElement.style.overflow = 'scroll';
  };

  handleClickOutside = (event) => {
    if (this.cnxPopup && !this.cnxPopup.contains(event.target)) {
      this.onClose();
    }
  };

  buttonOnClick = () => {
    if (this.state.step < 2) {
      return this.setState({ step: this.state.step + 1 });
    }
    this.setState({ step: 0, visible: false });
    document.documentElement.style.overflow = 'scroll';
  };

  onClose = () => {
    this.setState({ visible: false, step: 0 });
    document.documentElement.style.overflow = 'scroll';
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.visible && (
          <React.Fragment>
            <div className="cnx-popup__overlay" />
            <div className="cnx-popup__modal" ref={(el) => (this.cnxPopup = el)}>
              <span className="close-modal" onClick={this.onClose}>
                <i className="flaticon-cancel" /> ปิดหน้าต่างนี้
              </span>
              <img src={CNXPopup} alt={`Songkran Festival`} />
              <div className="content--wrapper">
                <button className="dh-button dh-animate-pulse" onClick={this.onClose}>
                  เลือกรถ <i className="flaticon-right-arrow" />
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
