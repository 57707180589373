import moment from 'moment-timezone'
import axios from 'axios'
import { API_GET_LOCATIONS } from '../../../variables/API'

export const getLocations = () => axios.get(API_GET_LOCATIONS).then(response => response.data)

export const getMomentFromTime = time => moment(`2000-01-01T${time}:00+07:00`)
export const timeSlot = () => {
  function _createTime(limit = 24, item = [], i = 0) {
    if (item.length < limit * 2) {
      return _createTime(
        limit,
        item.concat([
          { value: `${i > 9 ? i : '0' + i}:00`, label: `${i}:00` },
          { value: `${i > 9 ? i : '0' + i}:30`, label: `${i}:30` },
        ]),
        i + 1,
      )
    } else {
      return item
    }
  }

  return _createTime()
    .splice(12, _createTime().length)
    .concat(_createTime().splice(0, 12))
    .filter(item => item.value !== '00:00')
}

export const compareTime = (base, compare) => {
  const momentBase = getMomentFromTime(base)
  const momentCompare = getMomentFromTime(compare)
  return momentBase.isSameOrAfter(momentCompare) // NOTE: If base > compare (time) return true
}

export const isSameDay = (a, b) => {
  if (!a) {
    return false
  }
  if (!b) {
    return false
  }
  return a.startOf('day').isSame(b.startOf('day'))
}
export const currentTime = () => moment().format('HH:mm')
