import React from 'react';
import './style.scss';

const FormikInput = (props) => {
  const { type, placeholder, className, autoComplete, onChange, name, onKeyPress, onBlur, value, disabled, id } = props;
  return (
    <React.Fragment>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={`dh-input-formik ${className ? className : ''}`}
        onChange={(e) => {
          if (!onChange) return;
          return onChange(e);
        }}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        id={id}
      />
      {props.children}
    </React.Fragment>
  );
};

export default FormikInput;
