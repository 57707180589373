import React, { Component } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import creditCard from '../../../assets/images/creditcard-badge.svg';
import { SEARCH_PAGE } from '../../../variables/route';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
export default class ConfirmModal extends Component {
  state = {
    visible: false
  };
  toggle = () => {
    this.setState({
      visible: !this.state.visible
    });
  };
  onRedirectToSearchPage = () => {
    const query = queryString.parse(window.location.search);
    delete query.source;
    query.sources = 'local';
    query.booking_begin = query.pickup_datetime;
    query.booking_end = query.return_datetime;
    delete query.pickup_datetime;
    delete query.return_datetime;
    delete query.car_id;
    delete query.insurance_id;
    if (query.booking_begin.includes('T')) {
      query.booking_begin = moment(query.booking_begin).format('YYYY-MM-DD HH:mm');
    }
    if (query.booking_end.includes('T')) {
      query.booking_end = moment(query.booking_end).format('YYYY-MM-DD HH:mm');
    }
    const nextQuery = queryString.stringify(query);
    window.location.href = `${SEARCH_PAGE}?${nextQuery}`;
  };
  render() {
    return (
      <Modal show={this.state.visible} onHide={this.toggle} centered className="modal-confirm-credit-card">
        <i className="icon-close confirm-close-btn" onClick={this.toggle} />
        <Modal.Body className="modal-body--credit">
          <img src={creditCard} alt="Confirm" />
          <p className="confirm-description">
            รถคันนี้จำเป็น
            <span className="highlight">ต้องใช้บัตรเครดิต</span> ในการทำจองและล็อควงเงินมัดจำในวันรับรถ
          </p>
          <div id="invoice-box">
            <a href="tel:+6620385222">
              <i className="icon-phone" />
              <small className="font-weight-bold text-left">
                หากท่านต้องการใบกำกับภาษี กรุณาแจ้งเจ้าหน้าที่ไดรฟ์ฮับก่อนวันรับรถ
                เอกสารใบกำกับภาษีจะถูกจัดส่งให้ทางอีเมลภายใน 7 วันทำการ(นับจากวันที่รับรถ)
              </small>
            </a>
          </div>
          <Row className="confirm-btn">
            <Col>
              <Button variant="outline-primary" onClick={this.onRedirectToSearchPage} block>
                ดูเฉพาะรถที่ไม่ใช้บัตรเครดิต
              </Button>
            </Col>
            <Col>
              <Button variant="primary" onClick={this.props.onConfirm} block>
                ทำรายการต่อ
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
