let BB_API_URL = '';
let _SEARCH_API_URL = '';
let API_HOST = '';
let RATINGS_REVIEWS_API_URL = '';
let LEGACY_HOST = '';
let LEGACY_API = '';
let LEGACY_MANAGE_URL = '';
let _SURVEY_URL_LOCAL = '';
let _SURVEY_URL_INTER = '';
let _MARKETING_API = '';
let _DRIVEHUB_API = '';
let SEARCH_HOST = '';
let _HELP_WEB_URL = '';
let CHAT_HOST = '';

if (process.env.REACT_APP_STAGE === 'production') {
  BB_API_URL = process.env.REACT_APP_BB_API_URL;
  _SEARCH_API_URL = process.env.REACT_APP_SEARCH_API_URL;
  API_HOST = process.env.REACT_APP_API_HOST;
  RATINGS_REVIEWS_API_URL = process.env.REACT_APP_RATINGS_REVIEWS_API_URL;
  LEGACY_HOST = process.env.REACT_APP_LEGACY_HOST;
  LEGACY_API = process.env.REACT_APP_LEGACY_API;
  LEGACY_MANAGE_URL = process.env.REACT_APP_LEGACY_MANAGE_URL;
  _SURVEY_URL_LOCAL = process.env.REACT_APP_SURVEY_URL_LOCAL;
  _SURVEY_URL_INTER = process.env.REACT_APP_SURVEY_URL_INTER;
  _MARKETING_API = process.env.REACT_APP_MARKETING_API_URL;
  _DRIVEHUB_API = process.env.DRIVEHUB_API_URL || 'https://www.drivehub.co/api/v1/th';
  SEARCH_HOST = process.env.SEARCH_HOST || 'https://search.drivehub.co';
  _HELP_WEB_URL = process.env.HELP_WEB_URL || 'https://help.drivehub.co';
  CHAT_HOST = process.env.REACT_APP_CHAT_HOST;
} else {
  BB_API_URL = 'https://bigbrand-dev.drivehub.co/api';
  API_HOST = 'https://search-api-dev.drivehub.co';
  _SEARCH_API_URL = 'https://search-api-dev.drivehub.co/api';
  RATINGS_REVIEWS_API_URL = 'https://reviews-dev.drivehub.co/api';
  LEGACY_HOST = 'https://drivehub-dev.drivehub.co';
  LEGACY_API = 'https://backend-dev.drivehub.co/api';
  // LEGACY_API = 'http://localhost:3000/api';
  LEGACY_MANAGE_URL = 'https://manage-dev.drivehub.co';
  _SURVEY_URL_LOCAL = 'https://survey.drivehub.co/zs/mXB3e9';
  _SURVEY_URL_INTER = 'https://survey.drivehub.co/zs/Z1BUQw';
  _MARKETING_API = 'https://marketing-api-dev.drivehub.co/api';
  _DRIVEHUB_API = 'https://drivehub-dev.drivehub.co/api/v1/th';
  SEARCH_HOST = 'https://app-dev.drivehub.co';
  _HELP_WEB_URL = 'https://help-dev.drivehub.co';
  CHAT_HOST = process.env.REACT_APP_CHAT_HOST || 'https://chat-frontend-dev.drivehub.co';
}
export const SEARCH_API_URL = _SEARCH_API_URL;
export const LEGACY_WEB = LEGACY_HOST;
export const MANAGE_URL = LEGACY_MANAGE_URL;
export const API_CREATE_BOOKING_RECORD = `${BB_API_URL}/bookings`;
export const API_GET_LOCATIONS = `${SEARCH_API_URL}/locations`;
export const API_SEARCH_HOST = API_HOST;
export const API_GET_CARS = `${SEARCH_API_URL}/cars`;
export const API_GET_CAR = `${BB_API_URL}/cars/`;
export const API_GET_CATEGORIES = `${SEARCH_API_URL}/categories`;
export const API_GET_CATEGORIES_PRICING = `${SEARCH_API_URL}/categories_pricing`;
export const API_GET_CAR_DETAIL = `${BB_API_URL}/car/`;
export const API_GET_TOTAL_DEALERS = `${SEARCH_API_URL}/total_dealers`;
export const API_GET_FEATURES_AVAILABLE = `${SEARCH_API_URL}/features`;
export const API_VALIDATE_PROMO_CODE = `${BB_API_URL}/validate`;
export const API_LEGACY = `${LEGACY_API}/v2`;
export const API_LEGACY_GET_CAR_DETAIL = `${API_LEGACY}/car/`;
export const API_CREATE_ORIGIN_BOOKING = `${API_LEGACY}/booking_online_create`;
export const API_CREATE_ORIGIN_DRAFT = `${API_LEGACY}/booking_online_draft`;
export const API_LEGACY_GET_BOOKING = `${API_LEGACY}/bookings`;
export const API_BIGBRAND_GET_BOOKING = `${BB_API_URL}/bookings`;
export const API_GET_RATINGS_REVIEWS = `${RATINGS_REVIEWS_API_URL}/branches`;
export const SURVEY_URL_LOCAL = _SURVEY_URL_LOCAL;
export const SURVEY_URL_INTER = _SURVEY_URL_INTER;
export const MARKETING_API = _MARKETING_API;
export const DRIVEHUB_API = _DRIVEHUB_API;
export const BIGBRAND_API = BB_API_URL;
export const SEARCH_HOST_URL = SEARCH_HOST;
export const HELP_WEB = _HELP_WEB_URL;
export const CHAT_WEB = CHAT_HOST;
