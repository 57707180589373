import axios from 'axios'
import { API_GET_FEATURES_AVAILABLE } from '../../variables/API'
import { FEATURE_AVAILABLE_ACTION } from '../reducers/features'

export const getAvailableFeatures = () => dispatch => {
  const url = API_GET_FEATURES_AVAILABLE
  dispatch({
    type: FEATURE_AVAILABLE_ACTION.FETCHING,
  })
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: FEATURE_AVAILABLE_ACTION.FETCHED,
        payload: res.data,
      })
    })
    .catch(error => {
      console.log("Error can't get fetures available", error)
      dispatch({
        type: FEATURE_AVAILABLE_ACTION.ERROR,
        payload: error,
      })
    })
}
