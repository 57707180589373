import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import MediaQuery from 'react-responsive';
import LoadingBar from 'react-top-loading-bar';
import disableScroll from 'disable-scroll';
import moment from 'moment';
import queryString from 'query-string';
import { withTranslation } from '../../../locales';
import * as actions from '../../../ducks/actions/filter';
import * as carActions from '../../../ducks/actions/cars';
import SortingModal from './sortingModal';
import './style.scss';
import { pushDataLayer, pushTrackEvent } from '@utils/datalayer';

const SORT_OPTIONS = [
  {
    name: 'sort.rating_and_price',
    value: 'rating_and_price'
  },
  {
    name: `sort.price`,
    value: 'price'
  }
];
class FilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSortingModalOpen: false
    };
  }
  componentDidMount() {
    if (this.props.sortBy.selected.length === 0) {
      this.props.dispatch(actions.sortBy.select('price'));
    }
    window.addEventListener('hashchange', this.onHashChange);
    if (window.location.hash !== '') {
      window.location.hash = '';
    }
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange);
  }
  onHashChange = (e) => {
    e.preventDefault();
    if (window.location.hash === '') {
      disableScroll.off();
      this.props.dispatch(actions.toggleFilterLayoutMobile('CLOSE'));
      this.setState({ isSortingModalOpen: false });
    }
  };

  startLoading() {
    if (this.LoadingBar) {
      this.LoadingBar.staticStart();
    }
  }
  stopLoading() {
    if (this.LoadingBar) {
      this.LoadingBar.complete();
    }
  }

  fetchCars = debounce(() => {
    this.stopLoading();
    this.props.dispatch(carActions.initialFetchCars());
  }, 1000);
  fetchCount = debounce(() => {
    this.stopLoading();
    this.props.dispatch(actions.count.get());
  }, 1000);

  onToggleCleanFilter = () => {
    this.startLoading();
    this.props.dispatch({ type: 'FILTER_CLEAR' });
    this.fetchCars();
    this.fetchCount();
    pushDataLayer({
      event: 'track_event',
      event_category: 'search_result_section',
      event_action: 'reset',
      event_label: 'filter'
    });
  };

  onDeleteSelectedFilter = (item) => {
    this.startLoading();
    switch (item.key) {
      case 'source': {
        this.props.dispatch(actions.source.toggle(item.id));
        break;
      }
      case 'category': {
        this.props.dispatch(actions.category.toggle(item.id));
        break;
      }
      case 'dealer': {
        this.props.dispatch(actions.dealer.toggle(item.id));
        break;
      }
      default:
        console.log('key is not mactch.');
    }
    this.fetchCars();
    this.fetchCount();
  };

  onSelectSorting = (sortBy) => {
    pushDataLayer({
      event: 'track_event',
      event_category: 'search_result_section',
      event_action: 'sort_by',
      event_label: sortBy
    });
    this.props.dispatch(actions.sortBy.select(sortBy));
    this.props.dispatch(carActions.initialFetchCars());
    this.onToggleSortingFilter();
  };

  openFilterLayoutMobile = () => {
    pushTrackEvent('search_bookings_section', 'open', 'filter');
    window.location.hash = 'openFilter';
    // disableScroll.on()
    this.props.dispatch(actions.toggleFilterLayoutMobile('OPEN'));
  };

  onToggleSortingFilter = () => {
    if (this.state.isSortingModalOpen === false) {
      pushTrackEvent('search_bookings_section', 'open', 'sort_by');
    }
    this.setState({ isSortingModalOpen: !this.state.isSortingModalOpen });
  };

  get listOfSelectedFilter() {
    const { source, dealer, category, t, lang } = this.props;
    let listSelectedFilter = [];
    if (source.selected.length !== 0) {
      source.selected.forEach((item) => {
        listSelectedFilter.push({
          display: item === 'local' ? t('local') : t('inter'),
          id: item,
          key: 'source'
        });
      });
    }
    if (category.list.length !== 0) {
      category.selected.forEach((id) => {
        const item = category.list.find((i) => i.id + '' === id + '');
        if (!item) return;
        listSelectedFilter.push({
          display: item.name[lang],
          id: item.id,
          key: 'category'
        });
      });
    }
    if (dealer.list.length !== 0) {
      dealer.selected.forEach((id) => {
        const item = dealer.list.find((i) => i.id + '' === id + '');
        if (!item) return;
        listSelectedFilter.push({
          display: item.name.en,
          id: item.id,
          key: 'dealer'
        });
      });
    }
    return listSelectedFilter;
  }

  pushDataLayer = () => {
    try {
      const { query } = queryString.parseUrl(window.location.href);

      if (query.from) {
        const carType =
          this.props.category.selected
            .map((id) => {
              const selected = this.props.category.list.find((c) => c.id === parseInt(id));
              if (!selected) {
                return '';
              }
              return selected.name.en;
            })
            .join(', ') || 'undefined';
        const dealerName =
          this.props.dealer.selected
            .map((id) => {
              const selected = this.props.dealer.list.find((d) => d.id === parseInt(id));
              if (!selected) {
                return '';
              }
              return selected.name.en;
            })
            .join(', ') || 'undefined';
        const location = this.props.location.list.find((l) => l.id === parseInt(this.props.location.selected));

        const pickupDatetime = this.props.pickupDatetime.selected;
        const returnDatetime = this.props.returnDatetime.selected;
        const additional = {
          search_pickup_date: moment(pickupDatetime, 'YYYY-MM-DD HH:mm')
            .locale('en')
            .format('YYYY-MMM-DD'),
          search_pickup_time: pickupDatetime.split(' ')[1] + ':00',
          search_return_date: moment(returnDatetime, 'YYYY-MM-DD HH:mm')
            .locale('en')
            .format('YYYY-MMM-DD'),
          search_return_time: returnDatetime.split(' ')[1] + ':00',
          search_promotion_name: 'undefined',
          sort_by: query.sort_by,
          search_car_type: carType,
          search_dealer_type: query.sources || 'undefined',
          search_pickup_location: location.name.th,
          search_destination: location.city.name.th,
          search_rental_type: 'shortterm',
          search_dealer_name: dealerName,
          search_timestamp: moment()
            .locale('en')
            .format('YYYY-MMM-DD HH:mm:ss'),
          number_of_product_in_search_result: this.props.count.cars
        };
        pushDataLayer({
          event: 'track_event',
          event_action: 'search_bookings_log',
          event_label: query.from,
          event_category: 'search_bookings_success',
          ...additional
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.count.cars !== this.props.count.cars) {
      this.pushDataLayer();
    }
    return null;
  }
  componentDidUpdate() {
    return null;
  }

  render() {
    const { t, sortBy } = this.props;
    const { cars, dealers, fetching } = this.props.count;

    return (
      <MediaQuery minWidth={992}>
        {(matches) => {
          if (matches) {
            // Desktop
            return (
              <React.Fragment>
                <LoadingBar height={3} color="#0078ff" onRef={(ref) => (this.LoadingBar = ref)} />
                <div className="filter-bar">
                  <div className="car-summary">
                    {fetching ? (
                      ''
                    ) : (
                      <React.Fragment>
                        {t('found')} {cars} {t('ea')} {t('from')} {dealers} {t('match')}
                        <span onClick={this.onToggleCleanFilter}>{t('sort.clean_all')}</span>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="filter-badge">
                    {this.listOfSelectedFilter.map((badge) => (
                      <div className="filter-badge-item" key={badge.id}>
                        {badge.display}
                        <i className="flaticon-cancel" onClick={() => this.onDeleteSelectedFilter(badge)} />
                      </div>
                    ))}
                  </div>
                  <div className="filter-sort">
                    <div className="sort-item sort-item--title">{t('sort.sort_by')}</div>
                    {SORT_OPTIONS.map((sortItem) => (
                      <div
                        className={`sort-item ${sortItem.value === sortBy.selected ? 'active' : ''}`}
                        onClick={() => this.onSelectSorting(sortItem.value)}
                        key={sortItem.value}
                      >
                        {t(`${sortItem.name}`)}
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            );
          } else {
            // Mobile
            const selectedCount = [
              ...this.props.category.selected,
              ...this.props.source.selected,
              ...this.props.dealer.selected
            ];
            return (
              <React.Fragment>
                <div className="car-summary">
                  {fetching ? (
                    ''
                  ) : (
                    <React.Fragment>
                      {t('found')} {cars} {t('ea')} {t('from')} {dealers} {t('match')}
                    </React.Fragment>
                  )}
                </div>
                <div className="filter-bar--mobile">
                  <div className="filter-item" onClick={this.onToggleSortingFilter}>
                    <i className="flaticon-descendant" />
                    {t('sort.sorting')}
                  </div>
                  <div className="filter-item" onClick={this.openFilterLayoutMobile}>
                    <i className="flaticon-control" />
                    {selectedCount.length > 0 && <span className="selected-badge">{selectedCount.length}</span>}
                    {t('sort.filter')}
                  </div>
                  <div
                    className="filter-item"
                    onClick={() => {
                      this.props.onToggleFaq(-1);
                      pushTrackEvent('search_bookings_section', 'more', 'faqs');
                    }}
                  >
                    <i className="flaticon-question-mark-in-dark-circle" />
                    {t('sort.faq')}
                  </div>
                </div>
                <SortingModal
                  isOpen={this.state.isSortingModalOpen}
                  onToggleSortingFilter={this.onToggleSortingFilter}
                  t={t}
                  sortData={SORT_OPTIONS}
                  onSelectSorting={this.onSelectSorting}
                  sortBy={sortBy.selected}
                />
              </React.Fragment>
            );
          }
        }}
      </MediaQuery>
    );
  }
}
const mapStateToProps = (state) => state.filter;
export default connect(mapStateToProps)(withTranslation('filter')(FilterBar));
