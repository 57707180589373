import React from 'react'

const question = {
  icon: 'flaticon-rental',
  question: 'จะต้องไปรับรถอย่างไร?',
  answer: (
    <div>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <p className="indent">
        {/* FIXME:  */}
        หลังทำการจองสำเร็จ จะมีเจ้าหน้าที่จากบริษัทรถเช่าติดต่อกลับ ภายในไม่เกิน 15 นาที
        เพื่อยืนยันเอกสารและยืนยันการจองอีกครั้ง ท่านสามารถนัดแนะจุดรับรถกับบริษัทรถเช่าได้ โดยตรง
        ไม่ว่าจะเป็นที่สนามบิน ในตัวเมือง หรือที่โรงแรม (หากท่านต้องการรับหรือคืนรถนอก
        เหนือพื้นที่ที่กำหนด ทางบริษัทรถเช่าจะเรียกเก็บค่าบริการเพิ่มเติม
        ทั้งนี้ขึ้นอยู่กับเงื่อนไขของแต่ละบริษัท)
      </p>
      <b>
        <u>หากท่านทำการจองรถที่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        {/* FIXME:  */}
        สามารถรับรถได้ที่เคาน์เตอร์ที่อยู่ภายในตัวอาคารสนามบิน ซึ่งโดยปกติแล้วเคาน์เตอร์จะ
        อยู่บริเวณทางออกหลังรับกระเป๋าเสร็จแล้ว สำหรับบางสนามบินที่ไม่มีเคาน์เตอร์อยู่ด้านใน จะมี
        เจ้าหน้าที่ของบริษัทรับรถนั้นๆรอรับท่านที่ทางออกผู้โดยสารขาเข้า โดยทางเจ้าหน้าที่บริษัทรถ
        เช่าจะติดต่อไปหาท่านตามเวลานัดหมาย
      </div>
    </div>
  ),
}

export default question
