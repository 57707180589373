import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withTranslation } from '../../../locales';
import InsuranceBox from '../InsuranceBox';
import './style.scss';
import { dataLayer } from '@utils/datalayer';

class PricingDetails extends Component {
  state = {
    expand: false
  };
  toggleExpand = () => this.setState({ expand: !this.state.expand });
  get dates() {
    const dates = this.props.pricing.price_per_days;
    let result = [];
    dates.forEach((date, i) => {
      if (i > 0) {
        const prev = result.length - 1;
        if (result[prev].price === date.price) {
          result[prev].count++;
          result[prev].endAt = moment(date.date).format('DD MMM YYYY');
        } else {
          result.push({
            startAt: moment(date.date).format('DD MMM YYYY'),
            count: 1,
            ...date
          });
        }
      } else {
        result.push({
          startAt: moment(date.date).format('DD MMM YYYY'),
          count: 1,
          ...date
        });
      }
    });
    return result;
  }
  get isInter() {
    return ['inter', 'bigbrand'].includes(this.props.source);
  }
  render() {
    const { pricing, isOnlyShowTotalPrice, source, onApplyInsurance, t } = this.props;
    return (
      <div className="price_wrapper">
        <div
          data-event-category="bookings_section"
          data-event-action={this.state.expand ? 'hide' : 'expand'}
          data-event-label="price_detail"
          onClick={(e) => {
            dataLayer(e);
            this.toggleExpand();
          }}
          className="btn-expand"
        >
          <div>{this.state.expand ? t('pricing.hide') : t('pricing.expand')}</div>
          <div>{this.state.expand ? '−' : '+'}</div>
        </div>

        <div className={this.state.expand ? 'show dates' : 'hide dates'}>
          {this.dates.map((date) => (
            <div key={date.startAt} className="row">
              <div className="col-auto mr-auto">
                {date.startAt} {date.endAt ? `- ${date.endAt}` : date.endAt}
              </div>
              <div className="col-auto">
                {date.count} {t('day')} x{' '}
                {pricing.slash_from ? <span className="on-sale small">{parsedMoney(pricing.slash_from)}</span> : ''}
                {parsedMoney(date.price)}
              </div>
            </div>
          ))}
          {pricing.total_grace_period > 0 && (
            <div className="row">
              <div className="col-auto mr-auto">
                {t('pricing.overtime')} {pricing.duration_hours} {t('hour')} ( {t('pricing.exceed')}{' '}
                {source === 'local' ? 4 : 1} ) {t('pricing.exceed_tail')}
              </div>
              <div className="col-auto">{parsedMoney(pricing.total_grace_period)}</div>
            </div>
          )}
          {pricing.insurance_selected && (
            <div className="row">
              <div className="col-auto mr-auto">
                {t('pricing.insurance')} {pricing.insurance_selected['code']}
              </div>
              <div className="col-auto">
                {pricing.insurance_selected['total_days']} {t('day')} x{' '}
                {parsedMoney(pricing.insurance_selected['price_per_day'])}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-auto mr-auto">{t('pricing.delivery_price')}</div>
            <div className="col-auto">{parsedMoney(pricing.delivery_price)}</div>
          </div>
          <div className="row">
            <div className="col-auto mr-auto">{t('pricing.pickup_price')}</div>
            <div className="col-auto">{parsedMoney(pricing.pickup_price)}</div>
          </div>
          {isOnlyShowTotalPrice && pricing.discount_amount > 0 && (
            <div className="row">
              <div className="col-auto mr-auto">
                {t('pricing.discount_from_coupon')} <small className="green">{pricing.discount_code || ''}</small>
              </div>
              <div className="col-auto">
                <span className="green">-{parsedMoney(pricing.discount_amount)}</span>
              </div>
            </div>
          )}
        </div>
        {!isOnlyShowTotalPrice && (
          <React.Fragment>
            <div className="row">
              <div className="col-auto mr-auto">
                {t('pricing.summary.price_for')} {pricing.duration_days} {t('day')}{' '}
                {`${pricing.duration_hours ? `${pricing.duration_hours} ${t('hour')}` : ''}`}{' '}
              </div>
              <div className="col-auto">
                {pricing.slash_from ? (
                  <span className="on-sale">
                    {parsedMoney(pricing.slash_from * pricing.duration_days + parseInt(pricing.total_grace_period, 10))}
                  </span>
                ) : (
                  ''
                )}
                &nbsp;
                {parsedMoney(parseInt(pricing.total_price, 10) + parseInt(pricing.total_grace_period, 10))}
              </div>
            </div>
            {pricing.insurance_id && (
              <React.Fragment>
                <div className="row">
                  <div className="col-auto mr-auto">
                    {pricing.insurances
                      .filter((item) => {
                        return parseInt(item.id, 10) === parseInt(pricing.insurance_id, 10);
                      })
                      .map((item) => {
                        return `${item.code} ${t('for')} ${pricing.duration_days} ${t('day')} ${
                          pricing.duration_hours ? pricing.duration_hours + t('hour') : ''
                        }`;
                      })}
                  </div>
                  <div className="col-auto">
                    {pricing.insurances
                      .filter((item) => {
                        return parseInt(item.id, 10) === parseInt(pricing.insurance_id, 10);
                      })
                      .map((item) => {
                        return parsedMoney(item.insurance_total_price);
                      })}
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className="row">
              <div className="col-auto mr-auto">{t('pricing.summary.delivery_pickup')}</div>
              <div className="col-auto">
                {parseInt(pricing.delivery_price, 10) + parseInt(pricing.pickup_price, 10) !== 0 ? (
                  parsedMoney(pricing.delivery_price + pricing.pickup_price)
                ) : (
                  <span className="red">{t('free')}!</span>
                )}
              </div>
            </div>
            {this.isInter && (pricing.insurances || pricing.insurance_main) && (
              <InsuranceBox {...pricing} onApplyInsurance={onApplyInsurance} />
            )}

            {pricing.discount_amount > 0 && (
              <div className="row">
                <div className="col-auto mr-auto">
                  {t('pricing.discount_from_coupon')} <small className="green">{pricing.discount_code || ''}</small>
                </div>
                <div className="col-auto">
                  <span className="green">-{parsedMoney(pricing.discount_amount)}</span>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        <div className="row summary">
          <div className="col-auto mr-auto">{t('pricing.summary.total')}</div>
          <div className="col-auto">
            {!pricing.insurance_id && pricing.discount_amount > 0 && (
              <span className="on-sale">
                {parsedMoney(Math.round(pricing.grand_total_price) + Math.round(pricing.discount_amount))}
              </span>
            )}

            {!pricing.insurance_id && parsedMoney(pricing.grand_total_price)}

            {pricing.insurance_id &&
              pricing.discount_amount > 0 &&
              pricing.insurances
                .filter((item) => {
                  return parseInt(item.id, 10) === parseInt(pricing.insurance_id, 10);
                })
                .map((item, index) => {
                  return (
                    <span className="on-sale" key={index}>
                      {parsedMoney(Math.round(item.grand_total_price) + Math.round(pricing.discount_amount))}
                    </span>
                  );
                })}

            {pricing.insurance_id &&
              pricing.insurances
                .filter((item) => {
                  return parseInt(item.id, 10) === parseInt(pricing.insurance_id, 10);
                })
                .map((item) => {
                  return parsedMoney(Math.round(item.grand_total_price));
                })}
          </div>
        </div>
      </div>
    );
  }
}
const parsedMoney = (price) => `฿${parseInt(Math.round(price), 10).toLocaleString()}`;
PricingDetails.defaultProps = {
  pricing: {
    duration_days: 5,
    duration_hours: 4,
    pickup_datetime: '2018-12-10T10:00:00+07:00',
    return_datetime: '2018-12-15T14:00:00+07:00',
    price_per_days: [
      {
        date: '2018-12-10T00:00:00+07:00',
        note: 'ตุลาคม - กุมภาพันธ์',
        price: 1490
      },
      {
        date: '2018-12-11T00:00:00+07:00',
        note: 'ตุลาคม - กุมภาพันธ์',
        price: 1490
      },
      {
        date: '2018-12-12T00:00:00+07:00',
        note: 'ตุลาคม - กุมภาพันธ์',
        price: 1490
      },
      {
        date: '2018-12-13T00:00:00+07:00',
        note: 'ตุลาคม - กุมภาพันธ์',
        price: 1490
      },
      {
        date: '2018-12-14T00:00:00+07:00',
        note: 'ตุลาคม - กุมภาพันธ์',
        price: 1500
      }
    ],
    total_grace_period: 400,
    total_price: 7850,
    grand_total_price: 8050,
    available: true,
    deposit: 10000,
    delivery_price: 100,
    pickup_price: 100,
    location: {
      en: 'Don Mueang Airport',
      th: 'สนามบินดอนเมือง'
    }
  }
};

export default withTranslation('summary', 'common')(PricingDetails);
