import { combineReducers } from 'redux'
import cars from './cars'
import filter from './filter'
import booking from './booking'
import fetch from './fetch'
import reviews from './reviews'
import features from './features'

export default combineReducers({
  cars,
  filter,
  booking,
  fetch,
  reviews,
  features,
})
