import React, { PureComponent } from 'react';
import Picker, { DateUtils, RangeModifier } from 'react-day-picker';
import localeUtils from 'react-day-picker/moment';
import moment from 'moment';
import 'moment/locale/th';
import { ContextType } from '../context';
import { CalendarProp, CalendarState } from './interface';
import './style.scss';
const format = 'YYYY-MM-DD HH:mm';

class Calendar extends PureComponent<CalendarProp, CalendarState> {
  static contextType = ContextType;

  state: CalendarState = {
    count: 1
  };

  get initialMonth() {
    if (this.props.defaultDate) {
      return moment(this.props.defaultDate, format).toDate();
    }
    return new Date();
  }

  get range() {
    const { bookingBegin, bookingEnd } = this.props;
    const range: RangeModifier = {
      from: moment(bookingBegin, format).toDate(),
      to: moment(bookingEnd, format).toDate()
    };
    return range;
  }
  addDayToRange = (date: Date, range: RangeModifier) => {
    let result = { ...range };
    const current = moment(date).startOf('days');
    const from = moment(range.from).startOf('days');
    const to = moment(range.to).startOf('days');

    if (
      current.isSame(from) &&
      current.isSame(to)
      // ||
      // current.diff(from, 'days') <= -1 ||
      // current.diff(to, 'days') >= 1
    ) {
      result.from = date;
      result.to = date;
    } else {
      result = DateUtils.addDayToRange(date, range);
    }
    return result;
  };

  render() {
    const { singleMonth, bookingBegin, bookingEnd } = this.props;
    const { count } = this.state;
    const { isMobile } = this.context;

    let numberOfMonths = 2;

    if (isMobile) {
      numberOfMonths = 12;
    }
    if (singleMonth) {
      numberOfMonths = 1;
    }

    return (
      <div className="date-picker">
        <Picker
          locale="th"
          initialMonth={this.initialMonth}
          className="Selectable"
          numberOfMonths={numberOfMonths}
          selectedDays={[this.range]}
          localeUtils={localeUtils}
          modifiers={{ start: this.range.from, end: this.range.to }}
          onDayClick={(day) => {
            if (
              moment(day)
                .startOf('days')

                .isBefore(moment().startOf('days'))
            ) {
              return;
            }
            const r = this.addDayToRange(day, this.range);
            if (count === 1) {
              this.setState({ count: 0 });
              // if (this.props.focusField !== 'bookingEnd') {
              r.from = day;
              r.to = day;
              // }
              this.props.onSelected && this.props.onSelected('bookingBegin');
            } else {
              this.setState({ count: 1 });
              this.props.onSelected && this.props.onSelected('bookingEnd');
            }

            return this.props.onChange({
              bookingBegin: moment(r.from).format('YYYY-MM-DD ') + bookingBegin.split(' ')[1],
              bookingEnd: moment(r.to).format('YYYY-MM-DD ') + bookingEnd.split(' ')[1]
            });
          }}
          disabledDays={(date) => {
            const isDisabled =
              moment(date).startOf('days').isSameOrBefore(moment().startOf('days').subtract(1, 'days')) ||
              moment(date).startOf('years').isAfter(moment().add(1, 'years'));
            return isDisabled;
          }}
          canChangeMonth={!isMobile}
        />
      </div>
    );
  }
}

export default Calendar;
