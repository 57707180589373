import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { ContextType } from '@ui/components/context';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from '../../components/atoms/InterLocalBadge';
import { withTranslation } from '../../locales';
import {
  API_LEGACY_GET_BOOKING,
  LEGACY_WEB,
  API_BIGBRAND_GET_BOOKING,
  SEARCH_HOST_URL,
  CHAT_WEB
} from '../../variables/API';
import { BookingDetailResp, ChatComponentProps, ChatComponentState, ChatRenderState } from './interface';

import ErrorPage from '../../components/molecules/ErrorPage';
import './style.scss';

import InterIcon from '../../assets/icon/inter-icon.svg';
import LocalIcon from '../../assets/icon/local-icon.svg';
import { readCookie } from '../../utils';

const ENDPOINT = {
  local: API_LEGACY_GET_BOOKING,
  inter: API_BIGBRAND_GET_BOOKING
};
class ChatComponent extends React.PureComponent<ChatComponentProps, ChatComponentState> {
  static contextType = ContextType;

  context!: React.ContextType<typeof ContextType>;
  constructor(props: ChatComponentProps) {
    super(props);
    this.state = {
      booking: undefined,
      car: undefined,
      customer: undefined,
      dealer: undefined,
      pricing: undefined,
      transitions: [],
      rating: undefined,
      isLoading: true,
      isError: false,
      token: '',
      chatRenderState: ChatRenderState.Mobile
    };
  }

  componentDidMount() {
    const { query } = queryString.parseUrl(window.location.href);
    const lastName = query.last_name;

    const bookingID = this.props.match.params.id;
    const endpoint = ENDPOINT[this.source];
    this.getToken(bookingID, lastName);

    axios
      .get<BookingDetailResp>(`${endpoint}/${bookingID}`, {
        params: {
          last_name: lastName
        }
      })
      .then(({ data }) => {
        this.setState({ ...data, chatRenderState: this.chatRenderState });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isError: true, chatRenderState: this.chatRenderState });
      });
  }

  getToken = (bookingID, lastName) => {
    const adminToken = readCookie('admin_token');
    if (adminToken) {
      return this.setState({ token: adminToken, isLoading: false });
    }
    axios
      .get(`${LEGACY_WEB}/api/auth/consumer_token`, {
        params: {
          booking_id: bookingID,
          last_name: lastName,
          source: 'local'
        }
      })
      .then(({ data }) => {
        this.setState({ token: data.token, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getRatingReviewBranch = (branchId) => {
    const source = ['local', 'original'].includes(this.source) ? 'original' : 'bigbrand';
    axios
      .get(`${LEGACY_WEB}/api/v1/th/branch/${branchId}/stat?source=${source}`)
      .then((response) => {
        this.setState({ rating: response.data, isLoading: false });
      })
      .catch((error) => console.log(`Cannot get rating on branchId ${branchId}: ${error}`));
  };

  get source() {
    const { query } = queryString.parseUrl(window.location.href);
    const source = query.source;
    if (['local', 'original'].includes(source)) {
      return 'local';
    } else {
      return 'inter';
    }
  }
  get lastName() {
    const { query } = queryString.parseUrl(window.location.href);
    return query.last_name;
  }

  get chatRenderState() {
    const { isMobile } = this.context;
    return isMobile ? ChatRenderState.Mobile : ChatRenderState.Desktop;
  }

  render() {
    const { isError, booking, dealer, chatRenderState } = this.state;

    if (isError) return <ErrorPage />;
    if (this.state.isLoading === false && this.context.isMobile) {
      return (
        <iframe
          className="chat-iframe--mobile"
          title="chat-box"
          src={`${CHAT_WEB}/rooms/${this.props.match.params.id}?authorization=${this.state.token}&device=desktop&render=${chatRenderState}`}
        />
      );
    }
    return (
      <div className="chat">
        <Container>
          <Row>
            <Col lg={4} className="chat-menu">
              <Card className="card--dealer">
                <Card.Body>
                  <Card.Title>ข้อมูลบริษัทรถเช่า</Card.Title>
                  {dealer && (
                    <div className="chat-dealer">
                      <img src={dealer.logo} alt={dealer.name} className="dealer-logo" />
                      <div className="dealer-name">
                        <p className="title">{dealer.name}</p>
                        <p className="subtitle">{dealer.branch_name}</p>
                        <Badge
                          icon={this.source === 'inter' ? InterIcon : LocalIcon}
                          type={this.source === 'inter' ? 'inter' : 'local'}
                          size="small"
                          className="badge--source"
                        />
                      </div>
                    </div>
                  )}
                  <div className="bottom-section">
                    <Button
                      className="btn--chat-back-btn"
                      as="a"
                      href={`${SEARCH_HOST_URL}/booking/${booking?.id}?source=${this.source}&last_name=${this.lastName}`}
                    >
                      <i className="icon-left" />
                      กลับไปหน้าสถานะการจอง
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8} className="chat-component">
              {this.state.isLoading === false && (
                <iframe
                  className="chat-iframe"
                  title="chat-box"
                  src={`${CHAT_WEB}/rooms/${this.props.match.params.id}?authorization=${this.state.token}&device=desktop&render=${chatRenderState}`}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withTranslation('common', 'chat')(ChatComponent);
