import React, { useState } from 'react';
import moment from 'moment';
import './comment-card.scss';

const CommentCard = ({ name, created_at, pickup_at, massage, score, level }) => {
  const [toggle, setToggle] = useState(false);
  const createAt = moment(created_at)
    .add(543, 'years')
    .locale('th')
    .format('DD MMMM YY');
  const pickupAt = moment(pickup_at)
    .add(543, 'years')
    .locale('th')
    .format('MMMM YY');
  return (
    <div className="comment-card">
      <div className="card__header">
        <div className="header-title">
          <p className="title">{name}</p>
          <div className="rating">
            <p>
              {score.toFixed(1)}
              <span>/5</span>
            </p>
            <span className="vl vl--rating" />
            <p className="level">{level}</p>
          </div>
        </div>
        <div className="header-subtitle">
          <p>
            <span>รีวิวเมื่อ</span> {createAt}
            <span className="vl" />
            <span>รับรถ</span> {pickupAt}
          </p>
        </div>
      </div>
      <div className={`card__content ${toggle ? 'active' : ''} ${massage.length <= 0 ? 'empty' : ''}`}>
        {massage}
        {massage.length > 470 && (
          <div className={`see-more-overlay ${toggle ? 'expanded' : ''}`} onClick={() => setToggle(!toggle)}>
            {toggle ? 'อ่านแบบย่อ' : 'อ่านเพิ่มเติม'}{' '}
            <i className={toggle ? 'flaticon-arrow-up' : 'flaticon-arrow-down'} />
          </div>
        )}
      </div>
    </div>
  );
};
export default CommentCard;
