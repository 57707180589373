import React, { Component } from 'react';
import { withTranslation } from '../../../locales';
import propTypes from 'prop-types';
import Checkbox from '../../molecules/Checkbox';
import './style.scss';

const ORIGINAL_SOURCE = 'local';
const INTER_SOURCE = 'inter';

class NewFilterSource extends Component {
  static propTypes = {
    onChange: propTypes.func.isRequired,
    disabled: propTypes.array
  };
  static defaultProps = {
    disabled: []
  };
  toggleCheckbox = (value) => {
    this.props.onChange(value);
  };

  render() {
    const { selected, t } = this.props;
    return (
      <div className="filter-source">
        <div className="col-12">
          <div className={`inter-checkbox ${this.props.disabled.includes('inter') ? 'checkbox-disabled' : ''}`}>
            <Checkbox
              label={t('credit.title')}
              handleCheckboxChange={() => this.toggleCheckbox('inter')}
              image="creditCard"
              subLabel={
                <ul style={{ paddingLeft: 0 }}>
                  <li>
                    <span>{t('credit.documents')}</span>
                  </li>
                  <li>
                    <span>{t('credit.payment')}</span>
                  </li>
                </ul>
              }
              disabled={this.props.disabled.includes('inter')}
              value="inter"
              isChecked={selected ? selected.includes(INTER_SOURCE) : false}
              className="checkbox-underline"
            />
          </div>
        </div>
        <div className="col-12">
          <div className={`local-checkbox ${this.props.disabled.includes('local') ? 'checkbox-disabled' : ''}`}>
            <Checkbox
              label={t('cash.title')}
              handleCheckboxChange={() => this.toggleCheckbox('local')}
              subLabel={
                <ul style={{ paddingLeft: 0 }}>
                  <li>
                    <span>{t('cash.documents')}</span>
                  </li>
                  <li>
                    <span>{t('cash.payment')}</span>
                    <br />
                    <span className="optional-payment">{t('cash.optional_payment')}</span>
                  </li>
                </ul>
              }
              disabled={this.props.disabled.includes('local')}
              value="local"
              isChecked={selected ? selected.includes(ORIGINAL_SOURCE) : false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('filter')(NewFilterSource);
