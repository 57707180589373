import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import queryString from 'query-string';
import onClickOutside from 'react-onclickoutside';
import DatePicker from '@ui/components/date-picker';
import { dataLayer } from '@utils/datalayer';
import TimePicker from '../../molecules/TimePicker';
import { withTranslation } from '../../../locales';
import './style.scss';

const format = 'YYYY-MM-DD HH:mm';
class DateTimePicker extends Component {
  state = {
    focused: ''
  };
  componentDidMount() {
    const { query } = queryString.parseUrl(window.location.href);
    if (query.openCalendar === 'true') {
      return this.setState({
        focused: 'bookingBegin'
      });
    }
  }

  handleClickOutside = () => {
    this.props.onClose();
    this.setState({ focused: '' });
  };

  setFocus = (focused, event) => {
    dataLayer(event);
    this.setState({ focused });
  };

  onSubmitDatePicker = (range) => {
    this.props.onDatesChange({
      startDate: moment(range.bookingBegin, format),
      endDate: moment(range.bookingEnd, format)
    });
    this.props.onClose();
    this.setFocus('');
  };

  render() {
    const {
      startDate,
      endDate,
      pickupTime,
      returnTime,
      setPickUpTime,
      setReturnTime,
      pickupOptions,
      returnOptions,
      t
    } = this.props;

    return (
      <div className="datetime-picker">
        <div
          className="date-wrapper reset-padding align-self-center"
          data-event-label="pickup_date"
          data-event-category={this.props.eventCategory}
          onClick={(e) => this.setFocus('bookingBegin', e)}
        >
          <p className="date-label">
            {' '}
            {t('pickup_date')} <i className="flaticon-arrow-down" />
          </p>
          <p className="date">{startDate ? moment(startDate).format('DD/MM/YY') : t('please_select')}</p>
        </div>

        <DatePicker
          bookingBegin={startDate.format(format)}
          bookingEnd={endDate.format(format)}
          focused={this.state.focused}
          isActive={this.state.focused === 'bookingBegin' || this.state.focused === 'bookingEnd'}
          onSubmit={(range) => this.onSubmitDatePicker(range)}
          onClose={() => this.setFocus('')}
        />

        <div
          className="time-wrapper reset-padding align-self-center"
          data-event-label="pickup_time"
          data-event-category={this.props.eventCategory}
          onClick={(e) => dataLayer(e)}
        >
          <p className="date-label">
            {' '}
            {t('pickup_time')} <i className="flaticon-arrow-down" />
          </p>
          <TimePicker options={pickupOptions} value={pickupTime} handleChange={setPickUpTime} />
        </div>

        <div className="icon-wrapper reset-padding align-self-center">
          <i className="flaticon-right-arrow" />
        </div>

        <div
          className="date-wrapper reset-padding align-self-center"
          data-event-label="return_date"
          data-event-category={this.props.eventCategory}
          onClick={(e) => this.setFocus('bookingEnd', e)}
        >
          <p className="date-label">
            {' '}
            {t('return_date')} <i className="flaticon-arrow-down" />
          </p>
          <p className="date">{endDate ? moment(endDate).format('DD/MM/YY') : 'โปรดเลือก'}</p>
        </div>

        <div
          className="time-wrapper reset-padding align-self-center"
          data-event-label="return_time"
          data-event-category={this.props.eventCategory}
          onClick={(e) => dataLayer(e)}
        >
          <p className="date-label">
            {' '}
            {t('return_time')} <i className="flaticon-arrow-down" />
          </p>
          <TimePicker options={returnOptions} value={returnTime} handleChange={setReturnTime} />
        </div>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  focusedInput: PropTypes.string,
  pickupTime: PropTypes.string,
  returnTime: PropTypes.string,
  onDatesChange: PropTypes.func,
  setPickUpTime: PropTypes.func,
  options: PropTypes.array,
  setReturnTime: PropTypes.func
};

export default withTranslation('search_bar')(onClickOutside(DateTimePicker));
