import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import queryString from 'query-string';
import { dataLayer, pushDataLayer } from '@utils/datalayer';
import { withTranslation } from '../../../locales';
import local from '../../../assets/card/local.svg';
import inter from '../../../assets/card/inter.svg';
import visa from '../../../assets/card/visa_master.svg';
import instantConfirm from '../../../assets/icon/light.svg';
import creditCard from '../../../assets/card/credit_card.svg';
import noCreditCard from '../../../assets/card/no_credit_card.svg';
import pickup from '../../../assets/card/pickup.svg';
import document from '../../../assets/card/document.svg';
import insuranceIcon from '../../../assets/icon/icon_insurance.svg';
import baggageIcon from '../../../assets/icon/luggage.svg';
import luggageIcon from '../../../assets/icon/suitcase.svg';

import ToolTip from './Tooltip';
import TooLong from '../../molecules/TooLong';
import RatingTooltip from './RatingTooltip';

import './card.scss';
import '../../../assets/icon/flaticon.css';

const overlayStyle = {
  position: 'absolute',
  top: 2,
  left: 5,
  fontWeight: 'bold'
};

const ZERO_EXCESS = 'ZERO_EXCESS';

class Card extends Component {
  state = {
    isPushedDataLayer: false
  };
  componentDidMount() {
    window.document.addEventListener('scroll', () => this.isInViewport());
  }

  componentWillUnmount() {
    window.document.removeEventListener('scroll', () => this.isInViewport());
  }

  //our function which is called anytime document is scrolling (on scrolling)
  isInViewport = (offset = 0) => {
    if (!this.viewElement) return false;
    if (this.state.isPushedDataLayer) return false;
    const top = this.viewElement.getBoundingClientRect().bottom;
    const isInViewport = top + offset >= 0 && top - offset <= window.innerHeight;
    if (isInViewport) {
      this.setState({ isPushedDataLayer: true }, () => {
        pushDataLayer({
          event: 'ecommerce_productImpression',
          event_category: 'ecommerce',
          event_action: 'product_impression',
          event_label: this.props.title['en'],
          ecommerce: {
            currencyCode: 'THB',
            impressions: [this.productArgs]
          }
        });
      });
    }
  };

  get isLocal() {
    return this.props.source === 'local';
  }
  get overlayBadges() {
    const { t } = this.props;
    if (this.isLocal) {
      return <Badge containerStyle={{ ...overlayStyle }} color="#fff" background="#FF8D41" logo={local} text="LOCAL" />;
    }
    return (
      <React.Fragment>
        <Badge containerStyle={{ ...overlayStyle }} color="#fff" background="#0053BA" logo={inter} text="INTER" />
        <Badge
          containerStyle={{ ...overlayStyle, left: 57 }}
          reverse
          color="#0053BA"
          background="#D8EDFD"
          logo={visa}
          text={t('credit_card')}
        />
      </React.Fragment>
    );
  }
  get titleTrailing() {
    const { t } = this.props;
    if (this.isLocal) {
      return t('get_what_your_choose');
    }
    return t('or_equal');
  }
  get carDetails() {
    const { passengers, luggages, baggages, transmission, category, t } = this.props;
    return (
      <div className="details">
        <div className="car-content-badge">
          <i className="flaticon-car-compact" /> {t(`categories.${category.en.toLowerCase()}`)}
        </div>
        <div className="car-content-badge">
          <i className="flaticon-user" /> {passengers}
        </div>
        {luggages > 0 && (
          <div className="car-content-badge">
            <img className="luggage_icon" src={luggageIcon} alt="luggage-icon" /> {luggages}
          </div>
        )}
        {baggages > 0 && (
          <div className="car-content-badge">
            <img className="baggage_icon" src={baggageIcon} alt="baggage-icon" /> {baggages}
          </div>
        )}
        <div className="car-content-badge">
          <i className="flaticon-gearbox" /> {t(`transmission.${transmission}`)}
        </div>
        {this.isLocal ? <div className="ml-auto mr-0">{this.renderReadyToBook()}</div> : ''}
      </div>
    );
  }
  get carDetailUrl() {
    const { pickupDatetime, returnDatetime, id } = this.props;
    const query = queryString.parse(window.location.search);
    let source = this.isLocal ? 'local' : 'inter';
    const params = {
      location_id: query.location_id,
      source,
      pickup_datetime: moment(pickupDatetime).format('YYYY-MM-DD HH:mm'),
      return_datetime: moment(returnDatetime).format('YYYY-MM-DD HH:mm')
    };
    const parsedParams = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    const url = `/car/${id}?${parsedParams}#0`;
    return url;
  }

  calculateDiscountPercent = (slash_total_price, total_price) => {
    return Math.round(((slash_total_price - total_price) * 100) / slash_total_price);
  };

  renderReadyToBook = () => {
    const { t, toggleDocumentModal } = this.props;
    return (
      <React.Fragment>
        <span className="ready">{t('ready_to_book')}</span>
        {!this.isLocal && <InstantConfirm toggleDocumentModal={toggleDocumentModal} t={t} />}
      </React.Fragment>
    );
  };

  get productArgs() {
    const brand = `${this.props.vendor_name.th} : ${this.props.branch.name_th}`;
    return {
      name: this.props.title['en'], // Name or ID is required.
      id: (this.isLocal ? 'L' : 'I') + this.props.id, // For Example 0001
      price: parseInt(this.props.total_price), // For Example 3500
      brand: brand, // For Example True Leasing : True สาขาเชียงใหม่
      category: this.props.category.en, // For Example Eco, Suv, Medium, Van, Car_with_driver
      variant: this.isLocal ? 'Local' : 'Inter', // Dealer Type
      position: this.props.position,
      list: 'Search Results Page'
    };
  }

  get ecommArgs() {
    try {
      return {
        ecommerce: {
          click: {
            actionField: { list: 'Search Results Page' }, //  For Example Search Results Page, Destination Pages
            products: [this.productArgs]
          }
        }
      };
    } catch (error) {
      return {};
    }
  }
  render() {
    const {
      image_1,
      title,
      toggleDocumentModal,
      toggleInsuranceModal,
      toggleInsuranceZeroExcess,
      lang,
      t,
      slash_price_per_day,
      slash_total_price,
      total_price,
      badges
    } = this.props;

    const hasZeroExcess = badges.includes(ZERO_EXCESS);
    const isHertz = this.props.vendor_name.en === 'hertz';
    const isThrifty = this.props.vendor_name.en === 'Thrifty';
    const shouldHideInsuranceModel = this.isLocal || isHertz || isThrifty;

    return (
      <div className="car-card--candidate" ref={(e) => (this.viewElement = e)}>
        <section>
          <div className={`avatar ${hasZeroExcess ? 'avatar--with-badge' : ''}`}>
            {slash_price_per_day && (
              <p className="dc_percent_display">-{this.calculateDiscountPercent(slash_total_price, total_price)}%</p>
            )}
            <a target="_blank" href={this.carDetailUrl} rel="noopener noreferrer">
              <ImgCarWrapper src={image_1} />
              {this.overlayBadges}
            </a>
          </div>
        </section>
        <section>
          <header>
            <a style={{ flex: 1 }} target="_blank" href={this.carDetailUrl} rel="noopener noreferrer">
              <div>
                <h2>
                  <span className="mr-1">{title['en']}</span>
                  <small>{this.titleTrailing}</small>
                </h2>
                {this.carDetails}
              </div>
            </a>
            {this.isLocal ? '' : <div>{this.renderReadyToBook()}</div>}
          </header>
          <section className="body">
            <div>
              <DateTime {...this.props} />
              <ValueProps isLocal={this.isLocal} t={t} />
              <Vendor
                isLocal={this.isLocal}
                lang={lang}
                vendor_name={this.props.vendor_name}
                branch={this.props.branch}
                logo={this.props.logo}
              />
            </div>
            <div className="align-right">
              <Price {...this.props} />
              <Rental toggleDocumentModal={toggleDocumentModal} isLocal={this.isLocal} t={t} />
              <FreeCancel t={t} isLocal={this.isLocal} />
            </div>
          </section>
          <div>
            {shouldHideInsuranceModel ? '' : <ZeroExcess toggleInsuranceModal={toggleInsuranceModal} t={t} />}
            {hasZeroExcess && <ZeroExcess toggleInsuranceModal={toggleInsuranceZeroExcess} t={t} zeroExcess={true} />}
            <a
              target="_blank"
              className="btn btn-primary rent-button"
              data-event="ecommerce_productClick"
              data-event-category="ecommerce"
              data-event-action="product_click"
              data-event-label={title['en']}
              href={this.carDetailUrl}
              onClick={(e) => dataLayer(e, '', this.ecommArgs)}
              rel="noopener noreferrer"
            >
              {t('action')}
            </a>
          </div>
        </section>
      </div>
    );
  }
}

const valueProps = {
  inter: [
    {
      text: 'no_limit_distance'
    },
    {
      text: 'insurance.basic',
      tooltip: 'tooltips.basic_insurance'
    },
    {
      text: 'garantee'
    }
  ],
  local: [
    { text: 'location_delivery', tooltip: 'tooltips.transfer_car' },
    {
      text: 'insurance.basic',
      tooltip: 'tooltips.basic_insurance'
    },
    { text: 'garantee' }
  ]
};
const ValueProps = ({ isLocal }) => (
  <div className="valueprops">
    {valueProps[isLocal ? 'local' : 'inter'].map((props) => (
      <div key={props.text}>
        <i className="flaticon-check" />
        <ToolTip {...props} />
      </div>
    ))}
  </div>
);

const DateTime = ({ t, pickupDatetime, returnDatetime }) => (
  <div className="datetime">
    <span>
      {t('pickup')} : {moment(pickupDatetime).format('DD MMM YY, HH:mm')} {t('h')}
    </span>
    <span>
      {t('return')} : {moment(returnDatetime).format('DD MMM YY, HH:mm')} {t('h')}
    </span>
  </div>
);
const InstantConfirm = ({ t, toggleDocumentModal }) => (
  <div onClick={() => toggleDocumentModal('inter')} className="instant_confirm">
    <img src={instantConfirm} alt="ยืนยันทันที" />
    {t('instant_confirm')}
  </div>
);

export const Badge = ({
  logo,
  color = '#000',
  background = '#fff',
  text,
  containerStyle = {},
  reverse = false,
  className = ''
}) => {
  let items = [<img src={logo} key="img" alt={text} />, <div key="text">{text}</div>];
  if (reverse) {
    items = items.reverse();
  }

  return (
    <div
      className={`badge ${className !== '' ? className : ''}`}
      style={{
        background,
        color,
        ...containerStyle,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)'
      }}
    >
      {items}
    </div>
  );
};

const Price = ({ price_per_day, total_price, durationDays, durationHours, t, slash_price_per_day }) => (
  <div className="price">
    {slash_price_per_day ? (
      <div className="text-center slash-price">
        <span>
          {slash_price_per_day && Number(slash_price_per_day).toLocaleString()}/{t('day')}
        </span>
        <h3>
          {Number(price_per_day).toLocaleString()}
          <small>/{t('day')}</small>
        </h3>
      </div>
    ) : (
      <React.Fragment>
        <h3>
          {Number(price_per_day).toLocaleString()}
          <small>/{t('day')}</small>
        </h3>
      </React.Fragment>
    )}
    <div>
      {t('for')} {durationDays} {t('days')} {durationHours ? `${durationHours} ${t('hr')}` : ''}
    </div>
    <div className="total">
      {t('total')} ฿{Number(total_price).toLocaleString()}
    </div>
  </div>
);
const Rental = ({ isLocal, t, toggleDocumentModal }) => {
  if (isLocal) {
    return (
      <div>
        <Badge
          containerStyle={{ height: 24, fontWeight: 'bold' }}
          color="#fff"
          background="#FF9E41"
          logo={noCreditCard}
          text={t('no_credit_card_required')}
          className="info-badge"
        />
        <span style={{ cursor: 'pointer' }} onClick={() => toggleDocumentModal('local')}>
          <Badge
            containerStyle={{ height: 24 }}
            background="#FFD24C"
            logo={document}
            text={
              <span>
                {t('required_documents')}
                <i className="flaticon-information" />
              </span>
            }
            className="info-badge"
          />
        </span>
      </div>
    );
  }
  return (
    <div>
      <Badge
        containerStyle={{ height: 24 }}
        color="#fff"
        background="#0053BA"
        logo={creditCard}
        text={
          <span>
            {t('rent_and_pickup')}
            <b>{t('with_credit_card_only')}</b>
          </span>
        }
        className="info-badge"
      />
      <span style={{ cursor: 'pointer' }}>
        <Badge
          containerStyle={{ height: 24 }}
          background="#FFD24C"
          logo={pickup}
          text={<ToolTip text={t('pickup_at_counter')} tooltip={t('tooltips.airport_counter')} />}
          className="info-badge"
        />
      </span>
    </div>
  );
};

const Vendor = ({ vendor_name, branch, lang, logo, isLocal }) => {
  const vendor = vendor_name[lang];
  return (
    <div
      className={`vendor ${isLocal ? 'vendor--local' : 'vendor--inter'} ${
        branch.review.total <= 0 ? 'rating-empty' : ''
      }`}
    >
      <div className="vendor__logo">
        <img className="logo" src={logo} alt={vendor_name} />
      </div>
      <div className="vendor__detail">
        <TooLong length={isLocal ? 16 : 15} text={vendor}>
          {(text) => (
            <div className="name">
              <b>
                {text}
                {!isLocal && <i className="flaticon-shield" id="shield-icon" />}
              </b>
            </div>
          )}
        </TooLong>
        {/* {isLocal && <Star rating={branch.rating} />} */}
        {isLocal ? (
          <Badge
            containerStyle={{ width: 54, height: 19, fontWeight: 'bold' }}
            color="#fff"
            background="#FF8D41"
            logo={local}
            text="LOCAL"
          />
        ) : (
          <Badge
            containerStyle={{
              width: 54,
              height: 19,
              fontWeight: 'bold'
            }}
            color="#fff"
            background="#0053BA"
            logo={inter}
            text="INTER"
          />
        )}
        <RatingTooltip summaryData={branch.review.summary}>
          <div className={`rating-badge ${branch.review.total <= 0 ? 'empty' : ''}`}>
            <div className="score">
              {branch.review.rating}
              <span>/5</span>
            </div>
            <div className="level">
              {branch.review.total <= 0 ? (
                'ยังไม่มีรีวิว'
              ) : (
                <React.Fragment>
                  {branch.review.level} <span className="total-review">({branch.review.total} รีวิว)</span>
                </React.Fragment>
              )}
            </div>
          </div>
        </RatingTooltip>
      </div>
    </div>
  );
};

const FreeCancel = ({ isLocal }) =>
  isLocal ? (
    <div className="freecancel">
      <ToolTip text="free_cancel" placement="topRight" tooltip="tooltips.free_cancel_72hrs" />
    </div>
  ) : (
    <div className="freecancel">
      <ToolTip text="free_cancel" placement="topRight" tooltip="tooltips.free_cancel_48hrs" />
    </div>
  );

const ZeroExcess = ({ t, toggleInsuranceModal, zeroExcess }) => (
  <div className="zeroexcess mt-1" onClick={() => toggleInsuranceModal()}>
    <img src={insuranceIcon} alt="insurance-icon" />
    <div className="badge-text">
      {zeroExcess ? (
        <React.Fragment>
          <p className="mb-1">
            <strong>รถคันนี้ฟรี !!</strong> ประกันภัยแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ + ประกันการโจรกรรม (Zero
            Excess) <i className="flaticon-information" />
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            <strong>{t('zero_excess.more_secure')}</strong> {t('zero_excess.when_you_buy_from_us')}
          </p>
          <p>
            {t('zero_excess.starts_at')} &nbsp;
            <i className="flaticon-information" />
          </p>
        </React.Fragment>
      )}
    </div>
  </div>
);
// const Action = ({ t }) => <button className="btn btn-primary rent-button">{t('action')}</button>
export default withTranslation('card', 'common')(Card);

const ImgCarWrapper = styled.div`
  background-image: url(${(props) => props.src});
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
