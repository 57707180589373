import { defineAction } from 'redux-define'
import { combineReducers } from 'redux'

export const SUMMARY_RATINGS_REVIEWS_ACTION = defineAction('SUMMARY_RATINGS_REVIEWS', [
  'FETCHING',
  'CACHE',
  'FETCHED',
  'ERROR',
  'INITIAL',
  'FETCHING_BRANCH_LEVEL',
  'FETCHED_BRANCH_LEVEL',
  'TOGGLE_LEVEL',
])
export const RATINGS_REVIEWS_ACTION = defineAction('RATINGS_REVIEWS', [
  'FETCHING',
  'FETCHED',
  'ERROR',
  'INITIAL',
])

const initialRatingsReviewState = {
  fetching: false,
  branchLevelStats: {
    rating: 0,
    summary: [],
    total: 0,
    level: '',
  },
  carLevelStats: {
    rating: 0,
    summary: [],
    total: 0,
    level: '',
  },
  currentStats: {
    fetching: false,
    rating: 0,
    summary: [],
    total: 0,
    level: '',
  },
  comments: {
    fetching: false,
    reviews: [],
    total: 0,
  },
  isCarLevel: false,
}
const initialRatingReviewComments = {
  comments: {
    fetching: false,
    reviews: [],
    total: 0,
  },
}

const reviews = (state = initialRatingsReviewState, { type, payload, isCarLevel }) => {
  switch (type) {
    case SUMMARY_RATINGS_REVIEWS_ACTION.INITIAL: {
      return initialRatingsReviewState
    }
    case SUMMARY_RATINGS_REVIEWS_ACTION.FETCHING: {
      return {
        ...state,
        currentStats: {
          ...state.currentStats,
          fetching: true,
        },
        fetching: true,
      }
    }

    case SUMMARY_RATINGS_REVIEWS_ACTION.FETCHING_BRANCH_LEVEL: {
      return {
        ...state,
        branchLevelStats: {
          ...state.branchLevelStats,
          fetching: true,
        },
        currentStats: {
          ...state.currentStats,
          fetching: true,
        },
        fetching: true,
      }
    }

    case SUMMARY_RATINGS_REVIEWS_ACTION.FETCHED: {
      return {
        ...state,
        currentStats: {
          ...payload,
          fetching: false,
        },
        carLevelStats: isCarLevel ? { ...payload } : { ...state.carLevelStats },
        fetching: false,
      }
    }
    case SUMMARY_RATINGS_REVIEWS_ACTION.FETCHED_BRANCH_LEVEL: {
      return {
        ...state,
        branchLevelStats: {
          ...payload,
          fetching: false,
        },
        currentStats: {
          ...payload,
          fetching: false,
        },
        fetching: false,
      }
    }

    case SUMMARY_RATINGS_REVIEWS_ACTION.TOGGLE_LEVEL: {
      return {
        ...state,
        isCarLevel: !state.isCarLevel,
      }
    }

    case SUMMARY_RATINGS_REVIEWS_ACTION.ERROR: {
      return initialRatingsReviewState
    }

    // Get Comment Section

    case RATINGS_REVIEWS_ACTION.INITIAL: {
      return {
        ...state,
        ...initialRatingReviewComments,
      }
    }

    case RATINGS_REVIEWS_ACTION.FETCHING: {
      return {
        ...state,
        ...initialRatingReviewComments,
        fetching: false,
        comments: {
          fetching: true,
          ...state.comments,
        },
      }
    }

    case RATINGS_REVIEWS_ACTION.FETCHED: {
      return {
        ...state,
        fetching: false,
        comments: {
          fetching: false,
          ...payload,
        },
      }
    }

    case RATINGS_REVIEWS_ACTION.ERROR: {
      return {
        ...state,
        ...initialRatingReviewComments,
      }
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  reviews,
})
