import moment from 'moment-timezone';

export function getTimeWithOutTimeZone(time, format = 'Do MMM YY, HH:mm') {
  const timeWithOutZ = time.replace(/Z/g, '');
  return moment(timeWithOutZ).format(format);
}

export function convertLocalTimetoUTCTime(datetime) {
  // NOTE: Take moment object
  const UTCTime = moment(datetime, 'YYYY-MM-DD HH:mm')
    .utc()
    .format();
  return UTCTime;
}

export function getValidDate(date, addDays) {
  const _date = moment(date);

  if (date === undefined || !_date.isValid()) {
    const addedDays = moment().add(addDays, 'days');
    return moment(`${addedDays.format('YYYY-MM-DD')} 10:00`);
  } else {
    return _date;
  }
}

export function getDiffDateAsHours(startDate, endDate = moment()) {
  return moment(startDate).diff(endDate, 'hours');
}

export function readCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function setCookie(cname, cvalue, exdays, cpath) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + `;path=${cpath};domain=.drivehub.co`;
  // ';domain=localhost'
}

export function deleteCookie(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.drivehub.co;`;
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=drivehub-dev.drivehub.co;`;
}

export const readLocalStorage = (key) => {
  return window.localStorage.getItem(key);
};

export const setLocalStorage = (key, value) => {
  return window.localStorage.setItem(key, value);
};
export const deleteLocalStorage = (key) => {
  return window.localStorage.removeItem(key);
};
