import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from '../../../locales';
import * as actions from '../../../ducks/actions/booking';
import { SEARCH_CARS, CONFIRM_BOOKING } from '../../../variables/page';
import './style.scss';
import { dataLayer } from '@utils/datalayer';

const paymentText = (t) => {
  return (
    <MediaQuery maxWidth={768}>
      {(matches) => {
        if (matches) {
          return t('bigbrand.payment.mobile');
        } else {
          return t('bigbrand.payment.desktop');
        }
      }}
    </MediaQuery>
  );
};
const ProgressBar = (props) => {
  const { t } = props;
  const isInter = ['inter', 'bigbrand'].includes(props.carSource);
  return (
    <div className="progress_bar_wrapper container">
      <Breadcrumb className="breadcrumb-arrow margin-top-sm">
        <BreadcrumbItem active={props.active === SEARCH_CARS ? true : false}>
          {props.urlToSearchResultPage ? (
            <a
              onClick={(e) => dataLayer(e)}
              data-event-category="product_detail_section"
              data-event-action="back"
              data-event-label="search_result_page"
              href={props.urlToSearchResultPage ? props.urlToSearchResultPage : ''}
            >
              {t('1')}
            </a>
          ) : (
            <span className="text-decoration-note">{t('1')}</span>
          )}
        </BreadcrumbItem>
        <BreadcrumbItem
          active={props.step === 0 && props.active !== SEARCH_CARS && props.active !== CONFIRM_BOOKING ? true : false}
        >
          {props.active === CONFIRM_BOOKING ? (
            <span className="text-decoration-note">{t('2')}</span>
          ) : (
            <span className={`${props.step > 0 ? 'link-active' : ''}`} onClick={() => props.skip(0)}>
              {t('2')}
            </span>
          )}
        </BreadcrumbItem>
        <BreadcrumbItem
          active={props.step === 1 && props.active !== SEARCH_CARS && props.active !== CONFIRM_BOOKING ? true : false}
        >
          {props.active === CONFIRM_BOOKING ? (
            <span className="text-decoration-note">{t('3')}</span>
          ) : props.step > 1 ? (
            <span className={`${props.step > 1 ? 'link-active' : ''}`} onClick={() => props.skip(1)}>
              {t('3')}
            </span>
          ) : (
            <span className="text-decoration-note">{t('3')}</span>
          )}
        </BreadcrumbItem>
        <BreadcrumbItem
          active={props.step === 2 && props.active !== SEARCH_CARS && props.active !== CONFIRM_BOOKING ? true : false}
        >
          {props.active === CONFIRM_BOOKING ? (
            <span className="text-decoration-note">{isInter ? paymentText(t) : t('4')}</span>
          ) : props.step > 2 ? (
            <span className={`${props.step > 2 ? 'link-active' : ''}`} onClick={() => props.skip(2)}>
              {isInter ? paymentText(t) : t('4')}
            </span>
          ) : (
            <span className="text-decoration-note">{isInter ? paymentText(t) : t('4')}</span>
          )}
        </BreadcrumbItem>
        <BreadcrumbItem active={props.active === CONFIRM_BOOKING ? true : false}>
          <span className="text-decoration-note last-child-progressbar" style={{ border: 'none' }}>
            {isInter ? t('5.bigbrand') : t('5.local')}
          </span>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};

ProgressBar.propTypes = {
  active: PropTypes.string, // NOTE: 'searchCars', 'carDetails', 'bookingDetails', 'confirmBooking'
  urlToSearchResultPage: PropTypes.string,
  urlToCarDetailsPage: PropTypes.string
};
const mapStateToProps = (state) => ({
  step: state.booking.step
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      skip: actions.skip
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('progress')(ProgressBar));
