import React, { useState, useEffect, createRef, useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import { setCookie, readCookie } from '../utils';
import { ContextType } from '../context';
import './style.scss';
interface CookieBoxProps {
  prefix: string;
}
export const CookieBox: React.FC<CookieBoxProps> = ({ prefix }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const context = useContext(ContextType);
  const ref = createRef<HTMLDivElement>();
  const getElemRef = () => {
    // Start : To prevent SSR when call document
    const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
    if (!canUseDOM) return [null, null];
    // Stop : To prevent SSR when call document
    const bannerSearchRef = document.getElementById('banner-box');
    const contentSearchRef = document.getElementById('content-box');
    return [bannerSearchRef, contentSearchRef];
  };
  const handleResponsive = () => {
    const [bannerSearchRef, contentSearchRef] = getElemRef();
    if (ref.current) {
      if (!bannerSearchRef) return;
      if (!contentSearchRef) return;
      const defaultHeight = 512;
      if (context.isMobile) contentSearchRef.style.paddingTop = `${52 + ref.current.clientHeight}px`;
      bannerSearchRef.style.height = `${defaultHeight + ref.current.clientHeight}px`;
    }
  };

  useEffect(() => {
    const cookieAccept = readCookie('cookies_allow');
    const [bannerSearchRef, contentSearchRef] = getElemRef();
    if (cookieAccept === 'true') return setVisible(false);
    setVisible(true);
    if (ref.current) {
      if (!bannerSearchRef) return;
      if (!contentSearchRef) return;
      const defaultHeight = 512;
      if (context.isMobile) contentSearchRef.style.paddingTop = `${52 + ref.current.clientHeight + 32}px`;
      bannerSearchRef.style.height = `${defaultHeight + ref.current.clientHeight + 32}px`;
    }

    const listener = () => {
      const [bannerSearchRef, contentSearchRef] = getElemRef();
      if (ref.current) {
        if (!bannerSearchRef) return;
        if (!contentSearchRef) return;
        const defaultHeight = 512;
        if (context.isMobile) contentSearchRef.style.paddingTop = `${52 + ref.current.clientHeight}px`;
        bannerSearchRef.style.height = `${defaultHeight + ref.current.clientHeight}px`;
      }
    };
    window.addEventListener('resize', listener, false);
    return () => window.removeEventListener('resize', listener);
  }, [context.isMobile, ref]);

  const toggleVisible = () => {
    const [bannerSearchRef, contentSearchRef] = getElemRef();
    setVisible(false);
    setCookie('cookies_allow', 'true', 60, '/');
    if (!bannerSearchRef) return;
    if (!contentSearchRef) return;
    const defaultHeight = 512;
    bannerSearchRef.style.height = `${defaultHeight}px`;
    if (context.isMobile) contentSearchRef.style.paddingTop = `52px`;
    window.removeEventListener('resize', handleResponsive);
  };

  return (
    <Alert show={visible} onClose={toggleVisible} className="cookies-box m-0 rounded-0 p-3" ref={ref}>
      <div>
        เราใช้คุกกี้เพื่อมอบประสบการณ์การใช้งานเว็บไซต์ Drivehub.co ของท่านที่ดีกว่าเดิม ในการใช้งานเว็บไซต์ของเรา
        ถือว่าท่านยอมรับการใช้คุกกี้ตามที่ระบุใน{' '}
        <a className="d-inline" href={`/${prefix}/privacy`}>
          นโยบายความเป็นส่วนบุคคล
        </a>
      </div>
      <i className="icon-close" onClick={toggleVisible} />
    </Alert>
  );
};
