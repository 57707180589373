// @ts-nocheck

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import MediaQuery from 'react-responsive';
import axios from 'axios';
import moment from 'moment-timezone';
import Theme from '@ui/components/theme';
import Context from '@ui/components/context';
import SearchResultPage from './pages/SearchResultPage';
import CarDetailsAndBookingReview from './pages/CarDetailsAndBookingReview';
import BookingDetail from './pages/BookingDetail';
import BookingSuccess from './pages/BookingSuccess';
import Chat from './pages/Chat';
import { BOOKING_SUCCESS_PAGE, CAR_DETAILS_AND_BOOKING_REVIEW, BOOKING_DETAIL, CHAT } from './variables/route';
import WithHeaderLayout from './layouts/WithHeader';
import 'font-awesome/css/font-awesome.css';
import { Provider } from './locales';
import { readCookie, setCookie, deleteLocalStorage, deleteCookie } from './utils';
import { MARKETING_API } from './variables/API';
import { CS_MODE } from './variables/features';

import './scss/app.scss';
import './assets/icon/flaticon.css';
import { pushDataLayer } from '@ui/utils/datalayer';

moment.tz('Asia/Bangkok');

interface AppState {
  lang: string | null;
}
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'th'
    };
    this.setLang = this.setLang.bind(this);
  }
  _intervalID = 0;

  componentDidMount() {
    setTimeout(() => {
      pushDataLayer({
        event: 'virtual_pageview',
        pageTitle: document.title,
        pathPath: document.location.pathname + document.location.search
      });
    }, 500);

    this._intervalID = window.setInterval(() => {
      const duid = readCookie('__duid');
      const isLogOut = readCookie('logout');
      if (isLogOut !== null) {
        deleteCookie('logout');
        deleteLocalStorage('customer');
        deleteLocalStorage('consumer_first_name');
        deleteLocalStorage('consumer_last_name');
        deleteLocalStorage('consumer_email');
        deleteLocalStorage('consumer_phone_number');
      }
      if (CS_MODE) return;
      duid !== null && this.userInit(duid);
    }, 1000);
  }

  // userLogOut = () => {};

  userInit = (duid) => {
    clearInterval(this._intervalID);
    return axios
      .post(`${MARKETING_API}/voucher/init`, {
        duid: duid
      })
      .then(({ data }) => {
        const actionType = data.action;
        const isUserHaveFirstVisitCookie = readCookie('_FV');
        const userCampaignSession = readCookie('_CP');
        if (actionType === 'found' && isUserHaveFirstVisitCookie) return;

        const datetimeEncoded = window.btoa(moment().format('YYYY-MM-DD HH:mm'));
        setCookie('_FV', datetimeEncoded, 365, '/');
        !userCampaignSession && setCookie('_CP', '16MAR31MAR', 365, '/');
      });
  };

  setLang(lang) {
    if (this.state.lang === lang) return;
    this.setState({ lang });
    localStorage.setItem('lang', lang); // NOTE: Save lang to local storage.
  }

  render() {
    const { lang } = this.state;

    return (
      <Context>
        <Provider value={lang}>
          <BrowserRouter>
            <div>
              <Helmet>
                <meta charSet="utf-8" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
                {/* <!-- Twitter universal website tag code --> */}
                {process.env.REACT_APP_STAGE === 'production' ? (
                  <script async>
                    {`
                    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
                    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
                    twq('init','o1sto');
                    twq('track','PageView');
                  `}
                  </script>
                ) : (
                  ''
                )}
                {/* <!-- End Twitter universal website tag code --> */}

                {/* <!--Ecomobi record traffic id--> */}
                {process.env.REACT_APP_STAGE === 'production' ? (
                  <script type="text/javascript" src="//go.ecotrackings.com/eco_tracking_all_order.js">
                    {' '}
                  </script>
                ) : (
                  <script type="text/javascript" src="//dev-go.ecotrackings.com/eco_tracking_all_order.js">
                    {' '}
                  </script>
                )}
                {/* END Ecomobi SCRIPT */}

                {/* START 2C2P SCRIPT */}
                {process.env.REACT_APP_STAGE !== 'production' ? (
                  <script
                    type="text/javascript"
                    src="https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js"
                  ></script>
                ) : (
                  <script
                    type="text/javascript"
                    src="https://t.2c2p.com/SecurePayment/api/my2c2p.1.6.9.min.js"
                  ></script>
                )}
                {/* END 2C2P SCRIPT */}
              </Helmet>
              <div className="page-start" />
              <Theme>
                <Switch>
                  <Route
                    path={`${CAR_DETAILS_AND_BOOKING_REVIEW}/:id`}
                    component={(props) => (
                      <WithHeaderLayout {...props} setLang={this.setLang}>
                        {CarDetailsAndBookingReview}
                      </WithHeaderLayout>
                    )}
                  />
                  <Route
                    path={`${BOOKING_SUCCESS_PAGE}/:id`}
                    exact
                    component={(props) => (
                      <WithHeaderLayout {...props} setLang={this.setLang}>
                        {BookingSuccess}
                      </WithHeaderLayout>
                    )}
                  />
                  <Route
                    path={`${BOOKING_DETAIL}/:id`}
                    exact
                    component={(props) => (
                      <WithHeaderLayout {...props} setLang={this.setLang}>
                        {BookingDetail}
                      </WithHeaderLayout>
                    )}
                  />
                  <Route
                    path={`${CHAT}/:id`}
                    exact
                    component={(props) => (
                      <MediaQuery minWidth={992}>
                        {(matches) => {
                          if (matches) {
                            return (
                              <WithHeaderLayout {...props} setLang={this.setLang}>
                                {Chat}
                              </WithHeaderLayout>
                            );
                          } else {
                            return <Chat {...props} setLang={this.setLang} />;
                          }
                        }}
                      </MediaQuery>
                    )}
                  />
                  <Route path="/" component={SearchResultPage} />
                </Switch>
              </Theme>
            </div>
          </BrowserRouter>
        </Provider>
      </Context>
    );
  }
}

export default App;
