import React from 'react';
import PropTypes from 'prop-types';
import { LOCAL } from '../../../variables/source';
import { ACCEPTED, PENDING } from '../../../variables/bookingStatus';
import ChatButton from '../../atoms/ChatButton';
import './style.scss';

const DocumentDetail = (props) => {
  const { source, currentState, lastName, bookingID, feature } = props;
  if (source === LOCAL && currentState === PENDING)
    return (
      <div className="document-detail">
        <p>เอกสารที่ต้องส่งให้กับบริษัทรถเช่า</p>
        <div className="detail-items">
          <div className="item">
            <i className="flaticon-idcard1x"></i>
            <span>บัตรประชาชน หรือ หนังสือเดินทาง</span>
          </div>
          <div className="item">
            <i className="flaticon-car1x"></i>
            <span>ใบขับขี่ หรือใบขับขี่สากล</span>
          </div>
          <div className="item">
            <i className="flaticon-doc1x"></i>
            <span>เอกสารประกอบอื่นๆ ที่ได้ตกลงไว้กับบริษัทรถเช่า</span>
          </div>
        </div>
        {feature.chat && (
          <div className="footer">
            <p>
              <small>พูดคุยกับบริษัทรถเช่า เพื่อส่งเอกสารและอนุมัติการจอง</small>
            </p>
            <ChatButton source={source} lastName={lastName} bookingID={bookingID} />
          </div>
        )}
      </div>
    );
  if (currentState === ACCEPTED)
    return (
      <div className="document-detail">
        <p>เอกสารที่ต้องใช้ในวันรับรถ (ตัวจริงเท่านั้น)</p>
        <div className="detail-items">
          <div className="item">
            <i className="flaticon-profile1x"></i>
            <span>บัตรประชาชน หรือ หนังสือเดินทาง</span>
          </div>
          <div className="item">
            <i className="flaticon-phone1x"></i>
            <span>ใบขับขี่ หรือใบขับขี่สากล (จำเป็น)</span>
          </div>
          <div className="item">
            {source === LOCAL ? (
              <React.Fragment>
                <i className="flaticon-email1x"></i>
                <span>เอกสารประกอบอื่นๆ ที่ได้ตกลงไว้กับบริษัทรถเช่า</span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i className="flaticon-creditcard1x"></i>
                <span>บัตรเครดิต</span>
              </React.Fragment>
            )}
          </div>
        </div>
        {feature.chat && (
          <div className="footer">
            <p>
              <small>กรุณานำเอกสารไปให้ครบมิฉะนั้นบริษัทรถเช่าจะไม่สามารถปล่อยรถเช่าให้คุณได้</small>
            </p>
            <ChatButton source={source} lastName={lastName} bookingID={bookingID} isTransparent />
          </div>
        )}
      </div>
    );
  return '';
};

DocumentDetail.defaultProps = {
  source: '',
  currentState: ''
};

DocumentDetail.propTypes = {
  source: PropTypes.string,
  currentState: PropTypes.string
};

export default DocumentDetail;
