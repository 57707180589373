import React from 'react'

const question = {
  icon: '',
  question: 'การเปลี่ยนแปลงการจองทำอย่างไร?',
  answer: (
    <div>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        ท่านสามารถแจ้งการเปลี่ยนแปลงได้ทั้ง 2 ช่องทาง
        <div className="indent">1. บริษัทรถเช่าที่ท่านทำการจองเข้ามา</div>
        <div className="indent">
          2. เจ้าหน้าที่ Drivehub ที่เบอร์ 02-038-5222 หรือ Line Id: @Drivehub
        </div>
      </div>
      <div className="indent">
        ค่าใช้จ่ายและรถที่ท่านเลือก
        อาจจะเปลี่ยนแปลงได้ทั้งนี้เจ้าหน้าที่จะตรวจสอบข้อมูลให้และแจ้งกลับ
      </div>
      <b>
        <u>หากท่านทำการจองรถที่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        ท่านสามารถแจ้งข้อมูลกับเจ้าหน้าที่ Drivehub ที่เบอร์ 02-038-5222 หรือ Line Id: @Drivehub
      </div>
    </div>
  ),
}

export default question
