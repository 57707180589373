/* eslint-disable */
import React from 'react'
import PaymentTypeStore from './PaymentTypeStore'
import { observer } from 'mobx-react'
import CreditSymbol from '../../../../assets/icon/credit_symbol.png'
import KPNLogo from '../../../../assets/images/kpn_logo.png'
import NationalLogo from '../../../../assets/images/national_logo.png'
import SixtLogo from '../../../../assets/images/sixt_logo.png'
import { CAR_DETAILS } from '../../../../variables/page'

const question = {
  icon: 'flaticon-book',
  question: 'จะต้องทำการชำระเงินอย่างไร?',
  answer: action => {
    class Pay extends React.Component {
      componentDidMount = () => {
        const { source } = action
        if (['inter', 'bigbrand'].includes(source)) {
          this.props.store.selectType('credit')
        }
      }

      render() {
        const type = this.props.store.type
        const select = type => this.props.store.selectType(type)
        const { active } = action

        return (
          <div className="faq">
            {active === CAR_DETAILS ? (
              ''
            ) : (
              <div className="row justify-content-around tabs no-gutters">
                <div className="col-sm">
                  {/* FIXME:  */}
                  <button
                    onClick={() => select('cash')}
                    className={`btn ${type === 'cash' ? 'active' : ''} faq_tab`}
                  >
                    ชำระด้วยเงินสด หรือ <br />
                    บัตรเครดิต ณ วันรับรถ
                  </button>
                </div>
                <div className="col-sm">
                  <button
                    onClick={() => select('credit')}
                    className={`btn ${type === 'credit' ? 'active' : ''} faq_tab`}
                  >
                    ชำระด้วยบัตรเครดิตบนหน้าเว็บ*
                  </button>
                </div>
              </div>
            )}

            {type === 'cash' && (
              <div>
                <p className="indent">
                  {/* FIXME:  */}
                  สำหรับลูกค้าใหม่หรือทำการจองรถช่วงเทศกาล
                  <b>
                    {' '}
                    ทางบริษัทรถเช่าอาจเรียกเก็บค่าเช่าล่วงหน้าเพื่อล็อคคิวรถเป็นจำนวนเงิน 500-1000
                    บาท
                  </b>
                  โดยจะหักจากค่าเช่ารถ มิได้เป็นการเรียกเก็บเพิ่มเติม
                  และในวันรับรถท่านจะชำระเพียงค่าเช่าส่วนที่เหลือพร้อมกับค่าประกันความเสียหายด้วยบัตรเครดิตหรือเงินสด
                </p>
                <p className="indent">
                  หากชำระด้วยบัตรเครดิต จะมีการเรียกเก็บค่าธรรมเนียมเพิ่มเติมประมาณ 3%
                  จากราคาเช่าและค่าประกันความเสียหาย
                  <b>
                    {''}
                    ซึ่งจะมีให้บริการในเพียงบางจังหวัด
                    และบริษัทรถเช่าเพียงบางร้านเท่านั้นที่สามารถรับชำระด้วยบัตรเครดิตได้
                  </b>
                </p>
                <p>
                  *กรณีเช่ารถในช่วงวันเทศกาลหรือไฮซีซั่น (เดือนตุลาคมถึงกุมภาพันธ์)
                  ทางร้านอาจขอเรียกเก็บค่าล็อคคิวรถในจำนวนที่เพิ่มขึ้นทั้งนี้ขึ้นอยู่กับเงื่อนไขของทางบริษัทรถเช่านั้นๆ
                </p>
              </div>
            )}
            {type === 'credit' && (
              <React.Fragment>
                <div>
                  <p>สามารถใช้บัตรเครดิตชำระค่าเช่าล่วงหน้าผ่านเว็บไซต์ได้ทันที</p>
                  <div className="faq_note">
                    <u>หมายเหตุ</u>
                    <br />
                    {/* FIXME:  */}
                    *เฉพาะรถที่มีสัญลักษณ์บัตรเครดิตเท่านั้น{' '}
                    <img src={CreditSymbol} height="47" alt="Credit Card" />
                  </div>
                </div>
                <p className="mt-2" style={{ textDecoration: 'underline' }}>
                  บริษัทรถเช่าที่เปิดให้บริการ
                </p>
                <div className="faq_bigbrand_logo mb-2">
                  <img src={KPNLogo} height="30" alt="KPN" />
                  <img src={NationalLogo} height="30" alt="National" />
                  <img src={SixtLogo} height="30" alt="Sixt" />
                </div>
              </React.Fragment>
            )}
          </div>
        )
      }
    }
    Pay = observer(Pay)
    return <Pay store={PaymentTypeStore} />
  },
}

export default question
