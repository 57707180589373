import React, { Component } from 'react'
import ReactMarkDown from 'react-markdown/with-html'
import DefaultBigbrandCondition from './default'
import './style.scss'
export default class BigbrandCondition extends Component {
  get content() {
    const { vendor } = this.props
    if (vendor) {
      return vendor.term.content_th !== null ? (
        vendor.term.content_th.map((el, i) => (
          <ReactMarkDown source={el} escapeHtml={false} key={i} />
        ))
      ) : (
        <DefaultBigbrandCondition hideVisa={this.props.hideVisa} />
      )
    } else {
      return <DefaultBigbrandCondition hideVisa={this.props.hideVisa} />
    }
  }

  render() {
    return <div className="rental-condition__bigbrand">{this.content}</div>
  }
}
