import React from 'react';
import PropTypes from 'prop-types';
import ContactModal from '../../atoms/ContactModal';
import './style.scss';
import { dataLayer } from '@ui/utils/datalayer';

const CustomerDetail = (props) => {
  const {
    customer: { first_name, last_name, phone_number, email }
  } = props;
  return (
    <div className="customer-detail">
      <p>ข้อมูลผู้เช่ารถ</p>
      <div className="detail-items">
        <div className="item">
          <i className="flaticon-profile1x"></i>
          <span>{`${first_name} ${last_name}`}</span>
        </div>
        <div className="item">
          <i className="flaticon-phone1x"></i>
          <span>{phone_number}</span>
        </div>
        <div className="item">
          <i className="flaticon-email1x"></i>
          <span>{email}</span>
        </div>
      </div>
      <div className="footer">
        <i className="flaticon-qustion1x"></i>
        <small>
          {`หากต้องการแก้ไขข้อมูลกรุณาติดต่อ  `}
          <ContactModal>
            <span
              data-event-category="bookingstatus_check_section"
              data-event-action="edit_booking_detail"
              data-event-label="call_center"
              onClick={(e) => dataLayer(e)}
            >
              Call center
            </span>
          </ContactModal>
        </small>
      </div>
    </div>
  );
};

CustomerDetail.defaultProps = {
  customer: {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: ''
  }
};

CustomerDetail.propTypes = {
  customer: PropTypes.object
};

export default CustomerDetail;
