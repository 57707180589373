import React, { Component } from 'react';
import MainMenu from '../components/organisms/MainMenu';
// import Footer from '../components/molecules/Footer'
import Footer from '@ui/layouts/footer';

export default class StickyHeaderLayout extends Component {
  render() {
    return (
      <div>
        <MainMenu sticky={false} />
        <div>
          <this.props.children {...this.props} />
        </div>
        <Footer prefix="th" />
      </div>
    );
  }
}
