import React, { Fragment } from 'react';
import Mediaquery from 'react-responsive';

import carFrameIco from '../../../assets/icon/car-frame.svg';
import theftIco from '../../../assets/icon/theft-icon.svg';
import windowTyreIco from '../../../assets/icon/window-tyre-icon.svg';
import thidPersionIco from '../../../assets/icon/third-person-icon.svg';
import greenCheckIco from '../../../assets/icon/green-check-icon.svg';
import './style.scss';
const InsuranceTable = () => (
  <Fragment>
    {/* Desktop Version */}
    <Mediaquery minWidth={481}>
      <table className="table table-bordered text-center table--insurance table--desktop">
        <thead>
          <tr>
            <th>
              <h4>ประเภทประกันภัย</h4>
            </th>
            <th>
              <img alt="icon-insurance" src={thidPersionIco} />
              <br />
              ความคุ้มครอง
              <br />
              <b>บุคคลที่ 3</b>
            </th>
            <th>
              <img alt="icon-insurance" src={carFrameIco} />
              <br />
              ความคุ้มครอง
              <br />
              <b>Zero Excess (ตัวถัง)</b>
            </th>
            <th>
              <img alt="icon-insurance" src={theftIco} />
              <br />
              ความคุ้มครอง
              <br />
              <b>โจรกรรม</b>
            </th>
            <th>
              <img alt="icon-insurance" src={windowTyreIco} />
              <br />
              ความคุ้มครอง
              <br />
              <b>กระจก ยาง และล้อ</b>
            </th>
            <th>
              <b>ราคาต่อวัน*</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Row 1 */}
          <tr>
            <td>ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>รวมในค่าเช่าแล้ว</td>
          </tr>
          {/* Row 2 */}
          <tr>
            <td>ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (SCDW / SLDW / ECOW)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>-</td>
            <td>-</td>
            <td>240-535 บาท</td>
          </tr>
          {/* Row 3 */}
          <tr>
            <td>ประกันแบบไม่มีค่าเสียหายส่วยแรกเต็มรูปแบบ (PCDW / PLDW)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>-</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>350-550 บาท</td>
          </tr>
          {/* Row 4 */}
          <tr>
            <td>ประกันโจรกรรม (TP)</td>
            <td>-</td>
            <td>-</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>-</td>
            <td>85-150 บาท</td>
          </tr>
          {/* Row 5 */}
          <tr>
            <td>ประกันแบบไม่มีค่าเสียหายส่วยแรก Zero Excess + ประกันการโจรกรรม (SCDW + TP)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>-</td>
            <td>265-588 บาท</td>
          </tr>
          {/* Row 6 */}
          <tr>
            <td>ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ + ประกันการโจรกรรม (PCDW+TP)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>350-650 บาท</td>
          </tr>
        </tbody>
      </table>
    </Mediaquery>

    {/* Mobile Version */}
    <Mediaquery maxWidth={480}>
      <div className="card card--insurance card--mobile">
        {/* Row 1 */}
        <div className="card-body mb-2 shadow">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>CDW</div>
              <div>ฟรี</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Row 2 */}
        <div className="card-body mb-2 shadow">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>SCDW / SLDW / ECOW</div>
              <div>240-535 บาท</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={carFrameIco} />
                  </div>
                  <div>Zero Excess (ตัวถัง)</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Row 3 */}
        <div className="card-body mb-2 shadow">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>PCDW / PLDW</div>
              <div>240-535 บาท</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={carFrameIco} />
                  </div>
                  <div>Zero Excess (ตัวถัง)</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={windowTyreIco} />
                  </div>
                  <div>กระจก ยาง และล้อ</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Row 4 */}
        <div className="card-body mb-2 shadow">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>TP</div>
              <div>85-150 บาท</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={theftIco} />
                  </div>
                  <div>โจรกรรม</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Row 5 */}
        <div className="card-body mb-2 shadow">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>SCDW+TP</div>
              <div>265-588 บาท</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={carFrameIco} />
                  </div>
                  <div>Zero Excess (ตัวถัง)</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={theftIco} />
                  </div>
                  <div>โจรกรรม</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-body mb-2 shadow">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>PCDW+TP</div>
              <div>350-650 บาท</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={carFrameIco} />
                  </div>
                  <div>Zero Excess (ตัวถัง)</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={theftIco} />
                  </div>
                  <div>โจรกรรม</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={windowTyreIco} />
                  </div>
                  <div>กระจก ยาง และล้อ</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Mediaquery>
  </Fragment>
);
export default InsuranceTable;
