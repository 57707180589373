import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const TimePicker = (props) => {
  return (
    <div className="time-picker">
      <select value={props.value} onChange={props.handleChange}>
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

TimePicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array
};

export default TimePicker;
