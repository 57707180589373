import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const TYPE = {
  inter: 'INTER',
  local: 'LOCAL'
};

const InterLocalBagde = (props) => {
  return (
    <div className="d-inline-block">
      <div
        className={`inter-local-badge ${props.type === 'local' ? 'orange' : ''} ${
          props.size === 'small' ? 'small' : ''
        } ${props.className ? props.className : ''}`}
      >
        <img src={props.icon} alt={props.type} /> <span className="ml-1">{TYPE[props.type]}</span>
      </div>
    </div>
  );
};

InterLocalBagde.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['inter', 'local']).isRequired,
  size: PropTypes.oneOf(['small', 'medium'])
};

export default InterLocalBagde;
