import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import th from 'react-intl/locale-data/th';
import TagManager from 'react-gtm-module';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './ducks/store';
import './ducks/reactotronConfig';
import { GTM_ID } from './config';

// import registerServiceWorker from './registerServiceWorker'

addLocaleData(en); // [TODO: move to app.js ?]
addLocaleData(th); // [TODO: move to app.js ?]

const tagManagerArgs = {
  gtmId: GTM_ID
};
smoothscroll.polyfill();
TagManager.initialize(tagManagerArgs);

const store = configureStore();
const renderApp = () =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', () => {
    renderApp();
  });
}
if (window && document) {
  window['toggleSticky'] = () => {
    const element = document.getElementById('sticky-head');
    if (element) {
      try {
        const style = element.style;
        if (style.zIndex === '9999') {
          style.zIndex = '99';
        } else {
          style.zIndex = '100';
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
}

renderApp();
