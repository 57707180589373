import React, { Component } from 'react';
import RentalCondition from '../../organisms/RentalCondition';
import Cards from 'react-credit-cards';
import Payment from 'payment';
import moment from 'moment-timezone';
import cvcIcon from '../../../assets/images/cvv-icon.png';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import './style.scss';

export default class CreditCardForm extends Component {
  state = {
    focused: 'number',
    isValid: false
  };
  componentDidMount() {
    // Payment.formatCardNumber(document.querySelector('[name="number"]'))
    Payment.formatCardCVC(document.querySelector('[name="cvc"]'));
  }
  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  getCardNumberFormated = (value) => {
    return value.length === 4 || value.length === 9 || value.length === 14 ? value + ' ' : value;
  };

  handleInputChange(key) {
    return (event) => {
      this.props.onChange({
        key,
        value: event.target.value
      });
    };
  }
  handleCallback = (type, isValid) => {
    this.setState({ isValid: isValid });
  };

  get expMonthOptions() {
    const n = 12;
    return Array.from(Array(n).keys()).map((_, i) => {
      if (i + 1 < 10) {
        return `0${i + 1}`;
      }
      return i + 1;
    });
  }

  get expYearOptions() {
    const n = 10;
    return Array.from(Array(n).keys()).map((_, i) => parseInt(moment().format('YYYY'), 10) + i);
  }

  render() {
    const { number, name, expires, cvc, t, vendor, error } = this.props;
    return (
      <div className="contact_form_wrapper cc_form_wrapper">
        <p className="form_title">ข้อมูลบัตรเครดิตเพื่อชำระเงิน</p>
        <Form id="2c2p-payment-form" name="booking_payment">
          <Form.Label>หมายเลขบัตรเครดิต</Form.Label>
          <Form.Group>
            <i className="flaticon-lock" />
            <Form.Control
              className="form-control--payment-number"
              name="number"
              type="tel"
              isInvalid={error && !!error.card}
              onChange={(e) => {
                // 1st run
                this.props.onChange({
                  key: 'payment.number',
                  value: e.target.value
                });
              }}
              onKeyUp={(e) => {
                // 2nd run
                const code = e.which ? e.which : e.keyCode;
                if (code === 8) return;
                this.props.onChange({
                  key: 'payment.number',
                  value: this.getCardNumberFormated(e.target.value)
                });
              }}
              onKeyPress={(e) => {
                if (number.length >= 19) {
                  return e.preventDefault();
                }
              }}
              onFocus={this.handleInputFocus}
              value={number}
            />
            <input type="hidden" value={number.replace(/\s/g, '')} data-encrypt="cardnumber" />
            <Form.Control.Feedback type="invalid">หมายเลขบัตรเครดิตไม่ถูกต้อง.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>ชื่อบนบัตร</Form.Label>
            <Form.Control
              value={name}
              isInvalid={error && !!error.name}
              onChange={this.handleInputChange('payment.name')}
              type="text"
              name="name"
              onKeyUp={this.handleInputChange('payment.name')}
              onFocus={this.handleInputFocus}
            />
            <Form.Control.Feedback type="invalid">กรุณากรอกชื่อบนบัตรเครดิต.</Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>วันหมดอายุ (MM/YYYY)</Form.Label>
                <div className="d-flex flex-row position-relative">
                  <Form.Control
                    as="select"
                    data-encrypt="month"
                    defaultValue="MM"
                    isInvalid={error && (!!error.expires_month || !!error.expires_year || !!error.expires)}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.handleInputFocus({ target: { name: 'expiry' } });
                      this.props.onChange({
                        key: 'payment.expires',
                        value: `${value}/${expires.split('/')[1]}`
                      });
                    }}
                    className={
                      error && (!!error.expires_month || !!error.expires_year || !!error.expires) ? 'expires-error' : ''
                    }
                  >
                    <option values="MM" disabled>
                      MM
                    </option>
                    {this.expMonthOptions.map((m, i) => (
                      <option key={i} values={m}>
                        {m}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid" className="credit-card-expires-error">
                    วันหมดอายุไม่ถูกต้อง.
                  </Form.Control.Feedback>
                  <span className="my-auto px-1"> / </span>
                  <Form.Control
                    as="select"
                    className="m-0"
                    data-encrypt="year"
                    defaultValue="YYYY"
                    isInvalid={error && (!!error.expires_month || !!error.expires_year || !!error.expires)}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.handleInputFocus({ target: { name: 'expiry' } });
                      this.props.onChange({
                        key: 'payment.expires',
                        value: `${expires.split('/')[0]}/${value}`
                      });
                    }}
                  >
                    <option values="YYYY" disabled>
                      YYYY
                    </option>
                    {this.expYearOptions.map((y, i) => (
                      <option key={i} values={y}>
                        {y}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="payment-cvv">
                <Form.Label>
                  รหัส CVC/CVV <img className="cvv-img" src={cvcIcon} height="auto" width="auto" alt="cvc ดูหลังบัตร" />
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="cvc"
                  isInvalid={error && !!error.cvc}
                  onKeyUp={this.handleInputChange('payment.cvc')}
                  onChange={this.handleInputChange('payment.cvc')}
                  onFocus={this.handleInputFocus}
                  value={cvc}
                  data-encrypt="cvv"
                  autoComplete="off"
                />
                <i className="flaticon-lock" />
                <Form.Control.Feedback type="invalid">กรุณากรอกรหัส cvc/cvv.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <div className="margin-bottom-sm">
            <span className="secure-text">
              <i className="flaticon-lock" /> ข้อมูลบัตรทั้งหมดได้รับการป้องกัน เข้ารหัส และปลอดภัย
            </span>
          </div>
          <Cards
            number={number}
            name={name}
            expiry={expires}
            cvc={cvc}
            focused={this.state.focused}
            callback={this.handleCallback}
            acceptedCards={['visa', 'mastercard']}
          />
          <Form.Group className="term-box-container">
            <div className="term__bigbrand flex-center" id="term-checkbox">
              <Form.Check type="checkbox" name="term" onClick={this.props.toggleTermCheckbox} />
              <div>
                {t('term.i_have_credit')} {t('term.and_accept')}
                <span
                  className="term-link"
                  onClick={(e) => {
                    e.preventDefault();
                    this.rentalCondition.toggle('inter');
                  }}
                >
                  {t('term.term_and_policy')}
                  <i className="flaticon-out" />
                </span>
                <span>{t('term.all_term')}</span>
              </div>
            </div>
            <div id="term-error-text">{!this.props.isTermAccept && <small>{t('term.error_text')}</small>}</div>
          </Form.Group>
        </Form>
        <RentalCondition ref={(ref) => (this.rentalCondition = ref)} vendor={vendor} />
      </div>
    );
  }
}
CreditCardForm.defaultProps = {
  number: '',
  name: '',
  expiry: '',
  cvc: '',
  focused: ''
};
