import React from 'react';
import './style.scss';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <svg className="spinner" width="45px" height="45px" viewBox="0 0 66 66">
        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
      </svg>
    </div>
  );
};

export default LoadingSpinner;
