import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReviewsStats, getRatingsReviewsItems, onToggleRatingLevel } from '../../../ducks/actions/reviews';
import RatingsSummaryBox from './summary';
import CommentsBox from './comments-box';
import { LoadingIcon } from '../../atoms/Loading';
import LoadingSVG from '../../../assets/icon/loading.svg';
import { withTranslation } from '../../../locales';
import emptyReviewIcon from '../../../assets/icon/empty_icon.svg';
import './style.scss';
import { dataLayer } from '@utils/datalayer';

class RatingsReviews extends PureComponent {
  componentDidMount() {
    this.fetchReview();
  }
  fetchStats = () => {
    this.props.getReviewsStats(this.ratingReviewRefID, this.props.source, this.props.reviews.isCarLevel);
  };
  fetchReview = (sortBy, orderBy, offset, limit) => {
    this.props.getRatingsReviewsItems(
      this.ratingReviewRefID,
      this.props.source,
      this.props.reviews.isCarLevel,
      sortBy,
      orderBy,
      offset,
      limit
    );
  };
  togleReviewLevel = () => {
    this.props.onToggleRatingLevel().then(() => {
      this.fetchStats();
      this.fetchReview();
    });
  };
  get ratingReviewRefID() {
    const carID = window.location.pathname.split('/')[2];
    const refID = this.props.reviews.isCarLevel ? carID : this.props.branchId;

    return refID;
  }
  render() {
    const { reviews, t } = this.props;
    const { summary } = reviews.currentStats;
    return (
      <React.Fragment>
        {reviews.fetching ? (
          <div className="ratings-reviews-wrapper" style={{ height: 280 }}>
            <div className="text-center">
              <LoadingIcon bg={LoadingSVG} dark />
              <p>{t('review.loading')}</p>
            </div>
          </div>
        ) : (
          <div className="ratings-reviews-wrapper">
            {this.props.source !== 'inter' && (
              <div
                className="level-box"
                data-event-category="product_detail_section"
                data-event-action={this.props.reviews.isCarLevel ? 'untick' : 'tick'}
                data-event-label="only_this_car"
                onClick={(e) => {
                  dataLayer(e);
                  this.togleReviewLevel();
                }}
              >
                <input type="checkbox" checked={reviews.isCarLevel} />{' '}
                <span className="pl-2">{t('review.this_car_only')}</span>
              </div>
            )}
            {reviews.currentStats.total <= 0 ? (
              <div className="empty-box text-center" style={{ height: 200 }}>
                <img src={emptyReviewIcon} width="85" alt="Empty Reviews" />
                {t('review.empty_review_1')} <br />
                {t('review.empty_review_2')}
              </div>
            ) : (
              <React.Fragment>
                {summary.length > 0 && <RatingsSummaryBox summaryData={summary} />}
                {reviews.comments.fetching ? (
                  <div className="text-center">
                    <LoadingIcon bg={LoadingSVG} dark />
                    <p>{t('review.loading')}</p>
                  </div>
                ) : (
                  <CommentsBox fetchReview={this.fetchReview} comments={reviews.comments} t={t} />
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getReviewsStats, getRatingsReviewsItems, onToggleRatingLevel }, dispatch);
};

const mapStateToProps = (state) => ({
  ...state.reviews,
  branchId: state.fetch.details.branch.id,
  vendor: state.fetch.details.vendor
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('car_detail')(RatingsReviews));
