import React, { Component } from 'react';
import queryString from 'query-string';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import CouponBox from '../../molecules/CouponBox';
import modalHoc from '../../hoc/modalHoc';
import FormStep from './FormStep';
import PricingDetails from '../../molecules/PricingDetails';
import AdditionalValueProp from '../../molecules/AdditionalValueProp';
import BookingInfo from '../../molecules/BookingInfo';
import SubmitButton from '../../molecules/SubmitButton';
import CalendarPicker from '../../organisms/CalendarPicker';
import FetchApi from '../../hoc/fetchApi';
import { withTranslation } from '../../../locales';
import { INTER } from '../../../variables/source';
import { LEGACY_WEB } from '../../../variables/API';
import { readCookie } from '@ui/components/utils';
import RegisterBanner from '../../../assets/images/register-promotion-02.jpg';
import { setCookie } from '@ui/components/utils';
import { REGISTER_PROMOTION } from '../../../variables/features';
import './style.scss';
import { dataLayer } from '@utils/datalayer';

const getDateFormat = (source) =>
  ['inter', 'bigbrand'].includes(source) ? 'YYYY-MM-DDTHH:mm:ss+07:00' : 'YYYY-MM-DD HH:mm';

const parsedMoney = (price) => `฿${parseInt(price, 10).toLocaleString()}`;

class DetailsBoxWithBookingSummary extends Component {
  constructor(props) {
    super(props);
    const { query, url } = queryString.parseUrl(this.props.pricingUrl);
    this.state = {
      baseUrl: url,
      pickup_datetime: query.pickup_datetime
        ? moment(query.pickup_datetime)
        : moment()
            .add(1, 'days')
            .hour(10)
            .minutes(0),
      return_datetime: query.return_datetime
        ? moment(query.return_datetime)
        : moment()
            .add(2, 'days')
            .hour(10)
            .minutes(0),
      params: query,
      isEditDateTime: false,
      isTermAccept: false
    };
  }

  componentDidMount = () => {
    const params = queryString.parseUrl(this.props.pricingUrl);
    this.setState({ params: params.query, baseUrl: params.url });
    if (!params.query.pickup_datetime) {
      window.history.pushState(
        null,
        '',
        `/car/${this.state.params.car_id}?${this.parsedParams}${window.location.hash}`
      );
    }
    if (params.query.code) {
      this.props.dispatch({
        type: 'BOOKING_CODE_FIELD_CHANGE',
        payload: {
          value: params.query.code
        }
      });
    }
  };

  toggleTermCheckbox = () => {
    this.setState({ isTermAccept: !this.state.isTermAccept }, () => {
      if (this.state.isTermAccept) {
        document.getElementById('term-checkbox').classList.remove('checkbox-error');
      }
    });
  };

  resetTermCheckbox = () => {
    this.setState({ isTermAccept: false });
  };

  updateUrl = () => {
    window.history.pushState(null, '', `/car/${this.state.params.car_id}?${this.parsedParams}${window.location.hash}`);
    this.props.onDatesChange([this.state.pickup_datetime, this.state.return_datetime]);
  };

  onApplyInsurance = (e) => {
    if (this.state.params.insurance_id === e.target.value) {
      e.target.value = undefined;
      this.setState(
        {
          params: {
            ...this.state.params,
            insurance_id: undefined
          }
        },
        this.updateUrl
      );
    } else {
      this.setState(
        {
          params: {
            ...this.state.params,
            insurance_id: e.target.value
          }
        },
        this.updateUrl
      );
    }
  };

  onApplyCoupon = (code) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          code
        }
      },
      this.updateUrl
    );
  };
  get parsedParams() {
    const parsed = queryString.stringify({
      ...this.state.params,
      pickup_datetime: this.state.pickup_datetime.format(getDateFormat(this.state.params.source)),
      return_datetime: this.state.return_datetime.format(getDateFormat(this.state.params.source))
    });
    return parsed;
  }

  get url() {
    const consumerID = queryString.stringify({
      consumer_id: readCookie('_consumerID')
    });

    return `${this.state.baseUrl}?${this.parsedParams}&${consumerID}`;
  }

  get isInter() {
    return ['inter', 'bigbrand'].includes(this.state.params.source);
  }

  onEditDateTime = (isEdit = true) => {
    if (this.props.step === 0) {
      this.setState({
        isEditDateTime: isEdit
      });
    }
  };
  registerPromotion = () => {
    return (
      <div className="banner-register-promotion">
        <img
          alt="Register Promotion"
          src={RegisterBanner}
          onClick={() => {
            setCookie('_where', window.location.href, 60, '/');
            window.location.href = `${LEGACY_WEB}/th/user/register`;
          }}
        />
      </div>
    );
  };

  render() {
    const { vendor, step, carTitle, carAvailableFor, badges, t, lang } = this.props;
    const { isEditDateTime } = this.state;

    return (
      <React.Fragment>
        <FetchApi url={this.url}>
          {(data) => {
            return (
              <div>
                <MediaQuery minWidth={769}>
                  <p id="call_center_tel">
                    {t('call')} <a href="tel:+6620385222">02-038-5222</a>
                  </p>
                </MediaQuery>
                {step === 0 && REGISTER_PROMOTION && this.registerPromotion()}
                <div className="details_box_wrapper">
                  {step === 0 ? (
                    <CalendarPicker
                      pickup_datetime={this.state.pickup_datetime}
                      return_datetime={this.state.return_datetime}
                      location_name={data.location.th}
                      onChange={([pickup_datetime, return_datetime]) =>
                        this.setState({ pickup_datetime, return_datetime }, this.updateUrl)
                      }
                      onEditDateTime={this.onEditDateTime}
                      isEditDateTime={isEditDateTime}
                      isDisableChangeDateTime={step !== 0 ? true : false}
                    />
                  ) : (
                    <BookingInfo
                      carTitle={carTitle}
                      location={data.location[lang]}
                      pickupDatetime={this.state.pickup_datetime}
                      returnDatetime={this.state.return_datetime}
                    />
                  )}
                  <PricingDetails
                    source={this.state.params.source}
                    onApplyInsurance={this.onApplyInsurance}
                    pricing={{
                      delivery_price: 0,
                      pickup_price: 0,
                      deposit: 0,
                      ...data,
                      discount_code: this.state.params.code,
                      insurance_id: this.state.params.insurance_id
                    }}
                    isOnlyShowTotalPrice={step !== 0}
                  />
                  <CouponBox
                    code={this.state.params.code}
                    onApplyCoupon={this.onApplyCoupon}
                    discount_amount={data.discount_amount}
                    campaign_name={data.campaign_name ? data.campaign_name : undefined}
                    is_code_valid={data.is_code_valid}
                    isTyping={this.state.params.code !== this.props.code}
                  />
                  {this.state.params.source && Number(data.deposit) !== 0 ? (
                    <div className="price_wrapper">
                      <div className="panel-info">
                        <div className="row no-border pb-0">
                          <div className="col-auto mr-auto">
                            <b style={{ fontSize: 13 }}>
                              <i className="flaticon-shield" />
                              {this.state.params.source === INTER ? t('deposit_inter.title') : t('deposit.title')}
                            </b>
                          </div>
                          <div className="col-auto" style={{ textAlign: 'right', paddingRight: 10 }}>
                            <b>{parsedMoney(data.deposit || 0)}</b>
                          </div>
                        </div>
                        <div className="row no-border pt-0">
                          <div className="col-auto mr-auto pr-0">
                            <small>
                              {this.state.params.source === INTER ? (
                                <span>
                                  {t('deposit_inter.content_1')}
                                  <br />
                                  {t('deposit_inter.content_2')}
                                </span>
                              ) : (
                                t('deposit.content')
                              )}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {step < 2 && (
                    <div className="mt-3">
                      <AdditionalValueProp source={this.state.params.source} badges={badges} />
                    </div>
                  )}
                  {step > 0 && <hr />}
                  <FormStep
                    t={t}
                    toggleTermCheckbox={this.toggleTermCheckbox}
                    vendor={vendor}
                    isTermAccept={this.state.isTermAccept}
                  />
                  {!this.isInter ? (
                    step > 0 ? (
                      <p className="term_text">
                        {t('term.i_have_read')}
                        <a
                          href="https://www.drivehub.co/th/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-event-category="bookings_section"
                          data-event-action="go_to"
                          data-event-label="terms"
                          onClick={(e) => dataLayer(e)}
                        >
                          {t('term.term_and_policy')} <i className="flaticon-out" />
                        </a>
                        {t('term.in_this_booking')}
                      </p>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  <SubmitButton
                    carTitle={carTitle}
                    carAvailableFor={carAvailableFor}
                    data={data}
                    available={data.available}
                    isEditDateTime={this.state.isEditDateTime}
                    isCouponApplied={this.props.code ? this.props.code === this.state.params.code : true}
                    isTermAccept={this.state.isTermAccept}
                    resetTermCheckbox={this.resetTermCheckbox}
                  />
                  <BookWithDrivehub t={t} />
                </div>
              </div>
            );
          }}
        </FetchApi>
      </React.Fragment>
    );
  }
}

const BookWithDrivehub = ({ t }) => {
  return (
    <div id="book_with_drivehub">
      <i className="flaticon-lock" />
      <span>{t('secure.book_with_drivehub')}</span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  step: state.booking.step,
  code: state.booking.code
});

export default withTranslation('common', 'summary')(connect(mapStateToProps)(modalHoc(DetailsBoxWithBookingSummary)));
